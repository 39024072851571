import React from 'react'
import './style.css'
import PageLog from '../PageLog/PageLog'

const Travel = () => {
  return (
    <div className='total'>
      <PageLog/>

      <div className='dochead'>
        <h2> Explore Kerala</h2>
      <img   src="../images/Place/travel.png" />
      <h6>
      Kerala, a state on India's tropical Malabar Coast, has nearly 600km of Arabian Sea shoreline. It's known for its palm-lined beaches and backwaters, a network of canals. Inland are the Western Ghats, mountains whose slopes support tea, coffee and spice plantations as well as wildlife. National parks like Eravikulam and Periyar, plus Wayanad and other sanctuaries, are home to elephants, langur monkeys and tigers.
      </h6>
     
      </div>
    </div>
  )
}

export default Travel
