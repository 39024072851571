import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css'



const UserContent = (props) => {
// const EventContent = ({vend:{DOC_ID,DOC_TITL,DOC_DESC,DOC_DET,DOC_CATEGRY,DOC_PUBDATE,DOC_SUBCATEGRY,DOC_URL,image}}) => {

    const navigate= useNavigate()

    function goToUserContentDetails(){
     navigate("/UserContentDetails",{state : {id : props.item.DOC_ID}})
    }

  return (
    <>
    
    <div className='box'> 
         <div className='ima'>
         <img src={props.item.image ? props.item.image : props.data.image_url} alt='' />
        </div>
             <h3 className='tittle' onClick={goToUserContentDetails}>{props.item.DOC_TITL}</h3>
             
    </div>
    
    </>
  )
}

export default UserContent

