import{React,useState,useEffect} from 'react'
import axios from 'axios';
import '../style.css'


const Heros = () => {
  const [heros,SetHeros]=useState([])

  useEffect(() => { 
    axios.post('/myblock/mycity/heros')
.then(function (response) {
console.log(response.data);
SetHeros(response.data)
})
} ,[]);

  return (
    < div className='total'>
    <div className='dochead'>
        <h2>Heros in Kochi</h2>
    </div>
    <div className='herocontain'>
     {heros.map((item)=>{
                    return (
                      <div>
                        <img className='heroimg' src='../images/heros/heros.png' />
                        <h3>
                        First Name:{item.firstname}
                        </h3>
                        <h3>Last Name:{item.lastname}</h3>
                        
                        <h3>
                        Resume_id : {item.resume_id}
                        </h3>
                        <h3>
                        Specialization: {item.specialization}
                        </h3>
                        <h6>
                        {item.DESCRIPTION}
                        </h6>
                        
                         </div>
                      )})}
     </div> 
      
    </div>
  )
}

export default Heros
