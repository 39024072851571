import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

function PageLog() {

    const userid = Cookies.get('userid') || null;
    const firmid = Cookies.get("firmid") || null;
    const portalid = Cookies.get('portalid') || null;
    const visitedBy =Cookies.get('getEmailid') || null;

    useEffect(() => {       
            const currentURL = window.location.href;
            console.log('Current URL:', currentURL);

            const userLocale = navigator.language || navigator.userLanguage;
            //const userLocale = null;
            console.log('User Locale:', userLocale);

            const browser = navigator.userAgent;
            ///const browser = null;
            console.log("browser", browser)

           // debugger
            axios.post('/page-log-ip', { currentURL, userLocale, browser, "latitude": null, "longitude": null,userid,firmid,portalid,visitedBy})
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('Error logging IP:', error);
                });
        
}, []);

    return (
        <div>          
                <></>         

        </div>
    )
}

export default PageLog