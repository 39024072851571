import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './spotlightpage.css'


const SpotlightDetails = () => {
  const location = useLocation();
  console.log("state", location.state)
  let spotid = location.state.id
  let site = sessionStorage.getItem("site")

  const [spotdata1, setSpotData1] = useState([])
  const [spotdata2, setSpotData2] = useState([])


  useEffect(() => {
    axios.all([
      axios.post('/news/headlines/Spotlight/details', { "id": spotid }),
      axios.post('/news/headlines/Spotlight/techieindex/details', { "id": spotid })
    ])

      .then(axios.spread((data1, data2) => {
        console.log("spot", data1.data);
        setSpotData1(data1.data)
        setSpotData2(data2.data)
      }));

  }, []);





  if (site === "Myblock")
    return (
      <div className='pages'>


        {spotdata1.map((spot) => {
          return (<div className='eventpage'>
            <img src={spot.image} alt='' />
            <h3>{spot.DOC_TITL}</h3>
            <h6>{spot.DOC_DESC}</h6>
            <h6>{spot.DOC_DET}</h6>
            <a href={spot.DOC_URL}>
              <button>More</button>
            </a>
          </div>
          )
        })}
      </div>
    )
  if (site === "Techie-Index")
    return (
      <div className='pages'>


        {spotdata2.map((spot) => {
          return (<div className='eventpage'>
            <img src={spot.photo} alt='' />
            <h3>{spot.companyName}</h3>
            <h6>{spot.aboutPerson}</h6>

          </div>
          )
        })}
      </div>
    )



}

export default SpotlightDetails
