import { React, useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css'
import axios from 'axios';
import PageLog from '../PageLog/PageLog';


const Doctors = () => {

  let getPortal = sessionStorage.getItem("portalid")
  console.log("portal", getPortal);
  let getCity = sessionStorage.getItem("city")
  const [data, setData] = useState([])

  let id = sessionStorage.getItem("portalid", "****")

  useEffect(() => {
    axios.post('/citylocal/vendor/doctordetails', { "id": getPortal })
      .then(function (response) {
        console.log("restarant details", response.data);
        setData(response.data)
      })
  }, []);


  return (

    < div className='total'>
      <div className='dochead'>
      <PageLog/>

        <h5 class="fa-solid fa-user-doctor">Best doctors in {getCity}</h5>
      </div >

      <Container>
        <Row xs="auto" md={3}>
          {data.map((portal) => {
            return (
              <Col md={{ span: 2, offset: 3 }}>
                <Card key={portal.id}>
                  <Card.Body>
                    <Card.Img className='docimg' variant="top" src={portal.image} />
                    <Card.Title>{portal.VEND_TITL}</Card.Title>

                    <Card.Text>
                      {portal.VEND_DET}
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
            );

          })
          }
        </Row>
      </Container>
    </div >
  )
}

export default Doctors
