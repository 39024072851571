import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddedProbs1.css';
import QuestionDetailsPopup from './QuestionDetailsPopup';
import Cookies from 'js-cookie';

const AddedProbs = () => {
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [pageSize] = useState(4); // Number of items to display per page
  const getCity = sessionStorage.getItem("city");
  const portalid = Cookies.get('portalid');
  

  const fetchAddedQuestions = async () => {
    try {
      const response = await axios.get('/get-added-questions', {
        headers: {
          'Portalid': portalid,
        },
        params: {
          page: currentPage,
          pageSize: pageSize,
        },
      });

      if (response.status === 200) {
        setAddedQuestions(response.data);
      } else {
        console.error('Failed to fetch added questions from the backend');
      }
    } catch (error) {
      console.error('Error fetching added questions from the backend:', error);
    }
  };

  useEffect(() => {
    fetchAddedQuestions();
  }, [currentPage]); // Fetch data when the page changes

  const handleMoreClick = (question) => {
    setSelectedQuestion(question);
  };

  const handleClosePopup = () => {
    setSelectedQuestion(null);
  };

  const handleNextPage = () => {
    // Check if there is data in the next page
    if (addedQuestions.length === pageSize) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  

  return (
    <div className='added-questions-container'>
      <h3>Recently Added Issues in {getCity}</h3>
      <ul>
        {addedQuestions.map((question) => (
          <li key={question.PRO_ID}>
            {/* <span>{question.ADDED_DATE}</span> */}
            <span> {new Date(question.ADDED_DATE).toLocaleString()}</span>
            <button onClick={() => handleMoreClick(question)}>More</button>
            {question.QUESTIONS ? question.QUESTIONS : question.TYPED_QUESTIONS}
          </li>
        ))}
      </ul>
      <div className="pagination-buttons">
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>{'<'}</button>
      <span>{currentPage}</span>
      <button onClick={handleNextPage}>{'>'}</button>
    </div>
      {selectedQuestion && (
        <QuestionDetailsPopup
          question={selectedQuestion}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default AddedProbs;
