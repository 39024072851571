import React,{useState,useEffect} from 'react'
import ReactEcharts from "echarts-for-react"; 


export const Injured = () => {
  // importing the data
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetch("http://localhost:3000/message")
      .then((response) => response.json())
      .then((data) => setMessage(data));
  }, []);
  console.log(message)
    const option = {
        xAxis: {
          type: 'category',
          data: ['2014', '2015', '2016', '2017']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [message[4], message[5], message[6], message[7]],
            type: 'bar'
          }
        ]
      }; 
      return <ReactEcharts option={option} />;
}
