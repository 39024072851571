import React from 'react';
import './BusinessHome.css'; // Import your CSS file for styling
import PageLog from '../../PageLog/PageLog';

function BusinessHome() {
  return (
    <div className="business-home-container">
      <PageLog/>

      {/* <img
        className="background-image"
        src="./images/background.jpg"
        alt="Background"
      /> */}
      <div class="health-content">
      <nav className="business-nav">
        <a href="Addkfdocmnt" className="business-nav-a">Add Your Items</a>
        <a href="Addvendor" className="business-nav-a">Add Business</a>
      </nav>
      </div>
    </div>
  );
}

export default BusinessHome;
