import React from 'react'
import './login.css';

function LoginHeader() {

    let getSite = sessionStorage.getItem("site")
    return (
        <div>
            <nav className="w3-sidebar w3-bar-block w3-white w3-card w3-animate-left w3-xxlarge" style={{ display: 'none', zIndex: 2 }} id="mySidebar">
                <a href="javascript:void(0)" onclick="w3_close()" className="w3-bar-item w3-button w3-display-topright w3-text-teal">Close
                    <i className="fa fa-remove" />
                </a>
                <a href="#" className="w3-bar-item w3-button">Link 1</a>
                <a href="#" className="w3-bar-item w3-button">Link 2</a>
                <a href="#" className="w3-bar-item w3-button">Link 3</a>
                <a href="#" className="w3-bar-item w3-button">Link 4</a>
                <a href="#" className="w3-bar-item w3-button">Link 5</a>
            </nav>
            {/* Navbar */}
            <div className="w3-top">
                <div className="w3-bar w3-blue w3-left-align"> {/* Change the class to w3-blue for blue color */}
                    <a className="w3-bar-item w3-button w3-hide-medium w3-hide-large w3-right w3-hover-white w3-blue" href="javascript:void(0);" onclick="openNav()"><i className="fa fa-bars" /></a>
                    {/* Add your logo here */}
                    {/* <img src="/images/login/myblocks final1.png" alt="MyBlocks Logo" className="w3-bar-item w3-hide-small" style={{ height: '40px', marginTop: '8px' }} /> */}

                    {/* <img src="/images/login/logo-color.png" className="w3-bar-item w3-hide-small"  style={{ height: '250px', marginTop: '8px' }} /> */}

                    <img src="/images/login/logo.png" className="w3-bar-item w3-hide-small"  style={{ height: '100px', marginTop: '8px' }} />

                    <a href="/" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Home</a>
                    {/* <a href="/InterviewMainPage" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Interview</a> */}
                    <a href="/interviewspotlight" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Interview</a>                    
                    <a href="/AllSpotlights" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Spotlights</a>
                    <a href="/AllEvents" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Events</a>
                    <a href="/AllVendors" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Business</a>
                    <a href="/AllLeaders" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Leaders</a>
                    <a href="/Extra" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Extra</a>
                    <a href={getSite === "Techie-Index" ? "/profile" : "/Aboutus"} className="w3-bar-item w3-button w3-hide-small w3-hover-white">About us</a>
                    {/* Sub Navbar 1 */}
                    <div className="w3-bar w3-bar-actport w3-blue w3-right-align" >
                        <a href="/ActivityPortal" className="w3-bar-item w3-button">ACT PORTAL</a>
                        {/* <a href="/air" className="w3-bar-item w3-button">AIR INDEX</a>
                        <a href="/waterindex" className="w3-bar-item w3-button">WATER INDEX</a> */}
                        <a href="/afterLogin" className="w3-bar-item w3-button">COMMUNITY INDEX</a>
                    </div>
                    {/* <a href="/login" className="w3-bar-item w3-button w3-hide-small w3-right w3-hover-teal">Login</a> */}
{/* <div><br/><br/></div> */}
                </div>
                {/* Navbar on small screens */}
                {/*<div id="navDemo" className="w3-bar-block w3-theme-d2 w3-hide w3-hide-large w3-hide-medium">
    <img src="/images/login/myblocks final1.png" alt="MyBlocks Logo" className="w3-bar-item w3-hide-small" style={{ height: '40px', marginTop: '8px' }} />
    <a href="/" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Home</a>
    <a href="/interviewspotlight" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Interview</a>
                    <a href="/AllSpotlights" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Spotlights</a>
                    <a href="/AllEvents" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Events</a>
                    <a href="/AllVendors" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Business</a>
                    <a href="/AllLeaders" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Leaders</a>
                    <a href="/Extra" className="w3-bar-item w3-button w3-hide-small w3-hover-white">Extra</a>
                    <a href="/About" className="w3-bar-item w3-button w3-hide-small w3-hover-white">About us</a>
                </div> */}

                 <div id="navDemo" className="w3-bar-block w3-bar w3-blue w3-hide w3-hide-large w3-hide-medium">
                    <img src="/images/login/myblocks final1.png" alt="MyBlocks Logo" className="w3-bar-item w3-hide-small" style={{ height: '40px', marginTop: '8px' }} />
                    <a href="/" className="w3-bar-item w3-button">Home</a>
                    {/* <a href="/InterviewMainPage" className="w3-bar-item w3-button">Interview</a> */}
                    <a href="/interviewspotlight" className="w3-bar-item w3-button">Interview</a>
                    
                    <a href="/AllSpotlights" className="w3-bar-item w3-button">Spotlights</a>
                    <a href="/AllEvents" className="w3-bar-item w3-button">Events</a>
                    <a href="/AllVendors" className="w3-bar-item w3-button">Business</a>
                    <a href="/AllLeaders" className="w3-bar-item w3-button">Leaders</a>
                    <a href="/Extra" className="w3-bar-item w3-button">Extra</a>
                    <a href="/About" className="w3-bar-item w3-button">About us</a>
                    </div> 
            </div>
            {/* Sub Navbar 1 */}
            <div id="navDemo" className="w3-bar-block w3-bar w3-blue w3-hide w3-hide-large w3-hide-medium">
                <a href="/ActivityPortal" className="w3-bar-item w3-button">ACT PORTAL</a>
                {/* <a href="/air" className="w3-bar-item w3-button">AIR INDEX</a>
                <a href="/waterindex" className="w3-bar-item w3-button">WATER INDEX</a> */}
                <a href="/afterLogin" className="w3-bar-item w3-button">COMMUNITY INDEX</a>
            </div>

        </div>
    )
}

export default LoginHeader