import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//import NewMainPage from '../Home/NewMainPage/NewMainPage';
import Cookies from 'js-cookie';
import axios from 'axios';

function LoginFromHtml() {
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const username = query.get('uname');
    const password = query.get('pwd');

    const getEmailid = Cookies.get("getEmailid")
    const getPassword = Cookies.get("passwordData")
    const portalid = Cookies.get("portalid")
    const getMessage = Cookies.get("message")
    const [login, setLogin] = useState([])


    useEffect(() => {


        axios.post("/login/details", { "username": username, "password": password })
            .then((response) => {
                ;
                console.log(response.data.USERNAME)
                setLogin(response.data)


            }).catch((error) => {
                console.error("An error occurred during login:", error);

            });


    }, []);

    const cityLink = window.location.origin + `/CityFromId/${portalid}`;

    const openPage = () => {
        window.location.href = cityLink;
    }


    return (
        <div>
            {login.map((login) => {

                Cookies.set('userid', login.USER_ID);
                Cookies.set('getEmailid', login.USERNAME);
                Cookies.set('passwordData', true);
                Cookies.set('portalid', login.HOME_PORTALID);
                Cookies.set('mainpagecookie', 0);
                window.location.reload();
                return <div key={login.USERNAME}></div>;
            })}


            {getEmailid && getPassword ?
                (
                    openPage()
                ) :
                (
                    <div >
                       
                    </div>


                )}
        </div>
    );
}

export default LoginFromHtml