import React, { useEffect, useState } from 'react';
import axios from "axios";
import "./insertemails.css";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom"; // Import useNavigate

const InsertEmail = () => {
  // Define state for form data

  const firmid = Cookies.get("firmid")
  const userid = Cookies.get("userid")
  const usertype = Cookies.get("usertype")

  const navigate = useNavigate(); // Initialize useNavigate for page redirection


  const [emailData, setEmailData] = useState({
    EMAIL: "",
    PASSWORD: "",
    HOST: "",
    PORT: "",
    SECURE: "true",
    USERID: userid,
    FIRMID: firmid,
  });

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } else if (usertype === "USERAPP") {
      alert("Login with Businuess User Credentials");
      navigate('/login');
    }
  }, [userid, navigate]);

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Log form data before submission (optional)
    console.log("Form data submitted:", emailData);

    axios
      .post("/insert-email", emailData)
      .then((response) => {
        //alert("Data inserted successfully!");
       

        const confirmAddMore = window.confirm("Data added successfully. Would you like to add more ?");
        if (confirmAddMore) {
          setEmailData({
            EMAIL: "",
            PASSWORD: "",
            HOST: "",
            PORT: "",
            SECURE: "true",
            USERID: userid,
            FIRMID: firmid,
          });
        } else {
          navigate("/bulkemailhome"); // Navigate to the home page
        }
  
      })
      .catch((error) => {
        console.error("Error inserting data:", error);
      });
  };

  return (
    <div className="insert-email-container">
      <h2>Insert Email Data</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="EMAIL"
            value={emailData.EMAIL}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            name="PASSWORD"
            value={emailData.PASSWORD}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Host:</label>
          <input
            type="text"
            name="HOST"
            value={emailData.HOST}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Port:</label>
          <input
            type="number"
            name="PORT"
            value={emailData.PORT}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Secure:</label>
          <select
            name="SECURE"
            value={emailData.SECURE}
            onChange={handleChange}
            required
          >
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        </div>
        <button type="submit" className="insert-button">Submit</button>
      </form>
    </div>
  );
};

export default InsertEmail;
