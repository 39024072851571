import React from 'react'
import './trendshome.css'

function TrendsHome() {
    return (
        <div className="trends-home-container">
          

            {/* <img
            className="background-image"
            src="./images/background.jpg"
            alt="Background"
          /> */}
            <div class="health-content">
                <nav className="business-nav">
                    <a href="/trends" className="business-nav-a">Check Trends</a>
                    <a href="/trendsfollowercount" className="business-nav-a">Followers Count of Top Hotels</a>
                </nav>
            </div>
        </div>)
}

export default TrendsHome