

import React, { useEffect, useState } from 'react';
import './interview.css'



function Interviewlief_pedersen() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content from the same server
    fetch('/Interview/lief_pedersen.html')
      .then(response => response.text())
      .then(data => setHtmlContent(data));
  }, []);

  return (
    <div className='interview-container'>
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  
    </div>
  );
}

export default Interviewlief_pedersen;