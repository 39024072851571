import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import PageLog from '../../PageLog/PageLog';

function IjobsFromName() {

    const { country } = useParams();


    var currentUrl = window.location.href;
    var baseUrl = currentUrl.split("/")[2]; // Get the domain (e.g., "example.com")
    var newUrl = window.location.protocol + "//" + baseUrl + "/techieindexlatest";


    useEffect(() => {

        if (baseUrl === 'techie-index.com' || baseUrl === 'www.techie-index.com') {

            if (country === 'us') {

                sessionStorage.setItem("portalid", 10226)
                sessionStorage.setItem("city", "ijobs-USA")
                sessionStorage.setItem("parentportalid", 10078)
                window.location.href = newUrl;

            }

            if (country === 'uk') {
                sessionStorage.setItem("portalid", 10227)
                sessionStorage.setItem("city", "ijobs-UK")
                sessionStorage.setItem("parentportalid", 10078)
                window.location.href = newUrl;

            }

            if (country === 'ca') {
                sessionStorage.setItem("portalid", 10228)
                sessionStorage.setItem("city", "ijobs-CANADA ")
                sessionStorage.setItem("parentportalid", 10078)
                window.location.href = newUrl;

            }
            if (country === 'in') {
                sessionStorage.setItem("portalid", 10225)
                sessionStorage.setItem("city", "ijobs-INDIA")
                sessionStorage.setItem("parentportalid", 10078)
                window.location.href = newUrl;

            }

            if (country === 'de') {
                sessionStorage.setItem("portalid", 10229)
                sessionStorage.setItem("city", "ijobs-GERMANY")
                sessionStorage.setItem("parentportalid", 10078)
                window.location.href = newUrl;

            }
        }


    }, []);



    return (
        <><PageLog/></>
    )
}

export default IjobsFromName