import React, { useState } from 'react'
//import './login.css';


function LoginFooter() {
  
  return (
    <div>

<div className="w3-row-padding w3-padding-32 w3-bar w3-blue" id="work">
              <div className="w3-row-padding w3-center">
                <h2>Get the Most out of Your City with myblocks</h2>
                <p> MyBlock is a media company with the motto "Happy Living" as our guiding principle. Our primary mission is to empower individuals in every community to lead happier and healthier lives. MyBlock device gathers data from communities to generate customized notifications for local residents. Our AI-powered products then transform the data into valuable business insights.</p>
              </div>
              {/* Team Container */}
              <div className="w3-container w3-padding-32 w3-center" id="team">
                <h2>Connect with your Community</h2>
                <p>Happy Living:</p>
                <div className="w3-row"><br />
                  <div className="w3-third">
                    <img src="/images/login/360_F_484903867_KSrGbISoFdHxxxQXfsjB0EfUoendZ8UZ.jpg" alt="Boss" style={{ width: '45%' }} className="w3-circle w3-hover-opacity" />
                    <h3>User</h3>
                    <p>Relevant news and information from towns, businesses, and public agencies in real time.</p>
                  </div>
                  <div className="w3-third">
                    <img src="/images/login/pngtree-building-icon-isolated-on-abstract-background-png-image_1746590.jpg" alt="Boss" style={{ width: '45%' }} className="w3-circle w3-hover-opacity" />
                    <h3>Business</h3>
                    <p>The only way to instantly connect to the people, businesses, and happenings near your home.</p>
                  </div>
                  <div className="w3-third">
                    <img src="/images/login/istockphoto-585325854-612x612.jpg" alt="Boss" style={{ width: '45%' }} className="w3-circle w3-hover-opacity" />
                    <h3>Volunteer</h3>
                    <p>A secure environment where all peoples are verified.</p>
                  </div>
                </div>
              </div>
              {/* Container */}
              <div className="w3-container" style={{ position: 'relative' }}>
                <a onclick="w3_open()" className style={{ position: 'absolute', top: '-28px', right: '24px' }} />
              </div>
             
              {/* Contact Container */}
              <div className="w3-container w3-padding-32 w3-theme-l5" id="contact">
                <div className="w3-row">
                  {/* First Address */}
                  <div className="w3-col s6">
                    <div className="w3-padding-16"><span className="w3-xlarge w3-border-teal w3-bottombar">Contact Us</span></div>
                    <p>S2V IT Park, 826/2 Second Floor,</p>
                    <p>Kalapurackal Rd, Kochi-682021,</p>
                    <p>Kerala, India</p>
                    <p><i className="fa fa-phone w3-text-teal w3-xlarge" /> +91 0484-2955379</p>
                    <p><i className="fa fa-envelope-o w3-text-teal w3-xlarge" /> info@myblocks.in</p>
                  </div>
                  {/* Second Address */}
                  <div className="w3-col s6">
                    <br /> <br /> <br />
                    <p>4225 129th PL, Se No-4</p>
                    <p>Bellevue, WA-98006</p>
                    <p>USA</p>
                    <p><i className="fa fa-phone w3-text-teal w3-xlarge" /> +1 847-261-4361</p>
                    <p><i className="fa fa-envelope-o w3-text-teal w3-xlarge" /> info@myblocks.in</p>
                  </div>
                </div>
              </div>
              {/* Image of location/map */}
              {/* <img src className="w3-image w3-greyscale-min" style={{ width: '100%' }} /> */}
              {/* Footer */}
              <footer className="w3-container w3-padding-16 w3-theme-d1 w3-center" style={{ backgroundColor: '#ADD8E6' }}>
                <h4>Follow Us</h4>
                <a className="w3-button w3-large w3-teal" href="javascript:void(0)" title="Facebook"><i className="fa fa-facebook" /></a>
                <a className="w3-button w3-large w3-teal" href="javascript:void(0)" title="Twitter"><i className="fa fa-twitter" /></a>
                <a className="w3-button w3-large w3-teal" href="javascript:void(0)" title="Google +"><i className="fa fa-google-plus" /></a>
                <a className="w3-button w3-large w3-teal" href="javascript:void(0)" title="Google +"><i className="fa fa-instagram" /></a>
                <a className="w3-button w3-large w3-teal w3-hide-small" href="javascript:void(0)" title="Linkedin"><i className="fa fa-linkedin" /></a>
                <div style={{ position: 'relative', bottom: '100px', zIndex: 1 }} className="w3-tooltip w3-right">
                  <span className="w3-text w3-padding w3-teal w3-hide-small">Go To Top</span>
                  <a className="w3-button w3-theme" href="#myPage"><span className="w3-xlarge">
                    <i className="fa fa-chevron-circle-up" /></span></a>
                </div>
              </footer>
            </div>
    </div>
  )
}

export default LoginFooter;