// QuestionDetailsPopup.js
import React from 'react';
import './QuestionDetailsPopup.css';
import Cookies from 'js-cookie';

const QuestionDetailsPopup = ({ question, onClose }) => {
  const username = Cookies.get('name');

  console.log("question",question)
  return (
    <div className="question-details-popup">
      <div className="popup-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h3>More Details</h3>
        <p><strong>Problem:</strong> {question.QUESTIONS}</p>
        <p>reported by :  {question.NAME}</p>
        {/* <p><strong>Added Date and Time:</strong> {question.ADDED_DATE}</p> */}
        <p><strong>Added Date and Time:</strong> {new Date(question.ADDED_DATE).toLocaleString()}</p>
        <p><strong>Problem Details:</strong> {question.TYPED_QUESTIONS}</p>

        
      </div>
    </div>
  );
};

export default QuestionDetailsPopup;
