import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

// Card importing
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//importing Components
import Links from "../../Links";
import StateOption from './StateOption/StateOption'

//importing Chart
import { Chart1 } from "./Chart1";
import Chart2 from "./Chart2";
import { Injured } from "./Injured";
import Percentage from "./Percentage";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Accidents() {
  return (
    <>
      <Links/>
      <Box sx={{ width: "100%" }} style={{ marginTop: "80px" }}>
        <StateOption/>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Item>
              <Card>
                <CardContent>
                  <Typography variant="h4">
                    Total Killed in Andhra Pradesh
                  </Typography>
                  <Chart1 />
                </CardContent>
              </Card>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Card>
                <CardContent>
                  <Typography variant="h4">
                    Total Injured in Andhra Pradesh
                  </Typography>
                  <Injured />
                </CardContent>
              </Card>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Card>
                <CardContent>
                  <Typography variant="h4">Killed vs Injured</Typography>
                  <Chart2 />
                </CardContent>
              </Card>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Card>
                <CardContent>
                  <Typography variant="h4">Killed And Injured Percentage</Typography>
                  <Percentage/>
                </CardContent>
              </Card>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
