import React from 'react'
import './style.css'
import PageLog from '../PageLog/PageLog'

const Placetovisit = () => {
  return (
    <div className='total'>
      <PageLog/>

      <div className='dochead'>
        <h2>Munnar</h2>
      <img  className='imgs' src="../images/Place/place1.png" />
      <h6>

      Munnar is a town in the Western Ghats mountain range in India’s Kerala state. A hill station and former resort for the British Raj elite, it's surrounded by rolling hills dotted with tea plantations established in the late 19th century. Eravikulam National Park, a habitat for the endangered mountain goat Nilgiri tahr, is home to the Lakkam Waterfalls, hiking trails and 2,695m-tall Anamudi Peak
      Munnar is a beautiful hill station, and was a summer resort for the British during the British Raj.

The town is divided into two parts: Old Munnar, where the tourist information office is, and Munnar, where the bus station and most guest houses are located.

The region in and around Munnar varies in height from 1,500 m (4,900 ft) to 2,695 m (8,842 ft) above mean sea level. It is pronounced "Moon-aar" short for "moonu aar" meaning 3 rivers. Three rivers - Madupetti, Nallathanni and Periavaru — flow through this town, but join some distance away from Munnar.

Munnar is famous for its tea plantations, many of them started by the British. The "Kannan Devan" brand of tea from the Tata is cultivated and processed here.

The strobilanthus ("neelakurinji" in Malayalam, the local language), a blue flower which blooms only every 12 years, is found in this region. It last bloomed from August to October 2018. The Eravikulam National Park is the main Neelakurinji flowering area and the number of tourists allowed in the park during the blooming period was limited to 2,750 a day. Munnar also has the highest peak in South India, Anamudi, at 2,695 m.

Eravikulam National Park is one of the main tourist attractions, where you can find the endangered species of Nilgiri Tahr (mountain goat called "varayadu" in Malayalam). A trek to Rajamala ("King of hills" in Malayalam), in the morning is sure to spot a few friendly mountain goats. You can also find gaur, langur, lion-tailed macaque, and elephants depending on the season and time of travel. The altitude is 1,600 m- 2,000 m above sea level.

Climate
The season between October and March (post-monsoon) is considered the best to visit Munnar. Take some warm clothes and rain coats along with you. The temperature ranges from 14 to 25 degrees during summer, and 7 to 17degrees in winter. The atmosphere remains cool and pleasant throughout the year.


Wild elephants in Munnar

Nilgiri Tahr
Talk
Malayalam and Tamil are the main languages spoken here. Visitors often feel uncomfortable while talking with local people as they only talk in regional language. However, you can manage with English as people are highly educated here and they often communicate in English      
      </h6>
      </div>
    </div>
  )
}

export default Placetovisit
