import React, { useRef, useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

function BasicAppLogin() {


    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const password = query.get('password');

    const decodedid = id ? decodeURIComponent(id) : null;
    const decodedpassword = password ? decodeURIComponent(password) : null;    

    const usertype = query.get('usertype');

    //console.log("password",password)
    //console.log("usertype",usertype)

    //console.log("usertype",usertype)



    const getEmailid = Cookies.get("getEmailid")
    let portalid = Cookies.get("portalid")
    const getPassword = Cookies.get("passwordData")
    const navigate = useNavigate()
    const [login, setLogin] = useState([])

    useEffect(() => {

        const apiEndpoint = usertype === "Business" ? "/business/login/details" : usertype === "Admin" ? "/admin/login/details" : "/login/details";
        //console.log("apiEndpoint", apiEndpoint)
        //debugger

        axios.post(apiEndpoint, { "username": decodedid, "password": decodedpassword })
            .then((response) => {

                console.log(response.data.USERNAME)
                console.log(response.data.length)
                if (response.data.length === 0) {

                    alert("Incorrect Username or Password")
                }
                setLogin(response.data)


            }).catch((error) => {
                console.error("An error occurred during login:", error);

            });
    }, [decodedid, decodedpassword, usertype]);

    //const cityLink = window.location.origin + `/CityFromId/${portalid}`;

    return (
        <div>
            Loading...

            {login.map((login) => {

                if (usertype === "Business" || usertype === "Admin" ) {
                    Cookies.set("firmid", login.FIRM_ID);
                    Cookies.set("vend_id", login.FIRM_ID);
                    Cookies.set('getEmailid', login.USERNAME);
                    Cookies.set('passwordData', true);
                    Cookies.set('userid', login.EMPID);
                    Cookies.set('role_id', login.Employee_Level);
                    Cookies.set('username', login.EMPNAME);
                    Cookies.set('portalid', login.HOME_PORTALID);
                    Cookies.set('facebook_status', 0);
                    Cookies.set('category', login.CATEGORY);
                    Cookies.set('adminuser', login.ADMIN_USER);
                    // debugger
                    // Navigate based on user type
                    if (usertype === "Business") {
                        Cookies.set("usertype", "BUSINESSAPP");
                        navigate('/businessuserhome');
                    } else if (usertype === "Admin") {
                        Cookies.set("usertype", "ADMINAPP");
                        navigate('/adminuserhome');
                    }
            
                } else if (usertype === "Basic") {

                    Cookies.set('userid', login.USER_ID);
                    Cookies.set('getEmailid', login.USERNAME);
                    Cookies.set('passwordData', true);
                    Cookies.set('portalid', login.HOME_PORTALID);
                    Cookies.set('mainpagecookie', 0);
                    Cookies.set("vend_id", login.FIRM_ID);
                    Cookies.set('facebook_status', 0);
                    Cookies.set("firmid", login.FIRM_ID);
                    Cookies.set('role_id', login.ROLE_ID);
                    Cookies.set('name', login.NAME);
                    Cookies.set("usertype", "USERAPP");
                    //window.location.reload();
                    navigate('/AppHome');

                }

                //window.location.href = cityLink;               


                return <div key={login.USERNAME}></div>;
            })}

            {/* {getEmailid && getPassword ?
                (


                    window.location.href = cityLink





                    // 
                ) : null
            } */}
        </div>
    )
}

export default BasicAppLogin