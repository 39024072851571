import React, { useState } from 'react';
import axios from 'axios';


function FacebookContentScrapperHome() {
  const [url, setUrl] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    //e.preventDefault();
    setLoading(true);
    //setData(null); // Clear old data
    //setError(null); // Clear old error
    try {
      const encodedUrl = encodeURIComponent(url);
      const response = await axios.get(`/facebook/scrape?url=${encodedUrl}`);
      setData(response.data);
      setLoading(false);
      //alert(response.data.response)
    } catch (error) {
      setError(error.response.data.error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Enter URL" />
        <button type="submit" onClick={handleSubmit}>Submit</button>
      </div>
      {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." />} {/* Display the GIF */}
      {error && <p>Error: {error}</p>}
      {data && (
        <div>

          <p>Likes: {data.likes}</p>
          <p>Comments: {data.comments}</p>
          <p>Shares: {data.shares}</p>
        </div>
      )}
    </div>
  );
}

export default FacebookContentScrapperHome;