import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeadingD from './HeadingVend';
import { NavLink } from 'react-router-dom';
import './style.css'
import Vendcontent from './Ventcontent';

const Vendor = () => {
  const [vendor, SetVendor] = useState([])
  let port1 = sessionStorage.getItem("portalid")
  //let pin1 = sessionStorage.getItem("pincode")
  let parentport = sessionStorage.getItem("parentportalid")


  useEffect(() => {
    axios.post('/local/vendor/list', { "port1": port1, "parentport": parentport })
      .then(function (response) {
        //console.log("vendor news",response.data);
        //console.log("vendor page portal id",port1)
        SetVendor(response.data)
      })
  }, []);

  return (
    <>
      <section className='vendor'>
        <div className='container'>
          <HeadingD />

          <div className='content'>
            {vendor.map((vend) => {
              return (
                <Vendcontent key={vend.id} vend={vend} />
              )
            })}
          </div>
        </div>
      </section>
      <p align="center"  >
        <NavLink to="/AllVendors" >More</NavLink>
      </p>
    </>
  )
}

export default Vendor
