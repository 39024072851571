import React, { useState, useEffect } from 'react';
import axios from 'axios';





const Diabetes = () => {

  const [diabetesdata, setDiabetesData] = useState([])

  let portalid=sessionStorage.getItem("portalid")
  console.log("portalid",portalid)
  useEffect(() => {
    axios.post('/cindex/diabetes', { "id": portalid })
      .then(function (response) {
        // console.log(response.data);
        setDiabetesData(response.data)
      })
  }, []);
  console.log("diabetes length", diabetesdata.length)
  console.log("hook data", diabetesdata)


  
  if(portalid === null ){
  return (


    <div>
      <label for="exampleDia"> City/District not selected, goto main page & select one</label>
    </div>
  );
  }
  if (diabetesdata.length) {

    const firstData = diabetesdata[0];
    console.log("final", firstData)
    const finalResult = firstData.final_result;
    console.log("finalresult", finalResult)

    if (finalResult) {

      return (

        <div>
          <label for="exampleDia"> Based on your local data It is possible that you may be have Diabetics</label>
        </div>
      );
    }

    return (


      <div>
        <label for="exampleDia"> Based on your local data you may not be having Diabetics</label>
      </div>
    );

  }


}

export default Diabetes