import React, { useEffect, useState } from 'react';
import axios from "axios";
import "./addsendingemails.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom"; // Import useNavigate


function AddSendingEmails() {
  const [emailContent, setEmailContent] = useState("");
  const [groupName, setGroupName] = useState(""); // New state for group name
  const firmid = Cookies.get("firmid");
  const userid = Cookies.get("userid");
  const usertype = Cookies.get("usertype")

  const navigate = useNavigate(); // Initialize useNavigate for page redirection
  
  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } else if (usertype === "USERAPP") {
      alert("Login with Businuess User Credentials");
      navigate('/login');
    }
  }, [userid, navigate]);


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    const emails = emailContent.split(/[,\s;]+/);
    const uniqueEmails = emails.filter(
      (email) => email !== "" && isValidEmail(email)
    );

    if (uniqueEmails.length === 0) {
      console.error("No valid emails to submit");
      alert("No valid emails to submit");
      return;
    }

    if (!groupName) {
      console.error("Group name is required");
      alert("Group name is required");
      return;
    }

    try {
      // First, create the group
      const groupResponse = await axios.post("/load/groups", {
        groupName, userid, firmid,
      });

      const groupId = groupResponse.data.groupId; // Get the group ID from the response

      // Then, send the emails along with the group ID
      await axios.post("/load/emails", {
        uniqueEmails,
        userid,
        firmid,
        groupId,

      });

      console.log("Emails sent to the backend successfully");


      const confirmAddMore = window.confirm("Emails added successfully. Would you like to add more to a new group?");
      if (confirmAddMore) {
        setEmailContent(""); // Clear the emails field
        setGroupName(""); // Clear the group name field
      } else {
        navigate("/bulkemailhome"); // Navigate to the home page
      }


    } catch (error) {
      console.error("Error sending data to the backend:", error);

      if (error.response && error.response.status === 400) {
        // Handle duplicate group name error
        alert(error.response.data.message);
      } else {
        console.error("Error sending data to the backend:", error);
      }
    }
  };

  return (
    <div className="sending-emails">
      <h2>Add Sending Emails & Group Name</h2>
      <textarea
        value={emailContent}
        onChange={(e) => setEmailContent(e.target.value)}
        placeholder="Enter emails here"
        rows="4"
        cols="50"
      />
      <br />
      <br />
      <input
        type="text"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        placeholder="Enter group name"
        required
      />
      <br />
      <br />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default AddSendingEmails;
