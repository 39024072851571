// In your React component where you handle the form
import React, { useState, useEffect } from 'react';
import './ReportPage.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import PageLog from '../../PageLog/PageLog';

const SuccessPopup = ({ message }) => {
  return (
    <div className="success-popup">
      <p>{message}</p>
    </div>
  );
};
const ReportPage = () => {
  const [projectIds, setProjectIds] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [status, setStatus] = useState('');
  const [showPercentage, setShowPercentage] = useState(false);
  const [percentage, setPercentage] = useState('');
  const [crIds, setCRIds] = useState([]);
  const [selectedCRId, setSelectedCRId] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const userid = Cookies.get('userid');
  const username = Cookies.get('username');

  console.log('userid', userid);
  console.log('username', username);

  
  
  // useEffect(() => {
  //   console.log('UserID for fetching projects:', userid);
  //   fetch(`/projects?userid=${userid}`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch projects');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setProjectIds(data); // Set the received project IDs into state
  //     })
  //     .catch((error) => console.error(error));
  
  //   // Fetch both CR_ID and TASK from backend
  //   fetch('/cr_ids')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Check the structure of the received data
  //       console.log('CR IDs and Tasks:', data);
  
  //       // Assuming data is an array of objects with CR_ID and TASK fields
  //       setCRIds(data); // Set the received CR IDs and Tasks into state
  //     })
  //     .catch((error) => console.error(error));
  // }, [userid]);
  
  useEffect(() => {
    console.log('UserID for fetching projects:', userid);
  
    // Fetch projects
    axios.get(`/projects?userid=${userid}`)
      .then((response) => {
        setProjectIds(response.data); // Set the received project IDs into state
      })
      .catch((error) => {
        console.error('Failed to fetch projects:', error);
      });
  
    // Fetch CR_ID and TASK from backend
    axios.get('/cr_ids')
      .then((response) => {
        console.log('CR IDs and Tasks:', response.data);
        setCRIds(response.data); // Set the received CR IDs and Tasks into state
      })
      .catch((error) => {
        console.error('Failed to fetch CR IDs and Tasks:', error);
      });
  }, [userid]);

  const handleProjectChange = (e) => {
    setSelectedProjectId(e.target.value);
  };
  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
    setShowPercentage(selectedStatus === 'pending');
  };
  const handleSuccess = () => {
    setShowSuccessPopup(true);
  
    // Use setTimeout to hide the popup after 3 seconds
    setTimeout(() => {
      setShowSuccessPopup(false);
      resetForm(); // Reset form fields after hiding the popup
    }, 3000);
  };
  

  // Existing functions for handling form changes and submission remain the same

  const resetForm = () => {
    setSelectedProjectId('');
    setStatus('');
    setShowPercentage(false);
    setPercentage('');
    setSelectedCRId('');
    setShowSuccessPopup(false); // Hide success popup on form reset

    // Manually clear form fields using their corresponding state setters
    const form = document.querySelector('form');
    if (form) {
      form.reset();
    }
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const dataToSubmit = {};
    formData.forEach((value, key) => {
      dataToSubmit[key] = value;
    });

    dataToSubmit['percentage'] = percentage;
    dataToSubmit['emp_id'] = userid;
    dataToSubmit['emp_name'] = username;

    console.log('Data to be sent to the backend:', dataToSubmit);

 
  axios
      .post('/api/insert-employee-reports', dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('Data inserted successfully:', response.data);
        handleSuccess(); // Show success popup and reset form fields
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
        // Handle error scenarios
      });
  };
  
const handleCRIdChange = (e) => {
  setSelectedCRId(e.target.value);
};

  return (
    <div className="report-page">
      <PageLog/>
    <h1>Report Page</h1>
    <form onSubmit={handleSubmit}>
        <label>
          Created Date:
          <input type="date" name="createdDate" />
        </label>
        <label>
          Project ID:
          <select name="projectID" onChange={handleProjectChange} value={selectedProjectId}>
            <option value="">Select Project ID</option>
            {projectIds.map((project) => (
              <option key={project.PROJECT_ID} value={project.PROJECT_ID}>
                {`${project.PROJECT_ID} - ${project.DESCRIPTION}`}
              </option>
            ))}
          </select>
        </label>
        <label>
          Type:
          <select name="type">
            <option value="Ticket">Ticket</option>
            <option value="CR">ChangeRequest</option>
            <option value="Project">Project</option>
            
          </select>
        </label>
        <label>
          Report Type:
          <select name="reportType">
            <option value="Dailyplan">Daily Plan</option>
            <option value="Dailyreport">Daily Report</option>
            <option value="Actionplan">Action Plan</option>
            <option value="Result">Result</option>
            <option value="Timesheet">Timesheet</option>
          </select>
        </label>
       
        <label>
          Task:
          <input type="text" name="task" />
        </label>
        <label>
          Description:
          <input type="text" name="description" />
        </label>
        <label>
  Status:
  <select name="status" onChange={handleStatusChange} value={status}>
    <option value="">Select Status</option>
    <option value="complete">Complete</option>
    <option value="pending">Pending</option>
  </select>
</label>


      <label style={{ display: showPercentage ? 'block' : 'none' }}>
        Percentage:
        <input
          type="text"
          name="percentage"
          placeholder="Enter percentage"
          value={percentage}
          onChange={handlePercentageChange}
        />
      </label>
        <label>
          Time:
          <input type="time" name="time" />
        </label>
        <label>
          Action Numbers:
          <input type="text" name="actionNumbers" />
        </label>
        <label>
          Result Numbers:
          <input type="text" name="resultNumbers" />
        </label>
        <label>
          Remarks:
          <input type="text" name="remarks" />
        </label>
        <label>
          Task Type:
          <select name="taskType">
            <option value="Technical">Technical</option>
            <option value="Operations">Operations</option>
            
          </select>
        </label>
        <label>
          Task Baseline:
          <select name="baseline">
            <option value="Small">Small</option>
            <option value="Medium">Medium</option>
            <option value="Large">Large</option>
          </select>
        </label>
        <label>
          Start Time:
          <input type="time" name="startTime" />
        </label>
        <label>
          End Time:
          <input type="time" name="endTime" />
        </label>
       
        <label>
  CR ID:
  <select name="crID" value={selectedCRId} onChange={handleCRIdChange}>
    <option value="">Select CR ID</option>
    {Array.isArray(crIds) &&
      crIds.map((cr) => (
        <option key={cr.CR_ID} value={cr.CR_ID}>
          {`${cr.CR_ID} - ${cr.TASK}`} {/* Display both CR_ID and TASK */}
        </option>
      ))}
  </select>
</label>

        
        <label>
          Task ID:
          <input type="text" name="taskID" />
        </label>
        
        {showSuccessPopup && <SuccessPopup message="Data added successfully!" />}
        {/* Add other fields */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ReportPage;
