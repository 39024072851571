import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Singlepages from '../../singlepages/Singlepages';
import More from './more';
import './WhitePaper.css';


const Content = (props) => {
// const Content = ({item:{id,DOC_ID,image,DOC_DESC,DOC_SUBCATEGRY,DOC_TITL,DOC_PUBDATE}}) => {

   const navigate= useNavigate()

   function goToSinglepage(){
    navigate("/Singlepages",{state : {id : props.item.DOC_ID}})
   }

  return (
    <>
    
      <div className='box'>
        <div className='img'>
           <img src={props.item.image ? props.item.image : props.data.image_url} alt='' />
        </div>
        <div className='text'>
          <span className='category'>{props.item.DOC_SUBCATEGRY}</span>
       <h1 onClick={goToSinglepage}>{props.item.DOC_TITL.slice(0,35)}...</h1>
        <div className='author flex'>
        <span>Last updated 2min ago</span>
        </div>
        </div>
      </div>
    
    </>
  )
}

export default Content

