import React, { useState, useEffect } from 'react';
import './HospitalAdd.css';
import Cookies from 'js-cookie';
import axios from 'axios';

const HospitalForm = () => {
  // State to store form data
  const userid = Cookies.get('userid');
  const portalid = Cookies.get('portalid');
  const [hospitalData, setHospitalData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    Name: '',
    Place: '',
    Address: '',
    Phone: '',
    Doctors: '',
    Nursing_staffs: '',
    Other_staffs: '',
    Bed_no: '',
    Image: null, // Set to null initially
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);


  console.log("portalid", portalid)
  console.log("userid", userid)
  // const finalpath2 = `${portalid}/${userid}`;

  const fetchHospitalData = async () => {
    try {
      const response = await axios.get('/get-hospital-data', {
        params: {
          Portalid: portalid,
          Memberid: userid,
        },
      });
      setHospitalData(response.data);
    } catch (error) {
      console.error('Error fetching hospital data:', error);
    }
  };

  console.log("hospitalData", hospitalData)

  // useEffect hook to fetch data when the component mounts
  useEffect(() => {
    fetchHospitalData();
    createFolder();
  }, []);


  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'Image') {
      // Handle image changes separately
      setSelectedImage(files[0]);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // Use files[0] directly for the Image property
      }));
    } else {
      // Handle other form inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Function to create a folder on the server
  const createFolder = async () => {
    try {
      const response = await axios.post('/create/folder/hospitals', { portalid, userid });
      console.log('Folder creation result:', response);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  // const handleImageChange = (e) => {
  //   e.persist(); // Persist the synthetic event
  //   setSelectedImage(e.target.files[0]);
  //   console.log('Selected Image in handleImageChange:', e.target.files[0]);

  //   // After setting the selected image, update the form data
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     Image: e.target.files[0], // Use files[0] directly for the Image property
  //   }));
  // };


  const handleEditClick = (Id) => {
    const editingHospital = hospitalData.find((hospital) => hospital.ID === Id);
    console.log("editingHospital",editingHospital)
    setEditingData(editingHospital);
    setFormData({
      Name: editingHospital.NAME,
      Place: editingHospital.PLACE,
      Address: editingHospital.ADDRESS,
      Phone: editingHospital.PHONE,
      Doctors: editingHospital.DOCTORS,
      Nursing_staffs: editingHospital.NURSING_STAFFS,
      Other_staffs: editingHospital.OTHER_STAFFS,
      Bed_no: editingHospital.BED_NO,
      Image: null, // Keep the Image property null to prevent it from being changed
    });
    setSelectedImage(null);
    setIsEditing(true);
  };




  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Call the createFolder function to create folders on the server
    await createFolder();

    // Create FormData object and append form fields
    const formDataToSend = new FormData();
    formDataToSend.append('portalid', portalid);
    formDataToSend.append('userid', userid);
    formDataToSend.append('Name', formData.Name);
    formDataToSend.append('Place', formData.Place);
    formDataToSend.append('Address', formData.Address);
    formDataToSend.append('Phone', formData.Phone);
    formDataToSend.append('Doctors', formData.Doctors);
    formDataToSend.append('Nursing_staffs', formData.Nursing_staffs);
    formDataToSend.append('Other_staffs', formData.Other_staffs);
    formDataToSend.append('Bed_no', formData.Bed_no);
    console.log('Selected Image:', selectedImage);
    console.log('Form Data Name:', formData.Name);

    if (selectedImage) {
      formDataToSend.append('Image', selectedImage);
    }

    try {
      if (isEditing) {
        console.log('FormData to Send:', formDataToSend);
        const response = await axios.post(`/edit-hospital/${editingData.ID}`, formDataToSend);
        console.log(response.data.message);
        fetchHospitalData();
      } else {
        // Send a request to the backend to add new data
        const response = await axios.post('/submit-hospital', formDataToSend);
        console.log(response.data.message);
      }

      // Reset the form after submission if needed
      setFormData({
        Name: '',
        Place: '',
        Address: '',
        Phone: '',
        Doctors: '',
        Nursing_staffs: '',
        Other_staffs: '',
        Bed_no: '',
        selectedImage: null,
      });

      // Reset the selected image state
      setSelectedImage(null);
      fetchHospitalData();

      // Reset editing state
      setIsEditing(false);
      setEditingData(null);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  // Assuming you have a function to handle the delete click event
  const handleDeleteClick = async (Id) => {
    try {
      // Send a request to the backend to update the DOC_PRICE to 0 for the specified hospital id
      const response = await axios.post(`/delete-hospital/${Id}`);
      console.log(response.data.message);

      fetchHospitalData();

    } catch (error) {
      console.error('Error deleting hospital data:', error);
    }
  };


  return (
    <div className='container-hosptial12'>
      <form className="hospital1-form" onSubmit={handleSubmit}>
        <h1>Fill Hospital Details</h1>
        <label className="form-label">
          Name:
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleInputChange}
            required
          />
        </label>

        <label className="form-label">
          Place:
          <input
            type="text"
            name="Place"
            value={formData.Place}
            onChange={handleInputChange}
            required
          />
        </label>

        <label className="form-label">
          Address:
          <textarea
            name="Address"
            value={formData.Address}
            onChange={handleInputChange}
            required
            className="form-textarea" // Add this class
          />
        </label>

        <label className="form-label">
          Phone:
          <input
            type="text"
            name="Phone"
            value={formData.Phone}
            onChange={handleInputChange}
            required
          />
        </label>

        <label className="form-label">
          Number of Doctors:
          <input
            type="text"
            name="Doctors"
            value={formData.Doctors}
            onChange={handleInputChange}
            required
          />
        </label>

        <label className="form-label">
          Number of Nursing Staff:
          <input
            type="text"
            name="Nursing_staffs"
            value={formData.Nursing_staffs}
            onChange={handleInputChange}
            required
          />
        </label>

        <label className="form-label">
          Number of Other Staff:
          <input
            type="text"
            name="Other_staffs"
            value={formData.Other_staffs}
            onChange={handleInputChange}
            required
          />
        </label>

        <label className="form-label">
          Number of Beds:
          <input
            type="text"
            name="Bed_no"
            value={formData.Bed_no}
            onChange={handleInputChange}
            required
          />
        </label>

        <div>
          <label htmlFor="image">Upload Hospital  Photo:</label>
          <input
            type="file"
            className="input1"
            id="image"
            name="Image"  // Make sure the 'name' attribute is set to 'Image'
            onChange={handleInputChange}
          />
        </div>


        <button type="submit" className="form-button">
          Submit
        </button>
      </form>
      <h1>Hospital List</h1>
      <table className="hospital-table">
        <thead>
          <tr>
            <th>DOC_PRICE</th>
            <th>Name</th>
            <th>Place</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Number of Doctors</th>
            <th>Number of Nurses</th>
            <th>Number of Staff</th>
            <th>Number of Beds</th>
            <th>image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {hospitalData.map((hospital) => (
            <tr key={hospital.ID}>
              <td>{hospital.DOC_PRICE}</td>
              <td>{hospital.NAME}</td>
              <td>{hospital.PLACE}</td>
              <td>{hospital.ADDRESS}</td>
              <td>{hospital.PHONE}</td>
              <td>{hospital.DOCTORS}</td>
              <td>{hospital.NURSING_STAFFS}</td>
              <td>{hospital.OTHER_STAFFS}</td>
              <td>{hospital.BED_NO}</td>
              <td>
                <a href={hospital.IMAGE} target="_blank" rel="noopener noreferrer">
                  View Image
                </a>
              </td>
              <td>
                <div className="button-container">
                  <button className="edit-button" onClick={() => handleEditClick(hospital.ID)}>Edit</button>
                  <button className="delete-button" onClick={() => handleDeleteClick(hospital.ID)}>Delete</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );

};

export default HospitalForm;
