import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import InstaAccountSelect from './InstaAccountSelect';

function InstaPageCheck(props) {

    const { bardStory, id, extractedIDs } = props;
    const facebook_token = Cookies.get("facebook_token")
    //const processedIDs = [];

    const [processedIDs, setProcessedIDs] = useState([]);

    const [isLoopCompleted, setIsLoopCompleted] = useState(false);
    const [loading, setLoading] = useState(false);

    console.log("extractedIDs in page check", extractedIDs)

    useEffect(() => {
        setLoading(true);
        for (const item of extractedIDs) {
            getinstaaccount(item.group_page_id);
        }
        setLoading(false);
        setIsLoopCompleted(true);

    }, [extractedIDs, facebook_token]);


    // useEffect(() => {
    //     if (isLoopCompleted) {
    //         getinstadetails(processedIDs);
    //     }
    // }, [isLoopCompleted]);

    const getinstaaccount = async (group_page_id) => {

      


        const Response = await axios.get(`https://graph.facebook.com/v19.0/${group_page_id}?fields=instagram_business_account&access_token=${facebook_token}`);
        if (Response.data.instagram_business_account === undefined) {
            //let group_page_url = `https://www.facebook.com/profile.php?id=${group_page_id}`

            //alert(`\n ${group_page_url}\n\nThis account does not have an Instagram business account`)
        }
        else {
            const instagramAccountId = Response.data.instagram_business_account.id;

            try {

                const instaresponse = await axios.get(`https://graph.facebook.com/v19.0/${instagramAccountId}?fields=username&access_token=${facebook_token}`);

                console.log("instaresponse", instaresponse.data);
                // Push the ID into the processedIDs array
                // if (!processedIDs.includes(instagramAccountId)) {
                //     processedIDs.push(instagramAccountId);
                // }
                const idExists = processedIDs.some(obj => obj.id === instaresponse.data.id);

                // If it doesn't exist, add the whole instaresponse.data object to processedIDs
                if (!idExists) {
                    setProcessedIDs(prevIDs => [...prevIDs, instaresponse.data]);
                }
            }
            catch (error) {
                console.log(error);
            }

        }
    }
    console.log("processedIDs", processedIDs);

    // const getinstadetails = async (group_page_id) => {

    //     debugger

    //     if(group_page_id.length!=0){

    //         console.log("group_page_id in getinstadetails",group_page_id)

    //     }



    // }


    return (
        <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." />}
            </div>


            <InstaAccountSelect bardStory={bardStory} id={id} processedIDs={processedIDs} />

        </div>
    )
}

export default InstaPageCheck