import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './bulkemailsending.css';

function BulkEmailSending({ selectedemails, subject, message, groupID }) {

    console.log("t", selectedemails, subject, message, groupID)
    const firmid = Cookies.get("firmid")
    const userid = Cookies.get("userid")
    const [myemail, setMyEmail] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [emailResponses, setEmailResponses] = useState([]);
    const [emaildone, setEmailDone] = useState('false');
    const [batchNum, setBatchNum] = useState(null); // Initialize the batchNum state


    useEffect(() => {
        axios.get(`/get/myemailinfo?firmid=${firmid}&userid=${userid}`)
            .then((response) => {
                setMyEmail(response.data);
                console.log("myemail in response", response.data)
            })
            .catch((error) => {
                console.error('Failed to fetch projects:', error);
            });
    }, []);

    //console.log("myemail", myemail)
    useEffect(() => {
        // Fetch the batch number once before starting the email sending process
        const fetchBatchNumber = async () => {
            try {
                const response = await axios.get(`/get/next-batch-number?groupID=${groupID}`);
                const batchNum = response.data.batchNum; // Assuming the batch number is returned in the response
                setBatchNum(batchNum); // Set the batch number in state to use for all emails
            } catch (error) {
                console.error('Failed to fetch batch number:', error);
            }
        };

        fetchBatchNumber();
    }, [groupID]);

    useEffect(() => {
        if (myemail.length > 0 && batchNum !== null) {  // Ensure batchNum is fetched first
            const sendNextEmail = async () => {
                if (currentIndex < selectedemails.length) {
                    const emailObj = selectedemails[currentIndex];
                    const cleanedEmail = emailObj.EMAIL.trim();

                    try {
                        const response = await axios.post('/bulk-send-email', {
                            to: cleanedEmail,
                            subject: subject,
                            html: message,
                            email: myemail[0].EMAIL,
                            password: myemail[0].PASSWORD,
                            host: myemail[0].HOST,
                            port: myemail[0].PORT,
                            secure: myemail[0].SECURE,
                            firmid: firmid,
                            userid: userid,
                            groupID: groupID,
                            batchNum: batchNum // Use the same batch number for all emails
                        });

                        const updatedResponses = [...emailResponses, response.data.message];
                        setEmailResponses(updatedResponses);

                        if (currentIndex === selectedemails.length - 1) {
                            setEmailDone(true); // Set emaildone to true if this is the last email
                        }

                        setCurrentIndex(currentIndex + 1);
                    } catch (error) {
                        console.error('Failed to send email:', error);
                    }
                }
            };

            sendNextEmail();
        }
    }, [currentIndex, selectedemails, subject, message, myemail, batchNum]); // Include batchNum in the dependency array



    const cancelsending = () => {

        window.location.reload();
    }

    return (
        <div className="bulk-email-sending">
            <h3>Sending Emails...</h3>
            <br />

            <div className='bulk-sending-div' >
                {emailResponses.map((response, index) => (
                    <p key={index}>{response}</p>
                ))}
            </div>

            {emaildone === 'false' ?
                (<div className='bulk-sending-div'>
                    <button onClick={cancelsending}>Cancel</button>
                </div>) : (
                    <div className='bulk-sending-div'>
                        <button onClick={cancelsending}>Done</button>
                    </div>)
            }

        </div>
    )
}

export default BulkEmailSending