import React from 'react';
import './healthbookmobile.css';

function HealthBookMobile() {
  return (
    <div className="healthbook-container">
        <br/><br/>
      {/* <div className="link-healthbook">Community Healthbook</div>
      <div className="link-healthbook">Personal Healthbook</div>
      <div className="link-healthbook">My Contribution</div>
      <div className="link-healthbook">My Possibility - Chance to involve in Healthbook</div> */}

<a href="#community" className="link-healthbook">Community Healthbook</a>
      <a href="#personal" className="link-healthbook">Personal Healthbook</a>
      <a href="#contribution" className="link-healthbook">My Contribution</a>
      <a href="#possibility" className="link-healthbook">My Possibility - Chance to involve in Healthbook</a>
    </div>
  );
}

export default HealthBookMobile;