import './whatwedo.scss';

function WhatWeDo() {
    return (
        <section className="why_section layout_padding">
            <div className="container-whatwedo">
                <div className="heading_container heading_center">
                    <h2 className='h2-wwd'>
                        What do we do?
                    </h2>
                </div>
                <div className="why_container">
                    <div className="box">
                        <div className="img-box">
                            <img src={require('../images/technology.png')} alt="" />
                        </div>

                        <h5>
                            Technology
                        </h5>
                        <p>
                            Myblocks is a hyperlocal portal that uses the latest regenerative AI to create news and information about your town.
                            We cover a wide range of topics, including air, water, environment, local diseases, infections, accidents, crime, and more.
                            Our regenerative AI technology allows us to gather and analyze data from a variety of sources to create accurate and up-to-date news articles.
                            We also use our AI to generate insights and predictions that can help you stay informed about your community.
                        </p>

                    </div>
                    <div className="box">
                        <div className="img-box">
                            <img src={require('../images/modelsicon.png')} alt="" />
                        </div>
                        <h5>
                            Models
                        </h5>
                        <p>
                            We are committed to crafting cutting-edge large language models tailored to India's rich linguistic tapestry, thereby fueling the emergence of innovative GenAI applications.
                            Our bespoke enterprise models are meticulously designed to cater to the diverse linguistic landscape of India,
                            empowering businesses and individuals alike to harness the full potential of language technology on the web.
                        </p>
                    </div>
                    <div className="box">
                        <div className="img-box">
                            <img src={require('../images/platformicon.png')} alt="" />
                        </div>
                        <h5>
                            Our Platform
                        </h5>
                        <p>
                            We're dedicated to constructing a robust enterprise-grade platform that empowers you to seamlessly develop and assess your company's personalized AI applications. Our platform offers a
                            comprehensive suite of tools and resources designed to facilitate the creation, testing, and refinement of MYAI apps, ensuring they align perfectly with your business objectives.
                        </p>
                    </div>
                    <div className="box">
                        <div className="img-box">
                            <img src={require('../images/ecosystemicon.png')} alt="" />
                        </div>
                        <h5>
                            Ecosystem
                        </h5>
                        <p>
                            We are committed to contributing to open-source models and datasets, taking the lead in substantial data curation endeavors within the public-good domain. Our dedication lies in fostering collaborative
                            advancements that drive positive change and make a lasting impact on the AI community
                        </p>
                    </div>
                    <div className="box">
                        <div className="img-box">
                            <img src={require('../images/intrnlicon.png')} alt="" />
                        </div>
                        <h5>
                            International Impact
                        </h5>
                        <p>
                            Myblocks is a hyperlocal website dedicated to serving all towns, starting with India and expanding to the USA, UK, Canada, and Germany. We are your one-stop shop for all your local needs.
                            Myblocks is the perfect way to stay connected to your community and discover new things. Sign up today and start exploring your town!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhatWeDo;
