import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ActiveProbs1.css';
import QuestionDetailsPopup from './QuestionDetailsPopup';
import Cookies from 'js-cookie';

const ActiveProbs = (parentlocation) => {
  const [activeQuestions, setActiveQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(4); // Adjust the page size as needed
 
console.log("parentlocation",parentlocation.parentportalname)
  const portalid = Cookies.get('portalid');

  const fetchActiveQuestions = async () => {
    try {
      const response = await axios.get('/get-active-questions', {
        headers: {
          'Portalid': portalid,
        },
        params: {
          page: currentPage,
          pageSize: pageSize,
        },
      });

      console.log("response.data in active problems", response.data)

      if (response.status === 200) {
        setActiveQuestions(response.data);
        console.log("response.data in active probs", response.data)
      } else {
        console.error('Failed to fetch active questions from the backend');
      }
    } catch (error) {
      console.error('Error fetching active questions from the backend:', error);
    }
  };

 


  useEffect(() => {
    fetchActiveQuestions();
    
  }, [currentPage]); // Fetch data when the currentPage changes

  const handleMoreClick = (question) => {

    //console.log("question in handleMoreClick",question)
    //debugger
    setSelectedQuestion(question);
  };

  const handleClosePopup = () => {
    setSelectedQuestion(null);
  };


  const handleNextPage = () => {
    // Check if there is data in the next page
    if (activeQuestions.length === pageSize) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className='kerala-container'>
      
      <h3>Most Issues in {parentlocation.parentportalname}!</h3>
      <ul>
        {activeQuestions.map((question) => (
          <li key={question.PRO_ID}>
            {/* <span>{question.ADDED_DATE}</span> */}
            <span> {new Date(question.ADDED_DATE).toLocaleString()}</span>

            <button onClick={() => handleMoreClick(question)}>More</button>
            {question.QUESTIONS ? question.QUESTIONS : question.TYPED_QUESTIONS}
          </li>
        ))}
      </ul>
      {activeQuestions.length > 0 && (
        <div className="pagination-buttons">
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>{'<'}</button>
          <span>{currentPage}</span>
          <button onClick={handleNextPage}>{'>'}</button>
        </div>
      )}
      {selectedQuestion && (
        <QuestionDetailsPopup
          question={selectedQuestion}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default ActiveProbs;
