import './products.scss';
import businessIcon from '../images/business-icon.png';
import researchIcon from '../images/research-icon.png';
import enterpriseIcon from '../images/enterprise-icon.png';


function Products() {
    return (
        <section className="service_section layout_padding">
            <div className="service_container">
                <div className="container-products">
                    <div className="heading_container heading_center">
                        <h2 >
                            Our <span>Products</span>
                        </h2>
                        <p className='p-aboutus'>
                        Empowering Communities, One Locality at a Time, explore our products for Local News, Health Insights, and Community Engagement!
                        </p>
                    </div>
                    <div className="row-aboutus">
                        <div className="box">
                            <div className="img-box">
                                <img src={businessIcon} alt="" />
                            </div>
                                <h5>
                                    Platform-Secure AI for business
                                </h5>
                                <p className='box-text'>
                                With a focus on news relevancy and accuracy, our AI-driven solutions provide timely insights tailored 
                                to your local area, empowering you with the knowledge you need to stay ahead."
                                </p>
                            </div>
                        <div className="box ">
                            <div className="img-box">
                                <img src={enterpriseIcon} alt="" />
                            </div>
                                <h5>
                                    Cross-platform Ai for Enterprise
                                </h5>
                                <p className='box-text'>
                                Experience seamless operations and enhanced productivity across campuses, HR departments, and sales teams 
                                with Myblocks' cutting-edge Generative AI solutions, customized to meet your unique needs.

                                </p>
                        </div>
                        <div className="box ">
                            <div className="img-box">
                                <img src={researchIcon} alt="" />
                            </div>
                                <h5>
                                    Research
                                </h5>
                                <p className='box-text'>
                                    Explore our commitment to advancing science and AI research across various domains.
                                    Discover our open-source projects designed to foster community collaboration and innovation.
                                </p>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Products;