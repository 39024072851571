import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


function BasicCard() {
  return (
    <Card sx={{ maxWidth:'20vh' }} style={{backgroundColor:'#c70000'}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="white" gutterBottom>
          ICA INDIA
        </Typography>
        <Typography variant="h5" component="div" color='white'>
          152
        </Typography>
      </CardContent>
    </Card>
  );
}
export default BasicCard;