import React, { useState } from 'react';
import axios from 'axios';

function FacebookPagePostScrapper() {
 
    
    const [url, setUrl] = useState('');
    const [count, setCount] = useState('');
     const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (e) => {
      //e.preventDefault();
      setLoading(true);
      //setData(null); // Clear old data
      setError(null); // Clear old error
      try {
        const encodedUrl = encodeURIComponent(url);
        const response = await axios.get(`/facebook/pagepost/scrape?url=${encodedUrl}&count=${count}`);
        //setData(response.data);
        setLoading(false);
        alert(response.data.response)
      } catch (error) {
        setError(error.response.data.error);
        setLoading(false);
      }
    };
  
    return (
      <div>
        <div>
          <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Enter URL" />
          <input type="text" value={count} onChange={(e) => setCount(e.target.value)} placeholder="Enter Count of posts needed" />
          <button type="submit" onClick={handleSubmit}>Submit</button>
        </div>
        {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." />} {/* Display the GIF */}
        {error && <p>Error: {error}</p>}
     
      </div>
    );
  }

export default FacebookPagePostScrapper