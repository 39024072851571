import * as React from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";

//Village Data
import VillageData from "./Indexes/VillageData";

function AboutVillage() {
  return (
    <div>
      <Grid container spacing={2} style={{ paddingTop: "100px" }}>
        <Grid item lg={5} xs={6} md={3}>
          <Card
            sx={{ maxWidth: 600 }}
            style={{ height: "90vh", margin: "20px" }}
          >
            <CardHeader title={VillageData[0].information} />
            <CardHeader
              title={VillageData[0].villageName}
              subheader={VillageData[0].timeStamp}
            />
            <CardMedia
              component="img"
              height="194"
              image={VillageData[0].img}
              alt="Paella dish"
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {VillageData[0].description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={5} xs={6} md={3}>
          <Card
            sx={{ maxWidth: 700 }}
            style={{ height: "90vh", margin: "20px", boxShadow: "none" }}
          >
            <CardHeader title={VillageData[1].village} />

            <CardContent>
              <Typography variant="h6" gutterBottom>
                {VillageData[2].pollution}
              </Typography>
              <Typography variant="body" color="text.secondary">
                {VillageData[2].detail}
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {VillageData[3].water}
              </Typography>
              <Typography variant="body" color="text.secondary">
                {VillageData[3].detail}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
export default AboutVillage;
