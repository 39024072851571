import React, { useState, useEffect, useLayoutEffect } from 'react'
import axios from 'axios';
import './Health-section.css';
import Content from './Content';
import HeadingD from './HeadingVend';
import { NavLink } from 'react-router-dom';

const Health = () => {
  const [data1, setData] = useState([])
  const [data2, SetImage1] = useState([])
  // const [data3, SetImage2] = useState([])
  // const [portal_img1, SetPortalImg1] = useState([])
  // const [portal_img2, SetPortalImg2] = useState([])
  let port = sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")
  console.log("parentportalid in Health", parentport)
  let site = sessionStorage.getItem("site")


  useEffect(() => {
    axios.all([
      axios.post('/news/headlines/health'),
      axios.post(`/myblock/altimage/health`),
      // axios.post(`/techieindex/altimage/articles`),
      // axios.post(`/myblock/altimage/articles/portalid`, { "port": port }),
      // axios.post(`/techieindex/altimage/articles/portalid`, { "port": port })
    ])

      .then(axios.spread((data1, data2) => {
        // output of req.
        console.log('Health data1', data1, 'Health data2', data2)

        setData(data1.data)
        SetImage1(data2.data)
        // SetImage2(data3.data)
        // SetPortalImg1(data4.data)
        // SetPortalImg2(data5.data)


      }));

  }, []);



  return (
    <div>

      <section>
        <div className='healthsection-container'>
          <HeadingD />
          <section className='hero_health'>
            <div className='healthsection-container'>
              <div className='content'>
                {/*    {items.map((item) => {
                  return <Content key={item.id} item={item} />
                })}
 */}
                {data1.map((item, i) => {

                  // if (portal_img1[i] === undefined) {
                  //   portal_img1[i] = 0;
                  // }
                  // if (portal_img2[i] === undefined) {
                  //   portal_img2[i] = 0;
                  // }

                  //console.log("site value", site)
                  if (site === "Myblock") {
                    return <Content key={i} item={item} data={data2[i]} />;
                  }
                  // else if (site === "Techie-Index") {
                  //   return <Content key={i} item={item} data={data3[i]} portal_img={portal_img2[i]}/>;
                  // }
                })}
              </div>
            </div>
          </section>
        </div>
      </section>
      <p align="center"><NavLink to="/AllHealth">More</NavLink></p>
    </div>
  )
}

export default Health
