import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import { useLocation } from 'react-router-dom';
import './postlist.css'

const FbEngagementsList = () => {
    const navigate = useNavigate();
    const [facebookData, setFacebookData] = useState([]);
    const [loading, setLoading] = useState(false);


    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const category = query.get('Category');
    const portalid = query.get('portalid');




    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/api/fbengagementslist', {
                    params: {
                        Category: category,
                        portalid:portalid
                    }
                });
                console.log('Data fetched:', response.data);
                setFacebookData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // setError('Error fetching data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            // Cleanup function to cancel any pending axios requests
        };
    }, [category]);

    const goBack = () => {
        navigate(-1);
    };



    const handleBestPost = () => {
        navigate(`/bestPost?Category=${category}&portalid=${portalid}`);
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Caption copied to clipboard!');
        }, (err) => {
            console.error('Failed to copy text: ', err);
        });
    };


    return (
        <div className="clothing-page-container">
            <h1 className="page-title">{category} Trends</h1>
            <p className="page-description">Discover the latest trends in {category}.</p>

            <div className="center-button">
                <button className="btn-navigate" onClick={() => handleBestPost('Textile')} style={{ marginRight: '10px' }}>
                    Show Best Post
                </button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />}
            </div>


            <ul className="account-list">
                {facebookData.map(account => (
                    <li key={account.ID} className="account-item">
                        {/* <strong className="account-url">URL:</strong> <a href={account.POST_URL} target="_blank" rel="noopener noreferrer">{account.FACEBOOK_PAGE_URL}</a> */}
                        <br />
                        <strong className="account-info likes">Likes:</strong> {account.LIKES}
                        <br />
                        <strong className="account-info comments">Comments:</strong> {account.COMMENTS_COUNT}
                        <br />
                        <strong className="account-info shares">Shares:</strong> {account.SHARES}
                        <br /><br />
                        <strong className="account-info">View Post:</strong>
                        <a href={account.POST_URL} target="_blank" rel="noopener noreferrer">{account.POST_URL}</a>
                        <br /><br />
                        <strong className="account-info">Caption:</strong>  <br /><br />{account.CAPTION}
                        <br /><br />
                        <button onClick={() => copyToClipboard(account.CAPTION)} className="copy-button">Copy</button>
                        <br /><br />
                    </li>
                ))}
            </ul>

            <div className="centered-button">
            <button onClick={goBack} className="btn-go-back">Go Back</button>
            </div>
        </div>
    );
};





export default FbEngagementsList;
