import './styles.scss'

const Milestones = () => {
    return (<div>
        <div class="timeline">

            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>August 14, 2023</h3>
                <p>Startup CEO interviews launched.</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>May 29, 2023</h3>
                <p>New editorial team launches to shine a light on startups</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>

            <div class="timeline-empty">
            </div>

            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>January 10, 2023</h3>
                <p>Startup portals in India, the USA, and Canada get a fresh start.</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>July 11,2022</h3>
                <p>This August, we are calling on incubators and accelerators to support cross-country migrations.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>


            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>November 20,2021</h3>
                <p>Country wise product portals are launched for UK specific companies.</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>May 12,2021</h3>
                <p>Cross country Startup engagement section is launched in Startup portal.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>October 15,2020</h3>
                <p>Canada specific IT product section is launched.</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>January 15, 2020</h3>
                <p>Country wise portal is launched for German IT.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>

            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>October 25, 2019</h3>
                <p>AI health portal is launched</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>March 09, 2019</h3>
                <p>Portal for Health technology is Re launched for Medical devices & technology.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>October 15, 2018</h3>
                <p>Techieindex is joining Myblocks network of portals.</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>June 15, 2017</h3>
                <p>Portal with venture capital listing is added.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div><div class="timeline-empty">
            </div>




            < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>January 15, 2016</h3>
                <p>Health portal is added with more common diseases.</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>March 10, 2015</h3>
                <p>Web portal for medical technologies is started</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>October 1, 2014</h3>
                <p>Chronic care portals for most common diseases are launched for individual symptoms.</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>August 13, 2013</h3>
                <p>Life style management portal is launched</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>



            < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>June 23, 2012</h3>
                <p>Techieindex network is adding more health related information portals.</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>January 19, 2011</h3>
                <p>Techieindex has been relaunched and its URL has been changed to www.techie-index.com.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>March 1, 2004</h3>
                <p>Content partnership program is announced to share knowledge from techieindex knowledge foundry through knowledge pipes.</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>Feb 2, 2004</h3>
                <p>Techieindex launched its Techie VoIP User group for the VoIP user community with host of features.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div> < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>Jan 7,  2004</h3>
                <p>Techieindex launched the DW SUMMIT 2004, an online virtual conference on Data Management.</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>November 2003</h3>
                <p>First commercial launch of KF 1.0 used for indexing NRI contents. NRI index uses integration of KF &amp; techdex products</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>Oct, 2003</h3>
                <p>Started Techieindex Personalized Service aimed at software professional who are looking for a career change.</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>Auguest 25, 2003</h3>
                <p>Techieindex launches Intelligent knowledge indexing Product - Knowledge foundary � KF Beta for any verticals knowledge solutions</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div> < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>July 14, 2003</h3>
                <p> Techieindex helps TiE reach TiEcon 2003 to a wider global
                      audience through the unique Techie Event Coverage</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>July 02, 2003</h3>
                <p>Techieindex partners with Tradepub to provide the
                      developer community a single platform to buy the latest
                      books on IT</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>June 21, 2003</h3>
                <p>Techieindex launches 'The Techie Evangelists' a premier
                      listing of the world leading evangelists across all major
                      technologies</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>January 15, 2003</h3>
                <p> eDRM Application Suite Ver 2.1 launched with new and
                      enhanced features, such as the Profiler, Techie100, User
                      Tracking Tool, Content Management System etc.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>



            < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>December 7, 2002</h3>
                <p>eDRM Application Suite Ver 2.0 was launched with a host of
                      features designed to cut costs and time in building,
                      maintaining and customizing user groups.</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>November 15, 2002</h3>
                <p> The Techie SAN User Group, a most comprehensive knowledge
                      resource portal for the SAN developer community launched</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>September 12, 2002</h3>
                <p>The Techie eLearning portal on major Internet technologies
                      and a host of high end technologies launched</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>June 13, 2002</h3>
                <p>Techieindex launches its unique application data streaming
                      tool, The Techie Ticker that keeps you updated on the
                      events and happenings on all major technologies</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div> < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>April 25, 2002</h3>
                <p>'Techieindex Interactive Online Exhibition showcasing key
                      industry players under one roof with 24/7 live assistance
                      released</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>April 7,2002</h3>
                <p>Oracle Developer Support Network Launched at the Oracle
                      APPSWORLD 2002</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>March 25, 2002</h3>
                <p> Java Developer Support Network and enterprise knowledge
                      center on Java and related technologies launched at Java
                      world 2002.</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>March 10, 2002</h3>
                <p> Intended to be a bidding site, Techie Mall, incorporating&nbsp;
                      very advanced features for both submitting and bidding
                      projects launched</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div> < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>Feb 25, 2002</h3>
                <p> The BEA centric user group The Techie Bea User Group
                      launched at BEA eWorld 2002.</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>January 1, 2002</h3>
                <p> A first of its kind competitive ranking list of top
                      professionals across the software&nbsp; technology world
                      launched</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>November 12, 2001</h3>
                <p>Twenty two User groups added to www.techie-index.com</p>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>September 25, 2001</h3>
                <p> Continuing its expansion drive Techieindex started a
                      software development center in India, located at Kochi in
                      the southern state of Kerala.</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>


            < div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-component timeline-content">
                <h3>July 2001</h3>
                <p>Enormous response and considerable experience in managing
                      developer support networks led to the development of an
                      enterprise portal for developers www.techie-index.com
                      launched</p>
            </div>
            <div class="timeline-component timeline-content">
                <h3>March 2001</h3>
                <p> Another milestone in catering to the needs of developers
                      using Seibel's powerful CRM suite, the Seibel User group
                      launched</p>
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-empty">
            </div>
            <div class="timeline-middle">
                <div class="timeline-circle"></div>
            </div>
            <div class=" timeline-component timeline-content">
                <h3>December 2000</h3>
                <p>Actuateclub.com a one stop developer support network with
                      features that include discussion forums, jobs, resource
                      center was launched</p>
            </div>


        </div>
    </div>)
}

export default Milestones;