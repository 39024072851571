import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import FacebookSchedule from '../FacebookSchedule';

function InstaPostingSchedule(props) {

  const { bardStory, id, processedIDs } = props;

  console.log("insta schedule props", bardStory, id, processedIDs)
  const facebook_token = Cookies.get("facebook_token");
  const portalid = Cookies.get("portalid");
  const firmid = Cookies.get("firmid");
  const [image, setImage] = useState([]);
  const [data, setData] = useState([]);
  const userid = Cookies.get("userid")
  const [urls, setUrls] = useState([]);


  useEffect(() => {
    axios.post('/get/image/link', { "id": id })
      .then(function (response) {
        // console.log("image link in insta", response.data[0].image);
        const rawImage = response.data[0].image;
        let cleanedImage = rawImage.replace('..', ''); // Remove the leading two dots
        //cleanedImage = window.location.origin + cleanedImage
        cleanedImage = "https://www.myblocks.in" + cleanedImage

        // console.log("image link in insta", cleanedImage);
        setImage(cleanedImage);
        //const im ='https://picjumbo.com/wp-content/uploads/beautiful-nature-scenery-free-photo.jpg'
        //setImage(im);
      });

    // fetchhistory();
  }, [id]);


  const fetchhistory = async () => {
    try {
      const response = await axios.get('/post/history', {
        params: {
          id: id,
          social_name: 'instagram',
        },
      });
      // console.log("insta history", response.data)

      setData(response.data);
    }

    catch (error) {
      console.log(error);
    }


  }

  useEffect(() => {

    const newUrls = [];

    console.log("processedIDs", processedIDs)
    //debugger

    for (const insta_id of processedIDs) {

      try {

        const url = `https://graph.facebook.com/v17.0/${insta_id}/media?image_url=${image}&caption=${bardStory}&access_token=${facebook_token}`

        newUrls.push(url);
        console.log("insta_id", insta_id)

      } catch (error) {
        console.error("Error in loop:", error);
      }




    }
    console.log("newUrls in insta post page", newUrls)
    setUrls(newUrls);


  }, [processedIDs,image]);

  console.log("urls in insta post page", urls)


  return (
    <div>

      {/* <button onClick={posttoinsta}>Post to Instagram</button> */}


      <table className="content-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Posted Url</th>


          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.ID}>

              <td>{item.ID}</td>
              <td>
                <a href={item.URL} >{item.URL}</a>

              </td>

            </tr>
          ))}
        </tbody>
      </table>

      <FacebookSchedule bardStory={bardStory} id={id} urls={urls} type={'instagram'} />

    </div>
  )
}

export default InstaPostingSchedule