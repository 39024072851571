import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeadingD from './HeadingVend';
import { useNavigate, NavLink } from 'react-router-dom';
import './style.css'
import EventContent from './EventContent';

const Events = () => {

  const [data1, setData] = useState([])
  const [data2, SetImage1] = useState([])
  const [data3, SetImage2] = useState([])
  const [portal_img1, SetPortalImg1] = useState([])
  const [portal_img2, SetPortalImg2] = useState([])

  let site = sessionStorage.getItem("site")

  let port1 = sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")
  //let pin1 = sessionStorage.getItem("pincode")
  const navigate = useNavigate()

  /*    function goToVendorpage(){
      navigate("/vendorpage",{state : {id : "2962"}})
     } */

  useEffect(() => {
    axios.all([
      axios.post('/news/headlines/events', { "port1": port1, "parentport": parentport }),
      axios.post(`/myblock/altimage/events`),
      axios.post(`/techieindex/altimage/events`),
      axios.post(`/myblock/altimage/events/portalid`, { "port": port1 }),
      axios.post(`/techieindex/altimage/events/portalid`, { "port": port1 })
    ])

      .then(axios.spread((data1, data2, data3, data4, data5) => {
        // output of req.
        console.log('Events data1', data1, 'Events data2', data2, 'Events data3', data3)

        setData(data1.data)
        SetImage1(data2.data)
        SetImage2(data3.data)
        SetPortalImg1(data4.data)
        SetPortalImg2(data5.data)

      }));

  }, []);


  return (
    <>
      <section className='vendor'>
        <div className='container'>
          <HeadingD />

          <div className='content'>
            {/* {vendor.map((vend)=>{
                    return (
                      <EventContent key={vend.id} vend={vend}/>
                    )
                })} */}

            

            {data1.map((item, i) => {
              if (portal_img1[i] === undefined) {
                portal_img1[i] = 0;
                    }
              if (portal_img2[i] === undefined) {
                portal_img2[i] = 0;
                    }

              console.log("site value", site)
              if (site === "Myblock") {
                return <EventContent key={i} item={item} data={data2[i]} portal_img={portal_img1[i]}/>;
              }
              else if (site === "Techie-Index") {
                return <EventContent key={i} item={item} data={data3[i]} portal_img={portal_img2[i]}/>;
              }
            })}



          </div>
        </div>
      </section>
      <p align="center"  >
        <NavLink to="/AllEvents" >More</NavLink>
      </p>
    </>
  )
}

export default Events
