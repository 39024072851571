import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function FacebookLogin() {

    const facebook_status = Cookies.get("facebook_status")
    console.log("facebook_status", facebook_status)
    const location = useLocation();
    // const query = new URLSearchParams(location.search);
    // const urlval = query.get('url');
    const url = window.location.href;

    const navigate = useNavigate();

    //debugger

    const urlParams = new URLSearchParams(url.substring(url.indexOf('?') + 1));

    //dev
    //const urlval = urlParams.get('url');

    //prod
    const urlval = urlParams.get('code');

    console.log("url", urlval)
    const encodedUrlVal = encodeURIComponent(urlval);

    const [token, setToken] = useState('');

    useEffect(() => {

        if (facebook_status === '0') {
            console.log("facebook_status", facebook_status)
            //debugger
            //prod link old
            //window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=270784999165198&redirect_uri=https://myblocks.in/imageupload&state=12345&scope=pages_show_list, ads_management, business_management, instagram_basic, instagram_content_publish, publish_to_groups, groups_access_member_info, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile`

            //prod link
            window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=270784999165198&redirect_uri=https://myblocks.in/approvestory&state=12345&scope=instagram_basic,instagram_content_publish,pages_show_list,pages_manage_posts,business_management,pages_read_engagement`
            
            //pages_show_list, ads_management, business_management, instagram_basic, instagram_content_publish, publish_to_groups, groups_access_member_info, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile``

            //??
            //window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=270784999165198&redirect_uri=https%3A%2F%2F61.2.142.91%3A8440%2F&state=12345&scope=pages_show_list, ads_management, business_management, instagram_basic, instagram_content_publish, publish_to_groups, groups_access_member_info, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile`

            //my account link
            //window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=https%3A%2F%2F61.2.142.91%3A8440%2F&state=12345&scope=pages_show_list, ads_management, business_management, instagram_basic, instagram_content_publish, publish_to_groups, groups_access_member_info, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile`

            Cookies.set('facebook_status', 1);
            //alert('Set')

        }

        if (facebook_status === '1') {
            fetchData();
        }



    }, [])



    const fetchData = async () => {

        //alert('Set')
        Cookies.set('facebook_status', 2);
        //fetchData();

        try {

            //debugger

            //prod link old
            //const response = await axios.post(`https://graph.facebook.com/v19.0/oauth/access_token?client_id=270784999165198&redirect_uri=https://myblocks.in/imageupload&client_secret=351a285fbc1fd65b09b9b3233b2cb7e3&code=${encodedUrlVal}`);

            // prod link new            
            const response = await axios.post(`https://graph.facebook.com/v19.0/oauth/access_token?client_id=270784999165198&redirect_uri=https://myblocks.in/approvestory&client_secret=351a285fbc1fd65b09b9b3233b2cb7e3&code=${encodedUrlVal}`);

            //??
            //const response = await axios.post(`https://graph.facebook.com/v19.0/oauth/access_token?client_id=270784999165198&redirect_uri=https%3A%2F%2F61.2.142.91%3A8440%2F&client_secret=351a285fbc1fd65b09b9b3233b2cb7e3&code=${encodedUrlVal}`);

            //my account link
            //const response = await axios.post(`https://graph.facebook.com/v19.0/oauth/access_token?client_id=601794092158200&redirect_uri=https%3A%2F%2F61.2.142.91%3A8440%2F&client_secret=6e6baea88a12cab70c85eb9e163e0648&code=${encodedUrlVal}`);

            console.log("resp", response)
            console.log("teken value ", response.data.access_token)
            setToken(response.data.access_token)
            //   setTemp(response.data)
            //   console.log("temp in", temp)
            //alert('Set')
            Cookies.set('facebook_token', response.data.access_token);

            navigate(`/approvestory`)

        } catch (error) {
            console.log(error);
        }

        const facebook_token = Cookies.get("facebook_token")
        console.log("facebook_token", facebook_token)


        //window.location.href = window.location.origin + window.location.pathname;

    }


    console.log("teken out", token)


    return (
        <div>


            {/* <a href={`${encodedUrlVal}`}>link</a> */}
        </div>
    )
}

export default FacebookLogin