import React, { useState, useEffect, useLayoutEffect } from 'react'

import axios from 'axios';
import './WhitePaper.css';
import Content from './Content';
import HeadingD from './HeadingVend';
import PageLog from '../../PageLog/PageLog';

const AllWhitePaper = () => {
  const [data1, setData] = useState([])
  const [data2, SetImage1] = useState([])
  const [data3, SetImage2] = useState([])
  const [startIndex, setStartIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  let port = sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")
  console.log("parentportalid in Whitepaper", parentport)
  let site = sessionStorage.getItem("site")

  useEffect(() => {
    axios.all([
      axios.post('/news/headlines/whitepaper/more', { "port": port, "parentport": parentport }),
      axios.post(`/myblock/altimage/whitpaper`),
      axios.post(`/techieindex/altimage/whitpaper`)
    ])
      .then(axios.spread((data1, data2, data3) => {
        // output of req.
        console.log('Whitepaper data1', data1, 'Whitepaper data2', data2, 'Whitepaper data3', data3)

        setData(data1.data)
        SetImage1(data2.data)
        SetImage2(data3.data)
        setMaxIndex(data1.data.length);

      }));

  }, []);

  const handleNextClick = () => {
    if (startIndex + 9 < maxIndex) {
      setStartIndex(startIndex + 9);
    }
  };

  const handlePrevClick = () => {
    if (startIndex - 9 >= 0) {
      setStartIndex(startIndex - 9);
    }
  };



  return (
    <div>

      <section >
        <div className='container'>
          <HeadingD />
          <PageLog/>
          <section className='hero_whitepaper'>
            <div className='container'>
              <div className='content'>
                {/*   {items.map((item) => {
                  return <Content key={item.id} item={item} />
                })} */}

                {data1.slice(startIndex, startIndex + 9).map((item, i) => {

                  console.log("site value", site)
                  if (site === "Myblock") {
                    return <Content key={i} item={item} data={data2[i]} />;
                  }
                  else if (site === "Techie-Index") {
                    return <Content key={i} item={item} data={data3[i]} />;
                  }
                })}
              </div>
            </div>
          </section>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`}
              onClick={handlePrevClick}
              disabled={startIndex === 0}
            >
              Previous
            </button>
            <button
              className={`btn btn-primary ${startIndex + 9 >= maxIndex ? 'disabled' : ''}`}
              onClick={handleNextClick}
              disabled={startIndex + 9 >= maxIndex}
            >
              Next
            </button>
          </div>
        </div>
      </section>

    </div>
  )
}

export default AllWhitePaper
