import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './businessuseractivate.css';
import PageLog from '../PageLog/PageLog';

function BusinessUserActivate() {

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const empid = query.get('empid');

  console.log("empid", empid)
  const [userDetails, SetUserDetails] = useState([]);
  const [designation, setDesignation] = useState('Trainees');
  const [roleid, setRoleId] = useState('0');

  useEffect(() => {

    axios.get(`/get/businessuser/details?empid=${empid}`)
      .then((response) => {
        SetUserDetails(response.data);
        console.log("user details", response.data)
      })
      .catch((error) => {
        console.error('Failed to fetch projects:', error);
      });

  }, []);

  const handleSubmit = () => {

    axios.post('/activate/user', { "empid": empid, "designation": designation, "roleid": roleid })
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error('Failed to fetch menu:', error);
      });

  }


  return (

    <div className='business-user-activate'>
      <PageLog/>
      <h2>User Details</h2>
      <table>
        <thead>
          <tr>
            <th>Employee name</th>
            <td>{userDetails[0]?.EMPNAME}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Designation</td>
            <td> <select value={designation} onChange={(e) => setDesignation(e.target.value)}>
              <option value="Trainees">Trainees</option>
              <option value="Employee">Employee</option>
            </select></td>
          </tr>
          <tr>
            <td>EMAIL</td>
            <td>{userDetails[0]?.EMAIL}</td>
          </tr>
          <tr>
            <td>CREATED DATE</td>
            <td>{new Date(userDetails[0]?.CREATED_DATE).toLocaleString()}</td>
          </tr>
          <tr>
            <td>APPDATE</td>
            <td>{new Date(userDetails[0]?.APPDATE).toLocaleString()}</td>
          </tr>
          <tr>
            <td>Role ID</td>
            <td>
              <select value={roleid} onChange={(e) => setRoleId(e.target.value)}>
                {[...Array(11).keys()].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>

            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <button onClick={handleSubmit}>Activate</button>


      </div>

    </div>
  )
}

export default BusinessUserActivate