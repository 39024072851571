import React from "react";

//Logo import
import RedFace from "./Logos/RedFace.svg";

//Component import
import BasicCard from "./AirQuality";

//Table import
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/system";
import { Typography } from "@mui/material";

//Icons
import CloudQueueIcon from "@mui/icons-material/CloudQueue";


// importing Header
import Links from "../../Links";

const Air = () => {
  // const {id} = useParams();

  //console.log("hi here", id);
  return (
    <div style={{paddingTop:'100px'}}>
      <Links/>
      <Container
        fixed
        maxWidth="md"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <Typography variant="h4">Air Quality In Delhi</Typography>
        <Typography
          color="text.secondary"
          style={{ margin: "10px", fontSize: "20px" }}
        >
          Air Quality Index (AQI) and PM2.5 Air Pollution in New Delhi
        </Typography>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, maxWidth: 900 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow style={{ backgroundColor: "rgb(255,0,0,0.6)" }}>
                <TableCell>
                  <BasicCard />
                </TableCell>
                <TableCell align="right">
                  <Typography style={{ fontSize: "14px", color: "	#960000" }}>
                    LIVE ICA
                  </Typography>
                  <Typography style={{ color: "#960000", fontSize: "29px" }}>
                    Good
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  <CloudQueueIcon />
                  15&deg;C
                </TableCell>
                <TableCell align="right">
                  <img
                    src={RedFace}
                    alt="Red face"
                    style={{ width: "100px" }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography style={{ fontWeight: "bold", color: "RED" }}>
                    SUMMARY
                  </Typography>
                  <Typography>
                    What is the current air quality in New Delhi?
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                >
                  Air Pollution level
                </TableCell>
                <TableCell
                  align="right"
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                >
                  Air Quality index
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell
                  align="right"
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                >
                  Main Contaminant
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "16px" }}
                >
                  Harmful
                </TableCell>
                <TableCell align="right">152 ICA DELHI</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">PM 2.5</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    
    </div>
  );
};

export default Air;
