import React, { useState, useEffect } from 'react';
import './facebookschedule.css'
import axios from 'axios'
import Cookies from 'js-cookie';




function FacebookSchedule(props) {


  const { bardStory, id, urls,type } = props;

  console.log(" bardStory, id, urls", bardStory, id, urls,type)


  const firmid = Cookies.get("firmid")
  const userid = Cookies.get("userid")
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [image, setImage] = useState([]);



  console.log("firmid", firmid);
  console.log("userid", userid);


  useEffect(() => {
    axios.post('/get/image/link', { "id": id })
      .then(function (response) {
        //console.log("image link in insta", response.data[0].image);
        const rawImage = response.data[0].image;
        let cleanedImage = rawImage.replace('..', ''); // Remove the leading two dots
        //cleanedImage = window.location.origin + cleanedImage

        console.log("window.location.origin",window.location.origin)
        console.log("image link in insta", cleanedImage);

        // cleanedImage = window.location.origin + cleanedImage;
        cleanedImage = "http://34.172.164.239" + cleanedImage;

        // cleanedImage = "https://www.myblocks.in"+cleanedImage
        setImage(cleanedImage);
        // const im ='https://picjumbo.com/wp-content/uploads/beautiful-nature-scenery-free-photo.jpg'
        // setImage(im);
      });

  
  }, [id]);

  console.log('facebook schedule:', image);

  //const url = "https://graph.facebook.com/1021582145510097/photos?access_token=EAAD2RwuvjQ4BO86MJnZC70YVh4Qh6JIqmx3mg7lTKNCgjOIegn4Y03aqVgVxsum3ZBt02U22Xa2imAwoDTZCAZCksgYh2faFEtArhEXvLZCt3Wj4FCRZA55JI3EQocfhdEQdBgMzh3I3HaYLU601ZA9XOZASlznHhLWHejXk2gtMXSnfLUFNPd1SPSbcf30Q9ma4ATm8lDrvxrZAmZCUmsudQu5OC1YKXAorKupA9bzuIEDVDMh2CjwVF5";

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleSubmit = async () => {
    // You can perform any actions with the selectedDate and selectedTime here

    if(id===undefined){

      alert("please select an image before scheduling")
      return;
    }
    console.log('Date:', selectedDate);
    console.log('Time:', selectedTime);

    console.log('facebook schedule:', image);

    //debugger

    try {

      const axiosRequests = urls.map(url =>
        axios.post('/smp/scheduling', { "url": url, "sdate": selectedDate, "stime": selectedTime, "userid": userid, "firmid": firmid,"image":image,"bardStory":bardStory,"type":type })
      );

      // Wait for all promises to resolve
      const responses = await Promise.all(axiosRequests);

      // Log the responses
      responses.forEach(response => {
        console.log("result", response);
        alert(response.data)
      });

    }
    catch (error) {
      console.log(error);
    }
  

  // Add more logic as needed
};

return (
  <div className='facebookschedule-content-container'>



    <label>
      Date:
      <input type="date" value={selectedDate} onChange={handleDateChange} />
    </label>
    <br />
    <label>
      Time:
      <input type="time" value={selectedTime} onChange={handleTimeChange} />
    </label>
    <br />
    <button onClick={handleSubmit}>Submit</button>
  </div>
);
}

export default FacebookSchedule;