import React from 'react'
import './HeadingVend.css'

const HeadingD = () => {
  const getSite=sessionStorage.getItem("site")
  return (
    <div className='vendorheading'>
     <h6> {getSite==="Techie-Index"?"Business ":"Business "} </h6> 
    </div>
  )
}

export default HeadingD
