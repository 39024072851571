const VillageData = [
    {
        id: 0,
        information:'Information About Village',
        villageName: "Jaam Sawant ",
        description: 'A village is a clustered human settlement or community, larger than a hamlet but smaller than a town with a population typically ranging from a few hundred a small community or group of houses in a rural area, larger than a hamlet and usually smaller than a town, and sometimes (as in parts of the U.S.) incorporated as a municipality.',
        img: process.env.PUBLIC_URL + 'Images/Jaam.jpg',
      
    
    },
    {
        id: 1,
        village: "Problems inside Village",
       
    },
    {  id:2,
        pollution:'Air Pollution',
        detail:'Agricultural activities are the major human source of air pollution in rural areas. Burning of stubble in the field after harvesting, threshing operation, grain dust and large scale use of tractors harvester, combines and diesels operated tube well are major factor contributing to air pollution.',
        
    },
    {
        id: 3,
        water: 'Water Crisis',
        detail:'India has long faced the challenge of providing safe drinking water to over 700 million people in more than 1.5 million villages. In 1972, the government began to improve rural water supply, and in the mid-1980s the issue was declared a national priority. As a result, by 2011, 95 percent of India’s rural population had access to some form of water supply infrastructure. In practice however many systems were no longer functional.',
        
    },
] 

export default VillageData
