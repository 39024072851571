import React from 'react'
import './Heading.css'

const Heading = () => {
  const getSite=sessionStorage.getItem("site")
  return (
    <div className='heading'>
     <h6> {getSite==="Techie-Index"?" Spotlight" : "Spotlight"} </h6> 
    </div>
  )
}

export default Heading
