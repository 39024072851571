import { React, useState, useEffect } from 'react'
import axios from 'axios';
import Homepages from '../Home/Homepages';
import './TechieIndexLatest.css';
import ChildLinks from '../Home/ChildLinks/ChildLinks';
import PageLog from '../PageLog/PageLog';
import ListDisplay from './listDisplay.js';
import { itemHeaders, itemsIntervals } from './homeConstants.js';



const Mainpage = () => {
  sessionStorage.setItem("site", "Techie-Index");
  let getCity = sessionStorage.getItem("city")
  let getPortal = sessionStorage.getItem("portalid")
  let getPincode = sessionStorage.getItem("pincode")
  const [data, setData] = useState([])
  const [startupList, setStartupList] = useState([])
  const [jobsList, setJobsList] = useState([])
  const [techList, setTechList] = useState([])
  const [campusList, setCampusList] = useState([])
  const [otherList, setOtherList] = useState([])
  const intervals = itemsIntervals;
  const headers = itemHeaders;
  let id = sessionStorage.getItem("portalid", "****")


  const setListItems = (dataItems) => {
    if (dataItems.length) {
      dataItems.map(item => {
        for (const interval of intervals) {
          if (item.id >= interval.start && item.id <= interval.end) {
            addItemToList(interval.listName, item)
          }
        }
      })
    }
  }

  const addItemToList = (listName, data) => {
    switch (listName) {
      case "startupList":
        setStartupList(startupList => [...startupList, data])
        break;
      case "techList":
        setTechList(techList => [...techList, data]);
        break;
      case "jobsList":
        setJobsList(jobsList => [...jobsList, data])
        break;
      case "campusList":
        setCampusList(campusList => [...campusList, data])
        break;
      case "otherList":
        setOtherList(otherList => [...otherList, data]);
        break;
      default:
        setOtherList(otherList => [...otherList, data])
    }
  }
  useEffect(() => {
    axios.post('/cityS/PortalID/techieindexlatest_first4')
      .then(function (response) {
        console.log(response.data);
        setData(response.data)
        setListItems(response.data)
      })
  }, []);


  return (

    <div>
      <PageLog />

      {
        getPortal && getCity || getPincode ?
          <Homepages /> :
          <div>
            <ChildLinks />
            <div className='itemList'>
              <ListDisplay portal={startupList} title='Startups' header={headers.startupList}></ListDisplay>
              <ListDisplay portal={techList} title='Technology' header={headers.techList}></ListDisplay>
              <ListDisplay portal={jobsList} title='Jobs' header={headers.jobsList}></ListDisplay>
              <ListDisplay portal={campusList} title='Campus' header={headers.campusList}></ListDisplay>
              <ListDisplay portal={otherList} title='Other News' header={headers.otherList}></ListDisplay>
            </div>
          </div>
      }
    </div>
  )
}

export default Mainpage
