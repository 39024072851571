import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeadingD from './HeadingVend';
//import { useNavigate } from 'react-router-dom';
import './style.css'
import EventContent from './EventContent';
import PageLog from '../../PageLog/PageLog';

const AllEvents = () => {

    const [data1, setData] = useState([])
    const [data2, SetImage1] = useState([])
    const [data3, SetImage2] = useState([])
    const [portal_img1, SetPortalImg1] = useState([])
    const [portal_img2, SetPortalImg2] = useState([])
    const [startIndex, setStartIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    let site = sessionStorage.getItem("site")
    let port1 = sessionStorage.getItem("portalid")
    let parentport = sessionStorage.getItem("parentportalid")

    useEffect(() => {

        axios.all([
            axios.post('/news/headlines/events/all', { "port1": port1, "parentport": parentport }),
            axios.post(`/myblock/altimage/events`),
            axios.post(`/techieindex/altimage/events`),
            axios.post(`/myblock/altimage/events/portalid`, { "port": port1 }),
            axios.post(`/techieindex/altimage/events/portalid`, { "port": port1 })
        ])
            .then(axios.spread((data1, data2, data3, data4, data5) => {
                // output of req.
                console.log('Article data1', data1, 'Article data2', data2, 'Article data3', data3)

                setData(data1.data)
                SetImage1(data2.data)
                SetImage2(data3.data)
                SetPortalImg1(data4.data)
                SetPortalImg2(data5.data)
                setMaxIndex(data1.data.length);

            }));

    }, []);

    const handleNextClick = () => {
        //console.log('Next button clicked')
        if (startIndex + 18 < maxIndex) {
            setStartIndex(prevIndex => prevIndex + 18);
        }
    };

    const handlePrevClick = () => {
        //console.log('Previous button clicked')
        if (startIndex - 18 >= 0) {
            setStartIndex(prevIndex => prevIndex - 18);
        }
    };

    return (
        <div>

            <section className='vendor'>
                <div className='container'>
                    <HeadingD />
                    <PageLog/>
                    <div className='content'>

                        {data1.slice(startIndex, startIndex + 18).map((item, i) => {
                              if (portal_img1[i] === undefined) {
                                portal_img1[i] = 0;
                                    }
                              if (portal_img2[i] === undefined) {
                                portal_img2[i] = 0;
                                    }
                            if (site === "Myblock") {
                                return <EventContent key={i} item={item} data={data2[i]} portal_img={portal_img1[i]}/>;
                            }
                            else if (site === "Techie-Index") {
                                return <EventContent key={i} item={item} data={data3[i]} portal_img={portal_img2[i]}/>;
                            }
                        })}

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='left-buttons'>
                            <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        </div>
                        <div className='archives-link'>
                            <p align="center"  >
                                <a href={`http://61.2.142.91:8400/CityFromId/${port1}`}  >Archives</a>
                            </p>
                        </div>
                        <div className='right-buttons'>
                            <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 18 >= maxIndex}>Next</button>
                        </div>

                    </div>

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        <p align="center"  >
                            <a href={`http://61.2.142.91:8400/CityFromId/${port1}`}  >Archives</a>
                        </p>

                        <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 3 >= maxIndex}>Next</button>
                    </div> */}
                </div>
            </section>


        </div>
    )
}

export default AllEvents



