import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import HeadingD from './HeadingVend';
import { Table } from 'react-bootstrap';
import PageLog from '../../PageLog/PageLog';

export default function Extra() {

  const [extra, Setextra] = useState([])
  let id = sessionStorage.getItem("portalid")
  console.log("portal id in extra", id)
  if (id === null) {
    id = 0
  }

  useEffect(() => {
    axios.post('/Extra')
      .then(function (response) {
        console.log("Extra data", response.data)
        Setextra(response.data)
      })
  }, []);

  const filteredRows = extra.filter(data => data.PORTALID === parseInt(id));

  const displayRows = filteredRows.length > 0 ? filteredRows : extra.filter(data => data.PORTALID === 0);

  return (
    <>
      <PageLog />
      <br /><br /><br />
      <section className='vendor_extra'>
        <div className='content_extra'>
          <HeadingD />
          <Table striped bordered hover size="sm"  >
            <thead>
              <tr>

                <th  >Portal Name</th>
                <th >Link</th>
              </tr>
            </thead>
            <tbody>
              {displayRows.map((data, index) => (
                <tr key={index}>

                  <td>{data.TITLE}</td>
                  <td>
                    <a href={data.URL}>{data.URL}</a>
                  </td>
                </tr>
              ))}


            </tbody>
          </Table>
        </div>
      </section>
    </>
  )
}
