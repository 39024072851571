import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

//importing Header and Footer
import Links from "../Links";


// importing Link from React Router 
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function AllAi() {
  return (
    <>
      <Links />
      <Box sx={{ width: "100%" }} style={{ marginTop: '90px', marginBottom: '30px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Item>

              <Typography variant="h4"><Link to='/accident' style={{ textDecoration: 'none', color: 'black' }}>Accident</Link></Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4"><Link to='/air' style={{ textDecoration: 'none', color: 'black' }}>Air Pollution</Link></Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4"><Link to='/stress' style={{ textDecoration: 'none', color: 'black' }}>Stress</Link></Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4"><Link to='/main' style={{ textDecoration: 'none', color: 'black' }}>Nutrition</Link></Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4"><Link to='/WorldPressFreedom' style={{ textDecoration: 'none', color: 'black' }}>World Press Freedom</Link></Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4"><Link to='/sleepefficiency' style={{ textDecoration: 'none', color: 'black' }}>Sleep Efficiency</Link></Typography>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Typography variant="h4"><Link to='/VegetablePricing' style={{ textDecoration: 'none', color: 'black' }}>Commodity Pricing</Link></Typography>
            </Item>
          </Grid>

          
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Crime</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Food Habits</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Health</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Hunger</Typography>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Lung Cancer</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Poverty Index</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">School Education</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Smoking</Typography>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Street Hygiene</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Suicide</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Food Hygiene</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Waste Detection</Typography>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">World Happiness Report</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">World Press Freedom</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h4">Restaurant Hygiene</Typography>
            </Item>
          </Grid>
          {/* <Grid item xs={6}>
            <Item>
              <Typography variant="h4"><Link to='/SmokingTest' style={{ textDecoration: 'none', color: 'black' }}>Smoking Test</Link></Typography>
            </Item>
          </Grid> */}

        </Grid>
      </Box>

    </>
  );
}
