import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './trendingposts.css'

const TrendingPosts = () => {
  const [posts, setPosts] = useState([]);

  const category = 'Hotel';

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`http://localhost:8500/get/top/fbposts?category=${category}`);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);


  

  return (
    <div className="fb-container">
    <h1 className="heading">Top Facebook Posts in {category} Category</h1>
    <div className="posts-container">
      {posts.map((post, index) => (
        <div className="post" key={index}>
          <h2 className="post-title"> Total Post Engagement sum --- {post.engagement_sum}</h2>
          <p className="post-date">Post Date: {new Date(post.POST_DATE).toLocaleString()} </p>
          <p className="post-caption">Caption: {post.CAPTION || 'No caption provided'}</p>
          <p className="post-likes">Likes: {post.LIKES}</p>
          <p className="post-comments">Comments: {post.COMMENTS_COUNT}</p>
          <p className="post-shares">Shares: {post.SHARES}</p>
       
        </div>
      ))}
    </div>
  </div>
  );
};

export default TrendingPosts;