import React, {useState,useEffect, useLayoutEffect} from 'react'

import axios from 'axios';
import './Press_news_techie.css';
import Heading_press from './Heading_press';
import Content_press from './Content_press';


const News_press = () => {
    const [items,SetItems]=useState([])
    let port=sessionStorage.getItem("portalid")
    let pin=sessionStorage.getItem("pincode")
    // console.log(pin)
    useEffect(() => { 
        axios.post('http://localhost:5000/press/techie_index/release',{"port":port,"pin":pin})
  .then(function (response) {
    // console.log(response.data);
    SetItems(response.data)
  })
 } ,[]);

  return (
    <>
    <div>
        <Heading_press />
       <section className='press'>
            <div className='container'>
                {items.map((item)=>{
                    return <Content_press key={item.id} item={item}/>
                })}
            </div>
        </section>
       
    </div>
    </>
  )
  
}

export default News_press
