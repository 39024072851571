import React, { useEffect, useState, useRef } from 'react';
import './WatermarkForm.css';
import axios from "axios";
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';

const WatermarkForm = () => {

    const [watermarkType, setWatermarkType] = useState("image");
    const [watermarkImage, setWatermarkImage] = useState(null);
    const [watermarkText, setWatermarkText] = useState("");
    const userid = Cookies.get("userid")
    const portalid = Cookies.get("portalid")
    const usertype = Cookies.get("usertype")
    const navigate = useNavigate()

    console.log("portalid", portalid);

    console.log("userid", userid);

    const homepath = 'images/MyB_App';
    const finalpath = `${homepath}/${portalid}/${userid}/png`;

    


    useEffect(() => {
        if (!userid) {
            alert("Please login");
            navigate('/login');
        } else if (usertype === "USERAPP") {
            alert("Login with Businuess User Credentials");
            navigate('/login');
        }
    }, [userid, navigate]);

    useEffect(() => {

        createfolder();

    }, []);

    const createfolder = async () => {

        try {
            const response = axios.post('/create/folder/session', { "portalid": portalid, "userid": userid });
        }

        catch (error) {
            console.log(error);
        }

    };

    // Function to handle the radio button change
    const handleWatermarkTypeChange = (event) => {
        setWatermarkType(event.target.value);
        setWatermarkImage(null); // Reset image when switching to text
        setWatermarkText(""); // Reset text when switching to image
    };

    // Function to handle image upload
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setWatermarkImage(file);
    };

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("USERID", userid); // Hardcoded USERID
        formData.append("name", "Carol"); // Replace with actual value
        formData.append("watermark_type", watermarkType); // Use consistent naming

        formData.append("finalpath", finalpath);

        if (watermarkType === "image" && watermarkImage) {
            formData.append("watermark_path", watermarkImage);
        } else if (watermarkType === "text") {
            formData.append("watermark_text", watermarkText);
        }

        try {
            // POST the form data to your backend using Axios
            const response = await axios.post("/api/saveWatermark", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                alert("Watermark saved successfully!");
            } else {
                alert(`Failed to save watermark: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred while saving the watermark.");
        }
    };

    return (
        <div className="watermark-form">
            <h2 className="watermark-form__header">Logo/Company Name Upload</h2>
            <form onSubmit={handleSubmit}>
                {/* Select Watermark Type */}
                <div className="watermark-form__radio-group">
                    <label>
                        <input
                            type="radio"
                            value="image"
                            checked={watermarkType === "image"}
                            onChange={handleWatermarkTypeChange}
                        />
                        Company Logo
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="text"
                            checked={watermarkType === "text"}
                            onChange={handleWatermarkTypeChange}
                        />
                        Company Name
                    </label>
                </div>

                {/* Conditional rendering based on watermark type */}
                {watermarkType === "image" ? (
                    <div className="watermark-form__input-container">
                        <label>Upload Logo</label>
                        (For better result use transparent png images for logo)
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            name="watermark_path"
                            required
                        />
                    </div>
                ) : (
                    <div className="watermark-form__input-container">
                        <label>Enter Company Name</label>
                        <input
                            type="text"
                            value={watermarkText}
                            onChange={(e) => setWatermarkText(e.target.value)}
                            required
                        />
                    </div>
                )}
                <button className="watermark-form__button" type="submit">Save</button>
            </form>
        </div>
    );
};

export default WatermarkForm;
