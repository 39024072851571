import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

// Male Female and Children
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Main() {
  // Navigation
  const navigate = useNavigate();

  // State For the Age
  const [age, setAge] = useState();

  // State for Selection the gender
  const [selectedValue, setSelectedValue] = useState("female");
  //Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/result", { state: { id: 1, ages: age, gender: selectedValue } });
    
  };
  // Age
  const handleAge = (e) => {
    setAge(e.target.value);
  };
  console.log(age);
  const handleGender = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Card sx={{ minWidth: 275 }} style={{ height: "500px", boxShadow: "none" }}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={2} sm={2} md={2}></Grid>
              <Grid item xs={4} sm={8} md={8}>
                <Item>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={2} md={2}></Grid>
                    <Grid item xs={4} sm={8} md={8}>
                      <Item style={{ boxShadow: "none" }}>
                        <Typography
                          variant="h6"
                          style={{
                            marginRight: "60px",
                            marginBottom: "20px",
                            color: "black",
                          }}
                        >
                          Nutrition Calculator
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label="Age"
                          variant="outlined"
                          value={age}
                          onChange={handleAge}
                        />
                      </Item>
                      <Item style={{ boxShadow: "none" }}>
                        <FormControl style={{ marginRight: "110px" }}>
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            style={{
                              marginBottom: "10px",
                              marginRight: "70px",
                              color: "black",
                            }}
                          >
                            Gender
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              checked={selectedValue === "female"}
                              value="female"
                              control={<Radio />}
                              label="Female"
                              onChange={handleGender}
                            />
                            <FormControlLabel
                              checked={selectedValue === "male"}
                              value="male"
                              control={<Radio />}
                              label="Male"
                              onChange={handleGender}
                            />
                            <FormControlLabel
                              checked={selectedValue === "child"}
                              value="child"
                              control={<Radio />}
                              label="Child"
                              onChange={handleGender}
                            />
                          </RadioGroup>
                          <Button
                            variant="contained"
                            style={{ marginTop: "20px" }}
                            type="submit"
                          >
                            Calculate
                          </Button>
                        </FormControl>
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}></Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={2} sm={2} md={2}></Grid>
            </Grid>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
