import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import BulkEmailSending from './BulkEmailSending';
import './bulkemailcompose.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from "react-router-dom";
import { NavLink } from 'react-bootstrap';

function BulkEmailCompose({ selectedemails }) {

    const [emailContent, setEmailContent] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [nextpage, setNextPage] = useState('false');
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [emailtemplates, setEmailTemplates] = useState('');
    const firmid = Cookies.get("firmid")
    const userid = Cookies.get("userid")
    const [groupEmails, setGroupEmails] = useState([]);
    const [groupID, setgroupID] = useState([]);


    console.log("selectedemails", selectedemails)

    useEffect(() => {
        selectedemails.forEach((group) => {
            setgroupID(group.GROUP_ID);
            fetchEmailsByGroup(group.GROUP_ID);
        });
    }, [selectedemails]);

    const fetchEmailsByGroup = (groupId) => {
        axios.get(`/getEmailsByGroupId/${groupId}`)
            .then((response) => {
                setGroupEmails(response.data);
            })
            .catch((error) => {
                console.error('Error fetching emails:', error);
            });
    };

    // const handleTextAreaChange = (e) => {
    //     setEmailContent(e.target.value);
    // };

    const handleSubmit = () => {
        // Add logic to handle the submission of the composed email
        console.log('Email content:', emailContent);
        setNextPage('true')
        // You can send the composed email content to the server or perform other actions here
    };

    // Use the selectedemail array as needed in this component

    const handleSubjectChange = (e) => {
        setEmailSubject(e.target.value);
    };

    const handleQuillChange = (value) => {
        setEmailContent(value);

        console.log("quill text", value)
    };

    const handleSaveTemplate = () => {
        // Save the template data (content, subject, etc.) to your application state or server
        // For simplicity, let's just log the data for now
        console.log('Template saved:', { emailContent, emailSubject });


        const data = {
            userid,
            firmid,
            emailSubject,
            emailContent,
        };

        // Make Axios POST request to the backend
        axios.post('/save/current/template', data)
            .then(response => {
                // Handle successful response
                console.log('Template saved successfully:', response.data);
            })
            .catch(error => {
                // Handle error
                console.error('Error adding template:', error);
            });
    };


    const templateaxios = () => {

        axios.get(`/get/mytemplates?firmid=${firmid}&userid=${userid}`)
            .then((response) => {
                setEmailTemplates(response.data);
                console.log("myemail in response", response.data)
            })
            .catch((error) => {
                console.error('Failed to fetch projects:', error);
            });
    }


    const handleLoadTemplate = () => {

        templateaxios();

        setShowTemplateModal(true);

    };

    const handleCloseTemplateModal = () => {
        // Close the template modal
        setShowTemplateModal(false);
    };

    const selectTemplate = (subject, content) => {


        setEmailSubject(subject);
        setEmailContent(content);
        handleCloseTemplateModal();
    }

    const deleteTemplate = (templateId) => {

        axios.delete(`/delete/template/${templateId}`)
            .then((response) => {
                console.log(response.data);
                // Refresh templates after deletion
                templateaxios();


            })
            .catch((error) => {
                console.error('Failed to delete template:', error);
            });


    }





    return (
        <div>

            {nextpage === 'false' ?
                (
                    <div className='bulk-email-compose'>


                        <h3>Following mails have been selected</h3>
                        <br />
                        <div className="email-list-container">
                            {groupEmails.map((email, index) => (
                                <span key={email.E_ID}>
                                    {email.EMAIL}
                                    {index < groupEmails.length - 1 && '; '}
                                    {/* Insert a line break after every third email */}
                                    {(index + 1) % 3 === 0 && <br />}
                                </span>
                            ))}
                        </div>
                        <br />

                        <div>
                            <label htmlFor="emailSubject">Email Subject:</label>
                            <input
                                type="text"
                                id="emailSubject"
                                name="emailSubject"
                                value={emailSubject}
                                onChange={handleSubjectChange}
                            />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="emailContent">Compose Email:</label>


                            <ReactQuill
                                id='emailContent'
                                value={emailContent}
                                onChange={handleQuillChange}
                                modules={{
                                    toolbar: [
                                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                        // ['link', 'image'],
                                        ['link'],
                                        ['clean'],

                                    ]
                                }}
                                formats={[
                                    'header',
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strike',
                                    'blockquote',
                                    'list',
                                    'bullet',
                                    'link',
                                    'image',
                                ]}
                            />

                            <div className='button-container'>
                                <button onClick={handleSubmit}>Submit</button>
                                <div className='right-buttons'>
                                    <button onClick={handleSaveTemplate} style={{ backgroundColor: 'green', color: 'white' }}>Save <img src='../images/Myblock/UserContent/mailsave.png' alt="save" style={{ width: '20px', height: '20px' }} /></button>
                                    <button onClick={handleLoadTemplate} >Load <img src='../images/Myblock/UserContent/mailimport.png' alt="load" style={{ width: '20px', height: '20px' }} /></button>
                                </div>
                            </div>


                        </div>

                        {showTemplateModal && emailtemplates && (
                            <div className='template-modal-overlay'>
                                <div className='template-modal'>
                                    <h3>Email Templates</h3>
                                    {emailtemplates.map((template) => (
                                        <div key={template.T_ID}>
                                            <Link onClick={() => selectTemplate(template.SUBJECT, template.CONTENT)}>{template.SUBJECT} - {new Date(template.INSRT_DTM).toLocaleString()}</Link>
                                            <Link to='' onClick={() => deleteTemplate(template.T_ID)}><img src='../images/Myblock/UserContent/delete.png' alt="Delete" style={{ width: '20px', height: '20px' }} /></Link>
                                        </div>
                                    ))}
                                    <button onClick={handleCloseTemplateModal}>Close</button>
                                </div>
                            </div>
                        )}

                    </div>) : (<BulkEmailSending selectedemails={groupEmails} subject={emailSubject} message={emailContent} groupID={groupID}/>)

            }
        </div>
    );
}

export default BulkEmailCompose;