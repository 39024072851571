import React, {useState,useEffect, useLayoutEffect} from 'react'

import axios from 'axios';
import './News_techie.css';
import Content_techie from './Content_techie';
import Heading_art from './Heading_art';


const News_techie = () => {
    const [items,SetItems]=useState([])
    let port=sessionStorage.getItem("portalid")
    //let pin=sessionStorage.getItem("pincode")
    // console.log(pin)
    useEffect(() => { 
      axios.post('http://localhost:5000/news/techie_index/articles',{"port":port})

      //  axios.post('http://localhost:5000/news/techie_index/articles',{"port":port,"pin":pin})
  .then(function (response) {
     console.log("techie index articles",response.data);
    SetItems(response.data)
  })
 } ,[]);

  return (
    <>
    <div>
        <Heading_art />
       <section className='art'>
            <div className='container'>
                {items.map((item)=>{
                    return <Content_techie key={item.id} item={item}/>
                })}
            </div>
        </section>
       
    </div>
    </>
  )
  
}

export default News_techie
