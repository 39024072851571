import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeadingD from './HeadingVend';
import { useNavigate, NavLink } from 'react-router-dom';
import './style.css';
import UserContent from './UserContent';

const UserContentMainPage = () => {
  const [data1, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);


  const site = sessionStorage.getItem('site');
  const port1 = sessionStorage.getItem('portalid');
  const parentport = sessionStorage.getItem('parentportalid');
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .all([
        axios.post('/news/headlines/usercontent', { port1, parentport }),
        axios.post('/myblock/altimage/usercontent'),
        axios.post('/techieindex/altimage/usercontent')
      ])
      .then(axios.spread((data1Response, data2Response, data3Response) => {
        console.log('Data from /news/headlines/usercontent:', data1Response.data);
        console.log('Data from /myblock/altimage/usercontent:', data2Response.data);
        console.log('Data from /techieindex/altimage/usercontent:', data3Response.data);

        let filteredData1 = data1Response.data;

        // Condition to delete two items if site is 'Techie-Index' and port1 is '9455'
        if (site === 'Techie-Index' && port1 === '9455') {
          filteredData1 = filteredData1.slice(2);
        }

        // Condition to delete one item for other specific ports
        if (site === 'Techie-Index' && ['10231', '10226', '9458'].includes(port1)) {
          filteredData1 = filteredData1.slice(1);
        }

        setData(filteredData1);


        setData2(data2Response.data.map(item => item || 0));
        setData3(data3Response.data.map(item => item || 0));
      }))
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [port1, parentport]);



  const handleTitleClick = (title, docId) => {
    window.scrollTo({ top: 0, behavior: 'auto' });

    if (site === 'Techie-Index') {
      switch (port1) {
        case '10231':
          navigate('/imagelist');
          break;
        case '9455':
          if (title === 'Startup Ranking 2023') {
            navigate('/Ranking');
          } else if (title === 'VC Firm Ranking 2023') {
            navigate('/UsaVC');
          }
          break;
        case '10226':
          navigate('/H1b');
          break;
        case '9458':
          navigate('/CanadaUniversity');
          break;
        default:
          break;
      }
    }

    // Remove the object from data1

  };

  const renderContentBox = (title, imgSrc, altText, docId) => (
    <div className='box' onClick={() => handleTitleClick(title, docId)}>
      <div className='ima'>
        <img src={imgSrc} alt={altText} />
      </div>
      <h3>{title}</h3>
    </div>
  );

  return (
    <>
      <section className="vendor">
        <div className="container">
          <HeadingD />
          <div className="content">
            {site === 'Techie-Index' && port1 === '10231' && renderContentBox('Ranking list of 2021', "../images/Techieindex/UserContent/icampus.jpeg", "Ranking list of 2021", 10231)}
            {site === 'Techie-Index' && port1 === '9455' && renderContentBox('Startup Ranking 2023', "../images/Techieindex/UserContent/statupusa.png", "Startup Ranking 2023", 9455)}
            {site === 'Techie-Index' && port1 === '9455' && renderContentBox('VC Firm Ranking 2023', "../images/Techieindex/UserContent/vc.webp", "VC Firm Ranking 2023", 9455)}
            {site === 'Techie-Index' && port1 === '10226' && renderContentBox('H1B Sponsors', "../images/Techieindex/UserContent/h1b.jpg", "H1B Sponsors Ranking 2023", 10226)}
            {site === 'Techie-Index' && port1 === '9458' && renderContentBox('canada university', "../images/Techieindex/UserContent/canada-uni.webp", "Canada University Ranking 2023", 9458)}

            {data1.map((item, i) => {

              if (item === undefined) {
                return null; // Skip undefined items
              }
              const imageData = site === 'Myblock' ? data2[i] : data3[i];
              return <UserContent key={i} item={item} data={imageData || {}} />;
            })}
          </div>
        </div>
      </section>
      <p align="center">
        <NavLink to="/AllUserContent">More</NavLink>
      </p>
    </>
  );
};

export default UserContentMainPage;