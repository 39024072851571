import React from 'react'
import './Heading_art.css'

const Heading_art = () => {
  const getSite=sessionStorage.getItem("site")
  return (
    <div className='heading_art'>
     <h6> Articles </h6> 
    </div>
  )
}

export default Heading_art
