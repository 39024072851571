import { React, useState, useEffect } from 'react'
import axios from 'axios';
import Homepages from '../Home/Homepages';
import Material from './material';
import './mainpage.css';
import { useLocation } from 'react-router-dom';


const SubPage = () => {


  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('value'); 


  let getCity = sessionStorage.getItem("city")

  let getPortal = sessionStorage.getItem("portalid")

  let getPincode = sessionStorage.getItem("pincode")
  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])
  const [data3, setData3] = useState([])
  const [data4, setData4] = useState([])
  let id = sessionStorage.getItem("portalid", "****")
  sessionStorage.setItem("site", "Myblock");

  const handlepincode = () => {
    // console.log(PORTALID)
    sessionStorage.setItem("pincode", data1.current.value)
    window.location.reload();

  }

 
  useEffect(() => {
    axios.all([
      axios.post('/cityS/quality/all'),
      axios.post(`/cityS/childcount/all`),
      axios.post(`/cityS/active/all`),
      axios.post(`/cityS/latest/all`)
    ])
      .then(axios.spread((data1, data2, data3,data4) => {
        setData1(data1.data)
        setData2(data2.data)
        setData3(data3.data)
        setData4(data4.data)
      }));
  }, []);


  let dataToMap;
  if (type === 'quality') {
    dataToMap = data1;
  } else if (type === 'childcount') {
    dataToMap = data2;
  } else if (type === 'active') {
    dataToMap = data3;
  } else if (type === 'latest'){
    dataToMap = data4;
  }


  return (

    <div>
      {
        getPortal && getCity || getPincode ?
          <Homepages /> :

          <div className='page container paddingTB'>
            {/* <div className='input'>
            <h5>Enter your Pincode</h5>
           <input placeholder="Enter Pincode" ref={data} />
      <button onClick={handlepincode}>search</button>
      </div> */}
            <div className='city'>
              <h5>Select your city</h5>


              {dataToMap.map((portal) => {
                    
                return (<div className='mainpage'>

                  <Material key={portal.id} portal={portal} />

                </div>
                )
              })}
            </div>
          </div>
      }
    </div>
  )
}

export default SubPage
