import React, { useState } from 'react';
import axios from 'axios';
import './addmailingemail.css';
import Cookies from 'js-cookie';

function AddMailingEmail() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const firmid = Cookies.get("firmid")
  const userid = Cookies.get("userid")
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e) => {

    const inputEmail = e.target.value;
    setEmail(inputEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(emailRegex.test(inputEmail) ? '' : 'Invalid email address');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleAddEmail = () => {

    if (emailError) {
      console.error('Invalid email address');
      return;
    }
    // Perform validation if needed

    // Prepare data for the POST request
    const data = {
      userid,
      firmid,
      email,
      password,
    };

    // Make Axios POST request to the backend
    axios.post('/add/sender/email', data)
      .then(response => {
        // Handle successful response
        console.log('Email added successfully:', response.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error adding email:', error);
      });
  };
  return (
    <div className='add-mailing-email'>
      <br /><br />
      <label>Email ID</label>
      <input type="text" value={email} onChange={handleEmailChange} required />
      {emailError && <span className="error">{emailError}</span>}
      <br /><br />
      <label>App Password</label>
      <input type="text" value={password} onChange={handlePasswordChange} required />

      <button onClick={handleAddEmail}>Add</button>

      <br /><br />
      <h5>Gmail user will have to make seperate App password - follow instructions given in following document</h5>

      <a href='https://support.google.com/mail/answer/185833?hl=en'>Password Steps</a>

    </div>
  )
}

export default AddMailingEmail