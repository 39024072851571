import React, { useState, useEffect } from 'react'
import axios from 'axios';
import PageLog from '../../PageLog/PageLog';


function BusinessFromId() {

    const [id, setId] = useState(null);
    const [vendordata, setData] = useState([])

    useEffect(() => {
        const url = window.location.href;
        const regex = /business\/(\d+)$/; // assumes ID is the last part of the URL
        const match = url.match(regex);
        const id = match && match[1];


        if (id) {
            setId(id); // store ID in state variable
            axios.post('/BusinessID/id', { "id": id })
                .then(response => {
                    setData(response.data);

                    console.log("portal value", response.data)

                    // store response data in state variable
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, []);


    return (
        <div>
            <PageLog />
            <div className='pages'>


                {vendordata.map((vend) => {
                    return (<div className='vendorpage'>
                        <img src={vend.image} alt='' />
                        <h3>{vend.VEND_TITL}</h3>

                        {vend.VEND_DESC}
                        {vend.VEND_DET}
                        <br /><br />
                        Location:
                        <h6>{vend.VEND_CON_ADDR}</h6>
                        Website:
                        <a href={vend.VEND_URL}>
                            {vend.VEND_URL}
                        </a>
                    </div>
                    )
                })}
            </div>

        </div>
    );
}

export default BusinessFromId