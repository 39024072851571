import React, { useEffect, useState } from 'react';
import axios from 'axios';

const KfDocmntApprovalDetails = ({  }) => {
    const [document, setDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const docid ='668290965b0bef9c06ea5e89';

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await axios.get(`/kfdocmntapproval/viewdetails`, {
                    params: { docid }
                });
                setDocument(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDocument();
    }, [docid]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading document: {error.message}</p>;

    return (
        <div>
            {document && (
                <>
                    <h1>{document.DOC_TITL}</h1>
                    <p>{document.DOC_DESC}</p>
                    <a href={document.DOC_URL} target="_blank" rel="noopener noreferrer">Read more</a>
                    <p>Date: {new Date(document.DOC_SDATE).toLocaleDateString()}</p>
                    <img src={document.image} alt="Document" />
                </>
            )}
        </div>
    );
};

export default KfDocmntApprovalDetails;