import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";

// Divider
import Divider from "@mui/material/Divider";
import Breakfast from "./Breakfast";
import Lunch from "./Lunch";
import Dinner from "./Dinner";

export default function FoodRequired() {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h4">Foods For Good Health</Typography>

        <Breakfast />

        {/* Lunch */}
        <Divider style={{ margin: "20px" }} />
        <Lunch />
        {/* Dinner */}
        <Divider style={{ margin: "20px" }} />
        <Dinner />
      </CardContent>
    </Card>
  );
}
