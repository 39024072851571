import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import BulkEmailCompose from './BulkEmailCompose';
import './bulkemail.css';
import { useNavigate, Link } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';


function BulkEmailHome() {

  const firmid = Cookies.get("firmid")
  const userid = Cookies.get("userid")
  const usertype = Cookies.get("usertype")

  const [emails, setEmails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedEmails, setSelectedEmails] = useState([]);


  console.log("firmid", firmid)
  console.log("userid", userid)

  const [nextpage, setNextPage] = useState('false');
  const navigate = useNavigate();


  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } else if (usertype === "USERAPP") {
      alert("Login with Businuess User Credentials");
      navigate('/login');
    }
  }, [userid, navigate]);


  useEffect(() => {
    axios.get(`/get/myemailinfo?firmid=${firmid}&userid=${userid}`)
      .then((response) => {
        //console.log(response.data)
        console.log(response.data.length)
        if (response.data.length === 0) {
        }
      })
      .catch((error) => {

        if (error.response && error.response.status === 404) {
          alert("You dont seem to have added your mailing email,please add")
          navigate(`/addsender`)

        } else {
          console.error('Failed to fetch projects:', error);
        }
      });

  }, []);



  useEffect(() => {
    axios.get(`/get/myemailgroup?firmid=${firmid}&userid=${userid}`)
      .then((response) => {
        const initialCheckboxStates = response.data.reduce((acc, email) => {
          acc[email.GROUP_ID] = false;
          return acc;
        }, {});
        console.log("emails", response.data)
        setEmails(response.data);
        setCheckboxStates(initialCheckboxStates);
      })
      .catch((error) => {
        console.error('Failed to fetch projects:', error);
      });

  }, []);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCheckboxStates((prevStates) => {
      const updatedStates = {};
      Object.keys(prevStates).forEach((key) => {
        updatedStates[key] = !selectAll;
      });
      return updatedStates;
    });
  };

  const handleCheckboxChange = (e, emailId) => {
    const isChecked = e.target.checked;
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [emailId]: isChecked,
    }));
  };

  const handleSubmit = () => {

    const selected = emails.filter((email) => checkboxStates[email.GROUP_ID]);
    //console.log("selected email",selected)
    //debugger
    setSelectedEmails(selected);
    setNextPage('true')

  };


  return (
    <div>
      <br />
      <h1>Edex App</h1>

      <h2>Select a Group</h2>
      {nextpage === 'false' ?
        (<div className='bulk-email-home' >

          <table className="w1-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />

                </th>
                <th>Check All</th>
              </tr>
            </thead>
            <tbody>
              {emails.map((email) => (
                <tr key={email.GROUP_ID} className="email-item">
                  <td>
                    <input
                      type="checkbox"
                      checked={checkboxStates[email.GROUP_ID]}
                      onChange={(e) => handleCheckboxChange(e, email.GROUP_ID)}
                    />
                  </td>
                  <td>

                    <Link to={`/groupdetails?id=${email.GROUP_ID}`}>
                      {email.GROUP_NAME}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className='bulk-div'> */}

          <Link to="/addsendingemails">Add Emails into Groups </Link> &nbsp; &nbsp;
          <Link to="/emailsetdefault">Set default email sender </Link>&nbsp; &nbsp;
          <Link to="/addsender">Add New email sender </Link>&nbsp; &nbsp;
          <Link to="/mailhistory">View History </Link>
          {/* <Link to="/bulkemailhidemail">Hide unwanted Groups</Link> */}
          <br /><br />


          {/* </div> */}
          <div className='bulk-div-button'>

            <button onClick={handleSubmit} >Submit</button>


          </div>


        </div >) : (<BulkEmailCompose selectedemails={selectedEmails} />)

      }
      <PageLog />
    </div>
  )
}

export default BulkEmailHome