import React, { useState, useEffect } from 'react';
import './MyApp.css'; // Replace 'styles.css' with the correct path to your CSS file
import Cookies from 'js-cookie';
import axios from 'axios';
import PageLogLocation from '../PageLog/PageLogLocation';


const AppHome = () => {
  const [activeLink, setActiveLink] = useState('MyNews'); // Initial active link is 'MyNews'
  const [showMyNewsContent, setShowMyNewsContent] = useState(false); // State to control content display
  const [portalid, setPortalId] = useState(''); // State to store portalid
  const [showReport, setShowReport] = useState(false);
  const [healthLink, setHealthLink] = useState(''); // State to store the health link
  const role_id = Cookies.get("role_id");
  const [menu, setMenu] = useState('');

  useEffect(() => {
    // Retrieve portalid and userId from cookies
    const retrievedPortalId = Cookies.get('portalid');
    const retrievedUserId = Cookies.get('userid');

    if (retrievedPortalId) {
      console.log('Retrieved portalid:', retrievedPortalId);
      setPortalId(retrievedPortalId);
    }

    if (retrievedUserId) {
      console.log('Retrieved userId:', retrievedUserId);
      // Do something with retrievedUserId if needed
    }
  }, []);

  useEffect(() => {
    // Construct the cityLink using portalid
    const cityLink = `http://61.2.142.91:7100/CityFromId/${portalid}`;

    // Check if the portalid cookie is set
    if (!portalid) {
      // If the portalid cookie is not set, then display an error message
      console.error('Please set the portalid cookie before using this app.');
    }

    // Check if the cityLink is a valid URL
    if (!cityLink.startsWith('http://') && !cityLink.startsWith('https://')) {
      // If the cityLink is not a valid URL, then display an error message
      console.error('The cityLink is not a valid URL.');
    }
  }, [portalid]);

  // Handle the MyNews click event
  const handleMyNewsClick = () => {
    setActiveLink('MyNews');
    setShowMyNewsContent(true);
    setShowReport(false); // Hide the Report component when MyNews is clicked
  };

  const handleMyHealthClick = () => {
    // Retrieve the user ID (userId) from the cookie
    const retrievedUserId = Cookies.get('userid');

    if (!retrievedUserId) {
      console.error('User ID not found in the cookie.'); // Log an error message if userId is not found
      return;
    }

    console.log('Retrieved User ID:', retrievedUserId); // Log the retrieved userId

    // Make an Axios GET request to fetch the link based on the user ID from the cookie
    axios
      .get(`http://localhost:8432/api/fetch-link?userId=${retrievedUserId}`)
      .then((response) => {
        const link = response.data.link;

        if (link) {
          // If a valid link is received, set it to healthLink
          console.log('Received value from the backend:', link);
          setHealthLink(link);
        } else {
          console.error('No valid link received from the backend.');
        }

        setShowReport(true); // Display the Report component
        setShowMyNewsContent(false); // Hide the MyNews content
      })
      .catch((error) => {
        console.error('Error fetching link:', error);
      });
  };


  useEffect(() => {
    axios.post('/user/app/menu', { "role_id": role_id})
      .then(function (response) {
        //console.log(response.data);
        setMenu(response.data)
      })
      .catch(function (error) {
        console.error('Failed to fetch menu:', error);
      });
  }, [])




  return (
    <div className="myApp-home">
      <PageLogLocation />
      <header className="myApp-header">


        <div className="myApp-link-box">
          {menu ? (
            menu.map((link, index) => (
              <div key={index}>
                <a href={link.MENU_URL} className={`myApp-link`}> {link.MENU_NAME}</a>
                <br />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>




      </header>
    </div>
  );
};

export default AppHome;
