
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import PageLog from '../PageLog/PageLog';

function BusinessUserApprove() {

    const firmid = Cookies.get("firmid")
    const [userIds, SetUserIds] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(`/get/inactive/users?firmid=${firmid}`)
            .then((response) => {
                SetUserIds(response.data); // Set the received project IDs into state
            })
            .catch((error) => {
                console.error('Failed to fetch projects:', error);
            });

    }, []);

    const activate = (empid) =>{

        
        navigate(`/businessuseractivate?empid=${empid}`)

    }




    return (
        <div className="business-content-container">
            <PageLog/>
            <h1></h1>
            <table>
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>APPDATE</th>
                        <th>Phone</th>
                        <th>Mobile</th>                        
                        <th>Approve</th>

                        {/* Add more headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {userIds.map((employee) => (
                        <tr key={employee.EMPID}>
                            <td>{employee.EMPID}</td>
                            <td>{employee.EMPNAME}</td>
                            <td>{employee.DESIGNATION}</td>
                            <td>{new Date(employee.APPDATE).toLocaleString()}</td>
                            <td>{employee.PHONE}</td>
                            <td>{employee.MOBILE}</td>
                            <td><button onClick={() => activate(employee.EMPID)} >Activate</button></td>



                            {/* Add more cells as needed */}
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <div className='back-link'>
                <a href='/businessuserhome'>Back</a>

            </div>

        </div>
    )
}

export default BusinessUserApprove