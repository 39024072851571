import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './kfdocmntapproval.css'; // Import the CSS file for styling

const KfDocmntApproval = () => {
    const [documents, setDocuments] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;
    const portalid = 4501;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchDocuments(currentPage);
    }, [currentPage]);

    const fetchDocuments = async (page) => {
        try {
            setLoading(true);
            const response = await axios.get(`/kfdocmntapproval/list?portalid=${portalid}&page=${page}`);
            setDocuments(response.data.data);
            setTotalRows(response.data.totalRows);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching documents:', error);
            setLoading(false);
        }
    };

    const totalPages = Math.ceil(totalRows / limit);
    const maxVisiblePages = 10; // Number of pagination buttons to display

    const renderPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        let endPage = startPage + maxVisiblePages - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - maxVisiblePages + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`pagination-button ${currentPage === i ? 'active' : ''}`}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    const handleQuickApprove = async (event, docid) => {
        event.preventDefault();
        try {
            // Update document price to 3
            await axios.put('/kfdocmntapproval/updateDocPrice', { docid });

            // Fetch current page documents again to ensure correct display
            fetchDocuments(currentPage);

            setTotalRows(prevTotalRows => prevTotalRows - 1); // Update the totalRows count
        } catch (error) {
            console.error('Error handling quick approval:', error);
        }
    };








    return (
        <div>
            <h1>Documents</h1>
            <div className="documents-container">
                {loading && (
                    <div className="loader-overlay">
                        <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." className="loader" />
                    </div>
                )}
                <table className={`document-table ${loading ? 'loading' : ''}`}>
                    <thead>
                        <tr>
                            <th>Document Title</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map(doc => (
                            <tr key={doc._id}>
                                <td><a href={`/kfdocmntapprovaldetails?id=${doc._id}`} >{doc.DOC_TITL}</a></td>
                                <td>
                                    <a href="#" onClick={(event) => handleQuickApprove(event, doc._id)}>Quick Approve</a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                <div className="pagination">
                    {currentPage > 1 && (
                        <button
                            className="pagination-button"
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            Previous
                        </button>
                    )}
                    {renderPageNumbers()}
                    {currentPage < totalPages && (
                        <button
                            className="pagination-button"
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            Next
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default KfDocmntApproval;