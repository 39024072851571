import React, { useEffect, useState } from 'react';
import './Actportal.css'; // Import the CSS file
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import PageLog from '../../PageLog/PageLog';

const ActivePortalComponent = () => {
  const [activePortalData, setActivePortalData] = useState([]);

/*   const location = useLocation ();

  //debugger
  console.log("state",location.state);
  let act_id = location.state.id;   */

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const act_id = query.get('value'); 


  // useEffect(() => {
  //   console.log('Current act_id:', act_id);
  //   //debugger
  //   // fetch(`http://61.2.142.91:8411/actportal/${act_id}`)
  //   fetch(`/actportal/${act_id}`)
  //     .then((response) => response.json())
  //     .then((data) => setActivePortalData(data))
  //     .catch((error) => console.error('Error:', error));
  // }, []); 

  useEffect(() => {
    console.log('Current act_id:', act_id);
    axios.get(`/actportal/${act_id}`).
        then((response) => { setActivePortalData(response.data) })
        .catch((error) => { console.log(error) })
}, []);

  
  

  return (
    <div className="active-portal-container">
      <PageLog/>

      <h1 className="active-portal-heading">ACTIVITY PORTAL</h1>
      <div className="active-portal-content">
        <div className="active-portal-column">
          <h2>SUMMARY</h2>
          <ul>
            {activePortalData.map((row) => (
              <li key={row.CMPN_DTL_ID}>{row.DISPLAY_SUMMARY}</li>
            ))}
          </ul>
        </div>
        <div className="active-portal-column">
          <h2>DETAIL</h2>
          <ul>
            {activePortalData.map((row) => (
              <li key={row.CMPN_DTL_ID}>{row.DISPLAY_DETAIL}</li>
            ))}
          </ul>
        </div>
        <div className="active-portal-column">
          <h2>TEAM INSTRUCTIONS</h2>
          <ul>
            {activePortalData.map((row) => (
              <li key={row.CMPN_DTL_ID}>{row.TEAM_INSTRUCTIONS}</li>
            ))}
          </ul>
        </div>
        <div className="active-portal-column">
          <h2>USER BENEFITS</h2>
          <ul>
            {activePortalData.map((row) => (
              <li key={row.CMPN_DTL_ID}>{row.USER_BENEFITS}</li>
            ))}
          </ul>
        </div>
        <div className="active-portal-column">
          <h2>COMMUNITY BENEFITS</h2>
          <ul>
            {activePortalData.map((row) => (
              <li key={row.CMPN_DTL_ID}>{row.COMMUNITY_BENEFITS}</li>
            ))}
          </ul>
        </div>
        <div className="active-portal-column">
          <h2>HOW TO HELP</h2>
          <ul>
            {activePortalData.map((row) => (
              <li key={row.CMPN_DTL_ID}>{row.HOW_TO_HELP}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ActivePortalComponent;
