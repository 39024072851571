import React from 'react';
import { useState , useEffect } from 'react';
import axios from 'axios';


const More = () => {
    
  const [newsdata,setNewsData]=useState([])
    
//   useEffect(() => { 
//     axios.get('http://localhost:5000/citynews/news/singlepages')
// .then(function (response) {
// // console.log(response.data);
// setNewsData(response.data)
// })
// } ,[]);
 

  return (
    <div>
       <h1>Hai</h1> 
      {newsdata.map((news)=>{
                    return <div> {news.DOC_TITL}</div>
                })}
    </div>
  )
}

export default More
