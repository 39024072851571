import { React, useState, useEffect } from 'react';
import './businessuser.css'
import Cookies from 'js-cookie';
import axios from 'axios';
import PageLogLocation from '../PageLog/PageLogLocation';
import { useNavigate, useLocation } from 'react-router-dom';

function BusinessUserHome() {


  const [pagemenu, setMenu] = useState('');
  const role_id = Cookies.get("role_id");
  const category = Cookies.get("category");
  const userid = Cookies.get("userid");
  const navigate = useNavigate()

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } 
  }, [userid, navigate]);



  useEffect(() => {
    axios.post('/business/app/menu', { "role_id": role_id, "category": category })
      .then(function (response) {
        //console.log(response.data);
        setMenu(response.data)
      })
      .catch(function (error) {
        console.error('Failed to fetch menu:', error);
      });
  }, [])


  return (
    <div className="business-container">
      <PageLogLocation />
      <header >
        {/* <div className="business-link-box">
          <a href="/businessuserlist" className={`business-link`} >
            Employee List
          </a>
          <br/>
          <a href="/testqmmain" className={`business-link`} >
            My Tests
          </a>
         
        </div> */}

        <div className="business-link-box">
          {pagemenu ? (
            pagemenu.map((link, index) => (
              <div key={index}>
                <a href={link.MENU_URL} className={`business-link`}>
                  {link.MENU_NAME}
                </a>
                <br />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="business-content-container-temp">

        </div>
      </header>
    </div >
  )
}

export default BusinessUserHome