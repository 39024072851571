import React, { useState, useEffect } from 'react';
import './Questions.css';
import AddedProbs from './AddedProbs';
import ActiveProbs from './ActiveProbs';
import axios from 'axios';
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';


const Questions = () => {
  const [inputValue, setInputValue] = useState('');
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const portalid = Cookies.get('portalid');
  const userid = Cookies.get('userid');
  const getCity = sessionStorage.getItem("city");
  const username = Cookies.get('name') || 'Anonymous';
  const [parentlocation, setParentLocation] = useState('');
  //const [submitted, setSubmitted] = useState(false);

  console.log("username", username)

  const fetchDropdownOptions = async () => {
    //debugger
    try {
      const response = await axios.get('/get-dropdown-options');
      if (response.status === 200) {
        //const optionsData = response.data;
        setDropdownOptions(response.data);
      } else {
        console.error('Failed to fetch dropdown options from the backend');
      }
    } catch (error) {
      console.error('Error fetching dropdown options from the backend:', error);
    }
  };


  useEffect(() => {
    fetchDropdownOptions();
    fetchparentname();
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDropdownChange = (event) => {
    setSelectedDropdownValue(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/submit-question', {
        typedQuestion: inputValue,
        selectedDropdownValue: selectedDropdownValue, // Include the selected dropdown value
        portalid,
        userid,
        username,
      });

      if (response.status === 200) {
        console.log('Question submitted successfully');
        // Handle success, reset form fields or perform other actions
        setInputValue('');
        setSelectedDropdownValue(''); // Optionally reset the selected dropdown value
        alert("submitted")
        window.location.reload();
        //setSubmitted(true)

      } else {
        console.error('Failed to submit question');
      }

      fetchDropdownOptions();
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };

  const fetchparentname = async () => {
    try {
      const response = await axios.get('/fetch-portal-parent', {

        params: {
          portalid: portalid

        },
      });

      console.log("parent", response.data)

      setParentLocation(response.data)

    } catch (error) {
      console.error('Error fetching active questions from the backend:', error);
    }


  }

  return (
    <div className="main-container">
      {parentlocation.resultLength > 0 && (
        <>
          <div className="form-container">
            <h3>Most reported Issues in {getCity}</h3>
            <select className='drop' value={selectedDropdownValue} onChange={handleDropdownChange}>
              <option value="" disabled>Select a problem from here</option>
              {dropdownOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <h3>OR</h3>
            <h4>To report a New Issue</h4>
            <h4>Type it Below</h4>
            <textarea
              className='textbox'
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Enter here"
            />
            {showSuccessMessage && (
              <div className="success-message">
                <p>Added successfully!</p>
              </div>
            )}
            <button onClick={handleSubmit}>Submit</button>
          </div>
          <div className="added-probs-container">
            <AddedProbs />


            {parentlocation.resultLength > 0 && (

              <ActiveProbs parentportalname={parentlocation.parentPortalName} />

            )}
          </div>
        </>
      )}
          <PageLog/>
    </div>
  );
};

export default Questions;
