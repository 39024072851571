import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './Interviewpage.css'
import PageLog from '../../PageLog/PageLog';


const InterviewDetails = () => {
  const location = useLocation();
  console.log("state", location.state)
  let vendid = location.state.id


  const [vendordata, setVendorData] = useState([])



  useEffect(() => {
    axios.post('/spotlight/companies/name', { "id": vendid })
      .then(function (response) {
        console.log("interview", response.data);
        setVendorData(response.data)
      })
  }, []);


  // console.log("interview 11", vendordata);

  return (
    <div className='pages'>
      <PageLog />
      {vendordata.map((vend, index) => {
        const url = vend.REFYX_URL || "http://61.2.142.91:8080/refyx/login.jsp"; 
        return (<div className='interviewpage' key={index}>
          <img src={vend.photo} alt='' />
          <br/>  
          <h3>{vend.interviewPerson}</h3>
          <h6>{vend.designation}</h6>
          <br/>          
          <h6>{vend.aboutPerson}</h6>
          {/* <a href={`http://61.2.142.91:8080/refyx/login.jsp`} > */}
          <a href={url} >
            <button>More</button>
          </a>
        </div>
        )
      })}
    </div>
  )
}

export default InterviewDetails
