import React from 'react'
import './style.css'
import PageLog from '../PageLog/PageLog'

const Highlights = () => {
  return (
    <div className='total'>
      <PageLog/>

      <div className='dochead'>
        <h2>Bolgatty Palace & Island Resort</h2>
      <img className='imgs'  src="../images/Place/highlights.png" />
      <h6>

      Housing a beautiful resort within the oldest Dutch palace outside of Holland, Bolgatty Palace & Island Resort is located in the scenic Kochi. It features an outdoor swimming pool, a fitness centre and a spa and wellness centre.

The accommodation will provide you with a TV and air conditioning. Private bathroom also comes with free toiletries. Extras include bed linen.

At Bolgatty Palace & Island Resort you will find meeting facilities, a games room and an ironing service. An array of activities can be enjoyed on site or in the surroundings, including golfing and fishing. The property offers free parking.

The picturesque Vembanad Lake is located adjacent to the property. It is 5 km from both the KSRTC Bus Station and Ernakulam Junction Railway Station. The Cochin International Airport is 32 km away.

The in-house restaurant serves Indian, Chinese and continental delicacies. Room service can be requested for in-room dining comforts.

Couples particularly like the location — they rated it 8.9 for a two-person trip.

Bolgatty Palace & Island Resort has been welcoming Booking.com guests since 28 May 2014.      
      </h6>
      </div>
    </div>
  )
}

export default Highlights
