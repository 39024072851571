
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './businessuser.css'
import PageLog from '../PageLog/PageLog';

function BusinessUserList() {

    const firmid = Cookies.get("firmid")
    const usertype = Cookies.get("usertype")
    
    const navigate = useNavigate()
    const [employeeData, setEmployeeData] = useState([]);


    useEffect(() => {
        if (!firmid) {
            alert("Please login, else contact admin if you see alert even after login");
            navigate('/login');
        } else {
            axios.post("/employee/list", { "firmid": firmid })
                .then((response) => {
                    console.log("response.data", response.data);
                    setEmployeeData(response.data); // Assuming the response is an array

                })
                .catch((error) => {
                    console.error("An error occurred during login:", error);
                });
        }
    }, [firmid, navigate]);


    return (
        <div className="business-content-container">
          <PageLog/>
        <h1></h1>
        <table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Designation</th>
              <th>APPDATE</th>
              <th>Phone</th>
              <th>Mobile</th>

              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {employeeData.map((employee) => (
              <tr key={employee.EMPID}>
                <td>{employee.EMPID}</td>
                <td>{employee.EMPNAME}</td>
                <td>{employee.DESIGNATION}</td>
                <td>{employee.APPDATE}</td>
                <td>{employee.PHONE}</td>
                <td>{employee.MOBILE}</td>



                {/* Add more cells as needed */}
              </tr>
            ))}
          </tbody>
        </table>
        <br/>
        <div className='back-link'>
        <a href='/businessuserhome'>Back</a>

        </div>
     
      </div>
    )
}

export default BusinessUserList