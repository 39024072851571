import './customer.scss';

function Customer() {
    return (<div className='customers-section'>
        <h2>
            Our Customers
        </h2>
        <div className="snip1533">
            <figcaption>
                <blockquote>
                    <p>Myblocks revolutionized our resort's social media marketing strategy with AI-driven industry best practices and recommendations. Their expertise in
                        interpreting data and providing actionable insights has significantly boosted our user engagement metrics. Thanks to Myblocks, we've taken our resort's
                        social media presence to new heights.
                    </p>
                </blockquote>
                <h3>Tapovan </h3>
                <h4  className='h4-customer'>Luxury Resorts</h4>
            </figcaption>
        </div>
        <div className="snip1533">
            <figcaption>
                <blockquote>
                    <p>With Myblocks, the advantage lies in its comprehensive coverage of the entire ML pipeline. Offering sophisticated ML templates and superior performance
                        compared to other platforms, Myblocks simplifies the process of creating models for any problem. Mastering the platform was swift, and now I confidently
                        handle large, complex data processing tasks on the cloud with ease.
                    </p>
                </blockquote>
                <h3>Merlot</h3>
                <h4  className='h4-customer'>Home Decor</h4>
            </figcaption>
        </div>
        <div className="snip1533">
            <figcaption>
                <blockquote>
                    <p>As a longtime partner, Myblocks has consistently surpassed my expectations with their dedication to advancement and innovation. Their responsiveness to
                        feedback is remarkable, and they consistently deliver top-notch data modeling. Myblocks has truly been an invaluable asset in driving our business forward 
                        as well as helping us more fully utalize our existing resources.
                    </p>
                </blockquote>
                <h3>Premier Industries</h3>
                <h4  className='h4-customer'>Manufacturing Company</h4>
            </figcaption>
        </div>
    </div>
    );
}

export default Customer;
