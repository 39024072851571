import React from 'react'

const AD = ({data:{DOC_URL,image,DOC_DESC,DOC_SUBCATEGRY,DOC_TITL}}) => {
  
  return (
    <>
         <div className='box'>
        <div className='img'>
           <img src={image} alt='' />
        </div>
        <div className='text'>
          <span className='category'>{DOC_SUBCATEGRY}</span>
          <a href={DOC_URL}>
        <h1  >{DOC_TITL}</h1>
        </a>
        <h2>{DOC_DESC.slice(0,300)}...</h2>
        
       
        
        </div>
      </div>
    
    </>
  )
}

export default AD
