import React, { useRef, useState, useEffect } from 'react'
import './register.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'


function BusinessRegister() {

    const [name, setName] = useState("");
    const [id, setID] = useState("");
    const [key, setKey] = useState("");
    const [idError, setIDError] = useState("");
    const [keyError, setKeyError] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");
    // const [firmError, setFirmError] = useState("");
    const [contentError, setContentError] = useState("");
    const navigate = useNavigate()
    // const [firmIds, setFirmIds] = useState([]);
    const [ContentCategory, setContentCategory] = useState([]);
    // const [selectedFirmId, setSelectedFirmId] = useState('');
    const [selectedContentId, setSelectedContentId] = useState(null);
    const inputRef = useRef(null);
    const [pindata, setPin] = useState('')
    const [portalid, setPortID] = useState(3025);


    const validateID = (id) => {
        if (!id || id.trim() === "") {
            return "Username is required.";
        }
        return "";
    };

    const validateKey = (key) => {
        if (!key || key.trim() === "") {
            return "Password is required.";
        }
        return "";
    };

    const validatePhone = (phone) => {
        // Use a regular expression to check for a valid phone number format
        if (!/^[0-9]{10}$/.test(phone)) {
            return "Phone number should be 10 digits long.";
        }
        return "";
    };

    const validateEmail = (email) => {
        // Use a regular expression to check for a valid email format
        if (!/^\S+@\S+\.\S+$/.test(email)) {
            return "Invalid email address format.";
        }
        return "";
    };

    // const validateFirm = (selectedFirmId) => {
    //     if (!selectedFirmId || selectedFirmId.trim() === "") {
    //         return "Please select a Firm";
    //     }
    //     return "";
    // };

    const signup = () => {

        if (phone !== "") {
            console.log("phone", phone)
            const phoneError = validatePhone(phone);


            if (phoneError) {
                setPhoneError(phoneError);

                return;
            }
        }

        if (email !== "") {
            console.log("phone", phone)

            const emailError = validateEmail(email);

            if (emailError) {

                setEmailError(emailError);
                return;
            }
        }
        //debugger
        const idError1 = validateID(id);
        const keyError1 = validateKey(key);

        // const firmError1 = validateFirm(selectedFirmId);

        // if (idError1 || keyError1 || firmError1) {
        //     setIDError(idError1);
        //     setKeyError(keyError1);
        //     setFirmError(firmError1);
        //     return;
        // }

        if (idError1 || keyError1 ) {
            setIDError(idError1);
            setKeyError(keyError1);
      
            return;
        }
        signupfn();
    }


    const signupfn = () => {
        console.log("portalid", portalid)

        axios.post("/businessuser/signup/details", { "name": name, "id": id, "key": key,  "phone": phone, "email": email, "portalid": portalid,selectedContentId })
            .then((response) => {
                console.log("register response", response.status)

                if (response.status === 200) {
                    alert("Registered Sucessfully,Contact Administrator to activate your account")
                    navigate("/login");
                }
            })
            .catch((error) => {
                // Handle errors, including 403 status code
                if (error.response && error.response.status === 403) {

                    if (error.response.message === 'Username already exists') {
                        alert("User with this 'username' already exists try a differnet one");
                    }
                    else {
                        alert("User already registered with this email, try resetting your password");
                    }


                } else {
                    console.error("Error:", error.message);
                    // Handle other errors as needed
                }
            });
    }

    // useEffect(() => {
    //     axios.get(`/get/firms`)
    //         .then((response) => {
    //             setFirmIds(response.data); // Set the received project IDs into state
    //         })
    //         .catch((error) => {
    //             console.error('Failed to fetch firms:', error);
    //         });
    // }, []);

    useEffect(() => {
        axios.get(`/registration/content/dropdown`)
            .then((response) => {
                setContentCategory(response.data); // Set the received project IDs into state
            })
            .catch((error) => {
                console.error('Failed to fetch firms:', error);
            });
    }, []);




    // const handlefirmChange = (e) => {
    //     setSelectedFirmId(e.target.value);
    //     setFirmError("");
    // };


    const handlecontentChange = (e) => {
        setSelectedContentId(e.target.value);
        setContentError("");
    };



    const handlepincode = (event) => {
        event.preventDefault();
        const pin = inputRef.current.value;
        //console.log(pin)
        //sessionStorage.setItem("pincode", data1.current.value)
        axios.post('/pincode', { "pin": pin })
            .then(response => {
                console.log("response.data", response.data)
                if (response.data.length === 0) {
                    alert(" pincode not found")
                }
                setPin(response.data);
                console.log("after pindata", pindata)
            })
            .catch(error => {
                console.error(error);
            });

        //const path = `/PinPage?pin=${pin}`;
        //navigate(path);
        //window.location.reload();
    }


    const handleSelectPortal = (portalid) => {
        //console.log(a)

        setPortID(portalid)



    }

    return (
        <div>


            <br></br>
            <div>
                <input placeholder="Name" type="text" onChange={(e) => { setName(e.target.value) }} />
            </div>
            <br></br>
            <div>
                <input placeholder="Username" type="text" onChange={(e) => { setID(e.target.value); setIDError(""); }} />
                {idError && <span className="error-message">{idError}</span>}
            </div>
            <br></br>
            <div>
                <input placeholder="password" type="password" onChange={(e) => { setKey(e.target.value); setKeyError(""); }} />
                {keyError && <span className="error-message">{keyError}</span>}
            </div>
            <br></br>
            <div>
                <input placeholder="Phone" type="text" onChange={(e) => { setPhone(e.target.value); setPhoneError(""); }} />
                {phoneError && <span className="error-message">{phoneError}</span>}
            </div>
            <br></br>
            <div>
                <input placeholder="Email" type="text" onChange={(e) => { setEmail(e.target.value); setEmailError(""); }} />
                {emailError && <span className="error-message">{emailError}</span>}
            </div>
            <br></br>

            {/* <select name="firmID" onChange={handlefirmChange} value={selectedFirmId}>
                <option value="">Select Firm</option>
                {firmIds.map((firm) => (
                    <option key={firm.FIRM_ID} value={firm.FIRM_ID}>
                        {`${firm.FIRM_ID} - ${firm.FIRM_NAME}`}
                    </option>
                ))}
            </select>
            {firmError && <span className="error-message">{firmError}</span>} */}

            <select name="contentID" onChange={handlecontentChange} value={selectedContentId}>
                <option value="">Select Content Category</option>
                {ContentCategory.map((content) => (
                    <option key={content.ID} value={content.ID}>
                       {content.CATEGORY}
                    </option>
                ))}
            </select>
            {contentError && <span className="error-message">{contentError}</span>}

            <br></br>  <br />

            <div>
                <input name="input" type="text" placeholder="Enter Pincode" ref={inputRef} />
            </div>
            <div className="register-button-container">
                <button className="register-button" onClick={(event) => handlepincode(event)}>search pincode</button>
            </div>

            {pindata.length != 0 ? (
                <table style={{ margin: '10px 0px', border: '1px solid black', borderCollapse: 'collapse' }} >
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>Portal Name</th>
                            <th>Portal ID</th>
                            <th>Parent Portal ID</th>
                            <th>District Name</th>
                            <th>State Name</th>
                            {/* Add more column headers as needed */}
                        </tr>
                    </thead>
                    <tbody>
                        {pindata.map(portal => (
                            <tr key={portal.id}>
                                <td>

                                    <input
                                        type="radio"
                                        name="selectedPortal"
                                        value={portal.portalid}
                                        onChange={() => handleSelectPortal(portal.portalid)}
                                    />

                                </td>
                                <td>
                                    {/*                         <a href="#" onClick={() => handleclickportal(portal.portalid, portal.portalname, portal.parentportalid)}>{portal.portalname}</a> */}

                                    {portal.portalname}
                                </td>
                                <td>{portal.portalid}</td>
                                <td>{portal.parentportalid}</td>
                                <td>{portal.parentport}</td>
                                <td>{portal.state}</td>
                                {/* Add more table cells for other data in the row */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null

            }
            <br></br>
            <div className="register-button-container">
                <button onClick={signup} className="register-button">Signup</button>
            </div>
        </div>
    )
}

export default BusinessRegister