import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './WatermarkUploadImage.css';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';


function ImageUpload() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedSize, setSelectedSize] = useState('0');
    const [selectedColor, setSelectedColor] = useState('rgba(0, 0, 0, 1)'); // Default to black
    const [watermarkedImageResponse, setWatermarkedImageResponse] = useState(null);
    const userid = Cookies.get("userid")
    const portalid = Cookies.get("portalid")
    const usertype = Cookies.get("usertype")

    console.log("userid", userid);
    console.log("portalid", portalid);

    const homepath = 'images/MyB_App';
    const finalpath = `${homepath}/${portalid}/${userid}`;

    const navigate = useNavigate()

    useEffect(() => {
        if (!userid) {
            alert("Please login");
            navigate('/login');
        } else if (usertype === "USERAPP") {
            alert("Login with Businuess User Credentials");
            navigate('/login');
        }
    }, [userid, navigate]);

    useEffect(() => {
       
        createfolder();
    
      }, []);

    const createfolder = async () => {

        try {
          const response = axios.post('/create/folder/session', { "portalid": portalid, "userid": userid });
        }    
    
        catch (error) {
          console.log(error);
        }
    
      };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };
    const handleColorChange = (event) => {
        setSelectedColor(event.target.value);
    };
    const handleUpload = async (event) => {
        
        event.preventDefault();
        if (!selectedFile) return;
        const formData = new FormData();
       
        formData.append('userid', userid); // Include userId in the form data
        formData.append('portalid', portalid); 
        formData.append('size', selectedSize); // Pass selected size
        formData.append('color', selectedColor); // Pass selected color
        formData.append("finalpath", finalpath);
        formData.append('image', selectedFile);
       // debugger
        try {
            const response = await axios.post('/watermarkupload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            
            setWatermarkedImageResponse(response.data.message);
        } catch (err) {
            console.error('Error uploading the file', err);

            if (err.response && err.response.status === 503) {
                alert('No watermark found for this user. Please upload a watermark first.');
            } else {
                console.error('Error uploading the file', err);
            }
        }
    };

    return (
        <div className="image-upload">
            <h1>Upload Image Using logo/text</h1>
        <a href='/logoaddingconfig'>Upload Company logo/text</a>
        <br/>
            <form className="image-upload__form">
                <label className="image-upload__label">Select Font Size for Company Name:</label>
                <select className="image-upload__select" onChange={handleSizeChange}>
                    <option value="0">Select Size</option>
                    <option value="0.05">Small</option>
                    <option value="0.075">Medium</option>
                    <option value="0.1">Large</option>
                </select>

                <label className="image-upload__label">Select Font Color for Company Name:</label>
                <select className="image-upload__select" onChange={handleColorChange}>
                    <option value="rgba(0, 0, 0, 1)">Black</option>
                    <option value="rgba(255, 0, 0, 1)">Red</option>
                    <option value="rgba(0, 255, 0, 1)">Green</option>
                    <option value="rgba(0, 0, 255, 1)">Blue</option>
                    <option value="rgba(255, 255, 0, 1)">Yellow</option>
                    <option value="rgba(255, 165, 0, 1)">Orange</option>
                </select>

                <input className="image-upload__file-input" type="file" onChange={handleFileChange} />
                <button className="image-upload__button" onClick={handleUpload}>Upload Image</button>
            </form>

            {watermarkedImageResponse && (
                <div className="image-upload__response">
                    <h3>{watermarkedImageResponse}</h3>
                </div>
            )}
        </div>
    );
}

export default ImageUpload;
