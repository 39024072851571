import React, { useState, useEffect } from 'react'
import CryptoJS from 'crypto-js';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './forgotpassword.css'
import PageLogLocation from '../../PageLog/PageLogLocation';



function TxPasswordResetPage() {
    const [decryptedTextnew, setDecrypt] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [usertype, setUserType] = useState('');
    const [usertypetext, setUserTypeText] = useState('');
    const navigate = useNavigate()

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    //const decodedId = id ? decodeURIComponent(id.replace(/ /g, '+')) : null;
    const decodedId = id ? decodeURIComponent(id) : null;
    const secretKey = '#myblocks*';

    useEffect(() => {
        if (decodedId) {
            decrypt();
        }
    }, [decodedId]);

    const decrypt = () => {
        const decryptedBytes = CryptoJS.AES.decrypt(decodedId, secretKey);
        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
        // console.log(`Decrypted: ${decryptedText}`);
        //const originalEmail = decryptedText.replace(/#myblocks#$/, '');

        const parts = decryptedText.split('@');
        const secondAtValue = parts[2];

        //console.log("secondAtValue", secondAtValue)
        let originalEmail = '';
        if (secondAtValue === 'techieindex#') {
            originalEmail = decryptedText.replace(/@techieindex#$/, '');
            setUserType('techieindex')

        }
        else if (secondAtValue === 'ijobs#') {
            originalEmail = decryptedText.replace(/@ijobs#$/, '');
            setUserType('ijobs')
        }
        else if (secondAtValue === 'icampus#') {
            originalEmail = decryptedText.replace(/@icampus#$/, '');
            setUserType('icampus')
        }

        //console.log("",)
        setDecrypt(originalEmail);
    };


    //console.log("id", decodedId)

    // Remove 'mars' from the end
    //console.log(`Original Email: ${decryptedTextnew}`);



    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (password === '') {
            setError('Password cannot be empty');
            return;
        }

        try {
            // Make an Axios request to your backend
            const response = await axios.post('/password/reset/techieindex', {
                email: decryptedTextnew,
                password: password,
                usertype: usertype
            });

            // Handle the response from the backend as needed
            console.log(response.data);
            alert(response.data.message)
            // Reset state and navigate to a success page or perform any other action
            setPassword('');
            setConfirmPassword('');
            setError(null);
            navigate('/tlogin');
        } catch (error) {
            console.error('Error submitting password:', error);
            setError('Error submitting password. Please try again.');
        }
    };

    return (
        <div>
            <PageLogLocation />
            <br /><br /><br /><br /><br /><br /><br />
            <div className='tx-password-reset-container'>
                <h2>{usertype.charAt(0).toUpperCase() + usertype.slice(1)} Account Password Reset</h2>
                <p>Resetting password for <b>{decryptedTextnew}</b></p>

                <div>
                    <label>Password:</label>
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </div>

                <div>
                    <label>Confirm Password:</label>
                    <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                </div>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <button onClick={handleSubmit}>Submit</button>
            </div>
            <br /><br />
        </div>
    )
}

export default TxPasswordResetPage