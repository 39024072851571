const Overview = () =>{
    return(
        <div>
            <p >Techieindex
                      Inc., develops collaborative applications that extend and
                      complement traditional Business Application Systems. By
                      enabling teams from across and beyond the Organization to
                      resolve complex Customer issues more effectively,
                      Techieindex' Enterprise Application Suites, significantly
                      enhances a company's reach within the global developer
                      community.<br/>
                      <br/>
                      Techieindex TechDex application suite enables in capturing
                      and managing all customer issues and activities, while
                      dynamically building a highly productive, web-based
                      environment in which to collaborate thus enabling you
                      improve customer satisfaction by continuously reducing the
                      total cost of support and improving the quality of
                      responses to issues.<br/>
                      <br/>
                      TechDex application suite delivers high quality support
                      that's responsive as well as proactive and has
                      consistently delivered measurable business value to large,
                      leading companies. Clients benefit include,</p>
        </div>
    )
}

export default Overview;