import React, { useRef, useState, useEffect } from 'react';
import './login.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
import { useNavigate } from 'react-router-dom';
import PageLog from '../PageLog/PageLog';

const LoginPopup = () => {


  const userid = Cookies.get("userid")
  const getEmailid = Cookies.get("getEmailid")
  const getPassword = Cookies.get("passwordData")
  let portalid = Cookies.get("portalid")
  const getMessage = Cookies.get("message")
  const navigate = useNavigate()
  //debugger
  if (portalid === 'null' || portalid === "0" || portalid === '') {
    portalid = 3025;
  }


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [id, setID] = useState("");
  const [key, setKey] = useState("");
  const [login, setLogin] = useState([])
  const [userType, setUserType] = useState("USERAPP");


  const [searchpin, setPinSearchQuery] = useState('');
  sessionStorage.setItem("site", "Myblock");
  let getSite = sessionStorage.getItem("site")

  // Get the current URL hostname and port
  var hostname = window.location.hostname;
  var port = window.location.port;



  const handleSearch = () => {
    const baseUrl = window.location.origin + '/PinPage?pin=';
    const urlWithQuery = baseUrl + encodeURIComponent(searchpin);

    // Redirect the user to the generated URL
    window.location.href = urlWithQuery;
  };



  const log = () => {
    console.log("userType", userType)
    Cookies.set("usertype", userType);

    //debugger
    const apiEndpoint = userType === "BUSINESSAPP" ? "/business/login/details" : userType === "ADMINAPP" ? "/admin/login/details" : "/login/details";
    console.log("apiEndpoint", apiEndpoint)


    axios.post(apiEndpoint, { "username": username, "password": password })
      .then((response) => {


        console.log(response.data.USERNAME)
        console.log(response.data.length)
        if (response.data.length === 0) {

          alert("Incorrect Username or Password")
        }
        setLogin(response.data)


      }).catch((error) => {
        console.error("An error occurred during login:", error);

      });
  };

  const cityLink = window.location.origin + `/CityFromId/${portalid}`;


  return (
    <div>


      {login.map((login) => {

        if ((userType === "BUSINESSAPP" || userType === "ADMINAPP") && login.STATUS === 'Active') {
          Cookies.set("firmid", login.FIRM_ID);
          Cookies.set("vend_id", login.FIRM_ID);
          Cookies.set('getEmailid', login.USERNAME);
          Cookies.set('passwordData', true);
          Cookies.set('userid', login.EMPID);
          Cookies.set('role_id', login.Employee_Level);
          Cookies.set('username', login.EMPNAME);
          Cookies.set('portalid', login.HOME_PORTALID);
          Cookies.set('facebook_status', 0);
          Cookies.set('category', login.CATEGORY);
          Cookies.set('adminuser', login.ADMIN_USER);
          Cookies.set('name', login.EMPNAME);
          window.location.reload();
          // debugger
          // Navigate based on user type
          if (userType === "BUSINESSAPP") {
            navigate('/businessuserhome');
          } else if (userType === "ADMINAPP") {
            navigate('/adminuserhome');
          }
        } else if (userType === "BUSINESSAPP" && login.STATUS === 'Inactive') {
          alert("Your account is not activated, please contact Administrator");
        } else if (userType === "ADMINAPP" && login.STATUS === 'Inactive') {
          alert("Your admin account is not activated, please contact Administrator");
        } else if (userType === "USERAPP") {
          Cookies.set('userid', login.USER_ID);
          Cookies.set('getEmailid', login.USERNAME);
          Cookies.set('passwordData', true);
          Cookies.set('portalid', login.HOME_PORTALID);
          Cookies.set('mainpagecookie', 0);
          Cookies.set("vend_id", login.FIRM_ID);
          Cookies.set('facebook_status', 0);
          Cookies.set("firmid", login.FIRM_ID);
          Cookies.set('role_id', login.ROLE_ID);
          Cookies.set('name', login.NAME);
          window.location.reload();
          //window.location.href = cityLink;
        }


        return <div key={login.USERNAME}></div>;
      })}



      {getEmailid && getPassword ?
        (

          userType === "USERAPP"
            ? (
              window.location.href = cityLink

              //openPage()
            ) :
            (
              <></>
              //window.location.reload()
            )


          // 
        ) :
        (

          <div>
            {/* Pricing Row */}

           

            <div className="login-container">
              <div className="login-right-side">
                <div className="login-form-container">
                  <h2>Discover Your Town</h2>
                  {/* <form id="login-form"> */}
                  <input type="text" id="username" placeholder="Username" required onChange={(e) => {
                    setUsername(e.target.value);
                  }} />
                  <input type="password" id="password" placeholder="Password" required onChange={(e) => {
                    setPassword(e.target.value);
                  }} />
                  <select
                    id="userType"
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                  >
                    <option value="USERAPP">Basic User</option>
                    <option value="BUSINESSAPP">Business User</option>
                    <option value="ADMINAPP">Admin User</option>
                  </select>
                  <div className="forgot-password">
                    <a href="/forgotpasswordemail">Forgot Password?</a>
                  </div>
                  <button id="login-button" className="login-button" onClick={log}>Login</button>
                  {/* </form> */}
                </div>

                <div className="signup-link">
                  New to MyBlocks? <a href="/Register">Sign Up</a>
                </div>
              </div>
            </div>
          </div>
        )}
      <PageLog />

    </div >
  );

}
export default LoginPopup
