import React, {useState,useEffect} from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { ButtonToolbar } from 'react-bootstrap';
import { createBootstrapComponent } from 'react-bootstrap/esm/ThemeProvider';


    

function Barchart() {

        const [water,setWater]=useState([]);
        const [chartvalues,setChartvalues]=useState([]);
        const [chartkeys,setChartkeys]=useState([]);

        useEffect(() => { 
                axios.get('/water/waterindex')
          .then(function (response) {
            console.log(response.data);
            
            
        //     
            const propertyValues = Object.values(response.data[0]);
        //     console.log(propertyValues);
      let propvalues = propertyValues.map(pro => {return pro })
           console.log(propvalues);
      setChartvalues(propvalues);


      const propertyKeys = Object.keys(response.data[0]);
            console.log(propertyKeys);
            let propkeys = propertyKeys.map(pro => {return pro })
            setChartkeys(propkeys);
      
          })
         } ,[])


return(
    <div className='barchart' >
        <h3> Your Water Score</h3>

        <Chart 
        type='bar'
        // width={960}
        // height={750}
        

        series={[

                { 
                        name:"POLLUTANTS",
                        data:chartvalues,
                        
                
                }
        ]}
        options={{

                title:{ text:"Prepared by i3 technologies", 
                style:{fontSize:15}
        },
                subtitle:{
                        text:"Water Index",
                        style:{fontSize:20}
                },

                colors:['#0000FF'],
                theme:{mode:'dark'},
                
                xaxis:{
                        tickPlacement:"on",
                        categories: chartkeys,
                        
                 title:{text:"Pollutants",
                        style:{color:"#f90000", fontSize:20,}
                        }
                          },
                yaxis:{
                        labels:{
                                formatter:(val)=>{return `${val}mg/l`},
                                style:{ fontSize:15,
                                        colors:["#f90000"]}
                                },
                       
                        },
                
                
                legend:{
                        show:true,
                        position:"left"
                        },
                dataLabels:{
                        formatter:(val)=>{return `${val}`},
                        style:{ fontSize:10,
                                colors:["#f4f4f4"]}
                }


                }
        }
        
        >
                


        </Chart>


        </div>
)


}

export default Barchart