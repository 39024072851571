import React, { useEffect, useState } from "react";
import axios from "axios";
import "./emailsetdefault.css";
import Cookies from 'js-cookie';
import { useNavigate, Link } from 'react-router-dom';

function EmailSetDefault() {
  const [emailDetails, setEmailDetails] = useState([]);
  const firmid = Cookies.get("firmid")
  const userid = Cookies.get("userid")
  const usertype = Cookies.get("usertype")
  const navigate = useNavigate();

  
  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } else if (usertype === "USERAPP") {
      alert("Login with Businuess User Credentials");
      navigate('/login');
    }
  }, [userid, navigate]);

  useEffect(() => {
    fetchEmailDetails();
  }, []);

  const fetchEmailDetails = async () => {
    try {
      const response = await axios.get("/api/user-email-details", {
        params: { userid, firmid }, // Send as query parameters
      });
      setEmailDetails(response.data);
    } catch (error) {
      console.error("Error fetching email details:", error);
    }
  };

  const updateStatus = async (emId) => {
    try {
      await axios.post("/api/user-email-details/update-status", {
        emId,
      });
      fetchEmailDetails(); // Refresh data after update
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div>
      <h1>User Email Details</h1>
      <ul>
        {emailDetails.map((detail) => (
          <li key={detail.EM_ID}>
            <p>Email: {detail.EMAIL}</p>
            <p>Status: {detail.STATUS}</p>

            {detail.STATUS !== 'Active' && ( // Use logical AND for conditional rendering
              <button onClick={() => updateStatus(detail.EM_ID)}>
                Set Active
              </button>
            )}

          </li>
        ))}
      </ul>
    </div>
  );
}

export default EmailSetDefault;
