import React, { useEffect } from 'react';
import './Header.css';
import { Container, Nav, Navbar, Button, NavDropdown, Form, span } from 'react-bootstrap';
import logo from "../../images/logo.svg"
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faFacebook, faYoutube, faTwitter, } from '@fortawesome/free-brands-svg-icons';

import Login from '../../loginpage/login';

//React Router Dom
import { Link, useNavigate } from 'react-router-dom';
import HeaderContents from './HeaderContents';

import Cookies from 'js-cookie';



function Header() {

  const getCity = sessionStorage.getItem("city")
  const getEmailid = Cookies.get("getEmailid")
  const getPassword = Cookies.get("passwordData")
  let portalid = sessionStorage.getItem("portalid")
  const usertype = Cookies.get("usertype")

  const navigate = useNavigate()

  let getSite = sessionStorage.getItem("site")


  var currentUrl = window.location.href;
  var baseUrl = currentUrl.split("/")[2];

  sessionStorage.setItem("inside_site", " ");

  console.log("portalid in header", portalid)

  portalid = parseInt(portalid, 10);


  if (portalid === 708 || portalid === 709 || portalid === 710 || portalid === 711 || portalid === 712 || portalid === 713) {
    sessionStorage.setItem("inside_site", "health");

  }

  let inside_site = sessionStorage.getItem("inside_site")

  console.log("inside_site in header", inside_site)
  //debugger
  console.log("baseUrl", baseUrl)

  if (baseUrl === 'myblocks.in' || baseUrl === 'www.myblocks.in') {
    sessionStorage.setItem("site", "Myblock");
    getSite = sessionStorage.getItem("site")
  }

  if (baseUrl === 'techie-index.com' || baseUrl === 'www.techie-index.com') {
    sessionStorage.setItem("site", "Techie-Index");
    getSite = sessionStorage.getItem("site")
  }

  // if (baseUrl === 'localhost:7100') {
  //   sessionStorage.setItem("site", "Techie-Index");
  //   getSite = sessionStorage.getItem("site")
  // }


  // Get the current URL hostname and port

  // Remove the protocol and trailing slash (if any)
  // var strippedURL = `${hostname}:${port}`.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '');


  // // Check if there is data after the trailing slash
  // var regex = new RegExp(`${strippedURL}/([^/?]+)`);
  // var match = window.location.href.match(regex);

  // console.log(`current url`, window.location.href);

  // if (match) {
  //   console.log(`Data found after the trailing slash: ${match}`);
  // }

  // else {
  //   console.log("No data found after the trailing slash.",match, 'value');

  //   sessionStorage.setItem("site", "Myblock");
  //   getSite = sessionStorage.getItem("site")

  // }
  useEffect(() => {
    var hostname = window.location.hostname;
    var port = window.location.port;

    var strippedURL = `${hostname}:${port}`.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '');

    // Check if there is data after the trailing slash
    var regex = new RegExp(`${strippedURL}/(techieindexlatest|home)/([^/?]+)`);
    var match = window.location.href.match(regex);

    //console.log(`current url`, window.location.href);

    if (match) {
      var dataAfterSlash = match[1];
      if (dataAfterSlash === "home") {
        console.log("Data found after the trailing slash: /home");
        sessionStorage.setItem("site", "Myblock");
        getSite = sessionStorage.getItem("site");
      } else if (dataAfterSlash === "techieindexlatest") {
        console.log("Data found after the trailing slash: /techieindexlatest");
        sessionStorage.setItem("site", "Techie-Index");
        getSite = sessionStorage.getItem("site");
      }
    }
  }, []);

  console.log("site value", getSite);

  const handleclick = () => {
    // sessionStorage.clear();
    // sessionStorage.removeItem("city");
    // sessionStorage.removeItem("portalid");
    // sessionStorage.removeItem("pincode");

    const cookies = Cookies.get(); // Get all cookies
    for (const cookie in cookies) {
      Cookies.remove(cookie); // Remove each cookie
    }
    sessionStorage.removeItem("getPassword");
    sessionStorage.removeItem("getEmailid");
    if (getSite === 'Myblock') {
      navigate("/");
    }
    else {
      navigate("/techieindexlatest");
    }

    window.location.reload();
    // if (getSite === "Techie-Index") {
    //   // Call function for Techie-Index
    //   handleswitchtechieindex();
    // } else {
    //   // Call default function
    //   handleswitchmyblock();
    // }
    //
  }

  const handleswitchmyblock = () => {
    sessionStorage.removeItem("city");
    sessionStorage.removeItem("portalid");
    sessionStorage.removeItem("parentportalid");
    sessionStorage.removeItem("pincode");
    sessionStorage.removeItem("site");
    sessionStorage.setItem("site", "Myblock");

    window.location.reload();
  }
  const handleswitchtechieindex = () => {
    sessionStorage.removeItem("city");
    sessionStorage.removeItem("portalid");
    sessionStorage.removeItem("parentportalid");
    sessionStorage.removeItem("pincode");
    sessionStorage.removeItem("site");
    sessionStorage.setItem("site", "Techie-Index");

    window.location.reload();
  }
  const handletechie = () => {
    sessionStorage.setItem("site", "Techie-Index");
    sessionStorage.setItem("portalid", "508");
    sessionStorage.removeItem("city");
    sessionStorage.removeItem("parentportalid");
    //window.location.reload();
  }
  getSite = sessionStorage.getItem("site")


  return (
    <div className={ getSite === "Myblock" ? "header" : "header-techie"}>
      <Navbar fixed="top" className={ getSite === "Myblock" ? "color-nav" : "color-nav-techie"} variant='dark'>
        <Container>

          <Nav className="me-auto">
            {/* <Nav.Link href={getSite === "Techie-Index" ? "/techieindexlatest" : "/"} onClick={handleswitch}>Home</Nav.Link> */}

            <Nav.Link
              href={getSite === "Techie-Index" ? "/techieindexlatest" : "/"}
              onClick={() => {
                if (getSite === "Techie-Index") {
                  // Call function for Techie-Index
                  handleswitchtechieindex();
                } else {
                  // Call default function
                  handleswitchmyblock();
                }
              }}
            >
              Home
            </Nav.Link>
            {/* <Nav.Link href="/InterviewMainPage">Interview</Nav.Link> */}
            <Nav.Link href="/interviewspotlight">Interview</Nav.Link>
            <Nav.Link href="AllSpotlights">Spotlights</Nav.Link>
            <Nav.Link href="/AllEvents">Events</Nav.Link>
            <Nav.Link href="/AllVendors">Business</Nav.Link>
            <Nav.Link href="/AllLeaders">Leaders</Nav.Link>
            <Nav.Link href="/Extra">Extra</Nav.Link>
            <Nav.Link href={getSite === "Techie-Index" ? "/AboutUsTechie" : "/Aboutus"}>About Us</Nav.Link>





          </Nav>
          <div   >
            {getSite === "Techie-Index" ? <>
              <img src={logo} className='logo-techieindex'></img>
            </> :
              <></>
            }</div>
          {/* <Nav>
            <Nav.Link>{getEmailid && getPassword ? <>
              <NavDropdown title="Myhome" >
                <NavDropdown.Item href="/">Community Index</NavDropdown.Item>
                <NavDropdown.Item href="/">Personal Index</NavDropdown.Item>
                <NavDropdown.Item href="/">Media Brain</NavDropdown.Item>

              </NavDropdown>
            </> :
              <></>}</Nav.Link>
          </Nav> */}

          {/* <Nav>
            <Nav.Link>{getEmailid && getPassword && getSite !== "Techie-Index" ? (
              <>
                {usertype === "BUSINESSAPP" ? (
                  <Link to="/businessuserhome" style={{ textDecoration: 'none', color: 'white' }}> Business App </Link>
                ) : (
                  <Link to="/AppHome" style={{ textDecoration: 'none', color: 'white' }}> User App </Link>
                )}
              </>
            ) :
              <></>}</Nav.Link>
          </Nav> */}

          <Nav>
            <Nav.Link>
              {getEmailid && getPassword ? (
                <>
                  {getSite !== "Techie-Index" ? (
                    // Links for getSite !== "Techie-Index"
                    <>
                      {usertype === "BUSINESSAPP" ? (
                        <Link to="/businessuserhome" style={{ textDecoration: 'none', color: 'white' }}> Business App </Link>
                      ) : null}
                      {usertype === "ADMINAPP" ? (
                        <Link to="/adminuserhome" style={{ textDecoration: 'none', color: 'white' }}> Admin App </Link>
                      ) : null}
                      {usertype === "USERAPP" ? (
                        <Link to="/AppHome" style={{ textDecoration: 'none', color: 'white' }}> User App </Link>
                      ) : null}
                    </>
                  ) : (
                    // Links for getSite === "Techie-Index"
                    <>
                      {usertype === "ijobs" ? (
                        <Link to="/ijobspage" style={{ textDecoration: 'none', color: 'white' }}> iJobs Home </Link>
                      ) : null}
                      {usertype === "icampus" ? (
                        <Link to="/icampuspage" style={{ textDecoration: 'none', color: 'white' }}> iCampus Home </Link>
                      ) : null}
                      {usertype === "techieindex" ? (
                        <Link to="/techieindexpage" style={{ textDecoration: 'none', color: 'white' }}> TechieIndex home </Link>
                      ) : null}
                    </>
                  )}
                </>
              ) : <></>}
            </Nav.Link>
          </Nav>

          <Nav>

            {/* <Nav.Link >{
              getSite !== "Techie-Index" ? (getEmailid && getPassword ? (<Link to="/home" style={{ textDecoration: 'none', color: 'white' }} onClick={handleclick}> Logout </Link>) : <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}> Login </Link>)
                : (getEmailid && getPassword ? <Link to="/techieindex" style={{ textDecoration: 'none', color: 'white' }} onClick={handleclick}> Logout </Link> : <Link to="/tlogin" style={{ textDecoration: 'none', color: 'white' }}> Login </Link>)
            }


            </Nav.Link> */}

            <Nav.Link>
              {getSite !== "Techie-Index" ? (
                getEmailid && getPassword ? (
                  <Link to="/home" style={{ textDecoration: 'none', color: 'white' }} onClick={handleclick}>
                    Logout
                  </Link>
                ) : (
                  <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
                    Login
                  </Link>
                )
              ) : null }
            </Nav.Link>


          </Nav>
          {/*  <a href="https://www.mydoctorspot.com/care/login_lite.php" style={{ textDecoration: 'none', color: 'white' }}> Login </a> */}

          <span class="bi bi-search"></span>
        </Container>


      </Navbar>
      <br></br><br></br><br></br>
      <br></br><br></br>
      <Navbar className={ getSite === "Myblock" ? "color-nav" : "color-nav-techie"} variant="dark">
        <Container  >
          <h2 className='heading1'>{getSite === 'Techie-Index' ? null : "Myblock"}</h2>
          {/*   <h2 className='heading-place'> {getCity ? getCity : getSite ? "" : "Keralam"}</h2> */}
          <h2 className='heading-place'> {getCity ? <a href={`/CityFromId/${portalid}`} className="red-link">{getCity}</a> : getSite ? "" : " "}</h2>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll>


              <HeaderContents />


              {/* <div><Nav.Link href="/techieindexlatest" onClick={handletechie} >Techie-index</Nav.Link> </div> */}


            </Nav>

          </Navbar.Collapse>

          {/* <div className=" social-media-icons-white "> <a href="https://facebook.com"> <FontAwesomeIcon icon={faFacebook} /></a>
            <a href="https://youtube.com"> <FontAwesomeIcon icon={faYoutube} /></a>
            <a href="https://twitter.com"> <FontAwesomeIcon icon={faTwitter} /></a> </div> */}

        </Container>


      </Navbar>

    </div>


  )
}

export default Header