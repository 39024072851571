export const itemsIntervals = [
    { start: 22, end: 41, listName: "otherList" },
    { start: 42, end: 76, listName: "techList" },
    { start: 103, end: 110, listName: "startupList" },
    { start: 116, end: 120, listName: "jobsList" },
    { start: 121, end: 125, listName: "campusList" },
  ];

  export const itemHeaders = Object.freeze({
    startupList: "Startups are shaping the future by pioneering new technologies and tackling global challenges with innovative solutions.  This constant push for progress  disrupts established industries and creates entirely new markets .See how startups are shaping the future",
    techList: "Dive deeper: The what, why, and how of tech news. Become an informed observer, separating game-changing advancements from fleeting trends, and ultimately, anticipating the ways technology will continue to reshape our world.",
    jobsList: "Land your dream job: News that gets you hired. Stay ahead of the curve with industry-specific hiring trends, company acquisitions that open new doors, and in-demand skills that make you a top pick for recruiters",
    campusList: ". Demystify the application process, stay informed about scholarship opportunities, and explore exciting programs that align with your goals",
    otherList: "Beyond the Headlines: Deciphering the meaning behind tech's biggest stories"
  })