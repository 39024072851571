import './whoarewe.scss';

function WhoAreWe() {
  return (
    <section className="about_section layout_padding">
      <div className="container-whoarewe">
        <div className="heading_container heading_center">
          <h2 >
            Who are we?
          </h2>
          <p>
            Myblocks is a hyper local portal giving news and health information about a town. It's a one-stop shop for all things local,
            from government and business to arts and culture to healthcare.
          </p>
        </div>
        <div className="row-aboutus">
          <img className="img-box" src={require('../images/chatbot.png')} alt="" />
          <div className="detail-box">
            <h3>
              We are Myblocks!
            </h3>
            <p>
              Myblocks provides in-depth coverage of local issues and features local people and stories.
              It's the perfect place to stay up-to-date on what's happening in your community and to learn more about your neighbors.
              At Myblocks, we are dedicated to shaping the future of technology with our innovative approach to AI. Our mission revolves around pioneering
              transformative research that elevates the development, deployment, and distribution of GenAI applications in India. Committed to robustness,
              performance, and cost-effectiveness, we strive to make AI accessible and impactful across various sectors.
              Myblocks is at the forefront of cutting-edge AI, driving positive change and setting new standards in the industry.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoAreWe;
