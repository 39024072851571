import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GroupListSchedule from './Facebook/GroupListSchedule';
import './schedulemainpage.css'
import InstaGroupListShedule from './Instagram/InstaGroupListShedule';


function ScheduleMainPage() {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id');
  

  console.log("id", id);
  return (
    <div className='schedulemainpage-content-container'>

      




      <GroupListSchedule bardStory={'aa'} id={id} />

      <InstaGroupListShedule />
    </div>
  )
}

export default ScheduleMainPage