import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import FacebookPostSchedule from './FacebookPostSchedule';
import './grouplistschedule.css';
import { useNavigate } from 'react-router-dom';


// import './style.css';

function GroupListSchedule(props) {

    const { id } = props;
    const portalid = Cookies.get("portalid");
    const firmid = Cookies.get("firmid");
    const facebook_token = Cookies.get("facebook_token");
    const [grouplist, setGroupList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUrls, setSelectedUrls] = useState([]);
    const userid = Cookies.get("userid")
    const extractedIDs = [];
    const [textAreaValue, setTextAreaValue] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [pagelist, setPagelist] = useState([]);

    const facebook_status = Cookies.get("facebook_status")
    console.log("facebook_status in groupschedule", facebook_status)



    // useEffect(() => {
    //     axios.post('/group/list', { "userid": userid, "firmid": firmid })
    //         .then(function (response) {
    //             //console.log("group list", response.data);


    //             for (const item of response.data) {
    //                 //debugger
    //                 const match = item.SOCIAL_URL.match(/\d+/);

    //                 // Check if a match was found

    //                 console.log("dbdate", item.TOKEN_DATE)

    //                 let dbdate = new Date(item.TOKEN_DATE);



    //                 console.log("dbdate", dbdate)



    //                 let currentdate1 = new Date();
    //                 const currentDate = currentdate1
    //                 // console.log("ISTDate",ISTDate)
    //                 currentDate.setHours(currentDate.getHours() + 5); // IST is UTC+5
    //                 currentDate.setMinutes(currentDate.getMinutes() + 30); // IST is UTC+5:30

    //                 // // Format the ISTDate to a string in 'yyyy-MM-dd HH:mm:ss' format
    //                 currentDate.toISOString().slice(0, 19).replace('T', ' ');


    //                 //const currentDate1 =currentDate.getTime();
    //                 console.log("currentDate", currentDate)




    //                 if (item.ACCOUNT_TYPE === 'Page') {
    //                     if (item.TOKEN_DATE) {
    //                         const diff = currentdate1 - dbdate
    //                         //console.log ("diff",diff)

    //                         const daysDifference = Math.floor((diff) / (1000 * 60 * 60 * 24)); // Calculate the difference in days

    //                         //console.log ("daysDifference",daysDifference)

    //                         if (daysDifference > 85) {

    //                             getpageaccesstoken(item.ID, match[0], currentDate)
    //                         }
    //                     }
    //                     else
    //                         getpageaccesstoken(item.ID, match[0], currentDate)

    //                 }

    //             }
    //             getlist();
    //         });



    // }, []);


    useEffect(() => {


        if (facebook_token === undefined && facebook_status === "2") {

            console.log("facebook_status in error set", facebook_status)
            console.log("facebook_token in error set", facebook_token)


            setErrorMessage("Looks like you did not login/allow access to facebook, please logout & try again");


            //alert("Looks like there was an error, please logout & try again")

        }

        if (facebook_token && facebook_status === "2") {

            getpagelist();



            console.log("facebook_status in error reset", facebook_status)
            console.log("facebook_token in error reset", facebook_token)

            setErrorMessage('');


            //alert("Looks like there was an error, please logout & try again")

        }
    }, [facebook_token]);

    useEffect(() => {

        if (facebook_status === "1") {
            getpagelist();

        }



    }, [facebook_token]);

    useEffect(() => {

        pageloadfn();


    }, [pagelist, facebook_token]);



    const getpagelist = async () => {

        setLoading(true);

        let updatedPagelist = [];
        if (facebook_token === undefined && facebook_status === 2) {
            //debugger
            //window.location.reload();
            navigate(`/approvestory`)
        }

        try {
            const response = await axios.get(`https://graph.facebook.com/v19.0/me?access_token=${facebook_token}`);
            console.log("response in me", response);

            const myid = response.data.id;

            const pagelist_response = await axios.get(`https://graph.facebook.com/v19.0/${myid}/accounts?access_token=${facebook_token}`);
            console.log("response in pagelist_response", pagelist_response.data.data);



            if (pagelist_response.status === 400) {
                setLoading(false);
                setErrorMessage("Looks like you did not login/allow access to facebook, please logout & try again");


            }

            if (pagelist_response.data.data.length === 0) {
                setLoading(false);
                setErrorMessage("Looks like you did not allow pemission to show any pages ");
            }

            const data = pagelist_response.data.data;
            //debugger
            updatedPagelist = data.map(page => ({
                id: page.id,
                name: page.name
            }));
            setPagelist(updatedPagelist);
            // debugger
            console.log("Pagelist: in 1st", updatedPagelist);

            console.log("Pagelist: in 1st", pagelist);



        }
        catch (error) {
            console.log(error);
            setLoading(false);
            setErrorMessage("Looks like you did not login/allow access to facebook, please logout & try again");
        }
    }

    
    const pageloadfn = async () => {

        //debugger

        try {

            console.log("Pagelist: in pageloadfn", pagelist);
            const response = await axios.post('/group/list', { "userid": userid, "firmid": firmid, pagelist })
            //debugger
            //console.log("group list", response.data);

            for (const item of response.data) {

                const match = item.SOCIAL_URL.match(/\d+/);

                // Check if a match was found

                console.log("dbdate from item in group list", item.TOKEN_DATE)

                let dbdate;

                if (item.TOKEN_DATE) {
                    // If item.TOKEN_DATE is not null, use it
                    dbdate = new Date(item.TOKEN_DATE);
                }
                else if (item.TOKEN_DATE == '0000-00-00 00:00:00') {
                    dbdate = new Date();
                }
                else {
                    // If item.TOKEN_DATE is null, use the current date
                    dbdate = new Date();
                }

                console.log("dbdate group list newly created", dbdate)

                let currentdate1 = new Date();
                const currentDate = currentdate1
                // console.log("ISTDate",ISTDate)
                currentDate.setHours(currentDate.getHours() + 5); // IST is UTC+5
                currentDate.setMinutes(currentDate.getMinutes() + 30); // IST is UTC+5:30

                // // Format the ISTDate to a string in 'yyyy-MM-dd HH:mm:ss' format
                currentDate.toISOString().slice(0, 19).replace('T', ' ');


                //const currentDate1 =currentDate.getTime();
                console.log("currentDate", currentDate)




                if (item.ACCOUNT_TYPE === 'Page') {
                    if (item.TOKEN_DATE || item.TOKEN_DATE !== '0000-00-00 00:00:00') {
                        const diff = currentdate1 - dbdate
                        //console.log ("diff",diff)

                        const daysDifference = Math.floor((diff) / (1000 * 60 * 60 * 24)); // Calculate the difference in days

                        //console.log ("daysDifference",daysDifference)
                        //temp generate token for all ignoring data
                        //getpageaccesstoken(item.ID, match[0], currentDate)

                        if (daysDifference > 85 || daysDifference === 0) {

                            getpageaccesstoken(item.ID, match[0], currentDate)
                        }
                    }
                    else
                        getpageaccesstoken(item.ID, match[0], currentDate)

                }

            }
            getlist();
        }
        catch (error) {
            console.error("Error fetching group list:", error);
        }
    }



    const getlist = () => {

        axios.post('/group/list', { "userid": userid, "firmid": firmid ,pagelist})
            .then(function (response) {
                //console.log("group list", response.data);
                setGroupList(response.data)
            })

            setLoading(false);
    }



    const getpageaccesstoken = async (id, match, currentDate) => {
        //debugger
        console.log("id in getpageaccesstoken", id)
        try {
            const response = await axios.get(`https://graph.facebook.com/${match}?fields=access_token&access_token=${facebook_token}`);
            console.log("response in getpageaccesstoken", response);
            const postHistoryResponse = await axios.post('/facebook/set/token', { "id": id, "PAGE_ACCESS_TOKEN": response.data.access_token, "TOKEN_DATE": currentDate });
            console.log("result in getpageaccesstoken", postHistoryResponse);

        }
        catch (error) {
            console.log(error);
        }

        getlist();
    }


    useEffect(() => {
        if (selectAll) {
            setSelectedRows(grouplist.map(item => item.ID));
        } else {
            setSelectedRows([]);
        }
    }, [selectAll, grouplist]);

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
    };


    // const toggleSelectRow = (rowId) => {
    //     if (selectedRows.includes(rowId)) {
    //         setSelectedRows(selectedRows.filter(id => id !== rowId));
    //     } else {
    //         setSelectedRows([...selectedRows, rowId]);
    //     }
    // };

    const toggleSelectRow = (rowId) => {
        // Only allow one checkbox to be checked at a time
        setSelectedRows([rowId]);
    };


    useEffect(() => {
        const selectedUrls = grouplist
            .filter(item => selectedRows.includes(item.ID))
            .map(item => ({
                SOCIAL_URL: item.SOCIAL_URL,
                PAGE_ACCESS_TOKEN: item.PAGE_ACCESS_TOKEN,
                TOKEN_DATE: item.TOKEN_DATE,
            }));
        setSelectedUrls(selectedUrls);
    }, [selectedRows, grouplist]);



    console.log("grouplist", grouplist)

    console.log("selectedUrls", selectedUrls)


    selectedUrls.forEach((selectedData) => {
        // Use a regular expression to match numbers in the URL
        const match = selectedData.SOCIAL_URL.match(/\d+/);

        // Check if a match was found
        if (match) {
            const type = selectedData.SOCIAL_URL.includes("group") ? "group" : "page";
            // Extracted number is in match[0]
            const extractedNumberObject = { group_page_id: match[0], type: type, PAGE_ACCESS_TOKEN: selectedData.PAGE_ACCESS_TOKEN, TOKEN_DATE: selectedData.TOKEN_DATE, };
            extractedIDs.push(extractedNumberObject);
        }
    });

    console.log("extractedIDs", extractedIDs);

    return (
        <div className='grouplistschedule-container'>
            <h3>Facebook Scheduling</h3>

            <textarea
                className='grouplistschedule-textarea'
                value={textAreaValue}
                onChange={(e) => setTextAreaValue(e.target.value)}
                placeholder="Enter your Facebook post caption here"
            />


            {errorMessage && (
                <div className="error-message-facebook" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{errorMessage}</div>
            )}
            {!errorMessage && loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="../../../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />
                </div>
            )}

            <h1>Select a Post/Page</h1>

            <table className="content-table">
                <thead>
                    <tr>
                        <th className="checkbox-header">

                            {/* <input
                            type="checkbox"
                            onChange={toggleSelectAll}
                            checked={selectAll}
                        /> */}

                        </th>
                        <th>ID</th>
                        <th>Group/Page Url</th>
                        <th>Group/Page Description</th>
                        <th>Group or Page </th>
                    </tr>
                </thead>
                <tbody>
                    {grouplist.map((item) => (
                        <tr key={item.ID}>
                            <td className="checkbox-column">
                                <input
                                    type="checkbox"
                                    onChange={() => toggleSelectRow(item.ID)}
                                    checked={selectedRows.includes(item.ID)}
                                />
                            </td>
                            <td>{item.ID}</td>
                            <td>{item.SOCIAL_URL}</td>
                            <td>{item.URL_NAME}</td>
                            <td>{item.ACCOUNT_TYPE}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <FacebookPostSchedule bardStory={textAreaValue} id={id} extractedIDs={extractedIDs} />
        </div>
    );
}

export default GroupListSchedule;