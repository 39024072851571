import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
//import './familygroupdetails.css';
import { useLocation, useNavigate } from 'react-router-dom';

function FamilyGroupDetails() {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    let userid = Cookies.get("userid")


    const [mlist, setMembersList] = useState([]);
    // const [expandedItems, setExpandedItems] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const [formData, setFormData] = useState({
        Name: ''

    });

    const cardRef = useRef(null); // Reference to the card element


    useEffect(() => {

        fetchPosts();
    }, []);

    useEffect(() => {
        adjustCardHeight(); // Call the function to adjust card height on mount and when mlist changes
    }, [mlist]);

    const adjustCardHeight = () => {
        if (cardRef.current) {
            // Get the height of the list group
            const listGroupHeight = cardRef.current.querySelector('.list-group').scrollHeight;
            // Set the card height to the height of the list group plus any additional padding/margin
            cardRef.current.style.height = `${listGroupHeight + 200}px`; // Adjust the additional height as needed
        }
    };

    const fetchPosts = async () => {
        try {
            const response = await axios.get(`/api/populate/familygroupmembers?id=${id}`);

            setMembersList(response.data)

            console.log("response", response.data)
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };


    const togglePopup = (e) => {
        e.preventDefault();
        setShowPopup(!showPopup);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleToggleStatus = async (member) => {
        try {
            const updatedMember = { ...member, STATUS: member.STATUS === 'Active' ? 'Inactive' : 'Active' };
            // Update the member's status in the database
            await axios.put(`/api/update/member`, updatedMember);
            // Fetch updated member list
            fetchPosts();
        } catch (error) {
            console.error('Error toggling member status:', error);
        }
    };


    const insertname = async () => {

        try {
            const insertgroupresponse = await axios.post('/insert/into/members', {
                ...formData,
                id:id,
                userid: userid
            });
            console.log("result", insertgroupresponse);
            closePopup();
            fetchPosts();
            setFormData({Name: ''})
        } catch (error) {
            // Handle errors from the request or response
            console.error("Error in Axios post request:", error);
        }
    }
    return (
        <div className='content-container-familygpdetails'>
            <Card className='custom-card' ref={cardRef}>
                <Card.Header>
                    <center>
                        <img src="../images/AGIApp/group_family.png" alt="Simply Easy Learning" width="40" height="40" />
                        <span className="title" style={{ color: '#8866ff', fontSize: '25px' }}>jack</span>
                    </center>
                </Card.Header>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text></Card.Text>
                    <ListGroup>
                        {mlist.map((member, index) => (
                            <ListGroupItem key={index} className='list-group-item'>
                                <img src="../images/AGIApp/person.png" alt="Simply Easy Learning" width="30" height="30" />

                                &nbsp; {member.NAME}

                                {member.STATUS === 'Inactive' ? (
                                    <button className="plus-button" onClick={() => handleToggleStatus(member)}>+</button>
                                ) : (
                                    <button className="plus-button" onClick={() => handleToggleStatus(member)}>-</button>
                                )}


                                {/* <button className="plus-button" onClick={() => toggleExpansion(index)}>
                                    {expandedItems[index] ? '-' : '+'}
                                </button>   */}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                    {/* <Card.Text>Some more panel content here.</Card.Text> */}
                    <br></br>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <a href='#' onClick={(e) => togglePopup(e)}>Add New Members to this list</a>
                    </div>
                </Card.Body>

            </Card>

            {showPopup && (
                <Card className="popup-container">
                    <div className="popup">
                        <Card.Header>

                            <center>
                                <img src="../images/AGIApp/group_family.png" alt="Simply Easy Learning" width="40" height="40" />

                            </center>
                            <button className="close-button" onClick={closePopup}>X</button>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="family"><b>Member Name</b></label>
                                    <input type="text" className="form-control" id="family" name="Name" placeholder="Member Name" value={formData.Name} onChange={handleChange} required />
                                </div>

                                <div className='submit-button-div'>
                                    <button type="button" className="btn btn-primary" onClick={insertname}>Submit</button>
                                </div>
                            </div>
                        </Card.Body></div>
                </Card>
            )}

        </div>
    );
}

export default FamilyGroupDetails;