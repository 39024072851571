import './App.css';
import Products from './products/products';
import Header from './header/header';
import WhatWeDo from './whatwedo/whatwedo';
import Footer from './footer/footer';
import WhoAreWe from './whoarewe/whoarewe';
import Customer from './customer/customer';

function MainAboutUs() {
  return (
    <div className="App-aboutus">
      <Header></Header>
      <Products></Products>
      <WhoAreWe></WhoAreWe>
      <WhatWeDo></WhatWeDo>
      <Customer></Customer>
      {/* <Footer></Footer> */}
    </div>
  );
}

export default MainAboutUs;
