import React, { useState } from 'react';
//import './RainGaugeForm.css';
import axios from 'axios';
import { Link } from "react-router-dom";

function RainGaugeForm() {
  const [raindata, setRaindata] = useState({
    Datein: '',
    StartTime: '',
    EndTime: '',
    Rainfall: '',
    latitude: '',
    longitude: '',
    userid: '',
    weatherstory: '',
  });

  function submit() {
    // Handle form submission logic
    console.log(raindata);
    const date = raindata.Datein;
    const stime = raindata.StartTime;
    const etime = raindata.EndTime;
    const combinedStartDateTime = `${date} ${stime}:00`;
    const combinedEndDateTime = `${date} ${etime}:00`;

    // Create a new object with the combined DateTime value
    const newData = {
      ...raindata,
      startDateTime: combinedStartDateTime,
      endDateTime: combinedEndDateTime,
    };
    console.log(newData);
    axios.post('/raingauge', { raindata: newData  })
    .then(response => {
      if (response.status === 200) {
        alert("Data Added Sucessfully")
        
      }
    })
    .catch(error => {
        console.error(error);
    });


  }

  function handleChange(key, value) {
    setRaindata((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  return (

    <div className="app">
        <div className="split-screen">
            <div className="menu">
                <ul className="menu-list">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>

            <div className="page-container">
           

            <div className=" container ">
      <h2 className="raingauge-heading">Enter the measured Rain-Gauge Data</h2>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">Rain Date</label>
        <input
          className="raingauge-input"
          type="date"
          value={raindata.Datein}
          onChange={(event) => handleChange('Datein', event.target.value)}
          placeholder="Enter Date"
        />
      </div>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">Start Time</label>
        <input
          className="raingauge-input"
          type="time"
          value={raindata.StartTime}
          onChange={(event) => handleChange('StartTime', event.target.value)}
          placeholder="Enter Start Time (24 hour format)"
        />
      </div>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">End Time</label>
        <input
          className="raingauge-input"
          type="time"
          value={raindata.EndTime}
          onChange={(event) => handleChange('EndTime', event.target.value)}
          placeholder="Enter End Time (24 hour format)"
        />
      </div>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">Rainfall Measure</label>
        <input
          className="raingauge-input"
          type="text"
          value={raindata.Rainfall}
          onChange={(event) => handleChange('Rainfall', event.target.value)}
          placeholder="Enter Rainfall measure (in mm)"
        />
      </div>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">Latitude</label>
        <input
          className="raingauge-input"
          type="text"
          value={raindata.latitude}
          onChange={(event) => handleChange('latitude', event.target.value)}
          placeholder="Enter Latitude"
        />
      </div>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">Longitude</label>
        <input
          className="raingauge-input"
          type="text"
          value={raindata.longitude}
          onChange={(event) => handleChange('longitude', event.target.value)}
          placeholder="Enter Longitude"
        />
      </div>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">User ID</label>
        <input
          className="raingauge-input"
          type="text"
          value={raindata.userid}
          onChange={(event) => handleChange('userid', event.target.value)}
          placeholder="Enter User ID"
        />
      </div>

      <div className="raingauge-formGroup">
        <label className="raingauge-label">Weather Story</label>
        <textarea
          className="raingauge-textareainput"
          value={raindata.weatherstory}
          onChange={(event) => handleChange('weatherstory', event.target.value)}
          placeholder="Enter Weather Story"
        />
       
      </div>
      <br/>
      <div className="center-container">
      <button className="raingauge-buttonText" onClick={submit}>
        Submit
      </button>
      </div>
    </div>
            </div>
        </div>
    </div>

   
  );
}

export default RainGaugeForm;