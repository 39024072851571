import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './Eventpage.css'
import PageLog from '../../PageLog/PageLog';


const EventDetails = () => {
  const location = useLocation();
  console.log("state", location.state)
  let vendid = location.state.id


  const [vendordata, setVendorData] = useState([])



  useEffect(() => {
    axios.post('/citylocal/news/eventdesc', { "id": vendid })
      .then(function (response) {
        // console.log(response.data);
        setVendorData(response.data)
      })
  }, []);

  return (
    <div className='pages'>


      {vendordata.map((vend) => {
        return (<div className='eventpage'>
          <img src={vend.image} alt='' />
          <h3>{vend.DOC_TITL}</h3>
          <h6>{vend.DOC_DESC}</h6>
          {vend.DOC_DET && vend.DOC_DET.trim() !== '' && (
            <h6>
              {vend.DOC_DET.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </h6>
          )}
          <a href={vend.DOC_URL}>
            <button>More</button>
          </a>
        </div>
        )
      })}
      <PageLog />
    </div>
  )
}

export default EventDetails
