import './about.scss';
import aboutImage from '../images/slider-img.png'

function About() {
  return (
    <div className="banner-overlay">
      <div className="banner">
        <div className="row-aboutus">
          <div className="text">
            <p className="-text white">Explore hyperlocal possibilities with Myblocks, the generative AI solution! </p>
          </div>
          <div className="image"> <img src={aboutImage} className="banner-image" /> </div>
        </div>
      </div>
    </div>
  );
}

export default About;