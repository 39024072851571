import './header.scss';
import backgroundImage from '../images/hero-bg.png'
import About from '../about/about';
import logo from '../images/logo.png'

function Header() {
    return (
        <div className="hero_area">
                    <img src={logo} className='logo'></img>
            <div className="hero_bg_box">
                <div className="bg_img_box">
                    <img src={backgroundImage} alt=""></img>
                </div>
            </div>
            <About></About>
        </div>
    );
}

export default Header;