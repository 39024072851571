import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './HbReleases.css'; // Import CSS file for styling

const HbReleases = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [columnNames, setColumnNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch portal ID from the cookie or wherever it's stored
        let portalid = sessionStorage.getItem("portalid")
        //const portalId = getPortalIdFromCookie().trim(); // Remove whitespace characters
        // Fetch HB_RELEASES data for the logged-in user's portal ID
        const response = await axios.get(`/api/hb_releases/${portalid}`);

        setData(response.data);
      } catch (error) {
        setError('Error fetching data');
      }
    };

    // Fetch HB_RELEASES data and then fetch column names
    fetchData().then(() => {
      fetchColumnNames();
    });
  }, []);

  const fetchColumnNames = async () => {
    try {
      const response = await axios.get('/api/columns');
      setColumnNames(response.data);
    } catch (error) {
      console.error('Error fetching column names:', error);
    }
  };


  const downloadPdf = async (fileName) => {
    try {
      //const portalId = getPortalIdFromCookie().trim(); // Remove whitespace characters
      let portalid = sessionStorage.getItem("portalid")
      const encodedFileName = encodeURIComponent(fileName.trim()); // Remove whitespace characters
      //const pdfUrl = `http://localhost:8500/pdfs/${portalid}/${encodedFileName}`;
      const pdfUrl = `https://myblocks.in/pdfs/healthbook/${encodedFileName}`;
      
      window.open(pdfUrl, '_blank'); // Open PDF in a new tab
    } catch (error) {
      console.error('Error opening PDF:', error);
    }
  };

  return (
    <div className="hb-releases-container">
      <h2>HEALTHBOOK DETAILS</h2>
      {error && <p>{error}</p>}
      <div className="hb-columns-container">
        <ul>
          {data.map(item => (
            <li key={item.HB_ID} className="hb-column-box">
              <p className="hb-column">Town: {item.TOWN}</p>
              <p className="hb-column">HB Filename: <a href="#" onClick={() => downloadPdf(item.HB_FILENAME)}>{item.HB_FILENAME}</a></p>
              <p className="hb-column">Version: {item.VERSION}</p>
              <p className="hb-column">Release Date: {item.RELEASE_DTM}</p>
              <p className="hb-column">VIP REFYX ID: {item.VIP_REFYX_ID}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HbReleases;
