import React from 'react';

const Table = ({ parameters, values, handleInputChange, procedureCategory }) => {
    const additionalRows = [
        { name: 'age', units: 'years' },
        { name: 'gender', units: '' },
        { name: 'height', units: 'cm' },
        { name: 'weight', units: 'kg' },
    ];

    if (values.gender === 'female') {
        additionalRows.push({ name: 'Pregnancies', units: '' });
    }

    const allParameters = [...parameters, ...additionalRows];

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Parameter Name</th>
                        <th>Units</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {allParameters
                        .filter(param => param.name !== 'DiabetesPedigreeFunction' && param.name !== 'SkinThickness' && param.name !== 'BMI')
                        .map(param => (
                            <tr key={param.name}>
                                <td>{param.name}</td>
                                <td>{param.units}</td>
                                <td>
                                    {param.name === 'gender' ? (
                                        <select
                                            value={values['gender'] || ''}
                                            onChange={e => handleInputChange(e, 'gender')}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            value={values[param.name] || ''}
                                            onChange={e => handleInputChange(e, param.name)}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            * all values are compulsary
        </div>
    );
};

export default Table;