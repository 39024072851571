import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';

function GroupDetails() {
    const [emails, setEmails] = useState([]);
    const [newEmails, setNewEmails] = useState(""); // State for new emails
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const groupId = query.get('id'); // Get the group ID from the URL
    const firmId = Cookies.get("firmid");
    const userId = Cookies.get("userid");
    const userid = Cookies.get("userid")
    const usertype = Cookies.get("usertype")
    const navigate = useNavigate();

    useEffect(() => {
        if (!userid) {
          alert("Please login");
          navigate('/login');
        } else if (usertype === "USERAPP") {
          alert("Login with Businuess User Credentials");
          navigate('/login');
        }
      }, [userid, navigate]);


    useEffect(() => {
        if (groupId) {
            // Fetch emails based on GROUP_ID
            axios.get(`/api/emailslist?groupId=${groupId}&firmId=${firmId}&userId=${userId}`)
                .then((response) => {
                    setEmails(response.data);
                })
                .catch((error) => {
                    console.error('Failed to fetch emails:', error);
                });
        }
    }, [groupId, firmId, userId]);

    const handleSetStatus = (emailId, status) => {
        // Directly update the email status without confirmation
        axios.put(`/api/emails/status/${emailId}`, { status })
            .then(() => {
                // Update the email status in the state
                setEmails((prevEmails) =>
                    prevEmails.map((email) =>
                        email.E_ID === emailId ? { ...email, STATUS: status } : email
                    )
                );
            })
            .catch((error) => {
                console.error('Failed to update email status:', error);
            });
    };

    const handleSubmit = async () => {
        const emailsToAdd = newEmails.split(/[,\s;]+/).filter(email => email);
        if (emailsToAdd.length === 0) {
            alert("No valid emails to add.");
            return;
        }

        try {
            // Send the new emails to the backend
            await axios.post("/load/emails", {
                uniqueEmails: emailsToAdd,
                userid: userId,
                firmid: firmId,
                groupId,
            });
            alert("Emails added successfully!");
            // Optionally, fetch updated emails after addition
            const response = await axios.get(`/api/emailslist?groupId=${groupId}&firmId=${firmId}&userId=${userId}`);
            setEmails(response.data);
            setNewEmails(""); // Clear the input field after successful submission
        } catch (error) {
            console.error('Failed to add emails:', error);
            alert("Error adding emails.");
        }
    };

    return (
        <div>
            <h1>Email List for Group ID: {groupId}</h1>
            <textarea
                value={newEmails}
                onChange={(e) => setNewEmails(e.target.value)}
                placeholder="Enter new emails here (comma or space separated)"
                rows="4"
                cols="50"
            />
            <br />
            <button onClick={handleSubmit}>Add Emails</button>

            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {emails.map((email) => (
                        <tr key={email.E_ID}>
                            <td>{email.EMAIL}</td>
                            <td>{email.STATUS}</td>
                            <td>
                                {email.STATUS === 'Active' ? (
                                    <button onClick={() => handleSetStatus(email.E_ID, 'Inactive')}>Set Inactive</button>
                                ) : (
                                    <button onClick={() => handleSetStatus(email.E_ID, 'Active')}>Set Active</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default GroupDetails;
