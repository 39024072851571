import React from 'react'
import './style.css'
import PageLog from '../PageLog/PageLog'

const Organizations = () => {
  return (
    <div className='org'>
      <PageLog/>

      <div>
      <img   src="../images/organizations/org1.png" />
      <img   src="../images/organizations/org2.png"/>
      <img   src="../images/organizations/org3.png"/>
      
      </div>
    </div>
  )
}

export default Organizations
