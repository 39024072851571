import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Grid } from "@mui/material";

//Icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

//Header
import Links from "../../Links";
const MainCard = () => {
  return (
    <>
      <Links/>
      <div style={{ margin: "20px",paddingTop:'100px' }}>
        <Card sx={{ maxwidth: 700, maxheight: 1200 }}>
          <CardActionArea>
            <hr
              style={{
                color: "blue",
                height: "4px",
                backgroundColor: "#00004d",
                margin: "20px",
              }}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="body"
                component="div"
                style={{ color: "blue", fontWeight: "bold" }}
              >
                <span style={{ color: "#1E90FF" }}>Logo DOCTORSPOT </span>S2V IT
                PARK, KALAPURACKAL RD,VALLATHOL JN , THRIKAKKARA, KOCHI:- 682021
                Web:-.mydoctorspot.com
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{ color: "blue", marginTop: "30px", fontWeight: "bold" }}
              >
                Stress Assesment Report
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={3} xs={6} md={3}>
                  <Typography variant="h6">Date</Typography>
                  <div>1/09/2020 21:13:12</div>
                </Grid>
                <Grid item lg={3} xs={6} md={3}>
                  <Typography variant="h6">Name</Typography>
                  <div>Anand</div>
                </Grid>
                <Grid item lg={3} xs={6} md={3}>
                  <Typography variant="h6">Age</Typography>
                  <div>50</div>
                </Grid>
                <Grid item lg={3} xs={6} md={3}>
                  <Typography variant="h6">PSS Score</Typography>
                  <div>17</div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "40px" }}>
                <Grid item lg={3} xs={6} md={3}>
                  <Typography variant="h6">Blood Pressure</Typography>
                  <div>#N/A #N/A</div>
                  <Typography variant="body">Normal: 80/120 mmHg</Typography>
                </Grid>
                <Grid item lg={3} xs={6} md={3}>
                  <Typography variant="h6">Heart Rate</Typography>
                  <div>#N/A </div>
                  <Typography variant="body">Normal: 60-100 bpm</Typography>
                </Grid>
                <Grid item lg={3} xs={6} md={3}>
                  <Typography variant="h6">BMI</Typography>
                  <div>#N/A</div>
                  <Typography variant="body">
                    Normal: 18.5 To 24.9 Kg/m2
                  </Typography>
                </Grid>
              </Grid>
              <hr style={{ marginTop: "30px", backgroundColor: "black" }} />
              <hr style={{ marginTop: "30px", backgroundColor: "black" }} />
              <Typography
                component="div"
                gutterBottom
                variant="h6"
                style={{ float: "left" }}
              >
                <PlayArrowIcon />
                Scores ranging from 14-26 would be considered moderate stress.
              </Typography>

              <Typography
                component="div"
                gutterBottom
                variant="h6"
                style={{ float: "left" }}
              >
                <PlayArrowIcon />
                Scores ranging from 27-40 would be considered high perceived
                stress.
              </Typography>

              <Typography
                variant="h6"
                component="div"
                gutterBottom
                style={{ float: "left" }}
              >
                <PlayArrowIcon />
                Scores ranging from 0-13 would be considered low stress.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
      <div style={{ margin: "20px" }}>
        <Card sx={{ maxwidth: 700 }}>
          <CardActionArea>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ color: "#1E90FF", float: "left" }}
                  >
                    Advice For You
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    style={{
                      color: "blue",
                      marginTop: "30px",
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    You are under Moderate Stress please find leisure Time.
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ marginTop: "100px " }}
                  >
                    <span style={{ color: "orange" }}>Disclaimer :</span>
                    <span style={{ fontSize: "15px" }}>
                      The scores on the following self-assessment do not reflect
                      any particular diagnosis or course of treatment. They are
                      meant as a tool to help assess your level of stress. If
                      you have any further concerns about your current well
                      being, you may contact specialists listed in our portal.
                      Assesment is done with reference to PSS 10 scale
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    
    </>
  );
};

export default MainCard;
