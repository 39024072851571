import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Homepages from '../Homepages';
import MiddleFile from './MiddleFile';
import TechieIndexLatest from '../../TechieIndexLatest/TechieIndexLatest'
import PageLog from '../../PageLog/PageLog';
import Cookies from 'js-cookie';

function CityFromId() {

    const [id, setId] = useState(null);
    const [data, setData] = useState([])
    const flag = 0;
    useEffect(() => {
        const url = window.location.href;
        //const regex = /CityFromId\/(\d+)$/; // assumes ID is the last part of the URL
        const regex = /cityfromid\/(\d+)$/i;
        const match = url.match(regex);
        const id = match && match[1];
        const id1 = parseInt(id)
        Cookies.set('portalid', id1);
//debugger

        if (id) {
            setId(id); // store ID in state variable
            axios.post('/cityS/PortalID/id', { "id": id1 })
                .then(response => {
                    setData(response.data);

                    console.log("portal value", response.data)

                    // store response data in state variable
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, []);


    return (
        <div>


            {data.map((item, i) => {
                if (item.type == 'MYBLOCKS.IN' || item.type == 'MYBLOCKS.US' || item.type == 'MYBLOCKS.CA' || item.type == 'MYBLOCKS.UK') {
                    sessionStorage.removeItem("site");
                    sessionStorage.setItem("site", "Myblock");
                    return (<MiddleFile key={i} data={item} />)
                }
                else if (item.type == 'TECHIE-INDEX.COM') {
                    sessionStorage.removeItem("site");
                    sessionStorage.setItem("site", "Techie-Index");
                    return (<MiddleFile key={i} data={item} />)
                }

                else if (item.type == 'HEALTH.COM') {
                    sessionStorage.removeItem("site");
                    sessionStorage.setItem("site", "Myblock");
                    sessionStorage.setItem("inside_site", "health");
                    return (<MiddleFile key={i} data={item} />)
                }
            })}
            <PageLog />
        </div>
    );



}

export default CityFromId