import React, { Component } from 'react';
import axios from 'axios';
import './form.css';
import Cookies from 'js-cookie';
import PageLogLocation from '../../PageLog/PageLogLocation';
//import apiurl from '../../baseurl';
//import OutoDocPopUp from './outo-doc-pop-up'

class HealthSurvey extends Component {

    constructor(props) {
        super(props);

        this.state = {
            SURVEYERNAME: '',
            SURVRID: Cookies.get("userid"),
            DATE: '',
            CLASS: '1',
            DIVISION: 'A',
            FIRST_NAME: '',
            LAST_NAME: '',
            HOUSENAME: '',
            AGE: '',
            PINCODE: '',
            RESOURCE_ACC_WATER: 'Never',
            RESOURCE_ACC_AIR: 'Never',
            RESOURCE_ACC_FUND: 'Never',
            RESOURCE_ACC_EDUCATION: 'Never',
            RESOURCE_ACC_HEALTH: 'Never',
            ANUAL_INCOME: '<2.5 Lac/annum',
            STRESS_MEASUREMENT: 'false',
            QST1: 'Never',
            QST2: 'Never',
            QST3: 'Never',
            QST4: 'Never',
            QST5: 'Never',
            QST6: 'Never',
            QST7: 'Never',
            QST8: 'Never',
            QST9: 'Never',
            QST10: 'Never',
            JOBSTATUS: 'Prefer Not to say',
            BREAKFAST: [],
            LUNCH: [],
            SNACKS: [],
            DINNER: [],
            breakfastString: '',
            lunchString: '',
            snacksString: '',
            dinnerString: '',
            ALCOHOL: 'Never',
            TOBACCO: 'Never',
            SMOKING: 'Never',
            ACTIVITY: '',
            OUTODOC: 'Yes',
            DIABETES: 'Yes',
            HYPERTENSION: 'Yes',
            CHOLESTROL: 'Yes',
            HIST_CANCER: 'Yes',
            HIST_DIABETES: 'Yes',
            HIST_BP: 'Yes',
            HIST_CHOLESTROL: 'Yes',
            HEART: 'Yes',
            SKIN_ALLERGIES: 'Yes',
            HEAD_ACHE: 'Yes',
            COUGH: 'Yes',
            EAR_PAIN: 'Yes',
            RELATIVE_NAME: '',
            GOV_APPROVALS: 'Yes',
            TRANSPORT: 'Public'

        };
        this.userid = Cookies.get("userid");
        console.log("userid", this.userid)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }




    handleChange(event) {
        if (event.target.name === "SURVEYERNAME") {
            this.setState({ SURVEYERNAME: event.target.value });
        }
        if (event.target.name === "CLASS") {
            this.setState({ CLASS: event.target.value });
        }
        if (event.target.name === "DIVISION") {
            this.setState({ DIVISION: event.target.value });
        }
        if (event.target.name === "FIRST_NAME") {
            this.setState({ FIRST_NAME: event.target.value });
        }
        if (event.target.name === "LAST_NAME") {
            this.setState({ LAST_NAME: event.target.value });
        }
        if (event.target.name === "HOUSENAME") {
            this.setState({ HOUSENAME: event.target.value });
        }
        if (event.target.name === "AGE") {
            this.setState({ AGE: event.target.value });
        }
        if (event.target.name === "PINCODE") {
            this.setState({ PINCODE: event.target.value });
        }
        if (event.target.name === "RESOURCE_ACC_WATER") {
            this.setState({ RESOURCE_ACC_WATER: event.target.value });
        }
        if (event.target.name === "RESOURCE_ACC_AIR") {
            this.setState({ RESOURCE_ACC_AIR: event.target.value });
        }
        if (event.target.name === "RESOURCE_ACC_FUND") {
            this.setState({ RESOURCE_ACC_FUND: event.target.value });
        }
        if (event.target.name === "RESOURCE_ACC_EDUCATION") {
            this.setState({ RESOURCE_ACC_EDUCATION: event.target.value });
        }
        if (event.target.name === "RESOURCE_ACC_HEALTH") {
            this.setState({ RESOURCE_ACC_HEALTH: event.target.value });
        }
        if (event.target.name === "ANUAL_INCOME") {
            this.setState({ ANUAL_INCOME: event.target.value });
        }
        if (event.target.name === "STRESS_MEASUREMENT") {
            this.setState({ STRESS_MEASUREMENT: event.target.value });
        }
        if (event.target.name === "QST1") {
            this.setState({ QST1: event.target.value });
        }
        if (event.target.name === "QST2") {
            this.setState({ QST2: event.target.value });
        }
        if (event.target.name === "QST3") {
            this.setState({ QST3: event.target.value });
        }
        if (event.target.name === "QST4") {
            this.setState({ QST4: event.target.value });
        }
        if (event.target.name === "QST5") {
            this.setState({ QST5: event.target.value });
        }
        if (event.target.name === "QST6") {
            this.setState({ QST6: event.target.value });
        }
        if (event.target.name === "QST7") {
            this.setState({ QST7: event.target.value });
        }
        if (event.target.name === "QST8") {
            this.setState({ QST8: event.target.value });
        }
        if (event.target.name === "QST9") {
            this.setState({ QST9: event.target.value });
        }
        if (event.target.name === "QST10") {
            this.setState({ QST10: event.target.value });
        }

        if (event.target.name === "JOBSTATUS") {
            this.setState({ JOBSTATUS: event.target.value });
        }

        if (event.target.name === "DIABETES") {
            this.setState({ DIABETES: event.target.value });
        }
        if (event.target.name === "HYPERTENSION") {
            this.setState({ HYPERTENSION: event.target.value });
        }
        if (event.target.name === "CHOLESTROL") {
            this.setState({ CHOLESTROL: event.target.value });
        }
        if (event.target.name === "ACTIVITY") {
            this.setState({ ACTIVITY: event.target.value });
        }
        if (event.target.name === "BREAKFAST") {
            if (event.target.checked === true) {
                if (this.state.BREAKFAST.indexOf(event.target.value) !== -1) {
                    console.log("Yes, the value exists!")
                }
                else {
                    let array_breakfast = this.state.BREAKFAST
                    array_breakfast.push(event.target.value)
                    this.setState({ "BREAKFAST": array_breakfast })
                    console.log(this.state.BREAKFAST);
                }
            }
            if (event.target.checked === false) {
                let array_breakfast = this.state.BREAKFAST
                let index = array_breakfast.indexOf(event.target.value);
                array_breakfast.splice(index, 1); //Splice the array to remove the located index
                this.setState({ "BREAKFAST": array_breakfast })
                console.log(this.state.BREAKFAST);
            }

            this.breakfastString = this.state.BREAKFAST.join(', ');
            console.log("breakfastString", this.breakfastString);
            console.log("userid", this.userid)


            this.setState({ ...this.state, breakfastString: this.breakfastString }, () => {
                console.log("this.state", this.state); // This console.log will show the updated state
            });
        }
        if (event.target.name === "LUNCH") {
            if (event.target.checked === true) {
                if (this.state.LUNCH.indexOf(event.target.value) !== -1) {
                    console.log("Yes, the value exists!")
                }
                else {
                    let array_lunch = this.state.LUNCH
                    array_lunch.push(event.target.value)
                    this.setState({ "LUNCH": array_lunch })
                    console.log(this.state.LUNCH);
                }
            }
            if (event.target.checked === false) {
                let array_lunch = this.state.LUNCH
                let index = array_lunch.indexOf(event.target.value);
                array_lunch.splice(index, 1); //Splice the array to remove the located index
                this.setState({ "LUNCH": array_lunch })
                console.log(this.state.LUNCH);
            }

            this.lunchString = this.state.LUNCH.join(', ');
            console.log("lunchString", this.lunchString);


            this.setState({ ...this.state, lunchString: this.lunchString }, () => {
                console.log("this.state", this.state); // This console.log will show the updated state
            });
        }
        if (event.target.name === "SNACKS") {
            if (event.target.checked === true) {
                if (this.state.SNACKS.indexOf(event.target.value) !== -1) {
                    console.log("Yes, the value exists!")
                }
                else {
                    let array_snacks = this.state.SNACKS
                    array_snacks.push(event.target.value)
                    this.setState({ "SNACKS": array_snacks })
                    console.log(this.state.SNACKS);
                }
            }
            if (event.target.checked === false) {
                let array_snacks = this.state.SNACKS
                let index = array_snacks.indexOf(event.target.value);
                array_snacks.splice(index, 1); //Splice the array to remove the located index
                this.setState({ "SNACKS": array_snacks })
                console.log(this.state.SNACKS);
            }

            this.snacksString = this.state.SNACKS.join(', ');
            console.log("snacksString", this.snacksString);


            this.setState({ ...this.state, snacksString: this.snacksString }, () => {
                console.log("this.state", this.state); // This console.log will show the updated state
            });
        }
        if (event.target.name === "DINNER") {
            if (event.target.checked === true) {
                if (this.state.DINNER.indexOf(event.target.value) !== -1) {
                    console.log("Yes, the value exists!")
                }
                else {
                    let array_dinner = this.state.DINNER
                    array_dinner.push(event.target.value)
                    this.setState({ "DINNER": array_dinner })
                    console.log(this.state.DINNER);
                }
            }
            if (event.target.checked === false) {
                let array_dinner = this.state.DINNER
                let index = array_dinner.indexOf(event.target.value);
                array_dinner.splice(index, 1); //Splice the array to remove the located index
                this.setState({ "DINNER": array_dinner })
                console.log(this.state.DINNER);
            }

            this.dinnerString = this.state.DINNER.join(', ');
            console.log("dinnerString", this.dinnerString);


            this.setState({ ...this.state, dinnerString: this.dinnerString }, () => {
                console.log("this.state", this.state); // This console.log will show the updated state
            });
        }
        if (event.target.name === "ALCOHOL") {
            this.setState({ ALCOHOL: event.target.value });
        }
        if (event.target.name === "TOBACCO") {
            this.setState({ TOBACCO: event.target.value });
        }
        if (event.target.name === "SMOKING") {
            this.setState({ SMOKING: event.target.value });
        }
        if (event.target.name === "OUTODOC") {
            this.setState({ OUTODOC: event.target.value });
        }
        if (event.target.name === "HIST_CANCER") {
            this.setState({ HIST_CANCER: event.target.value });
        }
        if (event.target.name === "HIST_DIABETES") {
            this.setState({ HIST_DIABETES: event.target.value });
        }
        if (event.target.name === "HIST_BP") {
            this.setState({ HIST_BP: event.target.value });
        }
        if (event.target.name === "HIST_CHOLESTROL") {
            this.setState({ HIST_CHOLESTROL: event.target.value });
        }
        if (event.target.name === "HEART") {
            this.setState({ HEART: event.target.value });
        }
        if (event.target.name === "SKIN_ALLERGIES") {
            this.setState({ SKIN_ALLERGIES: event.target.value });
        }
        if (event.target.name === "HEAD_ACHE") {
            this.setState({ HEAD_ACHE: event.target.value });
        }
        if (event.target.name === "COUGH") {
            this.setState({ COUGH: event.target.value });
        }
        if (event.target.name === "EAR_PAIN") {
            this.setState({ EAR_PAIN: event.target.value });
        }
        if (event.target.name === "RELATIVE_NAME") {
            this.setState({ RELATIVE_NAME: event.target.value });
        }
        if (event.target.name === "GOV_APPROVALS") {
            this.setState({ GOV_APPROVALS: event.target.value });
        }
        if (event.target.name === "TRANSPORT") {
            this.setState({ TRANSPORT: event.target.value });
        }
    }



    handleSubmit(event) {
        //axios.defaults.baseURL = apiurl;
        //debugger
        event.preventDefault();

        console.log("this.state", this.state);
        axios.post('/full_survey', this.state)
            .then(response => {
                if (!response) {
                    alert("Not Sucssus:" + response.data)
                }
                else {
                    console.log("response.data", response.data)
                    alert(response.data.message)
                    this.setState({ FIRST_NAME: "", LAST_NAME: "", HOUSENAME: "", AGE: "", PINCODE: "", BREAKFAST: [], LUNCH: [], SNACKS: [], DINNER: [] });
                }
            }
            );
    }
    render() {
        return (
            <div className="employee-form-style">
                <PageLogLocation />
                {console.log(this.props.uname)}
                <form onSubmit={this.handleSubmit} className="form-style">
                    <h1 className="health-h1">Surveyor Details. ID:-{this.props.userid} </h1>
                    <label>Full Name of Surveyor<input disabled required type="text" name="SURVEYERNAME" value={this.props.uname} onChange={this.handleChange} /></label>
                    <label>Class
                        <input disabled required type="text" name="CLASS" value={this.props.sclass} onChange={this.handleChange} />
                    </label>
                    <label>Division
                        <input disabled required type="text" name="DIVISION" value={this.props.division} onChange={this.handleChange} />
                    </label>
                    <label>Gender
                        <input disabled required type="text" name="GENDER" value={this.props.gender} onChange={this.handleChange} />
                    </label>
                    <h1 className="health-h1">HEALTH SURVEY FORM</h1>
                    <label>First Name<input required type="text" name="FIRST_NAME" value={this.state.FIRST_NAME} onChange={this.handleChange} /></label>
                    <label>Last Name<input required type="text" name="LAST_NAME" value={this.state.LAST_NAME} onChange={this.handleChange} /></label>
                    <label>Family Name<input required type="text" name="HOUSENAME" value={this.state.HOUSENAME} onChange={this.handleChange} /></label>
                    <label>Date of birth<input required type="date" name="AGE" value={this.state.AGE} onChange={this.handleChange} /></label>
                    <label>Pin Code<input required type="text" name="PINCODE" value={this.state.PINCODE} onChange={this.handleChange} /></label>

                    <label> Are you Accessible to Clean Water Resources?
                        <select name="RESOURCE_ACC_WATER" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                            <option value="Always">Always</option>
                        </select>
                    </label>
                    <label> Are you Accessible to Clean Air?
                        <select name="RESOURCE_ACC_AIR" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                            <option value="Always">Always</option>
                        </select>
                    </label>
                    <label>  Are You Accessible to funds?
                        <select name="RESOURCE_ACC_FUND" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                            <option value="Always">Always</option>
                        </select>
                    </label>

                    <label>  Are you Accessible to Proper Education?
                        <select name="RESOURCE_ACC_EDUCATION" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                            <option value="Always">Always</option>
                        </select>
                    </label>

                    <label>  Are You Accessible to Health Resources?
                        <select name="RESOURCE_ACC_HEALTH" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                            <option value="Always">Always</option>
                        </select>
                    </label>
                    <label>Anual Income
                        <select name="ANUAL_INCOME" onChange={this.handleChange}>
                            <option selected value="<2.5 Lac/annum">Less than 2.5 Lac/annum</option>
                            <option value="2.5 to 5 Lac/annum">2.5 to 5 Lac/annum</option>
                            <option value="5 to 10 lac/annum">5 to 10 lac/annum</option>
                            <option value=">10 lac/annum">More than 10 lac/annum</option>
                        </select>
                    </label>
                    <label> Resource of Water?
                        <select name="RESOURCE_WATER" onChange={this.handleChange}>
                            <option selected value="well">Well</option>
                            <option value="Pipe Connection">Pipe Connection</option>
                            <option value="Pond">Pond</option>
                            <option value="River">River</option>
                        </select>
                    </label>

                    {/* <label>   Do you Like to go for Stress Survay.?
                        <select name="STRESS_MEASUREMENT" onChange={this.handleChange}>
                            <option selected value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </label> */}

                    <h1 className="health-h1">STRESS SURVEY FORM</h1>
                    <label>  In the last month, how often have you been upset because of something that happened unexpectedly?
                        <select name="QST1" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>  In the last month, how often have you felt that you were unable to control the important things in your life?
                        <select name="QST2" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>  In the last month, how often have you felt nervous and “stressed”?
                        <select name="QST3" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>In the last month, how often have you felt confident about your ability to handle your personal problems?
                        <select name="QST4" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label> In the last month, how often have you felt that things were going your way?
                        <select name="QST5" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>  In the last month, how often have you found that you could not cope with all the things that you had to do?
                        <select name="QST6" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>  In the last month, how often have you been able to control irritations in your life?
                        <select name="QST7" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>  In the last month, how often have you felt that you were on top of things?
                        <select name="QST8" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>  In the last month, how often have you been angered because of things that were outside of your control?
                        <select name="QST9" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <label>  In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?
                        <select name="QST10" onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Almost Never">Almost Never</option>
                            <option value="Sometimes">Sometimes</option>
                            <option value="Fairly Often">Fairly Often</option>
                            <option value="Very Often">Very Often</option>
                        </select>
                    </label>
                    <h1 className="health-h1">HABBIT SURVEY FORM</h1>
                    <label>Job Status
                        <select name="JOBSTATUS" value={this.state.JOBSTATUS} onChange={this.handleChange}>
                            <option selected value="House Wife">House Wife</option>
                            <option value="Daily Labour">Daily Labour</option>
                            <option value="Office Job">Office Job</option>
                            <option value="Student">Student</option>
                            <option value="Job Seeker">Job Seeker</option>
                        </select>
                    </label>
                    <h1 className="health-h1">Food Habit</h1>

                    <table>
                        <tr>
                            <th></th>
                            <th>Fruits</th>
                            <th>Meat/Fish</th>
                            <th>Vegetables</th>
                            <th>Soft-drinks</th>
                            <th>Tea/Coffee</th>
                        </tr>
                        <tr>
                            <td>Breakfast</td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="BREAKFAST" value="Fruits" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="BREAKFAST" value="Meat/Fish" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="BREAKFAST" value="Vegetables" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="BREAKFAST" value="Soft-drinks" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="BREAKFAST" value="Tea/Coffee" />
                            </td>
                        </tr>
                        <tr>
                            <td>Lunch</td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="LUNCH" value="Fruits" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="LUNCH" value="Meat/Fish" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="LUNCH" value="Vegetables" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="LUNCH" value="Soft-drinks" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="LUNCH" value="Tea/Coffee" />
                            </td>
                        </tr>
                        <tr>
                            <td>Snacks</td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="SNACKS" value="Fruits" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="SNACKS" value="Meat/Fish" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="SNACKS" value="Vegetables" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="SNACKS" value="Soft-drinks" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="SNACKS" value="Tea/Coffee" />
                            </td>
                        </tr>
                        <tr>
                            <td>Dinner</td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="DINNER" value="Fruits" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="DINNER" value="Meat/Fish" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="DINNER" value="Vegetables" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="DINNER" value="Soft-drinks" />
                            </td>
                            <td>
                                <input onChange={this.handleChange} className='food-type-input' type="checkbox" name="DINNER" value="Tea/Coffee" />
                            </td>
                        </tr>
                    </table>
                    {/* <div className='check-box-conatainer'>
                        <div className='food-type'></div>
                        <div className='check-box-input-label-conatainer'>
                            <label className='food-type-label'>Fruits </label>
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <label className='food-type-label'>Meat/Fish</label>
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <label className='food-type-label'>Vegetables</label>
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <label className='food-type-label'>Soft-drinks</label>
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <label className='food-type-label'>Tea/Coffee</label>
                        </div>
                    </div>
                    <div className='check-box-conatainer'>
                        <div className='food-type'>Breakfast</div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle1" name="BREAKFAST" value="Fruits" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle2" name="BREAKFAST" value="Meat/Fish" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="BREAKFAST" value="Vegetables" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="BREAKFAST" value="Soft-drinks" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="BREAKFAST" value="Tea/Coffee" />
                        </div>
                    </div>

                    <div className='check-box-conatainer'>
                        <div className='food-type'>Lunch</div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle1" name="LUNCH" value="Fruits" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle2" name="LUNCH" value="Meat/Fish" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="LUNCH" value="Vegetables" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="LUNCH" value="Soft-drinks" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="LUNCH" value="Tea/Coffee" />
                        </div>
                    </div>
                    <div className='check-box-conatainer'>
                        <div className='food-type'>Snacks</div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle1" name="SNACKS" value="Fruits" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle2" name="SNACKS" value="Meat/Fish" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="SNACKS" value="Vegetables" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="SNACKS" value="Soft-drinks" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="SNACKS" value="Tea/Coffee" />
                        </div>
                    </div>
                    <div className='check-box-conatainer'>
                        <div className='food-type'>Dinner</div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle1" name="DINNER" value="Fruits" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle2" name="DINNER" value="Meat/Fish" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="DINNER" value="Vegetables" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="DINNER" value="Soft-drinks" />
                        </div>
                        <div className='check-box-input-label-conatainer'>
                            <input onChange={this.handleChange} className='food-type-input' type="checkbox" id="vehicle3" name="breakfast" value="Tea/Coffee" />
                        </div>
                    </div> */}


                    <h1 className="health-h1">Use of Intoxicants</h1>
                    <label>Alcohol
                        <select name="ALCOHOL" value={this.state.ALCOHOL} onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                        </select>
                    </label>
                    <label>Tobacco
                        <select name="TOBACCO" value={this.state.TOBACCO} onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                        </select>
                    </label>
                    <label>Smoking
                        <select name="SMOKING" value={this.state.SMOKING} onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                        </select>
                    </label>
                    <h1 className="health-h1">Activity Level</h1>
                    <label>Activity
                        <select name="ACTIVITY" value={this.state.ACTIVITY} onChange={this.handleChange}>
                            <option selected value="Never">Never</option>
                            <option value="Rarely">Rarely</option>
                            <option value="Frequent">Frequent</option>
                        </select>
                    </label>
                    <label>Whether Check up done with AutoDoc
                        <select name="OUTODOC" value={this.state.OUTODOC} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    {/* { this.state.OUTODOC === "No" &&
                        <OutoDocPopUp/>
                    } */}
                    <h1 className="health-h1">Health Check</h1>
                    <div className='food-habit-heading-div'>
                    </div>
                    <div>
                        <label>Diabetes
                            <select name="DIABETES" value={this.state.DIABETES} onChange={this.handleChange}>
                                <option selected value="No">No</option>
                                <option value="Yes, check duration">Yes, check duration</option>
                                <option value="Under 1year">Under 1year</option>
                                <option value="More than 1 year">More than 1 year</option>
                            </select>
                        </label>
                        <label>Hypertension
                            <select name="HYPERTENSION" value={this.state.HYPERTENSION} onChange={this.handleChange}>
                                <option selected value="No">No</option>
                                <option value="Yes, check duration">Yes, check duration</option>
                                <option value="Under 1year">Under 1year</option>
                                <option value="More than 1 year">More than 1 year</option>
                            </select>
                        </label>
                        <label>Cholestrol
                            <select name="CHOLESTROL" value={this.state.CHOLESTROL} onChange={this.handleChange}>
                                <option selected value="No">No</option>
                                <option value="Yes, check duration">Yes, check duration</option>
                                <option value="Under 1year">Under 1year</option>
                                <option value="More than 1 year">More than 1 year</option>
                            </select>
                        </label>
                    </div>
                    <br />
                    <h1 className="health-h1">Family History of any of the diseases</h1>
                    <div className='food-habit-heading-div'>
                    </div>
                    <label>Cancer
                        <select name="HIST_CANCER" value={this.state.HIST_CANCER} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <label>Diabetes
                        <select name="HIST_DIABETES" value={this.state.HIST_DIABETES} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <label>Blood Pressure
                        <select name="HIST_BP" value={this.state.HIST_BP} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <label>Cholesterol
                        <select name="HIST_CHOLESTROL" value={this.state.HIST_CHOLESTROL} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <label>Heart
                        <select name="HEART" value={this.state.HEART} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <h1 className="health-h1">Diseases & Relationship with the person</h1>
                    <label>Diseases & Relationship with the person<input type="text" name="RELATIVE_NAME" value={this.state.RELATIVE_NAME} onChange={this.handleChange} /></label>

                    <h1 className="health-h1">Any other prolonged symptom</h1>
                    <label> Ear pain
                        <select name="EAR_PAIN" value={this.state.EAR_PAIN} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <label> Cough
                        <select name="COUGH" value={this.state.COUGH} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <label> Head Ache
                        <select name="HEAD_ACHE" value={this.state.HEAD_ACHE} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <label>Skin Allergies
                        <select name="SKIN_ALLERGIES" value={this.state.SKIN_ALLERGIES} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <h1 className="health-h1">Happy Survey</h1>

                    <label>Do you use Public or Private Transport
                        <select name="TRANSPORT" value={this.state.TRANSPORT} onChange={this.handleChange}>
                            <option selected value="Private">Private Transport</option>
                            <option value="Public">Public Transport</option>
                            <option value="Both">Both</option>
                        </select>
                    </label>
                    <label> Are you getting local government approvals on time? (Local means Panchayath/Municipality)
                        <select name="GOV_APPROVALS" value={this.state.GOV_APPROVALS} onChange={this.handleChange}>
                            <option selected value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </label>
                    <input className="login-button" type="submit" value="SUBMIT" />
                </form>
            </div>
        );
    }
}

export default HealthSurvey;
