import React, { useState, useEffect, useLayoutEffect } from 'react'
import axios from 'axios';
import './AllArticles.css';
import Content from './Content';
import HeadingD from './HeadingVend';
import PageLog from '../../PageLog/PageLog';

const AllArticles = () => {
  const [data1, setData] = useState([])
  const [data2, SetImage1] = useState([])
  const [data3, SetImage2] = useState([])
  const [portal_img1, SetPortalImg1] = useState([])
  const [portal_img2, SetPortalImg2] = useState([])
  const [startIndex, setStartIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  let port = sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")
  let site = sessionStorage.getItem("site")
  console.log("site in article", site)

  useEffect(() => {


    axios.all([
      axios.post('/news/headlines/articles/more', { "port": port, "parentport": parentport }),
      axios.post(`/myblock/altimage/articles`),
      axios.post(`/techieindex/altimage/articles`),
      axios.post(`/myblock/altimage/articles/portalid`, { "port": port }),
      axios.post(`/techieindex/altimage/articles/portalid`, { "port": port })
    ])
      .then(axios.spread((data1, data2, data3, data4, data5) => {
        setData(data1.data)
        SetImage1(data2.data)
        SetImage2(data3.data)
        SetPortalImg1(data4.data)
        SetPortalImg2(data5.data)
        setMaxIndex(data1.data.length);
      }));
  }, []);

  const handleNextClick = () => {
    if (startIndex + 9 < maxIndex) {
      setStartIndex(startIndex + 9);
    }
  };

  const handlePrevClick = () => {
    if (startIndex - 9 >= 0) {
      setStartIndex(startIndex - 9);
    }
  };

  return (
    <div>
      <section>
        <div className='container'>
          <HeadingD />
          <PageLog/>
          <section className='hero_articles'>
            <div className='container'>
              <div className='content'>
                {data1.slice(startIndex, startIndex + 9).map((item, i) => {

                  if (portal_img1[i] === undefined) {
                    portal_img1[i] = 0;
                  }
                  if (portal_img2[i] === undefined) {
                    portal_img2[i] = 0;
                  }
                  if (site === "Myblock") {
                    return <Content key={i} item={item} data={data2[i]} portal_img={portal_img1[i]} />;
                  }
                  else if (site === "Techie-Index") {
                    return <Content key={i} item={item} data={data3[i]} portal_img={portal_img2[i]}/>;
                  }
                })}
              </div>
            </div>
          </section>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <div className='left-buttons'>
              <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
            </div>
            <div className='archives-link'>
              <p align="center"  >
                <a href={`http://61.2.142.91:8400/CityFromId/${port}`}  >Archives</a>
              </p>
            </div>
            <div className='right-buttons'>
              <button className={`btn btn-primary ${startIndex + 9 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 9 >= maxIndex}>Next</button>
            </div>

          </div>

          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`}
              onClick={handlePrevClick}
              disabled={startIndex === 0}
            >
              Previous
            </button>
            <p align="center"  >
              <a href={`http://61.2.142.91:8400/CityFromId/${port}`}  >Archives</a>
            </p>

            <button
              className={`btn btn-primary ${startIndex + 9 >= maxIndex ? 'disabled' : ''}`}
              onClick={handleNextClick}
              disabled={startIndex + 9 >= maxIndex}
            >
              Next
            </button>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default AllArticles