import React, { useEffect, useState } from 'react'
import MainCard from './MainCard'
import Data from './Data'
import { Grid } from '@mui/material'

//import axios
import axios from 'axios'



//React Router Link
import { Link } from 'react-router-dom'

const Cards = ({ match }) => {

    //State for fetching data
    const [cardData, setCardData] = useState()

    //Fetching data
    useEffect(() => {
        axios.get('http://localhost:3100/allIndex').
            then((response) => { setCardData(response.data) })
            .catch((error) => { console.log(error) })
    }, []);
    console.log(cardData)
    return (
        <div>
            <Grid container spacing={2} >{
                cardData?.map((data) => {
                    return (
                    <Grid item lg={3} xs={6} md={3} key={data.DS_ID}>
                            <Link style={{textDecoration:'none'}} to={`/${data.title}`}><MainCard data={data} /></Link>
                    </Grid>
                )}
                   
                )
            }
            </Grid>
        </div>
    )
}

export default Cards