import React, { useRef, useState, useEffect } from 'react';
import './techieindexlogin.css';
import axios from 'axios';
import Cookies from 'js-cookie';

import { useNavigate } from 'react-router-dom';
import PageLogLocation from '../PageLog/PageLogLocation';

const TechieIndexLogin = () => {


  const userid = Cookies.get("userid")
  const getEmailid = Cookies.get("getEmailid")
  const getPassword = Cookies.get("passwordData")
  let portalid = Cookies.get("portalid")
  const getMessage = Cookies.get("message")
  const navigate = useNavigate()
  //debugger
  if (portalid === 'null' || portalid === "0" || portalid === '') {
    portalid = 3025;
  }


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [id, setID] = useState("");
  const [key, setKey] = useState("");
  const [login, setLogin] = useState([])
  const [userType, setUserType] = useState("techieindex");
  const [status, setStatus] = useState("");



  const [searchpin, setPinSearchQuery] = useState('');
  sessionStorage.setItem("site", "Myblock");
  let getSite = sessionStorage.getItem("site")

  // Get the current URL hostname and port
  var hostname = window.location.hostname;
  var port = window.location.port;



  const handleSearch = () => {
    const baseUrl = window.location.origin + '/PinPage?pin=';
    const urlWithQuery = baseUrl + encodeURIComponent(searchpin);

    // Redirect the user to the generated URL
    window.location.href = urlWithQuery;
  };






  const log = async () => {
    try {
      console.log("userType", userType);
      Cookies.set("usertype", userType);

      //debugger

      //const apiEndpoint = userType === "BUSINESSAPP" ? "/business/login/details" : "/login/details";

      const response = await axios.post('/techieindex/login/details', {
        "username": username,
        "password": password
      });

      console.log("response.data", response.data);
      setLogin(response.data);

      // if (response.data.length) {
      //   navigate('/accident'); // Replace '/new-page' with your desired URL
      // }

    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  };

  const openPage = () => {
    console.log("userType", userType)
    //debugger
    if (userType === "techieindex" && status === 'Active') {
      navigate('/techieindexpage');

    } else

      if (userType === "icampus" && status === 'Active') {
        navigate('/icampuspage');

      }
      else if (userType === "ijobs" && status === 'Active') {
        navigate('/ijobspage');

      }
    // else if(status === 'Inactive'){
    //   alert("Your account is not activated, please activate your account via the link provided in your email")
    //   navigate('/tlogin');
    //   //window.location.reload();
    // }
  }


  return (
    <div>

      {login.map((login) => {
        //debugger



        if (login.USER_TYPE === "techieindex" && login.STATUS === 'Active') {

          Cookies.set('userid', login.USER_ID);
          Cookies.set('getEmailid', login.USERNAME);
          Cookies.set('passwordData', true);

          Cookies.set("firmid", login.FIRM_ID);
          Cookies.set('role_id', login.ROLE_ID);

          setUserType("techieindex")
          setStatus('Active')
          Cookies.set('usertype', "techieindex");
          //navigate('/techieindexpage');

        } else

          if (login.USER_TYPE === "icampus" && login.STATUS === 'Active') {

            Cookies.set('userid', login.USER_ID);
            Cookies.set('getEmailid', login.USERNAME);
            Cookies.set('passwordData', true);

            Cookies.set("firmid", login.FIRM_ID);
            Cookies.set('role_id', login.ROLE_ID);

            setUserType("icampus")
            setStatus('Active')
            Cookies.set('usertype', "icampus");

            //navigate('/icampuspage');

          }
          else if (login.USER_TYPE === "ijobs" && login.STATUS === 'Active') {

            Cookies.set('userid', login.USER_ID);
            Cookies.set('getEmailid', login.USERNAME);
            Cookies.set('passwordData', true);

            Cookies.set("firmid", login.FIRM_ID);
            Cookies.set('role_id', login.ROLE_ID);

            setUserType("ijobs")
            setStatus('Active')
            Cookies.set('usertype', "ijobs");

            //navigate('/ijobspage');

          }
          else if (login.STATUS === 'Inactive') {
            // setStatus('Inactive')
            alert("Your account is not activated, please activate your account via the link provided in your email")

          }

        //window.location.reload();
        return <div key={login.USERNAME}></div>;
      })}

      {getEmailid && getPassword ?
        (
          openPage()

        ) :
        (

          <div>
            <PageLogLocation />
            <div className="tx-login-container">
              <div className="login-right-side">
                <div className="login-form-container">
                  <h2>Login</h2>
                  {/* <form id="login-form"> */}
                  <input type="text" id="username" placeholder="Username" required onChange={(e) => {
                    setUsername(e.target.value);
                  }} />
                  <input type="password" id="password" placeholder="Password" required onChange={(e) => {
                    setPassword(e.target.value);
                  }} />

                  <div className="forgot-password">
                    <a href="/txforgotpasswordemail">Forgot Password?</a>
                  </div>
                  <button id="login-button" className="login-button" onClick={log}>Login</button>
                  {/* </form> */}
                </div>
                New? <a href="/techieindexregister">Sign Up</a>
                {/* <div className="signup-link">
                  New User? <a href="/Register">Sign Up</a>
                </div> */}
              </div>
            </div>
          </div>
        )}

    </div >
  );

}
export default TechieIndexLogin
