const Careers = () => {
    return (
        <div>
            <p>
                Techieindex is a leading enterprise solutions developer with its ace product eDRM providing unmatched ease and flexibility in the implementation of websites intended for developer-support. Our high growth-rate can be attributed to the highly talented and very dedicated group of professionals who work for us.<br />
                <br />
                At Techieindex, we understand that employees are our most valuable resource. Our broad base of technology and ingrained talent at every level of our organization helps us stay ahead of others in this highly competitive market. We are committed to solving both day-to-day challenges facing our clients, as well as long-term strategic goals necessary to succeed in the marketplace.<br />
                <br />
                Techieindex provides a challenging work environment, to attract and retain the best talent in one. We enable employees to develop and contribute to their full potential. With our growing customer base, we are constantly on the lookout for talented candidates.<br />
                <br />
                Those interested in working in a highly competitive, yet motivated Company, that is bound to be an enriching experience, come join our Task Force by sending your resumes to<font size="4" face="Arial"> <a href="mailto:careers@techieindex.net">careers@techieindex.net</a></font>
            </p>
        </div>
    )
}

export default Careers;