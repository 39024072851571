import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './bulkemailhidemail.css';
import { useNavigate, Link } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';


function BulkEmailHideMail() {

  const firmid = Cookies.get("firmid")
  const userid = Cookies.get("userid")
  const [emails, setEmails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedEmailIds, setSelectedEmailIds] = useState([]);
  const navigate = useNavigate();




  useEffect(() => {
    loademails();

  }, []);

  const loademails =() =>{

    axios.get(`/get/emails?firmid=${firmid}&userid=${userid}`)
    .then((response) => {
      const initialCheckboxStates = response.data.reduce((acc, email) => {
        acc[email.E_ID] = false;
        return acc;
      }, {});
      console.log("emails", response.data)
      setEmails(response.data);
      setCheckboxStates(initialCheckboxStates);
    })
    .catch((error) => {
      console.error('Failed to fetch projects:', error);
    });
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCheckboxStates((prevStates) => {
      const updatedStates = {};
      Object.keys(prevStates).forEach((key) => {
        updatedStates[key] = !selectAll;
      });
      return updatedStates;
    });
  };

  const handleCheckboxChange = (e, emailId) => {
    const isChecked = e.target.checked;
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [emailId]: isChecked,
    }));
  };


  const handleSubmit = async () => {

    const selectedemailids = emails
    .filter((email) => checkboxStates[email.E_ID])
    .map((email) => email.E_ID);;
    //console.log("selected email",selected)
    //debugger

    
    try {
      const response = await axios.post('/hide/emails', {  selectedemailids });
      console.log("result", response);
      loademails();
      alert(response.data)
      navigate(`/bulkemailhome`)

    }
    catch (error) {
      console.log(error);
    }
    setSelectedEmailIds(selectedemailids);

  };

  console.log("selectedEmailids",selectedEmailIds)


  return (
    <div>
      <br/>
      <h1>Edex Hide email page</h1>
      <div className='bulkemailhidemail' >
      
      <div class="email-container">
        <table className="w1-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />

              </th>
              <th>Check Emails and click hide</th>
            </tr>
          </thead>
          <tbody>
            {emails.map((email) => (
              <tr key={email.E_ID} className="email-item">
                <td>
                  <input
                    type="checkbox"
                    checked={checkboxStates[email.E_ID]}
                    onChange={(e) => handleCheckboxChange(e, email.E_ID)}
                  />
                </td>
                <td>{email.EMAIL}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='bulk-div'>

        {/* <Link to="/addsendingemails">View hidden emails</Link> */}
        <br /><br />


      </div>
      <div className='bulk-div-button'>

        <button onClick={handleSubmit} >Hide mail</button>


      </div>


    </div ></div>
  )
}

export default BulkEmailHideMail