const Clients = () => {
    return (
        <div>
            <p>Our impressive range of clients are manifold. They come from across the spectrum of Top Technology Companies spread in various parts of the world. Our client list includes Companies, such as
                Actuate, BEA, BRIO etc.<br />
                <br />
                Our eDRM suite has enabled Companies to capture and manage all customer issues and activities, while dynamically building a highly productive, Web-based environment in which to collaborate. We help improve customer satisfaction by continuously reducing the total cost of support and improving the quality of responses to issues. Our expertise in this field enable us to eliminate the all-important factor called time-delay associated with authoring and sanctioning knowledge articles. Techieindex' understanding
                of business processes and industry allows us to design and develop solutions that are user friendly, value-additive and which ensures maximum return on the IT investments.<br />
                <br />
                Techieindex's value-added services to our clients brings forth new services and solutions of the highest Quality, thereby enabling them to enhance management and operational performance and in the process, helping them maximize their investment potential.<br />
                <br />
            </p>
        </div>
    )
}

export default Clients;