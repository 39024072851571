import React, { useState, useEffect } from 'react';
import './EditProfile.css';
import axios from 'axios';
import PageLog from '../../PageLog/PageLog';
import Cookies from 'js-cookie';

const EditProfile = () => {
    let userid = Cookies.get("userid")
    userid=parseInt(userid, 10)

    // const [formData, setFormData] = useState({
    //     id: '',
    //     name: '',
    //     age: '',
    //     gender: '',
    //     hospitalName: 'Mydoctorspot',
    //     phone: '',
    //     patientId: '', // Initialize patientId as an empty string
    //     MyblocksId: userid,
    // });

    
    const initialFormData = {
        id: '',
        name: '',
        age: '',
        gender: '',
        hospitalName: 'Mydoctorspot',
        phone: '',
        patientId: '',
        MyblocksId: userid,
    };

    const [formData, setFormData] = useState(initialFormData);

    //const [objectid, setObjectID] = useState('');
    const [error, setError] = useState('');


    //const userid = 103




    useEffect(() => {

        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await axios.get(`/api/populate/editprofile?MyblocksId=${userid}`);

            if (response.data.document) {

                const mappedData = {
                    id: response.data.document._id || '',
                    name: response.data.document.Name || '', // Map 'Name' to 'name'
                    age: response.data.document.Age || '', // Map 'Age' to 'age'
                    gender: response.data.document.Gender || '', // Map 'Gender' to 'gender'    
                    hospitalName: 'Mydoctorspot',
                    phone: response.data.document.phone || '', // Assuming phone is not provided in the response data
                    patientId: response.data.document.patientId || '', // Map 'patientId' to 'patientId'
                    MyblocksId: response.data.document.MyblocksId || '', // Map 'MyblocksId' to 'MyblocksId'
                };
                setFormData(mappedData);

            }
            //setObjectID(response.data.document._id)

            console.log("response", response.data)
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    console.log("formData", formData)
    //console.log("objectid", objectid)
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };



    const handleChange = (e) => {
        const { name, value } = e.target;
        // Validation for allowing only numbers in phone, age, and doctor spot fields
        if (name === 'phone' || name === 'age' || name === 'patientId') {
            // Only allow numeric values
            if (!/^\d*$/.test(value)) {
                setError('Please enter only numeric values.');
                return; // Do not update state if value is not numeric
            } else {
                setError('');
            }
        }

        if (name === 'phone' && value.length > 10) {
            setError('Phone number must be 10 digits.');
            return; // Do not update state if phone number length is not 10
        } else {
            setError('');
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        //debugger
        e.preventDefault();

        console.log("formData", formData)
        try {


            if (!formData.id) {
                const response = await axios.post('/submit-form', formData);

                if (response.status === 200) {
                    console.log('response:', response.data.message);
                    alert(response.data.message)
                    setFormData(initialFormData);
                    fetchPosts();

                    // You can also display a success message to the user if needed.
                }
            }
            else {
                const response = await axios.post('/update/profile/info', formData);

                if (response.status === 200) {
                    console.log('response:', response.data.message);
                    alert(response.data.message)
                    setFormData(initialFormData);
                    fetchPosts();
                    // You can also display a success message to the user if needed.
                }

            }


        } catch (error) {
            console.error('Error submitting form data:', error);

            // setError('An error occurred while submitting the form. Please try again.');
        }
    };

    return (
        <div className="center-container">
            <PageLog/>
            <div className="edit-profile-container">
                <br></br> <br></br>
                <h2>Doctorspot</h2>
                {error && <div className="alert alert-danger">{error}</div>}

                <form className="edit-profile-form">
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label>Age:</label>
                        <input
                            type="text"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label>Gender:</label>
                        <input
                            type="text"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label>Hospital Name:</label>
                        <input
                            type="text"
                            name="hospitalName"
                            value={formData.hospitalName}

                            className="form-control"
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone Number:</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="form-control"
                        />
                        
                    </div>
                    <div className="form-group">
                        <label style={{ color: 'red' }}>My Doctospot ID:</label>
                        <input
                            type="text"
                            name="patientId"
                            value={formData.patientId}
                            onChange={handleChange}
                            className="form-control"
                        />
                        
                    </div>
                    <div className="form-group">
                        <label>Myblocks ID:</label>
                        <input
                            type="text"
                            name="MyblocksId"
                            value={formData.MyblocksId}
                            className="form-control"
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" onClick={handleSubmit} className="btn ebtn-primary">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditProfile;
