import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import InstaPostingSchedule from './InstaPostingSchedule';

function InstaPageCheckSchedule(props) {

    const { bardStory, id, extractedIDs } = props;

    console.log("insta page check props", bardStory, id, extractedIDs)

    const facebook_token = Cookies.get("facebook_token")
    //const processedIDs = [];
    const [processedIDs, setProcessedIDs] = useState([]);



    // console.log("extractedIDs in page check", extractedIDs)

    useEffect(() => {
        for (const item of extractedIDs) {
            getinstaaccount(item.group_page_id);
        }
    }, [extractedIDs, facebook_token]);

    const getinstaaccount = async (group_page_id) => {

        try {

            const Response = await axios.get(`https://graph.facebook.com/v17.0/${group_page_id}?fields=instagram_business_account&access_token=${facebook_token}`);
            if (Response.data.instagram_business_account === undefined) {
                let group_page_url = `https://www.facebook.com/profile.php?id=${group_page_id}`

                alert(`\n ${group_page_url}\n\nThis account does not have an Instagram business account`)
            }
            else {
                const instagramAccountId = Response.data.instagram_business_account.id;
                // console.log("result", instagramAccountId);
                // Push the ID into the processedIDs array

                // Use setProcessedIDs to update the state
                setProcessedIDs((prevProcessedIDs) => {
                    if (!prevProcessedIDs.includes(instagramAccountId)) {
                        return [...prevProcessedIDs, instagramAccountId];
                    }
                    return prevProcessedIDs;
                });


                // if (!processedIDs.includes(instagramAccountId)) {
                //     processedIDs.push(instagramAccountId);
                // }
            }

        } catch (error) {
            // Handle other errors that might occur during the asynchronous operation
            // console.error("Error fetching Instagram account:", error);

            // You can customize the alert message based on the specific error or just use a generic message
            alert(`There seem to be an error, do verify if selected page has a instagram account linked to it `);
        }
    }
    console.log("processedIDs", processedIDs);


    return (
        <div>
            {processedIDs.length > 0 && (
                <InstaPostingSchedule bardStory={bardStory} id={id} processedIDs={processedIDs} />
             )}
        </div>
    )
}

export default InstaPageCheckSchedule