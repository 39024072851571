import React, { useState, useEffect } from 'react'
import CryptoJS from 'crypto-js';
import { useLocation, Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import './forgotpassword.css'
import LoginHeader from '../LoginHeader';
import LoginFooter from '../LoginFooter';
import PageLogLocation from '../../PageLog/PageLogLocation';


function PasswordResetPage() {
    const [decryptedTextnew, setDecrypt] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [usertype, setUserType] = useState('');
    const navigate = useNavigate()


    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    //const decodedId = id ? decodeURIComponent(id.replace(/ /g, '+')) : null;
    const decodedId = id ? decodeURIComponent(id) : null;
    const secretKey = '#myblocks*';

    useEffect(() => {
        if (decodedId) {
            decrypt();
        }
    }, [decodedId]);

    const decrypt = () => {
        const decryptedBytes = CryptoJS.AES.decrypt(decodedId, secretKey);
        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
        //console.log(`Decrypted: ${decryptedText}`);

        const parts = decryptedText.split('@');
        const secondAtValue = parts[2];

        //console.log("secondAtValue", secondAtValue)
        let originalEmail = '';
        if (secondAtValue === 'myblocks_basicuser#') {
            originalEmail = decryptedText.replace(/@myblocks_basicuser#$/, '');
            setUserType('Basicuser')
        }
        else if (secondAtValue === 'myblocks_business_admin#') {
            originalEmail = decryptedText.replace(/@myblocks_business_admin#$/, '');
            setUserType('BusinessAdminuser')
        }

        //console.log("originalEmail", originalEmail)       

        

        setDecrypt(originalEmail);
    };

    //console.log("usertype", usertype)
    //console.log("id", decodedId)

    // Remove 'mars' from the end
    //console.log(`Original Email: ${decryptedTextnew}`);



    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (password === '') {
            setError('Password cannot be empty');
            return;
        }

        let apiEndpoint = '';
        if (usertype === 'BusinessAdminuser') {
            apiEndpoint = "/password/reset/employusers";
            
          }
        //   else if (usertype === 'Adminuser') {
        //     apiEndpoint = "/login/verify/email/employuser";
        //     utype = 'Admin Account'
        //   }
          else
            if (usertype === 'Basicuser') {
              apiEndpoint = "/password/reset";
              
            }

        try {
            // Make an Axios request to your backend
            const response = await axios.post(apiEndpoint, {
                email: decryptedTextnew,
                password: password,
            });

            // Handle the response from the backend as needed
            console.log(response.data);
            alert(response.data.message)
            // Reset state and navigate to a success page or perform any other action
            setPassword('');
            setConfirmPassword('');
            setError(null);
            navigate('/');
        } catch (error) {
            console.error('Error submitting password:', error);
            setError('Error submitting password. Please try again.');
        }
    };

    return (
        <div>
            <LoginHeader />
            <PageLogLocation />
            <br /><br /><br /><br /><br /><br /><br />
            <div className='password-reset-container'>
                <h2>Password Reset</h2>
                <p>Resetting password for <b>{decryptedTextnew}</b></p>

                <div>
                    <label>Password:</label>
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </div>

                <div>
                    <label>Confirm Password:</label>
                    <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                </div>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <button onClick={handleSubmit}>Submit</button>
            </div>
            <br /><br />
            <LoginFooter />
        </div>
    )
}

export default PasswordResetPage