// StartupList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Details from './Details'; // Import the CompanyDetails component
import './Ranking1.css';
import PageLog from '../../PageLog/PageLog';

const itemsPerPage = 100;

const StartupList = () => {
  const [startups, setStartups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null); // Track the selected company

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/H1b');
        console.log('Fetched data from backend:', response.data);
        setStartups(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = startups.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(startups.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };
    // Scroll to the top of the table
    const tableElement = document.querySelector('.startup-table');
  
    if (tableElement) {
      const tableTop = tableElement.offsetTop;
  
      window.scrollTo({
        top: tableTop,
        behavior: 'auto',
      });
    } else {
      console.error('Table element not found');
    }

  return (
    <div>
      <PageLog/>
      <h1>Best H-1B Sponsors in 2023!</h1>
      <p></p>
      <table className="startup-table">
        <thead>
          <tr>
            <th>Ranking</th>
            <th>H-1B Sponsors</th>
            <th>Fiscal year</th>
            <th>Initial Approval</th>
            <th>Initial Denial</th>
            <th>Counting Approval</th>
            <th>Counting Denial</th>
            <th>City</th>
            <th>Zip</th>
            <th>State</th>
            <th>Country</th>
            <th>NAICS</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((startup) => (
            <tr key={startup.ST_ID}>
              <td>{startup.Ranking}</td>
              <td>{startup.company_name}</td>
              <td>{startup.Rank_Year}</td>
              <td>{startup.employees}</td>
              <td>{startup.job_openings}</td>
              <td>{startup.valuation}</td>
              <td>{startup.No_visa_prod}</td>
              <td>{startup.city}</td>
              <td>{startup.Zip}</td>
              <td>{startup.state}</td>
              <td>{startup.country}</td>
              <td>{startup.total_funding}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {pageNumbers.map((number) => (
          <span
            key={number}
            className={number === currentPage ? 'active' : ''}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </span>
        ))}
      </div>

      {/* Render the CompanyDetails component when a company is selected */}
      {selectedCompany && <Details company={selectedCompany} />}
    </div>
  );
};

export default StartupList;
