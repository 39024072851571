import { React, useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Homepages from '../Home/Homepages';
import Material from './material';
import { NavLink, useNavigate } from "react-router-dom";
import './mainpage.css';
import ChildLinks from '../Home/ChildLinks/ChildLinks';
import Cookies from 'js-cookie';

const MainpageCategories = () => {
  let getCity = sessionStorage.getItem("city")
  //console.log("city",getCity);
  let getPortal = sessionStorage.getItem("portalid")
  console.log("portal id in mainpage", getPortal);
  let getPincode = sessionStorage.getItem("pincode")
  sessionStorage.setItem("site", "Myblock");

  const getEmailid = Cookies.get("getEmailid")
  const getPassword = Cookies.get("passwordData")
  const portalid = Cookies.get("portalid")
  const mainpagecookie = Cookies.get("mainpagecookie")
  console.log("mainpagecookie", mainpagecookie)

  //console.log("pin",getPincode);
  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])
  const [data3, setData3] = useState([])
  const [data4, setData4] = useState([])

  const inputRef = useRef(null);
  const navigate = useNavigate()


  let id = sessionStorage.getItem("portalid", "****")
  //console.log("id",id);



  // useEffect(() => {
  //   console.log("Inside useEffect");

  //   const cityLink = window.location.origin + `/CityFromId/${portalid}`;
  //   console.log("Inside useEffect mainpagecookie", mainpagecookie)
  //   console.log("getEmailid", getEmailid)
  //   console.log("getPassword", getPassword)
  //   //debugger
  //   if (!parseInt(mainpagecookie, 10) && getEmailid && getPassword) {

  //     window.location.href = cityLink;
  //     Cookies.set('mainpagecookie', 1);
  //     //mainpagecookie = Cookies.get("mainpagecookie")
  //   }
  // }, []);




  const handleWindowClose = () => {
    Cookies.set('mainpagecookie', 0);

  };

  useEffect(() => {
    // Add a listener for the window's beforeunload event
    window.addEventListener('beforeunload', handleWindowClose);

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  const handlepincode = (event) => {
    event.preventDefault();
    const pin = inputRef.current.value;
    console.log(pin)
    //sessionStorage.setItem("pincode", data1.current.value)
    const path = `/PinPage?pin=${pin}`;
    navigate(path);



    //window.location.reload();

  }

  const cityLink = window.location.origin + `/CityFromId/${portalid}`;

  const openPage = () => {
    window.location.href = cityLink;
    Cookies.set('mainpagecookie', 1);
  }



  useEffect(() => {
    axios.all([
      axios.post('/cityS/quality'),
      // axios.post(`/cityS/childcount`),
      // axios.post(`/cityS/active`),
      // axios.post(`/cityS/latest`)
    ])
      .then(axios.spread((data1, data2, data3, data4) => {
        setData1(data1.data)
        //setData2(data2.data)
        //setData3(data3.data)
        //setData4(data4.data)

      }));
  }, []);

  //console.log("value",data1.current.value)
  return (

    <div>
      {!parseInt(mainpagecookie, 10) && getEmailid && getPassword ?
        (
          openPage()
        ) :

        (<div>
          {getPortal && getCity || getPincode ?
            <Homepages /> :
            <div>
              <ChildLinks />
              <div className='page container paddingTB'>
                <div className='city'>
                  <h5>Enter your Pincode</h5>
                  <input name="input" placeholder="Enter Pincode" ref={inputRef} />
                  <button onClick={(event) => handlepincode(event)}>search</button>



                </div>
              </div>



              <div className='page container paddingTB'>
                <div className='city'>
                  <h5>Based on Quality</h5>
                  {data1.map((portal) => {


                    return (
                      <div className='mainpage'>
                        <Material key={portal.id} portal={portal} />
                      </div>
                    )
                  })}

                  <div > <NavLink to="/Cities?value=quality" style={{ margin: '20px' }}>...Access All Portals</NavLink></div>
                </div>

              </div>

              <div className='page container paddingTB'>

                <div className='city'>
                  <h5>Based on City Count</h5>
                  {data2.map((portal) => {


                    return (
                      <div className='mainpage'>
                        <Material key={portal.id} portal={portal} />
                      </div>
                    )
                  })}
                  <div > <NavLink to="/Cities?value=childcount" style={{ margin: '20px' }}>...Access All Portals</NavLink></div>
                </div>
              </div>

              <div className='page container paddingTB'>

                <div className='city'>
                  <h5>Based on Active sites</h5>
                  {data3.map((portal) => {


                    return (
                      <div className='mainpage'>
                        <Material key={portal.id} portal={portal} />
                      </div>
                    )
                  })}
                  <div > <NavLink to="/Cities?value=active" style={{ margin: '20px' }}>...Access All Portals</NavLink></div>
                </div>
              </div>


              <div className='page container paddingTB'>

                <div className='city'>
                  <h5>Based on Latest Portal</h5>
                  {data4.map((portal) => {


                    return (
                      <div className='mainpage'>
                        <Material key={portal.id} portal={portal} />
                      </div>
                    )
                  })}
                  <div > <NavLink to="/Cities?value=latest" style={{ margin: '20px' }}>...Access All Portals</NavLink></div>
                </div>
              </div>
            </div>
          }
        </div>
        )
      }
    </div >
  )
}

export default MainpageCategories
