import { React, useState, useEffect } from 'react';
import './adminuserhome.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import PageLogLocation from '../PageLog/PageLogLocation';


function BusinessUserHome() {


  const [pagemenu, setMenu] = useState('');
  const role_id = Cookies.get("role_id");
  const category = Cookies.get("category");
  const adminuser = Cookies.get("adminuser");

  useEffect(() => {
    axios.post('/Admin/app/menu', { "role_id": role_id, "category":category, "adminuser": adminuser})
      .then(function (response) {
        //console.log(response.data);
        setMenu(response.data)
      })
      .catch(function (error) {
        console.error('Failed to fetch menu:', error);
      });
  }, [])


  return (
    <div className="admin-container">
      <PageLogLocation/> 
      <header >
        <div className="admin-link-box">
          {pagemenu ? (
            pagemenu.map((link, index) => (
              <div key={index}>
                <a href={link.MENU_URL} className={`admin-link`}> {link.MENU_NAME}</a>
                <br />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="admin-content-container-temp">

        </div>
      </header>
    </div >
  )
}

export default BusinessUserHome