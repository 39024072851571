import React, { useState, useEffect } from 'react'
import './forgotpassword.css'
import axios from 'axios';
import CryptoJS from 'crypto-js'; // Import CryptoJS
import PageLogLocation from '../../PageLog/PageLogLocation';



function TxForgotPasswordEmail() {

  const [email, setEmail] = useState('');
  const secretKey = '#myblocks*';
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [usertype, setUserType] = useState('techieindex');



  useEffect(() => {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  }, [email]);

  const encrypt = () => {

    let emailWithtext = '';
    if (usertype === 'techieindex') {
      emailWithtext = email + '@techieindex#';
    }
    else if (usertype === 'ijobs') {
      emailWithtext = email + '@ijobs#';
    }
    else
      if (usertype === 'icampus') {
        emailWithtext = email + '@icampus#';
      }

    const encryptedText = CryptoJS.AES.encrypt(emailWithtext, secretKey).toString();

    //console.log(`Encrypted: ${encryptedText}`);

    return encryptedText;
  };




  //console.log("isValidEmail outside", isValidEmail)

  const sendmail = async () => {
    //validateEmail();
    console.log("isValidEmail", isValidEmail)
    //let apiEndpoint = '';
    let utype = '';
    if (usertype === 'techieindex') {
      utype = 'Techie-index Account'
    }
    else if (usertype === 'ijobs') {
      utype = 'IJobs Account'
    }
    else
      if (usertype === 'icampus') {
        utype = 'ICampus Account'
      }


    if (isValidEmail) {
      try {
        const response = await axios.post("/login/verify/email/techieindex", { email, usertype });
        //debugger
        // Handle the response as needed
        console.log(response.data);

        const encryptedemail = encrypt();
        const urlEncodedText = encodeURIComponent(encryptedemail);

        const url = `https://www.techie-index.com/txpasswordresetpage?id=${urlEncodedText}`;

        //console.log("url", url);

        const emailResponse = await axios.post('/send/password/reset/email/tx', {
          to: email,
          subject: `${utype} Password Reset`,
          text: 'Click on the link below to reset your password',
          html: `<p>Click on the link below to reset your password</p>    
            <a href=${url}>Reset Password</a>`,
        });

        console.log(emailResponse.data);
        alert(emailResponse.data.message)
        setEmail('');
      } catch (error) {
        // Handle errors, including 403 status code
        if (error.response && error.response.status === 404) {
          alert("User with this email id does not exist,or you created an account without email , please create an account or contact administrator");
        } else {
          console.error("Error:", error.message);
          // Handle other errors as needed
        }
      }
    }
    else {
      // Display alert for invalid email
      alert("Please enter a valid email address");
    }
  };

  //console.log("usertype", usertype)


  return (
    <div>
      <PageLogLocation />
      <br /><br /><br /><br /><br /><br /><br />
      <div className='tx-forgot-password-container'>
        <b>Please enter your Registered Email ID</b>
        <br />
        <input
          type='text'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setIsValidEmail(true); // Reset validation when the user types
          }}
        />

        <table className='user-type-table'>
          <tbody>
            <tr>
              <td>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    value='techieindex'
                    checked={usertype === 'techieindex'}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  Techie-Index
                </label>
              </td>
              <td>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    value='ijobs'
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  IJobs
                </label>
              </td>
              <td>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    value='icampus'
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  ICampus
                </label>
              </td>

            </tr>
          </tbody>
        </table>


        <br />
        <button onClick={sendmail} >Submit Request</button>
      </div>
      <br /><br />

    </div>
  )
}

export default TxForgotPasswordEmail