import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CardContent, Typography } from "@mui/material";

// Food Required
import { Link } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Result = () => {
  //State for fetching data
  const [data, setCardData] = useState();

  //Fetching data
  useEffect(() => {
    axios
      .get("http://localhost:3002/nutrition")
      .then((response) => {
        setCardData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(data);

  const location = useLocation();
  const gender = location.state.gender;
  const age = location.state.ages;
  console.log("Total", age);
  console.log("All gender", gender);

  const Cards = () => {
    return (
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="h4"
                  style={{ marginLeft: "10px", marginTop: "30px" }}
                >
                  Gender: {location.state.gender}
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    marginBottom: "30px",
                  }}
                >
                  Age : {location.state.ages}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={8} md={8}>
                <Typography
                  variant="h4"
                  style={{ marginLeft: "10px", marginTop: "30px" }}
                >
                  Foods Required For the Good Nutritions
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    marginBottom: "30px",
                  }}
                ></Typography>
                <Link to="/foodsrequired" style={{ textDecoration: "none" }}>
                  <Button variant="contained" style={{ marginLeft: "300px" }}>
                    Foods
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Nutrition</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6">Measurement</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6">Source Of Goal</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6">Quantity</Typography>
                  </TableCell>

                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((nutrition) => {
                  const differentAge = () => {
                    if (age >= 1 && age <= 3 && gender === "child") {
                      return <>{nutrition.Child_1_3}</>;
                    } else if (age >= 4 && age <= 8 && gender === "female") {
                      return <>{nutrition.Female_4_8}</>;
                    } else if (age >= 4 && age <= 8 && gender === "male") {
                      return <>{nutrition.Male_4_8}</>;
                    } else if (age >= 9 && age <= 13 && gender === "female") {
                      return <>{nutrition.Female_9_13}</>;
                    } else if (age >= 9 && age <= 13 && gender === "male") {
                      return <>{nutrition.Male_9_13}</>;
                    } else if (age >= 14 && age <= 18 && gender === "female") {
                      return <>{nutrition.Female_14_18}</>;
                    } else if (age >= 14 && age <= 18 && gender === "male") {
                      return <>{nutrition.Male_14_18}</>;
                    } else if (age >= 19 && age <= 30 && gender === "female") {
                      return <>{nutrition.Female_19_30}</>;
                    } else if (age >= 19 && age <= 30 && gender === "male") {
                      return <>{nutrition.Male_19_30}</>;
                    } else if (age >= 31 && age <= 50 && gender === "female") {
                      return <>{nutrition.Female_31_50}</>;
                    } else if (age >= 31 && age <= 50 && gender === "male") {
                      return <>{nutrition.Male_31_50}</>;
                    } else if (age >= 51 && age <= 100 && gender === "female") {
                      return <>{nutrition.Female_51_plus}</>;
                    } else if (age >= 51 && age <= 100 && gender === "male") {
                      return <>{nutrition.Male_51_plus}</>;
                    } else {
                      return <>Enter valid values</>;
                    }
                  };
                  return (
                    <TableRow
                      key={nutrition.Nutrition}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">
                          {nutrition.Nutrition}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="subtitle1">
                          {nutrition.Calorie_measurement}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="subtitle1">
                          {nutrition.Source_of_goal}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="subtitle1">
                          {differentAge()}
                        </Typography>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Cards />
    </div>
  );
};

export default Result;
