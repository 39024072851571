import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import MobileContent from './MobileContent';
import './mobilenews.css';
import Cookies from 'js-cookie';

const MobileNews = () => {

    const [data1, setData] = useState([])
    const [data2, SetImage1] = useState([])
    const [data3, SetImage2] = useState([])
    const [portal_img1, SetPortalImg1] = useState([])
    const [portal_img2, SetPortalImg2] = useState([])
   

    const port = Cookies.get('portalid');
    let site = sessionStorage.getItem("site")
    console.log("portid in news", port)
    let parentport = 3004
    if (parentport === '') {
        console.log("error in news", parentport);
        parentport = 0;
    }
    console.log("parentport in news", parentport)
    //let pin=sessionStorage.getItem("pincode")
    // console.log(pin)
    useEffect(() => {



        axios.all([
            axios.post(`/news/headlines/entertainment/mobile`, { "port": port, "parentport": parentport }),
            axios.post(`/myblock/altimage/news`),
            axios.post(`/techieindex/altimage/news`),
            axios.post(`/myblock/altimage/news/portalid`, { "port": port }),
            axios.post(`/techieindex/altimage/news/portalid`, { "port": port })
        ])
            .then(axios.spread((data1, data2, data3, data4, data5) => {
                // output of req.
                console.log("data1 & data2 in news", data1, data2, data3, data4, data5)

                setData(data1.data)
                SetImage1(data2.data)
                SetImage2(data3.data)
                SetPortalImg1(data4.data)
                SetPortalImg2(data5.data)
            }));

    }, []);

    console.log("news data ", data1)

    return (
        <div>

            <section >
                <div className='news-container_mobile'>

                    <section className='hero_news_mobile'>
                        <div className='news-container_mobile'>
                            <div className='content'>
                                {data1.map((item, i) => {

                                    console.log("site value", site)
                                    if (portal_img1[i] === undefined) {
                                        portal_img1[i] = 0;
                                    }
                                    if (portal_img2[i] === undefined) {
                                        portal_img2[i] = 0;
                                    }
                                    if (site === "Myblock") {
                                        return <MobileContent key={i} item={item} data={data2[i]} portal_img={portal_img1[i]} />;
                                    }
                                    else if (site === "Techie-Index") {
                                        return <MobileContent key={i} item={item} data={data3[i]} portal_img={portal_img2[i]} />;
                                    }
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            </section>

        </div>
    )
}

export default MobileNews
