import React from 'react'
import Header from './Header'
import AboutVillage from './AboutVillage'
import Cards from './Indexes/Cards'
import Bottom from './Bottom'
import Links from './Links'

function AfterLogin() {
  return (
      <div>
    
      <Links/>
      <AboutVillage/>
      <Cards />
      
    </div>
  )
}

export default AfterLogin