import React from 'react';
import Advert from '../../Home/Main/advertisement/Advert';
import Spotlight from '../../Home/Main/flashnews/Spotlight';


import './style.css';

const Home_techie = () => {
  return (
    <>
      <main>
        <div className='container'>
            <section className='mainContent'> 
{/*             <Spotlight />
 */}             </section>
            <section className='sideContent'>
              <Advert />
            </section>

        </div>
      </main>
    </>
  )
}

export default Home_techie
