import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import './WaterMarkAddingHome.css';
import WaterMarkForm from './WaterMarkForm';

const WaterMarkAddingHome = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const portalid = Cookies.get("portalid");
  const userid = Cookies.get("userid");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('/watermark/get-image', {
          params: {
            userid: userid,
            portalid: portalid
          }
        });
        const updatedImages = response.data.results
          .filter(item => item && item.image)
          .map(item => {
            const updatedPath = item.image.replace('..', 'https://myblocks.in');
            return updatedPath;
          });

        setImages(updatedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImage();
  }, [userid, portalid]);

  const handleError = (event) => {
    // Remove image URL from state when an image fails to load
    setImages(images.filter(image => image !== event.target.src));
  };

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container-watermark">
      <h2>Click an image that you want to watermark</h2>
      <br/><br/><br/><br/><br/>
      {images.length > 0 ? (
        <div className="image-container-watermark">
          {images.map((image, index) => (
            <a
              key={index}
              href={image}
              target="_blank"
              rel="noopener noreferrer"
              className="image-link-watermark"
              onClick={(e) => {
                e.preventDefault(); // Prevent navigation
                openModal(image);
              }}
            >
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="image"
                onError={handleError} // Handle image loading errors
              />
            </a>
          ))}
        </div>
      ) : (
        <p>No images found.</p>
      )}

      {selectedImage && (
        <WaterMarkForm imageUrl={selectedImage} onClose={closeModal} />
      )}
    </div>
  );
};

export default WaterMarkAddingHome;