import React from 'react'
import { Container, Nav, Navbar, Button, NavDropdown, Form, span } from 'react-bootstrap';
import './Header.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';


export default function HeaderAttach() {
    let site = sessionStorage.getItem("site")
    const getCity = sessionStorage.getItem("city")
    let portalid = sessionStorage.getItem("portalid")
    const usertype = Cookies.get("usertype")
    const getEmailid = Cookies.get("getEmailid")
  const getPassword = Cookies.get("passwordData")

    if (site === "Myblock" || site === null) {
        return (
            <div>     <Navbar className='head22'>
                <Container>
                    <Nav className='head-2'>
                        <Button variant="secondary">
                            <Link to='/afterLogin' style={{ textDecoration: 'none', color: 'white' }}>
                                Community Index
                            </Link>
                        </Button>{' '}
                        {/* <Nav.Item>
                            <Nav.Link href="/air">Air Index</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/waterindex">Water Index</Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                            <Nav.Link href="/ActivityPortal">Activity Portal</Nav.Link>
                        </Nav.Item>
                        {getCity && (
                            <>
                                {/* <Nav.Item>
                                    <Nav.Link href="/Activeactivitys">Activitys in {getCity} </Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item>
                                    <Nav.Link href="/portalproblems">Report any Problems in {getCity} </Nav.Link>
                                </Nav.Item>
                            </>
                        )}
                    </Nav>
                </Container>
            </Navbar></div>
        )
    }
    else {
        return null;
    }
}
