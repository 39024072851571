import { Button } from 'bootstrap'
import React, { useRef } from 'react'
import './mainpage.css'
import { Link, useNavigate } from 'react-router-dom';

const Material = ({ portal: { id, portalname, portalid, parentportalid } }) => {

  //console.log("port",portalname);
  //console.log("portalid in material",portalid);
  const data = useRef();
  const navigate = useNavigate()
  const setPortalid = () => {
    // console.log(PORTALID)
    sessionStorage.setItem("portalid", portalid)
    sessionStorage.setItem("city", portalname)
    //console.log("portalid in material",portalid)
    if (parentportalid == '') {
      console.log("error in material", parentportalid);
      parentportalid = 0;
    }
    sessionStorage.setItem("parentportalid", parentportalid)
    // window.location.reload();

    const cityLink = window.location.origin + `/`;


    window.location.href = cityLink;

    //navigate("/home")

    //console.log("parent in material",parentportalid)

  }

  return (
    <div >

      <div>
        <button className='button' onClick={setPortalid}>{portalname}</button>
      </div>


    </div>
  )
}

export default Material 
