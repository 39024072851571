import React, { useState, useEffect } from 'react'
import './forgotpassword.css'
import axios from 'axios';
import CryptoJS from 'crypto-js'; // Import CryptoJS
import LoginHeader from '../LoginHeader';
import LoginFooter from '../LoginFooter';
import PageLogLocation from '../../PageLog/PageLogLocation';


function ForgotPasswordEmail() {

  const [email, setEmail] = useState('');
  const secretKey = '#myblocks*';
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [usertype, setUserType] = useState('Basicuser');



  useEffect(() => {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  }, [email]);

  const encrypt = () => {
    let emailWithtext='';
    if (usertype === 'Businessuser' || usertype === 'Adminuser') {
       emailWithtext = email + '@myblocks_business_admin#';
    }
    // else if (usertype === 'Adminuser') {
    //    emailWithtext = email + '#myblocks#';
    // }
    else
      if (usertype === 'Basicuser') {
         emailWithtext = email + '@myblocks_basicuser#';
      }
    
    const encryptedText = CryptoJS.AES.encrypt(emailWithtext, secretKey).toString();
    //console.log(`Encrypted: ${encryptedText}`);

    return encryptedText;
  };




  console.log("isValidEmail outside", isValidEmail)

  const sendmail = async () => {
    //validateEmail();
    console.log("isValidEmail", isValidEmail)
    let apiEndpoint = '';
    let utype = '';
    if (usertype === 'Businessuser') {
      apiEndpoint = "/login/verify/email/employuser";
      utype = 'Business Account'
    }
    else if (usertype === 'Adminuser') {
      apiEndpoint = "/login/verify/email/employuser";
      utype = 'Admin Account'
    }
    else
      if (usertype === 'Basicuser') {
        apiEndpoint = "/login/verify/email";
        utype = 'Basic Account'
      }


    if (isValidEmail) {
      try {
        const response = await axios.post(apiEndpoint, { email });

        // Handle the response as needed
        console.log(response.data);

        const encryptedemail = encrypt();
        const urlEncodedText = encodeURIComponent(encryptedemail);

        const url = `https://www.myblocks.in/passwordresetpage?id=${urlEncodedText}`;

        //console.log("url", url);

        // Now, only if the email is valid, make the second axios call
        const emailResponse = await axios.post('/send-email', {
          to: email,
          subject: `MyBlocks ${utype} Password Reset`,
          text: 'Click on the link below to reset your password',
          html: `<p>Click on the link below to reset your password</p>    
            <a href=${url}>Reset Password</a>`,
        });

        console.log(emailResponse.data);
        alert(emailResponse.data.message)
        setEmail('');
      } catch (error) {
        // Handle errors, including 403 status code
        if (error.response && error.response.status === 404) {
          alert("User with this email id does not exist, please create an account");
        } else {
          console.error("Error:", error.message);
          // Handle other errors as needed
        }
      }
    }
    else {
      // Display alert for invalid email
      alert("Please enter a valid email address");
    }
  };

  //console.log("usertype", usertype)


  return (
    <div>
      <LoginHeader />
      <PageLogLocation />
      <br /><br /><br /><br /><br /><br /><br />
      <div className='forgot-password-container'>
        <b>Please enter your Registered Email ID</b>
        <br />
        <input
          type='text'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setIsValidEmail(true); // Reset validation when the user types
          }}
        />
        <table className='user-type-table'>
          <tbody>
            <tr>
              <td>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    value='Basicuser'
                    checked={usertype === 'Basicuser'}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  Basic User
                </label>
              </td>
              <td>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    value='Businessuser'
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  Business User
                </label>
              </td>
              <td>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    value='Adminuser'
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  Admin User
                </label>
              </td>

            </tr>
          </tbody>
        </table>

        <br />
        <button onClick={sendmail} >Submit Request</button>
      </div>
      <br /><br />
      <LoginFooter />
    </div>
  )
}

export default ForgotPasswordEmail