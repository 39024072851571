import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './testqm.css'
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';

function TestQuestions() {
  const { id } = useParams();
  const role_id = Cookies.get("role_id")
  const firmid = Cookies.get("firmid")
  const [questions, setQuestions] = useState([]);
  console.log("role_id", role_id)
  console.log("id", id)

  useEffect(() => {
    axios.post("/api/getquestions", { "id": id, "firmid": firmid, "role_id": role_id })
      .then((response) => {
        setQuestions(response.data);
        console.log("response.data", response.data);
        console.log("id", id);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div>
      <PageLog />
      <center>
        <h1>QM Questions</h1>

      </center>


      <div className='testqm-form-container'>

        <ol>
          {questions.map((topic) => (
            <li key={topic.Q_ID}>
              <a href={`/answerbox/${topic.QM_ID}/${topic.Q_ID}`}>{topic.QUESTION}</a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default TestQuestions