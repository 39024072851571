
import Homepages from '../Homepages';
import { Link, useNavigate } from 'react-router-dom';
import React, {  useEffect } from 'react'

function MiddleFile(props) {
    const { id, portalname, portalid, parentportalid } = props.data;
    let site = sessionStorage.getItem("site")
    const navigate = useNavigate()

    useEffect(() => {
        sessionStorage.setItem("portalid", portalid);
        sessionStorage.setItem("city", portalname);
        sessionStorage.setItem("parentportalid", parentportalid);
        /* let parentPortalIdInt = parseInt(parentportalid);
        if (isNaN(parentPortalIdInt)) {
            console.log("error in middle", parentportalid);
            parentPortalIdInt = 0;
        } */  
        
    }, []);
  
    var currentUrl = window.location.href;
    var newUrl = currentUrl.substring(0, currentUrl.indexOf("/") + 1);
    if (site === "Techie-Index") {
        newUrl += "techieindexlatest";
      }

      if (site === "Myblock") {
        newUrl += "home";
      }
      
      window.location.href = newUrl;


    

    return (
        <div>
            
            {/* <Homepages /> */}
        </div>

    );
}

export default MiddleFile;