import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './bestpost.css'
import { useNavigate } from 'react-router-dom';
// import Upload from './Upload';

const BestPosts = () => {
  const [posts, setPosts] = useState([]);
  const [caption, setCaption] = useState([]);
  // const [nextpage, setNextPage] = useState('false');


  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const category = query.get('Category');
  const portalid = query.get('portalid');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {     

        const response = await axios.get('/get/best/post', {
          params: {
              Category: category,
              portalid:portalid
          }
      });

        console.log("response.data",response.data)
        setPosts(response.data);
        setCaption(response.data[0].CAPTION)
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);


  console.log("caption", caption)

  const goBack = () => {
    navigate(-1);
  };

  // const handleSubmit = () => {


  //   setNextPage('true')

  // };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Caption copied to clipboard!');
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };


  return (
    <div>
      {/* {nextpage === 'false' ?
        ( */}

      <div className="fb-container">
        <h1 className="heading">Top Facebook Post in {category} Category</h1>
        <div className="posts-container">
          {posts.map((post, index) => (
            <div className="post" key={index}>
              <h2 className="post-title"> Post Engagement count --- {post.TOTAL_INTERACTIONS}</h2>
              <br />
              {/* <p className="post-date">Post Date: {new Date(post.POST_DATE).toLocaleString()} </p> */}
              <p className="post-caption">Best Caption: <br /><br /> {post.CAPTION || 'No caption provided'}</p>
              {/* <p className="post-likes">Likes: {post.LIKES}</p>
          <p className="post-comments">Comments: {post.COMMENTS_COUNT}</p>
          <p className="post-shares">Shares: {post.SHARES}</p> */}

              <br /><br />
              <button onClick={() => copyToClipboard(post.CAPTION)} className="copy-button">Copy</button>
              <br /><br />

            </div>
          ))}


        </div>

        {/* <div className="centered-button">
            <button className="btn-navigate btn-go-back" onClick={handleSubmit}>
              Use
            </button>
          </div> */}

        <div className="centered-button">
          <button className="btn-navigate btn-go-back" onClick={goBack}>
            Go Back
          </button>
        </div>


      </div>

      {/* ) : (<Upload caption={caption}/>)

      } */}
    </div>

  );
};

export default BestPosts;