import React,{useState,useEffect} from "react";
import ReactEcharts from "echarts-for-react";

const Percentage = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetch("http://localhost:3000/message")
      .then((response) => response.json())
      .then((data) => setMessage(data));
  }, []);

  const option = {
    title: {
      text: "Killed and Injured B/W 2014 to 2017",
      subtext: "Data",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      bottom: 10,
      left: "center",
      data: ["Killed", "Injured"],
    },
    series: [
      {
        type: "pie",
        radius: "65%",
        center: ["50%", "50%"],
        selectedMode: "single",
        data: [
          {
            
            label: {
              formatter: [
                "{title|{b}}{abg|}",
                "  {weatherHead|Weather}{valueHead|Days}{rateHead|Percent}",
                "{hr|}",
                "  {Sunny|}{value|202}{rate|55.3%}",
                "  {Cloudy|}{value|142}{rate|38.9%}",
                "  {Showers|}{value|21}{rate|5.8%}",
              ].join("\n"),
              backgroundColor: "#eee",
              borderColor: "#777",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                title: {
                  color: "#eee",
                  align: "center",
                },
                abg: {
                  backgroundColor: "#333",
                  width: "100%",
                  align: "right",
                  height: 25,
                  borderRadius: [4, 4, 0, 0],
                },
              
                
               
                weatherHead: {
                  color: "#333",
                  height: 24,
                  align: "left",
                },
                hr: {
                  borderColor: "#777",
                  width: "100%",
                  borderWidth: 0.5,
                  height: 0,
                },
                value: {
                  width: 20,
                  padding: [0, 20, 0, 30],
                  align: "left",
                },
                valueHead: {
                  color: "#333",
                  width: 20,
                  padding: [0, 20, 0, 30],
                  align: "center",
                },
                rate: {
                  width: 40,
                  align: "right",
                  padding: [0, 10, 0, 0],
                },
                rateHead: {
                  color: "#333",
                  width: 40,
                  align: "center",
                  padding: [0, 10, 0, 0],
                },
              },
            },
          },
         
          { value: message[7], name: "Killed" },
          { value: message[8], name: "Injured" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <div>
      <ReactEcharts option={option} />;
    </div>
  );
};

export default Percentage;
