import React, { useState, useEffect } from 'react';
import './ManagerReview.css';
import axios from 'axios';
import PageLog from '../../PageLog/PageLog';


const ManagerReview = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('EMP_NAME'); // Default search field
  const [searchResults, setSearchResults] = useState([]);

  // const handleSearch = async () => {
  //   try {
  //     const response = await fetch(`/api/employee-report?searchTerm=${searchTerm}`);
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch');
  //     }
  //     const data = await response.json();
  //     // Set the fetched data to state to display results
  //     setSearchResults(data);
  //   } catch (error) {
  //     console.error('Error fetching employee reports:', error);
  //   }
  // };
  const handleSearch = async () => {
    try {
      const response = await axios.get(`/api/employee-report?searchTerm=${searchTerm}`);
  
      // Axios handles non-2xx status codes by throwing an error automatically for us
      const data = response.data;
  
      // Set the fetched data to state to display results
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching employee reports:', error);
    }
  };
  
  return (
    <div>
      <PageLog/>
      <div>
        <input
          type="text"
          placeholder="Enter search term"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select value={searchField} onChange={(e) => setSearchField(e.target.value)}>
          <option value="EMP_NAME">Employee Name</option>
          <option value="REP_TYPE">Report Type</option>
          <option value="CREATED_DATE">Created Date</option>
        </select>
        <button onClick={handleSearch}>Search</button>
      </div>
      <div class="table-container">
        <h2> Reports</h2>
        <table>
          <thead>
            {/* Add table headers based on your data */}
            <tr>
            <th>Report ID</th>
            <th>Created Date</th>
            <th>Employee ID</th>
            <th>Project ID</th>
            <th>Business Unit ID</th>
            <th>Report Type</th>
            <th>Employee Name</th>
            <th>Task</th>
            <th>Status</th>
            <th>Time</th>
            <th>Action Numbers</th>
            <th>Result Numbers</th>
            <th>Remarks</th>
            <th>Task Type</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Time Zone</th>
            <th>Updated Date</th>
            <th>Updated By ID</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((employee, index) => (
              <tr key={index}>
                {/* Display the fetched data */}
                <td>{employee.REPORT_ID}</td>
              <td>{employee.CREATED_DATE}</td>
              <td>{employee.EMP_ID}</td>
              <td>{employee.PROJ_ID}</td>
              <td>{employee.BU_ID}</td>
              <td>{employee.REP_TYPE}</td>
              <td>{employee.EMP_NAME}</td>
              <td>{employee.TASK}</td>
              <td>{employee.STATUS}</td>
              <td>{employee.TIME}</td>
              <td>{employee.ACTION_NOS}</td>
              <td>{employee.RSLT_NOS}</td>
              <td>{employee.REMARKS}</td>
              <td>{employee.TASK_TYPE}</td>
              <td>{employee.START_TIME}</td>
              <td>{employee.END_TIME}</td>
              <td>{employee.TIME_ZONE}</td>
              <td>{employee.UPDATED_DATE}</td>
              <td>{employee.UPDATED_BY_ID}</td>
                {/* ... */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManagerReview;
