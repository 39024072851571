import { React, useState, useEffect } from 'react'
import axios from 'axios';
import Homepages from '../Home/Homepages';
import MaterialTechie from './materialTechie';
import './TechieIndexLatest.css';



const TechieIndexSubPage = () => {
  let getCity = sessionStorage.getItem("city")

  let getPortal = sessionStorage.getItem("portalid")

  let getPincode = sessionStorage.getItem("pincode")
  const [data, setData] = useState([])
  const [abc, setAbc] = useState([])
  let id = sessionStorage.getItem("portalid", "****")

  const handlepincode = () => {
    // console.log(PORTALID)
    sessionStorage.setItem("pincode", data.current.value)
    window.location.reload();

  }

  useEffect(() => {


    axios.post('/cityS/PortalID/techieindexlatest')
      .then(function (response) {
        // console.log(response.data);
        setData(response.data)
      })




  }, []);


  return (

    <div>
      {
        getPortal && getCity || getPincode ?
          <Homepages /> :
          <div className='page container paddingTB'>
            {/* 
          <div className='input'>
            <h5>Enter your Pincode</h5>
           <input placeholder="Enter Pincode" ref={data} />
      <button onClick={handlepincode}>search</button>
      </div> */}

            <div className='city'>
              <h5>Select your Type</h5>
              {data.map((portal) => {

                if (portal.type === 'MYBLOCKS.IN') {
                  sessionStorage.setItem("site", "Myblock");
                } else if (portal.type === 'TECHIE-INDEX.COM') {
                  sessionStorage.setItem("site", "Techie-Index");
                }
                return (<div className='mainpage'>

                  <MaterialTechie key={portal.id} portal={portal} />

                </div>
                )
              })}
            </div>
          </div>
      }
    </div>
  )
}

export default TechieIndexSubPage
