// StartupList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Details from './Details'; // Import the CompanyDetails component
import './Ranking1.css';
import PageLog from '../../PageLog/PageLog';

const itemsPerPage = 100;

const StartupList = () => {
  const [startups, setStartups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null); // Track the selected company

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/startups');
        console.log('Fetched data from backend:', response.data);
        setStartups(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = startups.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(startups.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };
    // Scroll to the top of the table
    const tableElement = document.querySelector('.startup-table');
  
    if (tableElement) {
      const tableTop = tableElement.offsetTop;
  
      window.scrollTo({
        top: tableTop,
        behavior: 'auto',
      });
    } else {
      console.error('Table element not found');
    }

  return (
    <div>
      <PageLog/>
      <h1>Techie-Index Unveils the Top Successful Startups of 2023!</h1>
      <p className='P1'>In a groundbreaking move, Techie-Index is thrilled to announce the release of our highly anticipated ranking list 
        featuring the top successful startups of 2023. Meticulously curated and thoroughly researched, this list showcases the most
         innovative and impactful startups that have left an indelible mark on the tech landscape. As your go-to source for all things tech,
          we take pride in presenting a comprehensive ranking that reflects the dynamic and ever-evolving nature of the industry. 
          From groundbreaking technologies to visionary leaders, our ranking celebrates the achievements of these trailblazing startups
           that are shaping the future. Explore the full list now on Techie-Index and stay ahead in the world of technology!</p>
      <table className="startup-table">
        <thead>
          <tr>
            <th>Ranking</th>
            <th>Company Name</th>
            <th>URL</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>Founded Year</th>
            <th>Total Funding</th>
            <th className='linked'>LinkedIn</th>
            <th>Growth Percentage</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((startup) => (
            <tr key={startup.ST_ID}>
              <td>{startup.Ranking}</td>
              <td>
                {/* Use Link to navigate to the company details page */}
                <Link to={`/Details/${startup.ST_ID}`} onClick={() => handleCompanyClick(startup.ST_ID)}>
                 {startup.company_name}
                </Link>

              </td>
              <td><a href={`http://${startup.url}`} target="_blank" rel="noopener noreferrer">{startup.url}</a></td>
              <td>{startup.city}</td>
              <td>{startup.state}</td>
              <td>{startup.country}</td>
              <td>{startup.founded}</td>
              <td>{startup.total_funding}</td>
              <td><a href={startup.linkedin_url} target="_blank" rel="noopener noreferrer">{startup.linkedin_url}</a></td>
              <td>{startup.growth_percentage}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {pageNumbers.map((number) => (
          <span
            key={number}
            className={number === currentPage ? 'active' : ''}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </span>
        ))}
      </div>
      {/* Render the CompanyDetails component when a company is selected */}
      {selectedCompany && <Details company={selectedCompany} />}
    </div>
  );
};

export default StartupList;
