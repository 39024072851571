//Icons importing
import CarCrashSharpIcon from "@mui/icons-material/CarCrashSharp";
import SportsKabaddiSharpIcon from "@mui/icons-material/SportsKabaddiSharp";
import OpacitySharpIcon from "@mui/icons-material/OpacitySharp";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import MasksIcon from "@mui/icons-material/Masks";
import FavoriteIcon from '@mui/icons-material/Favorite';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';

import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
const Data = [
  {
    id: 0,
    icons: (
      <IconButton sx={{ p: 0 }}>
        <Avatar style={{ backgroundColor: "rgba(250,128,114)" }}>
          <CarCrashSharpIcon />
        </Avatar>
      </IconButton>
    ),
    name: "Accident",
    value: "10000",
    unit: "89",
    duration: "Daily",
    date: "2022-10-13 10:44:16",
  },
  {
    id: 1,
    icons: (
      <IconButton sx={{ p: 0 }}>
        <Avatar style={{ backgroundColor: "rgba(0, 150, 255)" }}>
          <SportsKabaddiSharpIcon />
        </Avatar>
      </IconButton>
    ),
    name: "Crime",
    value: "10000",
    unit: "89",

    duration: "Daily",
    date: "2022-10-13 10:44:16",
  },
  {
    id: 2,
    icons: (
      <IconButton sx={{ p: 0 }}>
        <Avatar style={{ backgroundColor: "rgba(253,132,31)" }}>
          <OpacitySharpIcon style={{ color: "#DD51C" }} />
        </Avatar>
      </IconButton>
    ),
    name: "Water",
    value: "10000",
    unit: "89",
    duration: "Daily",
    date: "2022-10-13 10:44:16",
  },
  {
    id: 3,
    icons: (
      <IconButton sx={{ p: 0 }}>
        <Avatar style={{ backgroundColor: "rgb(83, 52, 131)" }}>
          <CleaningServicesIcon />
        </Avatar>
      </IconButton>
    ),
    name: "Hygiene",
    value: "10000",
    unit: "89",
    duration: "Daily",
    date: "2022-10-13 10:44:16",
  },
  {
    id: 4,
    icons: (
      <IconButton sx={{ p: 0 }}>
        <Avatar style={{ backgroundColor: "rgb(78, 108, 80)" }}>
          <MasksIcon />
        </Avatar>
      </IconButton>
    ),
    name: "Pollution",
    value: "10000",
    unit: "89",
    duration: "Daily",
    date: "2022-10-13 10:44:16",
  },
  {
    id: 5,
    icons: (
      <IconButton sx={{ p: 0 }}>
        <Avatar style={{ backgroundColor: "rgb(172, 68, 37)" }}>
          <FavoriteIcon/>
        </Avatar>
      </IconButton>
    ),
    name: "Health",
    value: "10000",
    unit: "89",
    duration: "Daily",
    date: "2022-10-13 10:44:16",
  },
  {
    id: 6,
    icons: (
      <IconButton sx={{ p: 0 }}>
        <Avatar style={{ backgroundColor: "rgb(135, 88, 255)" }}>
          <DirectionsBikeIcon/>
        </Avatar>
      </IconButton>
    ),
    name: "Diabetes",
    value: "10000",
    unit: "89",
    duration: "Daily",
    date: "2022-10-13 10:44:16",
  },
];
export default Data;
