
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import MiddleFile from '../CityFromId/MiddleFile'
import PageLog from '../../PageLog/PageLog';


function CityFromName() {
    const { city } = useParams();
    const [data, setData] = useState([])
    //console.log(`current url`, window.location.href);

    const url = window.location.href;

    const segments = url.split('/');
    const MSegment = segments[3];
    console.log(`current url`, MSegment);

    useEffect(() => {

        console.log("city", city)

        // store ID in state variable
        axios.post('/city/fromname', { "city": city })
            .then(response => {
                setData(response.data);

                console.log("portal value", response.data)

                // store response data in state variable
            })
            .catch(error => {
                console.error(error);
            });

    }, []);


    const handleclickportal = (portalid, portalname, parentportalid) => {
        sessionStorage.setItem("portalid", portalid)
        sessionStorage.setItem("city", portalname)
        sessionStorage.setItem("parentportalid", parentportalid)
        var currentUrl = window.location.href;
        var baseUrl = currentUrl.split("/")[2]; // Get the domain (e.g., "example.com")
        var newUrl = window.location.protocol + "//" + baseUrl + "/home";
        window.location.href = newUrl;


    }

    // Use the 'location' parameter to load city data or perform other actions.
    // Example: Fetch city data based on the 'location' parameter.

    return (
        <div>
            <PageLog />
            {

                data.length === 1 && data.map((item, i) => {
                    if (item.type == 'MYBLOCKS.IN' && MSegment === 'india') {
                        sessionStorage.removeItem("site");
                        sessionStorage.setItem("site", "Myblock");
                        return (<MiddleFile key={i} data={item} />)
                    }

                    if (item.type == 'TECHIE-INDEX.COM' && MSegment === 'portal') {
                        sessionStorage.removeItem("site");
                        sessionStorage.setItem("site", "Techie-Index");
                        return (<MiddleFile key={i} data={item} />)
                    }

                })


            }

            {
                data.length > 1 && (
                    <>
                        {sessionStorage.removeItem("site")}
                        {sessionStorage.setItem("site", "Myblock")}
                        <div>
                            <table style={{ margin: '10px 350px', border: '1px solid black', borderCollapse: 'collapse' }} >
                                <thead>
                                    <tr>
                                        <th>Portal Name</th>
                                        <th>Portal ID</th>
                                        <th>Parent Portal ID</th>
                                        <th>Parent Portal Name</th>
                                        {/* Add more column headers as needed */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(portal => (
                                        <tr key={portal.id}>
                                            <td>
                                                <a href="#" onClick={() => handleclickportal(portal.portalid, portal.portalname, portal.parentportalid)}>{portal.portalname}</a>

                                            </td>
                                            <td>{portal.portalid}</td>
                                            <td>{portal.parentportalid}</td>
                                            <td>{portal.parentport}</td>
                                            {/* Add more table cells for other data in the row */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </>
                )
            }
        </div>
    )
}

export default CityFromName