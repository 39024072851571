import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import PageLog from '../../PageLog/PageLog';

function IcampusFromName() {

    const { country } = useParams();


    var currentUrl = window.location.href;
    var baseUrl = currentUrl.split("/")[2]; // Get the domain (e.g., "example.com")
    var newUrl = window.location.protocol + "//" + baseUrl + "/techieindexlatest";


    useEffect(() => {

        if (baseUrl === 'techie-index.com' || baseUrl === 'www.techie-index.com') {

            if (country === 'us') {

                sessionStorage.setItem("portalid", 10231)
                sessionStorage.setItem("city", "icampus-USA")
                sessionStorage.setItem("parentportalid", 10079)
                window.location.href = newUrl;

            }

            if (country === 'uk') {
                sessionStorage.setItem("portalid", 10232)
                sessionStorage.setItem("city", "icampus-UK")
                sessionStorage.setItem("parentportalid", 10079)
                window.location.href = newUrl;

            }

            if (country === 'ca') {
                sessionStorage.setItem("portalid", 10233)
                sessionStorage.setItem("city", "icampus-CANADA ")
                sessionStorage.setItem("parentportalid", 10079)
                window.location.href = newUrl;

            }
            if (country === 'in') {
                sessionStorage.setItem("portalid", 10230)
                sessionStorage.setItem("city", "icampus-INDIA")
                sessionStorage.setItem("parentportalid", 10079)
                window.location.href = newUrl;

            }
            if (country === 'de') {
                sessionStorage.setItem("portalid", 10234)
                sessionStorage.setItem("city", "icampus-GERMANY")
                sessionStorage.setItem("parentportalid", 10079)
                window.location.href = newUrl;

            }
        }

    }, []);



    return (
        <><PageLog/></>
    )
}

export default IcampusFromName