import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

//import React Router Link
import { Link } from "react-router-dom";

export default function Links() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                    m: 1,
                },
            }}
        >
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button><Link to="/" style={{ textDecoration: "none", color: "black" }}>
                    Home
                </Link></Button>
                <Button> <Link to="/aboutUs" style={{ textDecoration: "none", color: "black" }}>
                    About Us
                </Link></Button>
                <Button><Link to="/logins" style={{ textDecoration: "none", color: "black" }}>
                    Sign In
                </Link></Button>
                <Button>  <Link to="/signUp" style={{ textDecoration: "none", color: "black" }}>
                    Sign Up
                </Link></Button>
                <Button><Link
                    to="/allIndexResults"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    All Indexes
                </Link></Button>
                <Button> <Link
                    to="/final_results"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    Final Results
                </Link></Button>

            </ButtonGroup>

        </Box>
    );
}