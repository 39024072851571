import { React, useState, useEffect } from 'react';
//import './businessuser.css'
import Cookies from 'js-cookie';
import axios from 'axios';
import PageLog from '../PageLog/PageLog';

function TechieIndexPage() {


  const [pagemenu, setMenu] = useState('');
  const role_id = Cookies.get("role_id");
  const usertype = Cookies.get("usertype")


  useEffect(() => {
    axios.post('/techieindex/app/menu', { "role_id": role_id, "usertype": usertype })
      .then(function (response) {
        //console.log(response.data);
        setMenu(response.data)
      })
  }, [])


  return (
    <div className="business-container">
      <PageLog />


      <header >

        <div className="business-link-box">
          {pagemenu ? (
            pagemenu.map((link, index) => (
              <div key={index}>
                <a href={link.MENU_URL} className={`business-link`}>
                  {link.MENU_NAME}
                </a>
                <br />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="business-content-container-temp">

        </div>
      </header>
    </div >
  )
}

export default TechieIndexPage