import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Singlepages from '../../singlepages/Singlepages';
import More from './More_techie';




const Content_press = ({item:{id,DOC_ID,image,DOC_DESC,DOC_SUBCATEGRY,DOC_TITL,DOC_PUBDATE}}) => {

   const navigate= useNavigate()

   function goToSinglepage(){
    navigate("/Singlepages",{state : {id : DOC_ID}})
   }

  return (
    <>
    
      <div className='box'>
        <div className='img'>
           <img src={image} alt='' />
        </div>
        <div className='text'>
          <span className='category'>{DOC_SUBCATEGRY}</span>
       <h1 onClick={goToSinglepage}>{DOC_TITL.slice(0,55)}...</h1>
        <div className='author flex'>
        <span>Last updated 2min ago</span>
        </div>
        </div>
      </div>
    
    </>
  )
}

export default Content_press

