// StartupList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Details from './Details'; // Import the CompanyDetails component
import './Ranking1.css';
import PageLog from '../../PageLog/PageLog';

const itemsPerPage = 100;

const StartupList = () => {
  const [startups, setStartups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null); // Track the selected company

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/UsaVC');
        console.log('Fetched data from backend:', response.data);
        setStartups(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = startups.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(startups.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };
    // Scroll to the top of the table
    const tableElement = document.querySelector('.startup-table');
  
    if (tableElement) {
      const tableTop = tableElement.offsetTop;
  
      window.scrollTo({
        top: tableTop,
        behavior: 'auto',
      });
    } else {
      console.error('Table element not found');
    }

  return (
    <div>
      <PageLog/>
      <h1>"Techie-Index reveals the top VC firms in the USA for 2023!"</h1>
      <p>In a groundbreaking move, Techie-Index is excited to unveil our highly anticipated ranking of the top VC firms in the USA for 2023.
         Meticulously curated and extensively researched, this list highlights the most influential and successful venture capital firms that
          have significantly contributed to the dynamic landscape of the tech industry. As your ultimate resource for all things tech, we take
           pride in presenting a comprehensive ranking that reflects the ever-evolving nature of the venture capital sector. From strategic
            investments to visionary leadership, our list recognizes the outstanding contributions of these top VC firms that play a crucial
             role in shaping the future of technology. Explore the full list now on Techie-Index and stay informed at the forefront of the
              technology investment landscape!</p>
      <table className="startup-table">
        <thead>
          <tr>
            <th>Ranking</th>
            <th>Organization</th>
            <th>Location</th>
            <th>Number of Investments</th>
            <th>Number of Exits</th>
            <th>Key Investments</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((startup) => (
            <tr key={startup.ST_ID}>
              <td>{startup.Ranking}</td>
              <td> {startup.company_name}</td>
              <td>{startup.country}</td>
              <td>{startup.valuation}</td>
              <td>{startup.No_visa_prod}</td>
              <td>{startup.keywords}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {pageNumbers.map((number) => (
          <span
            key={number}
            className={number === currentPage ? 'active' : ''}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </span>
        ))}
      </div>

      {/* Render the CompanyDetails component when a company is selected */}
      {selectedCompany && <Details company={selectedCompany} />}
    </div>
  );
};

export default StartupList;
