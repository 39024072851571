import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

function PageLogLocation() {

    const [location, setLocation] = useState(null);
    const userid = Cookies.get('userid') || null;
    const firmid = Cookies.get("firmid") || null;
    const portalid = Cookies.get('portalid') || null;
    const visitedBy =Cookies.get('getEmailid') || null;


        console.log("a", userid, firmid, portalid)

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
                },
                (error) => {
                    console.log(`Error getting location: ${error.message}`);
                    setLocation({ latitude: null, longitude: null });
                }
            );
        } else {
            console.log('Geolocation is not supported by your browser.');
            setLocation({ latitude: null, longitude: null });
        }

    }, [])


    useEffect(() => {
        if (location) {
            const currentURL = window.location.href;
            console.log('Current URL:', currentURL);

              const userLocale = navigator.language || navigator.userLanguage;
              //const userLocale = null;
              console.log('User Locale:', userLocale);
  
              const browser = navigator.userAgent;
              //const browser = null;
              console.log("browser", browser)

            //debugger
            axios.post('/page-log-ip', { currentURL, userLocale, browser, "latitude": location.latitude, "longitude": location.longitude,userid,firmid,portalid,visitedBy })
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('Error logging IP:', error);
                });
        }

    }, [location]);
    // }, []);




    // if (location) {
    //     console.log("latitude, longitude ", location.latitude, location.longitude)

    // }
    return (
        <div>



            <></>


        </div>
    )
}

export default PageLogLocation