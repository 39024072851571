import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css'

const Card2 = (props) => {
  // const Card2 = ({data:{DOC_ID,DOC_URL,image,DOC_DESC,DOC_SUBCATEGRY,DOC_TITL,DOC_PUBDATE}}) => {

  const navigate = useNavigate()
  let site = sessionStorage.getItem("site")

  function goToSpotlightDetails() {

    // if (site === "Myblock")
    //   navigate("/SpotlightDetails", { state: { id: props.item.DOC_ID } })
    // else if (site === "Techie-Index")
    //   navigate("/SpotlightDetails", { state: { id: props.item.intrvw_id } })
    navigate(`/spotlightdetailspage?id=${props.item.intrvw_id}&type=spotlight`)  

  }


return (
    <>



      <div className='box'>
       
         
        
{/* 
        {site === "Myblock" ? (<div>
          <div className='ima'>
           <img src={props.item.image || props.portal_img.image_url || props.data.image_url} alt='' />
           </div>
          <h3 className='tittle' onClick={goToSpotlightDetails}>{props.item.DOC_TITL}</h3>
          </div>
        ) : (
          site === "Techie-Index" && ( */}
            <div>
               <div className='ima'>
           {/* <img src={ props.item.photo || props.portal_img.image_url || props.data.image_url } alt='' /> */}
           <img src={ props.item.photo  } alt='' />
           </div>
            <h3 className='tittle' onClick={goToSpotlightDetails}>{props.item.companyName}</h3>
            </div>
          {/* )
        )} */}

      </div>



    </>
  )
}

export default Card2
