import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './familygrouphome.css';

function FamilyGroupHome() {
    const [showPopup, setShowPopup] = useState(false);
    const [fdetails, setFamilyDetails] = useState([]);
    const [formData, setFormData] = useState({
        familyName: '',
        familyPlace: '',
        familyDescription: '',
    });
    const [notificationCount, setNotificationCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    let userid = Cookies.get("userid");
    let username = Cookies.get("username");

    const togglePopup = useCallback(() => {
        setShowPopup(prev => !prev);
    }, []);

    const closePopup = useCallback(() => {
        setShowPopup(false);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        // Implement search logic here
        console.log('Search query:', searchQuery);
        // Optionally, send the search query to your backend or filter the displayed results
    };

    useEffect(() => {
        fetchPosts();
        fetchNotificationCount();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await axios.get(`/api/populate/familygrouplist?userid=${userid}`);
            setFamilyDetails(response.data);
            console.log("response", response.data);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const fetchNotificationCount = async () => {
        try {
            // Assuming you have an API endpoint to get the notification count
            const response = await axios.get(`/api/notifications/count?userid=${userid}`);
            setNotificationCount(response.data.count);
        } catch (error) {
            console.error('Error fetching notification count:', error);
        }
    };

    const insertGroup = async () => {
        try {
            const insertGroupResponse = await axios.post('/insert/into/mydocfamily', {
                ...formData,
                userid,
                username
            });
            console.log("result", insertGroupResponse);
            closePopup();
            fetchPosts();
            setFormData({
                familyName: '',
                familyPlace: '',
                familyDescription: '',
            });
        } catch (error) {
            console.error("Error in Axios post request:", error);
        }
    };

    return (
        <div className='content-container-familygp'>
            <div className="w3-content w3-margin-top" style={{ maxWidth: '1400px' }}>
                <div className="w3-row-padding">
                    <div className="w3-onethird">
                        <div className="w3-container w3-card w3-white w3-margin-bottom">
                            <h2 className="w3-text-grey w3-padding-16">
                                <img src="../images/AGIApp/group_family.png" alt="Family/Group" width="80" height="80" />
                                &emsp;Family/Group
                                <a className="notification" href="/notifications">
                                    <span className="inbox">Inbox</span>
                                    <span className="badge">{notificationCount}</span>
                                </a>
                            </h2>
                            <form className="navbar-form-familygp navbar-left" onSubmit={handleSearchSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search family"
                                        id="search"
                                        name="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <button type="submit" className="btn btn-default">Submit</button>
                            </form>
                            <hr className='custom-hr' />
                            {fdetails.map((family, index) => (
                                <div key={index} className="w3-container">
                                    <h2>
                                        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                            <a style={{ textDecoration: 'none' }} href={`/familygroupdetails?id=${family.FAMILY_ID}`}>
                                                <img src="../images/AGIApp/group.jpg" className="img-circle" alt="Family" width="50" height="50" />
                                                &emsp;{family.FAMILY_NAME}
                                            </a>
                                        </span>
                                    </h2>
                                    <hr className='custom-hr' />
                                </div>
                            ))}
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <button className="open-button" onClick={togglePopup}>Add</button>
            {showPopup && (
                <div className="popup-container">
                    <div className="popup">
                        <button className="close-button" onClick={closePopup}>X</button>
                        <div>
                            <div className="form-group">
                                <label htmlFor="family"><b>Family/Group Name</b></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="family"
                                    name="familyName"
                                    placeholder="Family Name"
                                    value={formData.familyName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="place"><b>Family/Group Place</b></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="place"
                                    name="familyPlace"
                                    placeholder="Place"
                                    value={formData.familyPlace}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description"><b>Family/Group Description</b></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    name="familyDescription"
                                    placeholder="Description of family"
                                    value={formData.familyDescription}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='submit-button-div'>
                                <button type="button" className="btn btn-default" onClick={insertGroup}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FamilyGroupHome;