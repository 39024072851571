


import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import './watershw.css';
import { PieChart, Pie} from 'recharts';
import Barchart from './BarChart';






function Watershow() {


  


  const [data , setData] = useState({
      
      waterbody:"",
      score: "" 
      
  })

 const [cdata , setCdata] = useState({
      Water_source:"",
      dtm:"",
      Temperature: "" ,
      DO:"",
      Nitrate:"",
      Ammonia:"",
      Chloride:"",
      COD:"",
      TSS:"",
      Turbidity:"",
      Fluride:"",
      Pottassium:"",
      Water_level:"",
      TOC:"",
      BTX:"",
      Water_quality_index :"",
      pH :"",
      Conductivity :"",
      BOD:""
      
  })
  
  

  
  function submit(e){
  e.preventDefault();
  
  axios.post("/desktop/test/form_base",data )
      .then(res=>{
          // console.log(res.data)
      })
  
  }

  function place(f){
    f.preventDefault();
    // console.log(cdata)
    
    axios.post("/desktop/test/form_adv",cdata )
        .then(res=>{
            // console.log(res.data)
        })
    
    }

  const reload = ()=>{
    window.location.reload();
  }
  
  function handle(e){
  const newdata={...data}
  newdata[e.target.id]= e.target.value
  setData(newdata)
  

}

function click(f){
  const truedata={...cdata}
  truedata[f.target.id]= f.target.value
  setCdata(truedata)
  // console.log(truedata)

}




var showdate=new Date();
// var displaytodaysdate=showdate.getDate()+'/'+(showdate.getMonth()+1)+'/'+showdate.getFullYear();
var dt=showdate.toDateString();  


const pie = [
  {name: 'Geeksforgeeks', students: 400},
  {name: 'Technical scripter', students: 700},
  {name: 'Geek-i-knack', students: 200},
  {name: 'Geek-o-mania', students: 1000}
];


  return (
   
<div className='container_water'>

<div className="logo_water"> &ensp; Water Quality Testing</div>
<div className='header_water'><div className='greet'><h5> &emsp; Hi Arun,  &ensp;  Welcome!   &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &ensp;  Today : &emsp;  {dt}</h5></div> </div>
<div className='testbase'>
<h4 className='base'>BASIC TEST</h4>
<h7> Enter your water score below</h7>
<form onSubmit={(e)=> submit(e)}>
<div> <select className='input_water' onChange={(e)=>handle(e)} id="waterbody"  value={data.waterbody} placeholder='Test result' type= "text">
<option value="choose">Type of water source</option>
        <option value="well">Well</option>
        <option value="borewell">Borewell</option>
        <option value="River" >River</option>
  </select></div> 
  <div> <input className='input_water' onChange={(e)=>handle(e)} id="score"  value={data.score} placeholder='Test result' type= "number"></input>mg/l</div> 
          

          <div> <button className='button_water'>Submit</button></div> 

          </form>
  
  </div>



<div className='testadv'>

<h4 className='base'>ADVANCED TEST</h4>
<h7> Enter your water score below</h7>
<form c onSubmit={(f)=> place(f)}>
           
          <div> <select className='input_water' onChange={(f)=>click(f)} id="Water_source"  value={cdata.Watersource} placeholder='watersource' type= "text">
          <option value="choose">Type of water source</option>
                  <option value="well" >Well</option>
                  <option value="borewell" >Borewell</option>
                  <option value="river">River</option>
            </select></div> 
            <div> <input className='input_water' onChange={(f)=>click(f)} id="dtm"  value={cdata.dtm} placeholder=' date' type= "date"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Temperature"  value={cdata.Temperature} placeholder=' temperature  °C' type= "number"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="DO"  value={cdata.DO} placeholder='DO   mg/l' type= "number"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Nitrate"  value={cdata.Nitrate} placeholder='Nitrate  mg/l' type= "number"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Ammonia"  value={cdata.Ammonia} placeholder='Ammonia mg/l' type= "number"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Chloride"  value={cdata.Chloride} placeholder='Chloride mg/l' type= "number"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="COD"  value={cdata.COD} placeholder='COD mg/l' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="TSS"  value={cdata.TSS} placeholder='TSS mg/l' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Turbidity"  value={cdata.Turbidity} placeholder='Turbidity mg/l' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Fluride"  value={cdata.Fluride} placeholder='Fluride mg/l' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Pottassium"  value={cdata.Pottassium} placeholder='Pottassium mg/l' type= "number"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Water_level"  value={cdata.Water_level} placeholder='Water level mg/l' type= "number"></input> </div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="TOC"  value={cdata.TOC} placeholder='TOC mg/l' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="BTX"  value={cdata.BTX} placeholder='BTX mg/l' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Water_quality_index"  value={cdata.Water_quality_index} placeholder='Water quality index ' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="pH"  value={cdata.pH} placeholder='pH mg/l' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="Conductivity"  value={cdata.conductivity} placeholder='conductivity' type= "number"></input></div> 
          <div> <input className='input_water' onChange={(f)=>click(f)} id="BOD"  value={cdata.BOD} placeholder='BOD mg/l' type= "number mg/l"></input> </div> 
          <div> <button className='button_water' onClick={reload}>Submit</button></div> 

          </form>
</div>



<div className='content_water'>
  
<div>What does </div><div> your water Score </div><div> look like?</div>


{/* <div className='circle'>   
score {data.score}
</div> */}

<div className='chart'>
<Barchart/>
 
</div>

    

</div>

</div>


       
  );
}

export default Watershow;
