import './AboutUsTechie.scss'
import Careers from './components/careers.js';
import Clients from './components/clients.js';
import Contact from './components/contact.js';
import Milestones from './components/milestones.js';
import Overview from './components/overview.js';
import Team from './components/team.js';
import { tabLabels } from './constants.js'
import { useState } from 'react';

const AboutUsTechie = () => {
    const [selectedTab, setSelectedTab] = useState('Overview');
    
    function handleTabChange (tab) {
        setSelectedTab(tabLabels[tab])
        
    }

    return (
        <div class="aboutus-popup">
            <div class="tabs">
                <input type="radio" id="tab1" name="tab" defaultChecked={true} onClick={() => handleTabChange("overview")} />
                <label for="tab1">{tabLabels.overview}</label>
                <input type="radio" id="tab2" name="tab" onClick={() => handleTabChange("milestones")} />
                <label for="tab2">{tabLabels.milestones}</label>
                <input type="radio" id="tab3" name="tab" onClick={() => handleTabChange("clients")} />
                <label for="tab3">{tabLabels.clients}</label>
                <input type="radio" id="tab4" name="tab" onClick={() => handleTabChange("careers")} />
                <label for="tab4">{tabLabels.careers}</label>
                <input type="radio" id="tab5" name="tab" onClick={() => handleTabChange("team")} />
                <label for="tab5">{tabLabels.team}</label>
                <input type="radio" id="tab6" name="tab" onClick={() => handleTabChange("contact")} />
                <label for="tab6">{tabLabels.contact}</label>
            </div>
            <div className="about-content"><div className='header-grey'>{selectedTab}</div>
                <div className='content-html'>
                    <div>{selectedTab === tabLabels.overview && <Overview />}</div>
                    <div>{selectedTab === tabLabels.milestones && <Milestones />}</div>
                    <div>{selectedTab === tabLabels.clients && <Clients />}</div>
                    <div>{selectedTab === tabLabels.careers && <Careers />}</div>
                    <div>{selectedTab === tabLabels.team && <Team />}</div>
                    <div>{selectedTab === tabLabels.contact && <Contact />}</div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsTechie;