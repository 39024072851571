import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ActivityPortal.css';
//import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';

function ActivityPortal() {

  const [activity, setActivity] = useState([]);

  useEffect(() => {
    // axios.get('http://61.2.142.91:8411/activityportal')
    axios.get('/activityportal')
      .then(response => {
        setActivity(response.data);
        //console.log('res', activity);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);
  /* 
        const navigate= useNavigate()
  
        function goToActivityDetails(CMPN_DTL_ID){
          console.log('comp id',CMPN_DTL_ID)
         navigate("/actportal1",{state : {id : CMPN_DTL_ID}})
        }
    */



  return (
    <div className="activity-portal"> {/* Add the CSS class */}
      <PageLog />

      <h1>ActivityPortal</h1>
      <table>
        <thead>
          <tr>
            <th>Details</th>
            <th>Participation Type</th>
            <th>Participation Detail</th>
            {/*  <th>Status</th> */}
            <th>Main Role</th>
            <th>Lead</th>
            {/*  <th>Display Priority</th> */}
          </tr>
        </thead>
        <tbody>
          {activity.map(item => (
            <tr key={item.PORTAL_ID}>
              <td>
                <Link to={`/actportal?value=${item.CMPN_DTL_ID}`}>{item.DETAILS}</Link>
                {/*  <a href="#" onClick={() => goToActivityDetails(item.CMPN_DTL_ID)}>{item.DETAILS}</a> */}

              </td>
              <td>{item.PARTICIPATION_TYPE}</td>
              <td>{item.PARTICIPATION_DETAIL}</td>
              {/* <td>{item.STATUS}</td> */}
              <td>{item.MAIN_ROLE}</td>
              <td>{item.LEAD}</td>
              {/*   <td>{item.DISPLAY_PRIORITY}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ActivityPortal