import React, { useEffect, useState } from 'react';
import './interview.css'



function InterviewMainPage() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content from the same server
    fetch('/Interview/interview03032011.html')
      .then(response => response.text())
      .then(data => setHtmlContent(data));
  }, []);

  return (

    <div className='interview-container'>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />

    </div>
    // <div>
    //   <iframe
    //     title="HTML Page"
    //     width="100%"
    //     height="900"
    //     src='/mainhomepage/index(3).html'
    //   />
    // </div>


  );
}

export default InterviewMainPage;