import React from 'react';
import './style.css';

const imagesData = [
  { id: 1, title: 'New York University (NYU)', count: 4717 },
  { id: 2, title: 'Northeastern University', count: 2921 },
  { id: 3, title: 'University of California, Los Angeles (UCLA)', count: 2836 },
  { id: 4, title: 'University of Southern California (USC)', count: 2740 },
  { id: 5, title: 'Arizona State University (ASU)', count: 2387 },
  { id: 6, title: 'University of Texas at Austin (UTA)', count: 2295 },
  { id: 7, title: 'University of Illinois at Urbana-Champaign (UIUC)', count: 2275 },
  { id: 8, title: 'Purdue University', count: 2183 },
  { id: 9, title: 'Rutgers University', count: 2089 },
  { id: 10, title: 'University of Wisconsin-Madison (UW-Madison)', count: 1980 },
  { id: 11, title: 'Georgia Institute of Technology', count: 1947 },
  { id: 12, title: 'Columbia University', count: 1938 },
  { id: 13, title: 'Carnegie Mellon University', count: 1835 },
  { id: 14, title: 'Texas A&M University', count: 1762 },
  { id: 15, title: 'Michigan State University', count: 1749 },
  { id: 16, title: 'Ohio State University', count: 1725 },
  { id: 17, title: 'University of Minnesota, Twin Cities', count: 1664 },
  { id: 18, title: 'University of Missouri-Columbia', count: 1632 },
  { id: 19, title: 'University of Massachusetts Amherst', count: 1579 },
  { id: 20, title: 'Syracuse University', count: 1552 },
  { id: 21, title: 'University of Maryland, College Park', count: 1528 },
  { id: 22, title: 'University of California, San Diego (UCSD)', count: 1509 },
  { id: 23, title: 'University of Michigan, Ann Arbor', count: 1494 },
  { id: 24, title: 'University of Washington', count: 1485 },
  { id: 25, title: 'Kansas State University', count: 1467 },
  { id: 26, title: 'Tulane University', count: 1438 },
  { id: 27, title: 'Drexel University', count: 1433 },
  { id: 28, title: 'University of Pittsburgh', count: 1378 },
  { id: 29, title: 'University of Rochester', count: 1374 },
  { id: 30, title: 'Iowa State University', count: 1367 },
  { id: 31, title: 'University of Connecticut', count: 1354 },
  { id: 32, title: 'University of Pennsylvania', count: 1341 },
  { id: 33, title: 'University of North Texas', count: 1331 },
  { id: 34, title: 'University of California, Irvine (UCI)', count: 1321 },
  { id: 35, title: 'University of Arizona', count: 1318 },
  { id: 36, title: 'University of Colorado at Boulder', count: 1312 },
  { id: 37, title: 'University of Nebraska-Lincoln', count: 1293 },
  { id: 38, title: 'University at Buffalo, SUNY', count: 1286 },
  { id: 39, title: 'North Carolina State University', count: 1275 },
  { id: 40, title: 'University of Cincinnati', count: 1261 },
  { id: 41, title: 'Florida International University', count: 1259 },
  { id: 42, title: 'Lehigh University', count: 1254 },
  { id: 43, title: 'University of Houston', count: 1242 },
  { id: 44, title: 'New Jersey Institute of Technology (NJIT)', count: 1235 },
  { id: 45, title: 'University of Alabama at Birmingham', count: 1234 },
  { id: 46, title: 'University of Nevada, Las Vegas (UNLV)', count: 1224 },
  { id: 47, title: 'University of Central Florida (UCF)', count: 1213 },
  { id: 48, title: 'University of Delaware', count: 1204 },
  { id: 49, title: 'Oklahoma State University', count: 1198 },
  { id: 50, title: 'University of New Mexico', count: 1194 },
  
  { id: 51, title: 'University of Oregon', count: 1187 },
  { id: 52, title: 'University of Utah', count: 1180 },
  // { id: 53, title: 'Saint Louis University', count: 0 },
  // { id: 54, title: 'University of California, Berkeley (UC Berkeley)', count: 0 },
  // { id: 55, title: 'Harvard University', count: 0 },
  // { id: 56, title: 'Stanford University', count: 0 },
  // { id: 57, title: 'Massachusetts Institute of Technology (MIT)', count: 0 },
  // { id: 58, title: 'University of Michigan, Ann Arbor', count: 0 },
  // { id: 59, title: 'University of California, Los Angeles (UCLA)', count: 0 },
  // { id: 60, title: 'Texas A&M University', count: 0 },
  // { id: 61, title: 'Columbia University', count: 0 },
  // { id: 62, title: 'Georgia Institute of Technology', count: 0 },
  // { id: 63, title: 'University of Florida', count: 0 },
  // { id: 64, title: 'University of Pennsylvania', count: 0 },
  // { id: 65, title: 'University of Washington', count: 0 },
  // { id: 66, title: 'University of California, San Diego (UCSD)', count: 0 },
  // { id: 67, title: 'University of Wisconsin-Madison', count: 0 },
  // { id: 68, title: 'University of Chicago', count: 0 },
  // { id: 69, title: 'University of Maryland, College Park', count: 0 },
  // { id: 70, title: 'Rutgers, The State University of New Jersey', count: 0 },
  // { id: 71, title: 'Northwestern University', count: 0 },
  // { id: 72, title: 'Princeton University', count: 0 },
  // { id: 73, title: 'University of Minnesota, Twin Cities', count: 0 },
  // { id: 74, title: 'Cornell University', count: 0 },
  // { id: 75, title: 'Ohio State University', count: 0 },
  // { id: 76, title: 'University of North Carolina at Chapel Hill', count: 0 },
  // { id: 77, title: 'University of California, Davis (UC Davis)', count: 0 },
  // { id: 78, title: 'University of Virginia', count: 0 },
  // { id: 79, title: 'Boston University', count: 0 },
  // { id: 80, title: 'University of California, Irvine (UC Irvine)', count: 0 },
  // { id: 81, title: 'Pennsylvania State University', count: 0 },
  // { id: 82, title: 'University of California, Santa Barbara (UCSB)', count: 0 },
  // { id: 83, title: 'Michigan State University', count: 0 },
  // { id: 84, title: 'University of California, Riverside (UC Riverside)', count: 0 },
  // { id: 85, title: 'Yale University', count: 0 },
  // { id: 86, title: 'Duke University', count: 0 },
  // { id: 87, title: 'University of Southern Florida', count: 0 },
  // { id: 88, title: 'University of Houston', count: 0 },
  // { id: 89, title: 'Virginia Tech', count: 0 },
  // { id: 90, title: 'Brown University', count: 0 },
  // { id: 91, title: 'University of Georgia', count: 0 },
  // { id: 92, title: 'University of Pittsburgh', count: 0 },
  // { id: 93, title: 'Purdue University Northwest', count: 0 },
  // { id: 94, title: 'Johns Hopkins University', count: 0 },
  // { id: 95, title: 'University of Massachusetts Amherst', count: 0 },
  // { id: 96, title: 'University of Arizona', count: 0 },
  // { id: 97, title: 'Florida International University', count: 0 },
  // { id: 98, title: 'Indiana University Bloomington', count: 0 },
  // { id: 99, title: 'Drexel University', count: 0 },
  // { id: 100, title: 'Temple University', count: 0 },
  // { id: 101, title: 'University of Illinois at Chicago (UIC)', count: 0 },
  // { id: 102, title: 'Syracuse University', count: 0 },
  // { id: 103, title: 'California Institute of Technology (Caltech)', count: 0 },
  // { id: 104, title: 'University of Texas at Austin', count: 0 },
  // { id: 105, title: 'Virginia Commonwealth University', count: 0 },
  // { id: 106, title: 'Rice University', count: 0 },
  // { id: 107, title: 'University of Notre Dame', count: 0 },
  // { id: 108, title: 'University of Cincinnati', count: 0 },
  // { id: 109, title: 'University of Missouri', count: 0 },
  // { id: 110, title: 'University of Connecticut', count: 0 },
  // { id: 111, title: 'Iowa State University', count: 0 },
  // { id: 112, title: 'University of Colorado Boulder', count: 0 },
  // { id: 113, title: 'Washington State University', count: 0 },
  // { id: 114, title: 'University of South Florida', count: 0 },
  // { id: 115, title: 'University of Delaware', count: 0 },
  // { id: 116, title: 'University of Alabama', count: 0 },
  // { id: 117, title: 'University of Central Florida', count: 0 },
  // { id: 118, title: 'University of Kentucky', count: 0 },
  // { id: 119, title: 'University of Tennessee, Knoxville', count: 0 },
  // { id: 120, title: 'University of Massachusetts Boston', count: 0 },
  // { id: 121, title: 'Colorado State University', count: 0 },
  // { id: 122, title: 'Temple University', count: 0 },
  // { id: 123, title: 'University of Nebraska-Lincoln', count: 0 },
  // { id: 124, title: 'University of Oklahoma', count: 0 },
  // { id: 125, title: 'University of Oregon', count: 0 },
  // { id: 126, title: 'University of Rhode Island', count: 0 },
  // // { id: 127, title: 'University of South Carolina', count: 0 },
  // { id: 128, title: 'University of Utah', count: 0 },
  // { id: 129, title: 'Utah State University', count: 0 },
  // { id: 130, title: 'University of Vermont', count: 0 },
  // { id: 131, title: 'University of Wyoming', count: 0 },
  // { id: 132, title: 'Wayne State University', count: 0 },
  // { id: 133, title: 'Florida State University', count: 0 },
  // { id: 134, title: 'University of Arkansas', count: 0 },
  // { id: 135, title: 'University of Hawaii at Manoa', count: 0 },
  // { id: 136, title: 'University of Nevada, Las Vegas (UNLV)', count: 0 },
  // { id: 137, title: 'University of New Mexico', count: 0 },
  // { id: 138, title: 'University of North Dakota', count: 0 },
  // { id: 139, title: 'University of North Texas', count: 0 },
  // { id: 140, title: 'University of South Dakota', count: 0 },
  // { id: 141, title: 'University of West Florida', count: 0 },
  // { id: 142, title: 'New Jersey Institute of Technology (NJIT)', count: 0 },
  // { id: 143, title: 'University of Massachusetts Lowell', count: 0 },
  // { id: 144, title: 'University of Alaska Fairbanks', count: 0 },
  // { id: 145, title: 'University of Idaho', count: 0 },

  
];

const ImageList = () => {
  return (
    <div className="image-list">
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Title</th>
            <th>Indian Students (Fall in 2021)</th>
          </tr>
        </thead>
        <tbody>
          {imagesData.map((image, index) => (
            <tr key={image.id}>
              <td>{index + 1}</td>
              <td>{image.title}</td>
              <td>{image.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ImageListWithInfo = () => {
  return (
    <div>
      <h1 className="main-heading">Faces of Changing Landscape - Ranking of American universities for Indian Students</h1>
      <p className="description">
        This personalized guide ranks American universities for Indian students based on visa friendliness, ensuring a smooth transition. It offers a simplified list that balances academic excellence with visa accessibility, making it easier for students to choose the right university without visa hurdles.
        This ranking system aids Indian students in selecting reputable institutions that also align with their visa requirements, streamlining their application process and ensuring a more straightforward educational journey in the USA.
      </p>
      <ImageList />
    </div>
  );
};

export default ImageListWithInfo;