import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Details1.css';
import PageLog from '../../PageLog/PageLog';

const Details = () => {
  const [details, setDetails] = useState({});
  const [startupData, setStartupData] = useState({});
  const { companyId } = useParams();
  console.log('companyId:', companyId);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch details for the given company
        const responseDetails = await axios.get(`/details/${companyId}`);
        setDetails(responseDetails.data);

        // Fetch startup data
        const responseStartups = await axios.get('/startups');
        const startupDataMap = responseStartups.data.reduce((map, startup) => {
          map[startup.ST_ID] = startup;
          return map;
        }, {});
        setStartupData(startupDataMap);
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    fetchData();

  }, [companyId]);
  // Add this function to your component or utility functions
  const addHttp = (url) => {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      return 'http://' + url;
    }
    return url;
  };


  return (
    <div className="container1">
      <PageLog/>
      <div className="form-container1">
        <div className="company-info">
          {details.logo && <img src={details.logo} alt="Company Logo" className="logo-image" />}
          <h1>{startupData[companyId] ? startupData[companyId].company_name : 'N/A'}</h1>
        </div>
        <p className="details-label">About</p>
        <p className="details-text">{details.About}</p>
        <p className="details-label">Location</p>
        <p className="details-text">
          {startupData[companyId] ? `${startupData[companyId].city}, ${startupData[companyId].state}, ${startupData[companyId].country}` : 'N/A'}
        </p>
        <p className="details-label">URL</p>
        <p className="details-text">
          {startupData[companyId] ? (<a href={addHttp(startupData[companyId].url)} target="_blank" rel="noopener noreferrer">{startupData[companyId].url}</a>) : ('N/A')}
        </p>
        <p className="details-label">Founded Year</p>
        <p className="details-text">{startupData[companyId] ? startupData[companyId].founded : 'N/A'}</p>
        <p className="details-label">Total Funding</p>
        <p className="details-text">{startupData[companyId] ? startupData[companyId].total_funding : 'N/A'}</p>
        <p className="details-label">Employees</p>
        <p className="details-text">{startupData[companyId] ? startupData[companyId].employees : 'N/A'}</p>

        <p className="details-label">LinkedIn</p>
        <p className="details-text">
          {startupData[companyId] ? (<a href={startupData[companyId].linkedin_url} target="_blank" rel="noopener noreferrer">LinkedIn Profile</a>) : ('N/A')}</p>

      </div>
    </div>
  );
};

export default Details;
