import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './trends.css';

const Trends = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedPortalId, setSelectedPortalId] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(''); // State to manage loading text
  const [loadingInterval, setLoadingInterval] = useState(null); 
  const [categories, setCategories] = useState([]);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/trends/portaldetails');
        console.log('Data fetched:', response.data);
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();

    return () => {
      // Cleanup function to cancel any pending axios requests
    };
  }, []);

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find(option => option.portalname === selectedValue);
    if (selectedOption) {
      setSelectedOption(selectedValue);
      setSelectedPortalId(selectedOption.portalid);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/trends/categories', {
          params: {
            portalid: selectedPortalId
          }
        }); 
        console.log('Categories fetched:', response.data);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedPortalId) {
      fetchCategories();
    }
  }, [selectedPortalId]);


  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;    
    navigate(`/fbengagementslist?Category=${selectedValue}&portalid=${selectedPortalId}`);
  };

//for loader
  useEffect(() => {
    // Start loading animation when loading state changes
    if (loading) {
      setLoadingInterval(
        setInterval(() => {
          setLoadingText((prevText) => {
            switch (prevText) {
              case '':
                return '.';
              case '.':
                return '..';
              case '..':
                return '...';
              default:
                return '';
            }
          });
        }, 500) // Adjust interval duration as needed
      );
    } else {
      // Stop loading animation when loading state is false
      clearInterval(loadingInterval);
      setLoadingText('');
    }

    // Cleanup function to clear interval when component unmounts or loading state changes
    return () => {
      clearInterval(loadingInterval);
    };
  }, [loading]);

  return (
    <div className="trends-container">
      <h1>Current Trends</h1>
      
      <p>Explore your category below:</p>
      {loading ? (
             <div className="loading-text">
                  <br/>
             Loading<span className="loading-dots">{loadingText}</span>

             <br/><br/><br/>

             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />}
            </div>
           </div>

      ) : (
        <div className="category-buttons">
          <select
            id="category"
            className="input1"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="">Select a Location</option>
            {options.map((option, index) => (
              <option key={index} value={option.portalname}>
                {option.portalname}
              </option>
            ))}
          </select>
        </div>
      )}
      <br />
      {categories.length > 0 && (
        <div className="category-buttons">
          <select
            id="category"
            className="input1"
            value={selectedOption} // Use selectedOption instead of selectedCategory
            onChange={handleCategoryChange} // Update selected option
          >
            <option value="">Select a category</option>
            {categories.map((category, index) => (
              <option key={index} value={category.CATEGORY}>
                {category.CATEGORY}
              </option>
            ))}
          </select>
        </div>
      )}
      <br />
      <div className="centered-button">
        <button className="btn-navigate btn-go-back" onClick={goBack}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default Trends;