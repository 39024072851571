import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import './Point.css';
import PageLog from '../../PageLog/PageLog';

function Point() {
  const [totalPoints, setTotalPoints] = useState(null);
  const [detailedData, setDetailedData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  // Function to format a date string into a human-readable format
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Adjust the format as needed
  };

  useEffect(() => {
    const retrievedUserId = Cookies.get('userid');
    console.log('Retrieved User ID:', retrievedUserId);

    if (retrievedUserId) {
      // Log the value being passed to the backend with a label
      console.log('Backend passing value:', retrievedUserId);

      // Send a request to the backend to get the total points
      axios
        .get(`/api/endpoint?userId=${retrievedUserId}`)
        .then((response) => {
          const points = response.data.point;
          setTotalPoints(points);
        })
        .catch((error) => {
          console.error('Error fetching total points:', error);
        });
    } else {
      console.error('User ID not found in the cookie.');
    }
  }, []);

  // Function to fetch detailed data from the backend
  const fetchDetailedData = () => {
    const retrievedUserId = Cookies.get('userid'); // Define retrievedUserId here
    // Make an API call to fetch detailed data
    axios
      .get(`/api/detailed-pointdata?userId=${retrievedUserId}`) // Replace with the correct API endpoint
      .then((response) => {
        const data = response.data;
        // Format the date for each item
        data.forEach((item) => {
          item.formattedDate = formatDateString(item.insrt_dtm);
        });
        setDetailedData(data);
        setShowTable(true);
      })
      .catch((error) => {
        console.error('Error fetching detailed data:', error);
      });
  };

  return (
    <div className='point-container'>
      {totalPoints !== null ? (
        <div>
          <PageLog/>
          <p>Your total points: {totalPoints}</p>
          <button onClick={fetchDetailedData} className='more-details-button'>More Details</button>
          {showTable && (
            <table className='point-table'>
              <thead>
                <tr>
                  <th>Activity</th>
                  <th>Date</th>
                  <th>Point</th>
                </tr>
              </thead>
              <tbody>
                {detailedData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.activity}</td>
                    <td>{item.formattedDate}</td> {/* Display the formatted date */}
                    <td>{item.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Point;
