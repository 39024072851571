import React from 'react';
import './WaterMarkForm.css';

const WaterMarkForm = ({ imageUrl, onClose }) => {
  return (
    <div className="template-modal-overlay">
      <div className="template-modal">
        <h2>Watermark Image</h2>
        <img
          src={imageUrl}
          alt="Selected"
          className="resized-image"
        />
        <form>
          {/* Add your form fields here */}
          <label>
            Watermark Text:
            <input type="text" name="watermarkText" />
          </label>
          <br />
          <button type="submit">Apply Watermark</button>
        </form>
        <button onClick={onClose} className="close-button-watermark">Close</button>
      </div>
    </div>
  );
};

export default WaterMarkForm;