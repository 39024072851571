import React from 'react'
import PageLog from '../../PageLog/PageLog';

function ITServicesFromName() {

    var currentUrl = window.location.href;
    var baseUrl = currentUrl.split("/")[2]; // Get the domain (e.g., "example.com")
    var newUrl = window.location.protocol + "//" + baseUrl + "/techieindexlatest";

    if (baseUrl === 'techie-index.com' || baseUrl === 'www.techie-index.com') {
    sessionStorage.setItem("portalid", 35)
    sessionStorage.setItem("city", "IT Services" )
    sessionStorage.setItem("parentportalid", 512)
    window.location.href = newUrl;
    }
  return (
    <div>
      <PageLog/>
      Loading...</div>
  )
}

export default ITServicesFromName