import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './resumeupload.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const ResumeUpload = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const userid = Cookies.get("userid");
  const portalid = Cookies.get("portalid");
  const homepath = 'resume';
  const finalpath = `${homepath}/${portalid}/${userid}`;
  const navigate = useNavigate();

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } else {
      createFolder();
    }
  }, [userid, navigate]);

  const createFolder = async () => {
    try {
      await axios.post('/create/folder/session', { homepath, portalid, userid });
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "application/pdf" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        setFile(selectedFile);
        setMessage('');
      } else {
        setFile(null);
        setMessage('Please select a valid PDF or DOCX file.');
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('No file selected');
      return;
    }
    const formData = new FormData();
    formData.append('finalpath', finalpath);
    formData.append('pdf', file);
  
    try {
      const res = await axios.post('/upload-resume', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      const pdfPath = res.data.file.path;
      console.log('PDF Path:', pdfPath);
      console.log('userid:', userid);
  
      setMessage('File uploaded successfully');
  
      await axios.post('/run-python-script', { pdfPath, userid });
      window.location.reload();
    } catch (err) {
      setMessage('Failed to upload file');
    }
  };
  
  
  return (
    <div className="upload-container">
      <h1>Upload Resume</h1>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <input type="file" accept=".pdf,.docx" onChange={onFileChange} />
        </div>
        <div className="form-group">
          <button type="submit">Upload</button>
        </div>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default ResumeUpload;
