import React from 'react'
import './HeadingVend.css'

const HeadingD = () => {
  const getSite=sessionStorage.getItem("site")
  return (
    <div className='vendorheading'>
{/*      <h6> {getSite?"Business":"Vendors"} </h6> 
 */}   
 <h6>User Content</h6>
  </div>
  )
}

export default HeadingD
