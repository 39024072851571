import React from 'react';
import { useNavigate } from 'react-router-dom';
import './careerbook.css';
import './GapAnalysis'
import './ResumeUpload'
import './Roadmap'

const CareerBook = () => {
  const navigate = useNavigate();

  return (
    <div className="career-book-container">
      <h1>Career Book</h1>
      <div className="button-container">
        <button onClick={() => navigate('/resumeupload')}>Resume Upload</button>
        <button onClick={() => navigate('/gapanalysis')}>Gap Analysis</button>
        <button onClick={() => navigate('/roadmap')}>Roadmap</button>
      </div>
    </div>
  );
};

export default CareerBook;
