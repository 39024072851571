import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './style.css';
import InterviewContent from './InterviewContent';
import HeadingVend from './HeadingVend';
import 'bootstrap/dist/css/bootstrap.min.css';
import PageLog from '../../PageLog/PageLog';

const AllLeaders = () => {

    const [data1, setData] = useState([])

    const [startIndex, setStartIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);
    let port = sessionStorage.getItem("portalid")
    let parentport = sessionStorage.getItem("parentportalid")
    console.log("myport", port)



    useEffect(() => {
        axios.post('/news/interview/more', { "id": port, "parentport": parentport })
            .then(function (response) {
                console.log("full leaders data", response.data)
                setData(response.data)
                setMaxIndex(response.data.length);
            })
    }, []);

    const handleNextClick = () => {
        //console.log('Next button clicked')
        if (startIndex + 18 < maxIndex) {
            setStartIndex(prevIndex => prevIndex + 18);
        }
    };

    const handlePrevClick = () => {
        //console.log('Previous button clicked')
        if (startIndex - 18 >= 0) {
            setStartIndex(prevIndex => prevIndex - 18);
        }
    };

    return (
        <div>

            <section className='vendor'>
                <div className='container'>
                    <HeadingVend />
                    <PageLog/>
                    <div className='content'>

                        {data1.slice(startIndex, startIndex + 18).map((item, i) => {

                            return <InterviewContent key={i} vend={item} />;

                        })}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='left-buttons'>
                            <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        </div>
                        <div className='archives-link'>
                            <p align="center"  >
                                <a href={`http://61.2.142.91:8400/CityFromId/${port}`}  >Archives</a>
                            </p>
                        </div>
                        <div className='right-buttons'>
                            <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 18 >= maxIndex}>Next</button>
                        </div>

                    </div>
{/* 
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        <p align="center"  >
                            <a href={`http://61.2.142.91:8400/CityFromId/${port}`}  >Archives</a>
                        </p>

                        <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 18 >= maxIndex}>Next</button>
                    </div> */}
                </div>
            </section>


        </div>
    )
}

export default AllLeaders



