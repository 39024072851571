import React from 'react'
import './Heading_press.css'

const Heading_press = () => {
  const getSite=sessionStorage.getItem("site")
  return (
    <div className='heading_press'>
     <h6> Press Release</h6> 
    </div>
  )
}

export default Heading_press
