import React from 'react'
import FacebookLogin from './ImageUpload/FacebookLogin'
import Cookies from 'js-cookie';
import PageLog from '../PageLog/PageLog';

function SmpHome() {

    //const facebook_status = Cookies.get("facebook_status")

    return (
        <div className="business-home-container">
            <PageLog />

            {/* {['0', '1'].includes(facebook_status) && <FacebookLogin />} */}

            {/* <img
      className="background-image"
      src="./images/background.jpg"
      alt="Background"
    /> */}
            <div class="health-content">
                <nav className="business-nav">
                    <a href="/imageupload" className="business-nav-a">Upload Image & post</a>
                    {/* <a href="/uploadmultipleimages" className="business-nav-a">Upload One or More Images & Schedule</a> */}
                    {/* Add one or more social accounts using below link
                    <a href="/addsocial" className="business-nav-a">Add Social</a> */}
                </nav>
            </div>
        </div>
    )
}

export default SmpHome