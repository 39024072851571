import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './Vendorpage.css'
import PageLog from '../PageLog/PageLog';


const Vendorpage = () => {
  const location = useLocation();
  console.log(location.state)
  let vendid = location.state.id
  let pin1 = localStorage.getItem("pincode")
  console.log(pin1)
  const [vendordata, setVendorData] = useState([])



  useEffect(() => {
    axios.post('/citylocal/vendor/vendordesc', { "id": vendid, "pin1": pin1 })
      .then(function (response) {
        // console.log(response.data);
        setVendorData(response.data)
      })
  }, []);

  return (
    <div className='pages'>



      {vendordata.map((vend) => {
        return (<div className='vendorpage'>
          <img src={vend.image} alt='' />
          <h3>{vend.VEND_TITL}</h3>
          <h6>{vend.VEND_DESC}</h6>
          <h6>{vend.VEND_DET}</h6>
          <a href={vend.VEND_URL}>
            <button>More</button>
          </a>
        </div>
        )
      })}
      <PageLog />
    </div>
  )
}

export default Vendorpage
