import React, { useState } from 'react';
import axios from 'axios';

function FacebookOtp() {
    const [code, setCode] = useState('---');

    async function fetchCode() {
        try {
            const response = await axios.get('/facebook/otp');
            if (response.status === 200) {
                //debugger
                //console.log('response.data', response.data.current_otp);
                setCode(response.data.current_otp);
            } else {
                console.error('Failed to fetch OTP:', response);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    return (
        <div>
            <h1>Current 2FA Code:</h1>
            <div id="code">{code}</div>
            <button onClick={fetchCode}>Fetch Code</button>
        </div>
    );
}

export default FacebookOtp;