import React, {useState,useEffect} from 'react';
import axios from 'axios';
import HeadingD from './HeadingVend';
import {useNavigate,NavLink } from 'react-router-dom';
import './style.css'
import InterviewContent from './InterviewContent';

const Events = () => {
  const [vendor,SetVendor]=useState([])
  let port1=sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")
  let site=sessionStorage.getItem("site")
  
  console.log("site in interview",site)
  //let pin1 = sessionStorage.getItem("pincode")
  //const navigate= useNavigate()

/*    function goToVendorpage(){
    navigate("/vendorpage",{state : {id : "2962"}})
   } */

    useEffect(() => { 
        axios.post('/news/interview',{"id":port1, "parentport": parentport})
  .then(function (response) {
     //console.log("event news",response.data);
     //console.log("event page portal id",port1)
    SetVendor(response.data)
  })
 } ,[]);

  return (
    <>
      <section className='vendor'>
        <div className='container'>
            <HeadingD />
    
            <div className='content'>
                {vendor.map((vend)=>{
                    return (
                      <InterviewContent key={vend.id} vend={vend}/>
                    )
                })}
             </div>
        </div>
      </section>
      <p align="center"  >
        <NavLink to="/AllLeaders" >More</NavLink>
      </p>
    </>
  )
}

export default Events
