import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import FacebookSchedule from '../FacebookSchedule';



function FacebookPostSchedule(props) {

    const { bardStory, id, extractedIDs } = props;

    console.log("scheduling testing",bardStory, id, extractedIDs)
    const facebook_token = Cookies.get("facebook_token")
    const portalid = Cookies.get("portalid");
    const firmid = Cookies.get("firmid");
    const userid = Cookies.get("userid")


    // axios.post('/update/story', { "id": id, "bardStory": bardStory })
    //     .then(function (response) {
    //         console.log("update status", response.data);

    //     });
    const [data, setData] = useState([]);
    const [urls, setUrls] = useState([]);






    
    useEffect(() => {

        // const formData = new FormData();
        // formData.append('message', bardStory)
        // // Append the image file to the FormData object
        // const imageResponse = await fetch(image);
        // const imageBlob = await imageResponse.blob();
        // formData.append('source', imageBlob, 'image.jpg');
        const newUrls = [];

        for (const extractedID of extractedIDs) {
            const { group_page_id, type, PAGE_ACCESS_TOKEN, TOKEN_DATE } = extractedID;

            console.log("group_page_id", group_page_id)
            console.log("type", type)

            if (type === 'page') {             

                const url = `https://graph.facebook.com/${group_page_id}/photos?access_token=${PAGE_ACCESS_TOKEN}`
                newUrls.push(url);
              
            }


            if (type === 'group') {


                const url = `https://graph.facebook.com/${group_page_id}/photos?access_token=${facebook_token}`
                newUrls.push(url);
             
            }
        }

        setUrls(newUrls);

    }, [extractedIDs]);


    console.log("urls in fb post page",urls)





    return (
        <div>
         

            <FacebookSchedule  bardStory={bardStory} id={id} urls={urls}  type={'facebook'}/>


           

        </div>
    )
}



export default FacebookPostSchedule