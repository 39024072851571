import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

function DisplayAnalytics() {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let id = query.get('id');
    const [data, setData] = useState(null);


    useEffect(() => {
        getfbdate();

    }, []);


    const getfbdate = async () => {

        try {

            const response = await axios.get(`/get/fb/analytics?batch=${id}`);
            setData(response.data);
            console.log('response', response);

        } catch (error) {
            console.log(error.response.data.error);
            console.log(error);

        }
    }

    return (
        <div>


            {data && (
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>URL</th>
                            <th>type</th>
                            <th>follower count</th>
                            <th>Page Likes</th>
                            <th>Info about errors or issues</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr key={item.ID}>
                                <td>{item.ID}</td>
                                <td>{item.PAGE_GROUP_URL}</td>
                                <td>{item.PAGE_GROUP_TYPE}</td>
                                <td>{item.FOLLOWER_MEMBER_COUNT}</td>
                                <td>{item.PAGE_LIKES}</td>
                                <td>{item.COMMENTS}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>)

}


export default DisplayAnalytics