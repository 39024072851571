import React, { useState } from "react";
import database from "./UserLoginData";
//import "./Logins.css";
import AfterLogin from "../AfterLogin";

const Login = () => {
  //React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const errors = {
    uname: "Invalid UserName",
    pass: "Invalid Password",
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    //Find user login information
    const userData = database.find((user) => user.userName === uname.value);

    //Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        //Invalid Password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        setIsSubmitted(true);
      }
    } else {
      //UserName is not Found
      setErrorMessages({ name: "uname", messages: errors.uname });
    }
  };
  //Generate jsx code for error messages

  const renderErrorMessage = (name) => {
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );
  };

  //Code For the Login Form
  const renderForm = (
    <div className="app">
      <div className="login-form">
        <div className="form">
          <div className="title">Sign in</div>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label>UserName</label>
              <input type="text" name="uname" required />
              {renderErrorMessage("uname")}
            </div>
            <div className="input-container">
              <label>Password</label>
              <input type="password" name="pass" required />
              {renderErrorMessage("pass")}
            </div>
            <div className="button-container">
              <input type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <div>{isSubmitted ? <AfterLogin /> : renderForm}</div>
    </div>
  );
};

export default Login;
