import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import './ViewSentEmails.css'; // Optional: Add your styles here

const ViewSentEmails = () => {
    const [emails, setEmails] = useState([]);
    const location = useLocation();
    const userid = Cookies.get("userid")
    const usertype = Cookies.get("usertype")
    const navigate = useNavigate(); // Use useNavigate for navigation


    // Parse query params (groupid and batch) from the URL
    const queryParams = new URLSearchParams(location.search);
    const groupId = queryParams.get('groupid');
    const batch = queryParams.get('batch');

    
    useEffect(() => {
        if (!userid) {
          alert("Please login");
          navigate('/login');
        } else if (usertype === "USERAPP") {
          alert("Login with Businuess User Credentials");
          navigate('/login');
        }
      }, [userid, navigate]);
    

    useEffect(() => {
        // Fetch emails based on groupid and batch
        axios
            .get(`/get/emails/sent?groupid=${groupId}&batch=${batch}`)
            .then((response) => {
                setEmails(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sent emails:', error);
            });
    }, [groupId, batch]);

    // Check if there are emails to display
    if (emails.length === 0) {
        return <p>No emails found for this group and batch.</p>;
    }

    // Extract the sender (From) and all unique recipients (To)
    const emailFrom = emails[0].MAIL_FROM_LIST; // Assuming this is the same for all emails
    const emailSubject = emails[0].SUBJECT; // Subject is assumed to be the same
    const emailContent = emails[0].CONTENT; // Content is assumed to be the same

    // Collect unique "To" addresses
    // const toEmails = [...new Set(emails.map(email => email.MAIL_TO_LIST))]; // Unique To addresses
    // const toEmails = [...new Set(emails.flatMap(email => email.MAIL_TO_LIST.split(',')))].map(email => email.trim());
    const toEmails = emails.flatMap(email => email.MAIL_TO_LIST.split(',').map(email => email.trim()));


    return (
        <div className="sent-emails-container">
            <h1>Sent Emails for Group ID {groupId} and Batch {batch}</h1>
            <div className="email-item">
                <p><strong>From:</strong> {emailFrom}</p>
                <p><strong>To:</strong></p>
                <div className="to-emails">
                    {toEmails.map((toEmail, index) => (
                        <p key={index}>{toEmail}</p> // Each To address in a new line
                    ))}
                </div>
                <p><strong>Subject:</strong> {emailSubject}</p>
                <p><strong>Content:</strong> {emailContent}</p>
                <p><strong>Total Emails Sent:</strong> {emails.length}</p>
            </div>
        </div>
    );
};

export default ViewSentEmails;
