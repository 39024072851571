const database = [
    {
        userName: 'user1',
        password: 'pass1'
        
    },
    {
        userName: 'user2',
        password:'pass2'
    }
]
export default database;