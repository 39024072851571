import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import './profile.css'
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';
import LoginPopup from '../../loginpage/LoginPopup';
import { useNavigate } from 'react-router-dom';

const EditAccountProfileBusiness = () => {
    const [vendorUser, setVendorUser] = useState({});
    const userid = Cookies.get("userid");
    const homePortalId = Cookies.get("portalid");

    const usertype = Cookies.get("usertype")

    const inputRef = useRef(null);
    const [pindata, setPin] = useState([]);
    const [selectedPortal, setSelectedPortal] = useState('');
    const [showLogin, setShowLogin] = useState(false); // State to control showing login window
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [portalname, setPortalName] = useState('');
    const [townurl, setTownUrl] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [updatedportalid, setUpdatedPortalid] = useState('');
    const [parentportalidurl, setParentPortalidUrl] = useState('');
    const navigate = useNavigate()

    var currentUrl = window.location.href;
    const domain = new URL(currentUrl).origin;
    console.log("domain", domain)
    const newUrl = `${domain}/Cityfromid`;


    useEffect(() => {
        if (!userid) {
            setShowLogin(true); // Show login window when user is not logged in
        } else {
            if (usertype !== 'BUSINESSAPP') {
                alert('Not a business type user');
                if (usertype === 'ADMINAPP') {
                    navigate('/adminuserhome');
                }
                if (usertype === 'USERAPP') {
                    navigate('/AppHome');
                }
            } else {
                fetchProfile();
                fetchportalname();
            }
        }
    }, [userid, usertype]);

    console.log("homePortalId", homePortalId)

    const fetchportalname = async (reload = false) => {
        try {
            const response = await axios.post('/fetchPortalName_business_user', { homePortalId });
            console.log("response portal", response.data.result)
            setPortalName(response.data.result.portalname)
            sessionStorage.setItem("city", response.data.result.portalname);

            setZipCode(response.data.result.zipcode)
            setUpdatedPortalid(response.data.result.portalid)
            let portalid = response.data.result.portalid;
            let parentportaiid = response.data.result.parentportalid;
            const newUrl_final = `${newUrl}/${portalid}`;
            console.log("newUrl", newUrl_final)
            setTownUrl(newUrl_final)
            const parenturl = `${newUrl}/${parentportaiid}`;
            setParentPortalidUrl(parenturl)



            if (reload) {
                window.location.reload();
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        }

    }

    console.log("townurl", townurl)


    const fetchProfile = async () => {
        try {
            const response = await axios.post('/userprofile_business_user', { userid });
            setVendorUser(response.data[0] || {});
            console.log("profile data", response.data[0])
            // Set latitude and longitude from vendorUser
            setLatitude(response.data[0]?.LATITUDE || '');
            setLongitude(response.data[0]?.LONGITUDE || '');
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const handlepincode = async (event) => {
        event.preventDefault();
        const pin = inputRef.current.value;
        try {
            const response = await axios.post('/pincode', { pin });
            setPin(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const handleUpdate = () => {
        //debugger
        const updatedUser = { ...vendorUser };

        if (selectedPortal) {
            updatedUser.HOME_PORTALID = selectedPortal;
        }

        console.log("latitude", latitude)
        console.log("longitude", longitude)
        if (latitude) {
            updatedUser.LATITUDE = latitude;
        }
        if (longitude) {
            updatedUser.LONGITUDE = longitude;
        }
        if (password1 && password1 === password2) {
            updatedUser.PASSWORD = password1;
        } else if (password1 !== password2) {
            alert("Passwords don't match");
            return;
        }

        if (!updatedUser.EMPNAME.trim() || !updatedUser.USERNAME.trim()) {
            alert("Name and Username cannot be empty");
            return;
        }

        console.log("updatedUser", updatedUser)

        setVendorUser(updatedUser);

        axios.post('/profile/Update_business_user', { updatedUser })
            .then(response => {
                if (response.status === 200) {
                    alert(response.data.message)
                    if (selectedPortal) {
                        Cookies.set('portalid', selectedPortal);
                        sessionStorage.setItem("portalid", selectedPortal);
                    }

                    fetchportalname(true);
                    // window.location.reload();
                    setPassword1('');
                    setPassword2('');
                    fetchProfile();
                }
            })
            .catch(error => {
                if (error.response.status === 400) {

                    // console.log("error.response.data.error",error.response.data)
                    alert("Email already exists in the database.");
                } else {
                    console.error('Error updating profile:', error.response.status);
                }
            });
    };

    const requestLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                },
                (error) => {
                    console.log(`Error getting location: ${error.message}`);
                }
            );
        } else {
            console.log('Geolocation is not supported by your browser.');
        }
    };

    // const handleReload = () => {
    //     fetchportalname(true); // Pass true to trigger reload
    // };

    return (
        <div className="center-container">
            <PageLog />
            {showLogin && (
                <div className='template-modal-overlay'>
                    <div className='template-modal'>
                        <LoginPopup />
                    </div>
                </div>
            )}

            <br></br><br></br>

            <div className="edit-profile-container-account">
                

                <div className="edit-profile-form">
                    <h2>Edit Profile</h2>
                    <br/>
                    <div className="profile-info-row">
                        <label>Current town:</label>
                        <div>{portalname}</div>
                    </div>
                    <div className="profile-info-row">
                        <label>My Town News:</label>
                        <div><a href={townurl}>{townurl}</a></div>
                    </div>
                    <div className="profile-info-row">
                        <label>Parent portal News:</label>
                        <div><a href={parentportalidurl}>{parentportalidurl}</a></div>
                    </div>
                    <div className="profile-info-row">
                        <label>Pin/Zip:</label>
                        <div>{zipcode}</div>
                    </div>
                    <div className="profile-info-row">
                        <label>Portal ID:</label>
                        <div>{updatedportalid}</div>
                    </div>
                    <div className="centered-input">
                        <input name="input" type="text" placeholder="Enter Pincode" ref={inputRef} />
                    </div>
                    <div className="register-button-container">
                        <button className="register-button" onClick={(event) => handlepincode(event)}>search pincode</button>
                    </div>

                    {pindata.length !== 0 && (
                        <table className="editaccountprofile-table">
                            <thead>
                                <tr>
                                    <th className="editaccountprofile-th">Select</th>
                                    <th className="editaccountprofile-th">Portal Name</th>
                                    <th className="editaccountprofile-th">Portal ID</th>
                                    <th className="editaccountprofile-th">Parent Portal ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pindata.map(portal => (
                                    <tr key={portal.id}>
                                        <td>
                                            <input
                                                type="radio"
                                                name="selectedPortal"
                                                value={portal.portalid}
                                                onChange={() => setSelectedPortal(portal.portalid)}
                                            />
                                        </td>
                                        <td>{portal.portalname}</td>
                                        <td>{portal.portalid}</td>
                                        <td>{portal.parentportalid}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <div className="form-group">
                        <label>Name:</label>
                        <input type="text" value={vendorUser.EMPNAME || ''} onChange={(e) => setVendorUser({ ...vendorUser, EMPNAME: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" value={vendorUser.USERNAME || ''} onChange={(e) => setVendorUser({ ...vendorUser, USERNAME: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>E-Mail</label>
                        <input type="text" value={vendorUser.EMAIL || ''} onChange={(e) => setVendorUser({ ...vendorUser, EMAIL: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" value={password1} onChange={(e) => setPassword1(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Repeat Password</label>
                        <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Latitude</label>
                        <input type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Longitude</label>
                        <input type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                    </div>
                    <div className="register-button-container">
                        <button onClick={requestLocation}>Load My Current GPS</button>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <button className="profile-button" onClick={handleUpdate}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAccountProfileBusiness;