import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ciindexmainpage.module.css';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const CiIndexPindexMainPage = () => {
  const [aiList, setAiList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [initialSelectedItems, setInitialSelectedItems] = useState([]);
  // const [aitype] = useState('CIndex');
  const portalid = Cookies.get('portalid');

  const userid = Cookies.get('userid');


  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const aitype = query.get('Category');


 

  useEffect(() => {

    if (aitype !== 'cindex' && aitype !== 'pindex') {
      alert('Malformed URL: Category must be "cindex" or "pindex".');
      return;
    }
    const fetchData = async () => {
      setLoading(true);
      try {
        const aiListResponse = await axios.get('/aiindex/list', { params: { aitype } });
        setAiList(aiListResponse.data);

        fetchDataAfterSubmit();
        
        // const selectedListResponse = await axios.get('/aiindex/cindex/selectedlist');
        // setInitialSelectedItems(selectedListResponse.data.map(item => item.AI_ID));
        
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [aitype]);

  useEffect(() => {
    setSelectedItems(new Set(initialSelectedItems));
  }, [initialSelectedItems]);

  const handleCheckboxChange = (item) => {
    if (selectedItems.has(item.AI_ID)) {
      selectedItems.delete(item.AI_ID);
    } else {
      selectedItems.add(item.AI_ID);
    }
    setSelectedItems(new Set(selectedItems));
  };

  const handleSubmit = () => {
    const selectedIds = Array.from(selectedItems);

    console.log("selectedIds", selectedIds);
    axios.post('/aiindex/addtransaction', { selectedIds,  portalid,userid, aitype })
      .then(response => {
        console.log('Success:', response.data);
        alert(response.data.message);
        fetchDataAfterSubmit();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const fetchDataAfterSubmit = async () => {
    try {
      const selectedListResponse = await axios.get('/aiindex/selectedlist', { params: { portalid,userid, aitype  } });
      setInitialSelectedItems(selectedListResponse.data.map(item => item.AI_ID));
    } catch (err) {
      console.error('Error fetching updated selected list:', err);
    }
  };

  return (
    <div className={styles.aiCindexContainer}>
      {/* <h1 className={styles.title}>AI Index List</h1> */}

      {loading && <p>Loading...</p>}
      {error && <p className={styles.error}>Error: {error.message}</p>}
      <h2 className={styles.selectedListTitle}>  {aitype === 'cindex' ? 'CIndex List' : 'PIndex List'}</h2>

      <table className={styles.aiTable}>
        {/* <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead> */}
        <tbody>
          {aiList.map(item => (
            <tr key={item.AI_ID}>
              <td>
                <label>
                  <input
                  className={styles.aicheckbox}
                    type="checkbox"
                    checked={selectedItems.has(item.AI_ID)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                </label>
              </td>
              <td>{item.AI_PROGRAM}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.selectedList}>
        <h2 className={styles.selectedListTitle}>Selected {aitype === 'cindex' ? 'C' : 'P'}index:</h2>
        <ul className={styles.selectedListItems}>
          {Array.from(selectedItems).map(itemId => (
            <li key={itemId} className={styles.selectedListItem}>
              {aiList.find(item => item.AI_ID === itemId)?.AI_PROGRAM}
            </li>
          ))}
        </ul>
      </div>

      <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
    </div>
  );
};

export default CiIndexPindexMainPage;