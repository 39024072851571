import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './style.css';

import HeadingD from './HeadingVend';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';

const AllVendorCategories = () => {

    const [data1, setData] = useState([])


    const [startIndex, setStartIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);
    let port = sessionStorage.getItem("portalid")
    const navigate = useNavigate()


    function goToVendorpage(CFG_PRNT_CD, CONFIG_DES) {
        // function goToVendorpage(CFG_PRNT_CD) {
            console.log("CONFIG_DES",CONFIG_DES)
            //debugger
        if (CONFIG_DES === 'Hospitals') {
            navigate("/hospitallist")
        }
        else if(CONFIG_DES === 'Hotels'){
          navigate("/hotellist")
        }
        else
        {
            navigate("/AllVendors", { state: { id: CFG_PRNT_CD } })
        }

    }



    useEffect(() => {
        axios.post('/local/vendorcategory/list/all')
            .then(function (response) {
                console.log("full vendors data", response.data)
                setData(response.data)
                setMaxIndex(response.data.length);
            })
    }, []);

    const handleNextClick = () => {
        //console.log('Next button clicked')
        if (startIndex + 18 < maxIndex) {
            setStartIndex(prevIndex => prevIndex + 18);
        }
    };

    const handlePrevClick = () => {
        //console.log('Previous button clicked')
        if (startIndex - 18 >= 0) {
            setStartIndex(prevIndex => prevIndex - 18);
        }
    };

    return (
        <div>

            <section className='vendor'>
                <div className='container'>
                    <HeadingD />
                    <PageLog/>
                    <div className='content'>

                        {data1.slice(startIndex, startIndex + 18).map((item, i) => {

                            return (

                                <div className='box' onClick={() => goToVendorpage(item.CFG_PRNT_CD,item.CONFIG_DES)} style={{ cursor: 'pointer' }}>
                                    <div className='ima'>
                                        <img src={item.IMAGE} alt='' />
                                    </div>
                                    <h3 className='tittle'>{item.CONFIG_DES}</h3>
                                </div>

                            );

                        })}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='left-buttons'>
                            <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        </div>
                        <div className='archives-link'>
                            <p align="center"  >
                                <a href={`http://61.2.142.91:8400/CityFromId/${port}`}  >Archives</a>
                            </p>
                        </div>
                        <div className='right-buttons'>
                            <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 18 >= maxIndex}>Next</button>
                        </div>

                    </div>
                </div>
            </section>


        </div>
    )
}

export default AllVendorCategories



