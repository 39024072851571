import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link , NavLink} from 'react-router-dom';
import './Style/Home.css';
import PageLog from '../../../PageLog/PageLog';
//import Header from './Header';
//import Login from './Login';


function HospitalList() {
  const [hospitals, setHospitals] = useState([]);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track user login state
  const [username, setUserName] = useState(''); // Store the user's name


  useEffect(() => {
    // Fetch hospital data from your backend API here using axios
    axios.get('/hospitals') // Adjust the URL to match your server
      .then((response) => {
        setHospitals(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function renderStaticStars(count) {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClass = i <= count ? 'active' : '';
      stars.push(
        <span key={i} className={`static-star ${starClass}`}>
          ★
        </span>
      );
    }
    return stars;
  }

  return (
    <div className="hospital-form">
     <PageLog/>
      <form className="hospitals-container">
        {hospitals.map((hospital) => (
          <div key={hospital.ID} className="hospital-card">
            <div>
            <NavLink to={`/HospitalDetail/${hospital.ID}`} className="more-link-main">
              <img src={hospital.IMAGE} alt={hospital.IMAGE} />
              <p className="star-rating-static">Rating: {renderStaticStars(hospital.RATING)}</p>
              <p>{hospital.NAME}</p>
            </NavLink>
            </div>
            <NavLink to={`/HospitalDetail/${hospital.ID}`} >more</NavLink>

            
          </div>
        ))}
      </form>
    </div>
  );
}

export default HospitalList;
