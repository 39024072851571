import React from 'react';
import './InstagramTutorial.css'; // Import the CSS for styling

const images = [
  { src: '/images/facebook/instagram_tutorial/pic1.jpg', alt: 'click More' },
  { src: '/images/facebook/instagram_tutorial/pic2.jpg', alt: 'click Settings' },
  { src: '/images/facebook/instagram_tutorial/pic3.jpg', alt: 'click Account type and tools' },
  { src: '/images/facebook/instagram_tutorial/pic4.jpg', alt: 'click Switch to professional Account' },
  { src: '/images/facebook/instagram_tutorial/pic5.JPG', alt: 'slect Business or Creator' },
  { src: '/images/facebook/instagram_tutorial/pic6.JPG', alt: 'here Creator is selected,click next' },
  { src: '/images/facebook/instagram_tutorial/pic7.JPG', alt: 'Select a category & click done' },
  { src: '/images/facebook/instagram_tutorial/pic8.jpg', alt: `Now login to your facebook account's page` },
  { src: '/images/facebook/instagram_tutorial/pic9.jpg', alt: 'Click Linked Accounts' },
  { src: '/images/facebook/instagram_tutorial/pic10.jpg', alt: 'click view' },
  { src: '/images/facebook/instagram_tutorial/pic11.JPG', alt: 'clcik connect account' },
  { src: '/images/facebook/instagram_tutorial/pic12.JPG', alt: 'click connect' },
  { src: '/images/facebook/instagram_tutorial/pic13.JPG', alt: 'click confirm & login to your instagram account when prompted' },
  { src: '/images/facebook/instagram_tutorial/pic14.JPG', alt: `You're all set! Facebook will now show a connected Instagram Business account` }
];

const InstagramTutorial = () => {
  return (
    <div className="image-gallery">

<h1>   How to change your account to a Professional Account</h1>

<p className="description"> <strong> Log into your Instagram account</strong> </p>
      {images.map((image, index) => (
        <div key={index} className="image-container">
          <img src={image.src} alt={image.alt} className="image" />
          <p className="description"><strong>{index+1}) {image.alt}</strong></p>
        </div>
      ))}
    </div>
  );
};

export default InstagramTutorial;