import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Addcontents.css';
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';

const Addkfdocmnt = () => {
  const [DOC_TITL, setDOC_TITL] = useState('');
  const [DOC_DESC, setDOC_DESC] = useState('');
  const [DOC_URL, setDOC_URL] = useState('');
  const [DOC_SDATE, setDOC_SDATE] = useState('');
  // const [DOC_CATEGRY, setDOC_CATEGRY] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState([]);
  const portalid = Cookies.get('portalid');
  const userid = Cookies.get('userid');
  const adminuser = Cookies.get("adminuser");
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const usertype = Cookies.get("usertype")

  const [showAddSuccessMessage, setShowAddSuccessMessage] = useState(false);
  const [showUpdateSuccessMessage, setShowUpdateSuccessMessage] = useState(false);



  console.log("portalid", portalid)
  console.log("userid", userid)

  const finalpath = `${portalid}/${userid}`;

  useEffect(() => {
    fetchOptions();
    fetchkfdocmntData();
    createfolder();
  }, []);

  const handleEdit = (item) => {
    // Set the form data with the selected row for editing
    setDOC_TITL(item.DOC_TITL);
    setDOC_DESC(item.DOC_DESC);
    setDOC_URL(item.DOC_URL);
    setDOC_SDATE(item.DOC_SDATE);
    setSelectedOption(item.DOC_CATEGRY);
    setSelectedImage(null); // Clear the image selection for editing
    setIsEditing(true);
    // Set the editing data for later use in handleSubmit
    setEditingData(item);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const createfolder = async () => {

    try {
      const response = await axios.post('/create/folder/session', { "portalid": portalid, "userid": userid });
      console.log("result", response);
    }


    catch (error) {
      console.log(error);
    }

  };

  const fetchOptions = async () => {
    try {
      const response = await axios.get('/kf_doc_Categry');
      setOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  const fetchkfdocmntData = async () => {
    try {
      // Get DOC_VEND_ID from session storage
      const DOC_VEND_ID = Cookies.get('vend_id');

      if (!DOC_VEND_ID) {
        // Handle the case where DOC_VEND_ID is not found in session storage
        console.log('DOC_VEND_ID not found in session storage');
        return;
      }

      const response = await axios.get(`/doc?vendorId=${DOC_VEND_ID}`);
      setData(response.data);

      console.log("response.data", response.data)
    } catch (error) {
      console.log(error);
    }
  };


  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('DOC_TITL', DOC_TITL);
    formData.append('DOC_DESC', DOC_DESC);
    formData.append('DOC_URL', DOC_URL);
    formData.append('DOC_SDATE', DOC_SDATE);
    formData.append('DOC_CATEGRY', selectedOption);
    formData.append('vendorId', Cookies.get('vend_id'));
    formData.append('portalid', portalid);
    formData.append('finalpath', finalpath);
    formData.append('userid', userid);
    formData.append('usertype', usertype);
    formData.append('image', selectedImage);

    try {
      if (isEditing) {
        setShowUpdateSuccessMessage(true);
        // If editing, use update API instead of add API
        const response = await axios.put(`/kfupdate/${editingData.DOC_ID}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setShowUpdateSuccessMessage(false);
        console.log('Update Response:', response.data);
      } else {
        // If not editing, use add API
        setShowAddSuccessMessage(true);
        const response = await axios.post('/kf_docmnt_upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setShowAddSuccessMessage(false);
        console.log('Add Response:', response.data);
      }

      // Reset form fields
      setSelectedOption('');
      setSelectedImage(null);
      setDOC_SDATE('');
      setDOC_URL('');
      setDOC_DESC('');
      setDOC_TITL('');

      // Reset editing state
      setIsEditing(false);
      setEditingData(null);

      // Fetch updated data
      fetchkfdocmntData();
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleDelete = (docId) => {
    axios
      .delete(`/kfDOC_MNT_delete/${docId}`)
      .then((response) => {
        console.log('Data DOC_PRICE updated successfully to 0');
        // Fetch the updated data after deletion
        fetchkfdocmntData();
      })
      .catch((error) => {
        console.error('Error updating DOC_PRICE:', error);
      });
  };

  const handleApprove = (docId) => {
    axios
      .put(`/kf-docmnt-approve/${docId}`)
      .then((response) => {
        console.log('Document approved successfully');
        // Fetch the updated data after approval
        fetchkfdocmntData();
      })
      .catch((error) => {
        console.error('Error approving document:', error);
      });
  };

  return (
    <div className="kf-body">
      <PageLog />

      <form onSubmit={handleSubmit} className='kf-form'>
        <h2>
          <u>Add Contents</u>
        </h2>

        <label>
          Title:
          <input
            type="text"
            value={DOC_TITL}
            onChange={(e) => setDOC_TITL(e.target.value)}
            required
          />
        </label>

        <br />

        <div>
          <label>DESCRIPTION: </label>
        </div>
        <textarea
          value={DOC_DESC}
          onChange={(e) => setDOC_DESC(e.target.value)}
          required
        ></textarea>
        <br />

        <label>
          Link:
          <input
            type="text"
            value={DOC_URL}
            onChange={(e) => setDOC_URL(e.target.value)}
            required
          />
        </label>

        <br />

        <label>
          Starting Date:
          <input
            type="date"
            value={DOC_SDATE}
            onChange={(e) => setDOC_SDATE(e.target.value)}
            required
          />
        </label>
        <br />

        <label htmlFor="category">Category:</label>
        <select
          id="category"
          className="kf-input-select"
          value={selectedOption}
          onChange={handleOptionChange}
           type="submit"
           required
        >
          <option value="" disabled>Select a Category</option>
          {options.map((option) => (
            <option key={option.CONFIG_DES} value={option.CONFIG_DES}>
              {option.CONFIG_DES}
            </option>
          ))}
        </select>

        <div>
          <label htmlFor="image">Select an image:</label>
          <input
            type="file"
            className="kf-input"
            id="image"
            onChange={handleImageChange}
          />
        </div>
        <br />


        <div className="btn-container">

          {showAddSuccessMessage && (
            <div className="success-message  show">
              {/* <p>Added successful!</p> */}
              <p>Adding....</p>
            </div>
          )}
          {showUpdateSuccessMessage && (
            <div className="success-message  show">
              {/* <p>Update successful!</p> */}
              <p>Updating....</p>
            </div>
          )}

          <div className="btn-container">
            <button className="kf-button"  type="submit">
              Submit
            </button>
          </div>

        </div>
      </form>
      <h2>Uploaded Data</h2>
      <table className="content-table">
        <thead>
          <tr>
            <th>USER_ID</th>
            <th>Title</th>
            <th>DESCRIPTION</th>
            <th>Link</th>
            <th>Category</th>
            <th>DATE</th>
            <th>DOC_PRI</th>
            <th>IMAGE</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            console.log('Mapping data for item:', item);
            // Add a condition to check if doc_price is equal to 5
            if ((item.DOC_PRICE > 0 ) && item.portalid === parseInt(portalid, 10)) {
              return (
                <tr key={item.DOC_ID}>
                  <td>{item.MEMBER_ID}</td>
                  <td>{item.DOC_TITL}</td>
                  <td>{item.DOC_DESC}</td>
                  <td>{item.DOC_URL}</td>
                  <td>{item.DOC_CATEGRY}</td>
                  <td>{item.DOC_SDATE}</td>
                  <td>{item.DOC_PRICE}</td>
                  <td>
                    <a href={item.image} target="_blank" rel="noopener noreferrer">
                      View Image
                    </a>
                  </td>
                  <td>
                    {adminuser === 'Active' && (
                      <button
                        className="T-kf-button"
                        type="button"
                        onClick={() => handleApprove(item.DOC_ID)}
                      >
                        Approve
                      </button>
                    )}
                    <button
                      className="T-kf-button"
                      type="button"
                      onClick={() => handleEdit(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="T-kf-button"
                      type="button"
                      onClick={() => handleDelete(item.DOC_ID)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            } else {
              // If doc_price is not equal to 5, skip rendering this row
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Addkfdocmnt;
