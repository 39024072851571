import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
//import PageLog from '../../PageLog/PageLog';

function StartupFromName() {

    const navigate = useNavigate()


    const { country } = useParams();


    var currentUrl = window.location.href;
    var baseUrl = currentUrl.split("/")[2]; // Get the domain (e.g., "example.com")
    var newUrl = window.location.protocol + "//" + baseUrl + "/techieindexlatest";
    console.log("baseUrl", baseUrl)

    useEffect(() => {
        if (baseUrl === 'techie-index.com'|| baseUrl === 'www.techie-index.com') {

            if (country === 'us') {
                debugger
                sessionStorage.setItem("portalid", 9455)
                sessionStorage.setItem("city", "USA - Startups")
                sessionStorage.setItem("parentportalid", 508)
                window.location.href = newUrl;

            }

            if (country === 'uk') {
                sessionStorage.setItem("portalid", 9457)
                sessionStorage.setItem("city", "UK - Startups")
                sessionStorage.setItem("parentportalid", 508)
                window.location.href = newUrl;

            }

            if (country === 'ca') {
                sessionStorage.setItem("portalid", 9458)
                sessionStorage.setItem("city", "CANADA - Startups")
                sessionStorage.setItem("parentportalid", 508)
                window.location.href = newUrl;

            }
            if (country === 'in') {
                sessionStorage.setItem("portalid", 9459)
                sessionStorage.setItem("city", "INDIA-Startups")
                sessionStorage.setItem("parentportalid", 508)
                window.location.href = newUrl;

            }
            if (country === 'de') {
                sessionStorage.setItem("portalid", 9456)
                sessionStorage.setItem("city", "GERMANY - Startups")
                sessionStorage.setItem("parentportalid", 508)
                window.location.href = newUrl;

            }
        }


    }, []);



    return (
        <>
            {/* <PageLog/> */}

        </>
    )
}

export default StartupFromName