import React, { useEffect, useRef, useState } from 'react'
import MainpageCategories from '../mainpage/MainpageCategories'
import Homepages from '../Home/Homepages'
import './register.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import LoginHeader from './LoginHeader'
import LoginFooter from './LoginFooter'
import BusinessRegister from './BusinessRegister'
// import PageLogLocation from '../PageLog/PageLogLocation'

const Register = () => {


  const getEmail = sessionStorage.getItem("emailData")
  const getPassword = sessionStorage.getItem("passwordData")
  const getMessage = sessionStorage.getItem("message")


  const [name, setName] = useState("");
  const [id, setID] = useState("");
  const [key, setKey] = useState("");
  const [town, setTown] = useState("");
  const [dist, setDist] = useState("");
  const [state, setSt] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [selectedPortal, setSelectedPortal] = useState('');
  const [portalid, setPortID] = useState(3025);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [idError, setIDError] = useState("");
  const [keyError, setKeyError] = useState("");


  const navigate = useNavigate()
  const inputRef = useRef(null);
  const [pindata, setPin] = useState('')
  console.log("pindata", pindata)
  const [type, setType] = useState('basic');
  //const [location, setLocation] = useState(null);


  useEffect(() => {
    requestLocation();
  }, []);

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          //setLocation({ latitude, longitude });
          setLat(latitude); // Set latitude in state
          setLong(longitude); // Set longitude in state
          console.log('position.coords', position.coords);
        },
        (error) => {
          console.log(`Error getting location: ${error.message}`);
          //setLocation({ latitude: null, longitude: null });
        }
      );
    } else {
      console.log('Geolocation is not supported by your browser.');
      //setLocation({ latitude: null, longitude: null });
    }
  };


  const validateForm = () => {
    // if (!selectedPortal) {
    //   alert('Please select a portal.');
    //   return false;
    // }

    // if (!/^\d+(\.\d+)?$/.test(portalid) || !/^\d+(\.\d+)?$/.test(long)) {
    // if (!/^\d+(\.\d+)?$/.test(portalid)) {
    //   alert('City/Town must be selected with Pincode');
    //   return false;
    // }

    return true;
  };

  const validatePhone = (phone) => {
    // Use a regular expression to check for a valid phone number format
    if (!/^[0-9]{10}$/.test(phone)) {
      return "Phone number should be 10 digits long.";
    }
    return "";
  };

  const validateEmail = (email) => {
    // Use a regular expression to check for a valid email format
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      return "Invalid email address format.";
    }
    return "";
  };

  const validateID = (id) => {
    if (!id || id.trim() === "") {
      return "Username is required.";
    }
    return "";
  };

  const validateKey = (key) => {
    if (!key || key.trim() === "") {
      return "Password is required.";
    }
    return "";
  };

  const signup = () => {

    if (!validateForm()) {
      return;
    }
    if (phone !== "") {
      console.log("phone", phone)
      const phoneError = validatePhone(phone);


      if (phoneError) {
        setPhoneError(phoneError);

        return;
      }
    }

    if (email !== "") {
      console.log("phone", phone)

      const emailError = validateEmail(email);

      if (emailError) {

        setEmailError(emailError);
        return;
      }
    }

    const idError1 = validateID(id);
    const keyError1 = validateKey(key);
    if (idError1 || keyError1) {
      setIDError(idError1);
      setKeyError(keyError1);
      return;
    }

    if (!/^\d+(\.\d+)?$/.test(portalid)) {
      if (window.confirm("Looks like you have not selected a City/Town, press OK if you still want to proceed ?")) {
        // The user clicked "Yes," so you can perform some action here
        // alert("You clicked Yes!");
        signupfn();
      }

      // else {
      //   // The user clicked "No" or closed the dialog, handle accordingly
      //   alert("You clicked No or closed the dialog.");
      // }

    }
    else {
      signupfn();
    }
    //console.log("portalid",portalid)

  };

  const signupfn = () => {

    // if(portalid === '' ){
    //   setPortID(3025)
    // }

    axios.post("/signup/details", { "name": name, "id": id, "key": key, "town": town, "dist": dist, "state": state, "portalid": portalid, "phone": phone, "email": email, lat, long })
      .then((response) => {
        console.log("register response", response.status)

        if (response.status === 201) {
          alert("Registered Sucessfully, Login to Continue")
          navigate(-1);
        }
      })
      .catch((error) => {
        // Handle errors, including 403 status code
        // if (error.response && error.response.status === 403) {
        //   alert("User already registered with this email, try resetting your password");

        // } else {
        //   console.error("Error:", error.message);
        //   // Handle other errors as needed
        // }

        if (error.response && error.response.status === 403) {
          if (error.response.data.message === 'Username already exists') {
            alert("Username already registered, please choose another one.");
          } else {
            alert("User already registered with this email, try resetting your password");
          }
        } else {
          console.error("Error:", error.message);
          // Handle other errors as needed
        }
      });
  }

  const handlepincode = (event) => {
    event.preventDefault();
    const pin = inputRef.current.value;
    //console.log(pin)
    //sessionStorage.setItem("pincode", data1.current.value)
    axios.post('/pincode', { "pin": pin })
      .then(response => {
        console.log("response.data", response.data)
        if (response.data.length === 0) {
          alert(" pincode not found")
        }
        setPin(response.data);
        console.log("after pindata", pindata)
      })
      .catch(error => {
        console.error(error);
      });

    //const path = `/PinPage?pin=${pin}`;
    //navigate(path);
    //window.location.reload();
  }


  const handleSelectPortal = (portalname, parentport, state, portalid) => {
    //console.log(a)
    setTown(portalname)
    setDist(parentport)
    setSt(state)
    setPortID(portalid)
    setSelectedPortal(portalname);

  }



  return (
    <div >
      <LoginHeader />
      {/* <PageLogLocation /> */}
      <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>
      {
        getEmail && getPassword ?
          <MainpageCategories /> :
          <div className='register-form-container' >
            <h2>Signup</h2>
            <div className="radio-group">

              <label className="radio-label" >
                <input
                  className='input-radio'
                  type="radio"
                  value="basic"
                  checked={type === 'basic'}
                  onChange={() => setType('basic')}
                />
                Basic User

              </label>

              <label className="radio-label1" >
                <input
                  className='input-radio'
                  type="radio"
                  value="business"
                  checked={type === 'business'}
                  onChange={() => setType('business')}
                />

                Business User
              </label>

            </div>
            {type === 'basic' ? (
              <div >


                <br></br>
                <div>
                  <input placeholder="Name" type="text" onChange={(e) => { setName(e.target.value) }} />
                </div>
                <br></br>
                <div>
                  <input placeholder="Username" type="text" onChange={(e) => { setID(e.target.value); setIDError(""); }} />
                  {idError && <span className="error-message">{idError}</span>}
                </div>
                <br></br>
                <div>
                  <input placeholder="password" type="password" onChange={(e) => { setKey(e.target.value); setKeyError(""); }} />
                  {keyError && <span className="error-message">{keyError}</span>}
                </div>
                <br></br>
                <div>
                  <input placeholder="Phone" type="text" onChange={(e) => { setPhone(e.target.value); setPhoneError(""); }} />
                  {phoneError && <span className="error-message">{phoneError}</span>}
                </div>
                <br></br>
                <div>
                  <input placeholder="Email" type="text" onChange={(e) => { setEmail(e.target.value); setEmailError(""); }} />
                  {emailError && <span className="error-message">{emailError}</span>}
                </div>
                <br></br>


                <div>
                  <input placeholder="latitude" type="text" onChange={(e) => { setLat(e.target.value) }} value={lat} />
                </div>
                <br></br>
                <div>
                  <input placeholder="longitude" type="text" onChange={(e) => { setLong(e.target.value) }} value={long} />
                </div>
                To autoload location to above textboxes enable location for this site & reload page,refer this for more <a href='https://support.google.com/chrome/answer/142065'>info</a>

                {/* & click here to <a href='#' onClick={requestLocation}>Reload</a> */}
                <br></br>
                <br></br>


                {/* <h5>Enter your Pincode</h5> */}
                <div>
                  <input name="input" type="text" placeholder="Enter Pincode" ref={inputRef} />
                </div>
                <div className="register-button-container">
                  <button className="register-button" onClick={(event) => handlepincode(event)}>search pincode</button>
                </div>

                {pindata.length != 0 ? (
                  <table style={{ margin: '10px 0px', border: '1px solid black', borderCollapse: 'collapse' }} >
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Portal Name</th>
                        <th>Portal ID</th>
                        <th>Parent Portal ID</th>
                        <th>District Name</th>
                        <th>State Name</th>
                        {/* Add more column headers as needed */}
                      </tr>
                    </thead>
                    <tbody>
                      {pindata.map(portal => (
                        <tr key={portal.id}>
                          <td>

                            <input
                              type="radio"
                              name="selectedPortal"
                              value={portal.portalid}
                              onChange={() => handleSelectPortal(portal.portalname, portal.parentport, portal.state, portal.portalid)}
                            />

                          </td>
                          <td>
                            {/*                         <a href="#" onClick={() => handleclickportal(portal.portalid, portal.portalname, portal.parentportalid)}>{portal.portalname}</a> */}

                            {portal.portalname}
                          </td>
                          <td>{portal.portalid}</td>
                          <td>{portal.parentportalid}</td>
                          <td>{portal.parentport}</td>
                          <td>{portal.state}</td>
                          {/* Add more table cells for other data in the row */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null

                }

                <br></br>
                <div className="register-button-container">
                  <button onClick={signup} className="register-button">Signup</button>
                </div>

              </div>) : (<BusinessRegister />)
            }
          </div>

      }
      <br></br> <br></br> <br></br>
      <LoginFooter />
    </div>
  )
}

export default Register
