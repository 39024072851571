import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './techieindexactivate.css';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import PageLog from '../PageLog/PageLog';

function TechieIndexActivate() {

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const email = query.get('email');
    const encodednum = query.get('enum');
    const encnum = encodednum ? decodeURIComponent(encodednum) : null;
    //console.log("id", id, email)
    const [verificationCode, setVerificationCode] = useState('');
    const [randomCode, setRandomCode] = useState('');
    const navigate = useNavigate()



    useEffect(() => {

        setRandomCode(generateRandomNumber());
    }, []);



    const generateRandomNumber = () => {
        return Math.floor(100000 + Math.random() * 900000);
    };

    const decryptthenum = () => {
        // debugger
        const secretKey = '#techieindex!';
        //console.log("encnum",encnum)
        const bytes = CryptoJS.AES.decrypt(encnum, secretKey);
        //console.log("bytes",bytes)
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
        // Parse the string to an integer
        const decryptedNumber = parseInt(decryptedString, 10);
        return decryptedNumber;


    }




    const handleButtonemailverify = () => {
        const decnum = decryptthenum();
        // debugger
        //if(verificationCode===randomCode){
        if (verificationCode.trim() === decnum.toString()) {

            console.log('Verification Code Entered:', verificationCode);

            axios.post('/activate/techieindex/user', { "id": id })
                .then(function (response) {
                    console.log(response.data);
                    setVerificationCode('')
                    alert(response.data)
                    navigate("/tlogin");
                })
                .catch(function (error) {
                    console.error('Failed to fetch menu:', error);
                });

        }

    };

    //const randomCode = generateRandomNumber();

    const handleButtonClick = () => {

        //debugger
        //if(verificationCode===randomCode){
        if (verificationCode.trim() === randomCode.toString()) {

            console.log('Verification Code Entered:', randomCode);

            axios.post('/activate/techieindex/user', { "id": id })
                .then(function (response) {
                    console.log(response.data);
                    setVerificationCode('')
                    alert(response.data)
                    navigate("/tlogin");
                })
                .catch(function (error) {
                    console.error('Failed to fetch menu:', error);
                });

        }

    };


    return (
        <div className='techieindex-activate-form-container'>
            <PageLog />

            <br /><br />
            {email === '' ? (<>
                <div>
                    Please enter this number <span style={{ color: 'red', cursor: 'default' }}>{randomCode}</span> in the textbox to verify your account

                </div>

            </>) : (<div>

                Please enter the activation code sent in your email id
            </div>)


            }
            <br /><br />
            <input
                type="text"
                placeholder="Enter Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
            />
            <br /><br />

            {email === '' ? (<>
                <button onClick={handleButtonClick}>Verify</button> </>) : (
                <div>
                    <button onClick={handleButtonemailverify}>Verify</button>
                </div>
            )
            }


        </div>
    )
}

export default TechieIndexActivate