import React from 'react'
import PageLog from '../PageLog/PageLog'

function IJobsPage() {
  return (
    <div>
      <PageLog/>

      IJobsPage</div>
  )
}

export default IJobsPage