import React,{useEffect,useState} from "react";
import ReactEcharts from "echarts-for-react";

export const Chart1 = () => {

  const [message, setMessage] = useState("");
  useEffect(() => {
    fetch("http://localhost:3000/message")
      .then((response) => response.json())
      .then((data) => setMessage(data));
  }, []);
 
  
  const option = {
    xAxis: {
      type: "category",
      data: ["2014","2015", "2016", "2017"],
    },

    yAxis: {
      type: "value",
    },

    series: [
      {
        data: [message[0], message[1], message[2],message[3]],
        type: "bar",
      },
    ],
  };
  return <ReactEcharts option={option} />;
};
