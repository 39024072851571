import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './topvolunteers.css'
import Cookies from 'js-cookie';


const TopVolunteers = () => {
  const [topPoints, setTopPoints] = useState([]);
  let portalid = Cookies.get("portalid")
  portalid=parseInt(portalid, 10)

  useEffect(() => {
    const fetchTopPoints = async () => {
      try {
        const response = await axios.get('/api/top-points', {
          params: {
            portalid: portalid
          }
        });
        setTopPoints(response.data);
      } catch (error) {
        console.error('Error fetching top points:', error);
      }
    };

    fetchTopPoints();
  }, []);

  return (
<div className="top-points-container">
      <center><h2>Top Volunteers' Points </h2></center>
      <table className="points-table">
        <thead>
          <tr>
            {/* <th>User ID</th> */}
            <th>Name</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {topPoints.map((point, index) => (
            <tr key={index}>
              {/* <td>{point.RESUME_ID}</td> */}
              <td>{point.user_name}</td>
              <td>{point.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopVolunteers;