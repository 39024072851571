import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Style/HospitalDetail.css';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageLog from '../../../PageLog/PageLog';

function HospitalDetail() {
  const { id } = useParams();
  const [hospital, setHospital] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [review, setReview] = useState('');
  const [reviewsdata, setReviewsData] = useState([]);
  const [overallrating, getOverallRating] = useState([]);


  const [rating, setRating] = useState(0); // Added for star rating



  const userid = Cookies.get("userid")
  const username = Cookies.get("name")
  console.log("userid",userid)
  console.log("username",username)


  const getreviews = () => {
    axios.get(`/get/rating/reviews/${id}`)
      .then((response) => {
        setReviewsData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching reviews: ' + error);
      });
  }


  const submitReview = () => {
    if (review.trim() === '') {
      alert('Please enter your review before submitting.');
    } else {
      // Log the values being sent to the server
      console.log('Selected Procedure:', selectedProcedure);
      console.log('Review Text:', review);
      
      // Send 'review' and 'selectedProcedure' to the server
      axios
        .post('/upload-reviews', {
          "Review": review, "selectedProcedure": selectedProcedure, "User_Id": userid, "Username": username, "rating": rating, "hosp_id": id
        })
        .then((response) => {
          alert(response.data.message);
        })
        .catch((error) => {
          console.error('Error submitting review:', error);
        });
    }
    //debugger
    //getreviews();
    updateoverallreview();
    window.location.reload();
  };

  const updateoverallreview = () =>{

    console.log("Rounded abc", roundedAbc);
  }

  console.log("selectedProcedure", selectedProcedure)

  useEffect(() => {
    if (!id) {
      console.error('ID is undefined');
      setLoading(false);
      return;
    }

    axios
      .get(`/hospital/${id}`)
      .then((response) => {
        setHospital(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Request failed:', error);
        setError('Error loading hospital details.');
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`/procedures/${id}`)
      .then((response) => {
        setProcedures(response.data);
        console.log("procedures",response.data)
      })
      .catch((error) => {
        console.error('Error fetching procedures:', error);
      });
  }, []);

  useEffect(() => {
    // Make an Axios request to fetch reviews for the hospital with its ID
    axios.get(`/get/rating/reviews/${id}`)
      .then((response) => {
        setReviewsData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching reviews: ' + error);
      });

    getoverallrating();
    // const finalrating = calculateAverageRating()
    //   console.log("finalrating",finalrating)
  }, [id]);


  const getoverallrating = () => {


    axios.get(`/get/calculate/rating/${id}`)
      .then((response) => {
        console.log("OVERALL",response.data);
        getOverallRating(response.data)

      })
      .catch((error) => {
        console.error('Error fetching reviews: ' + error);
      });
  }


  console.log("overallrating", overallrating)
  const sum = overallrating.reduce((total, rating) => {
    return total + parseInt(rating.RATING);
  }, 0);

  const abc = sum / overallrating.length;
  console.log("abc", abc)

  const roundedAbc = Math.ceil(abc);

  




  // Star rating component
  const renderStars = (count) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={`star ${i <= rating ? 'active' : ''}`}
          onClick={() => setRating(i)}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  function renderStaticStars(count) {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClass = i <= count ? 'active' : '';
      stars.push(
        <span key={i} className={`static-star ${starClass}`}>
          ★
        </span>
      );
    }
    return stars;
  }

  console.log("rating", rating)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!hospital) {
    return <div>Hospital not found.</div>;
  }

  return (
    <div className="hospital-detail-main-container">
      <PageLog/>
      <div className="hospital-detail">
        <div className="hospital-image">
          <img src={hospital.IMAGE} alt={hospital.IMAGE} />
        </div>
        <div className="hospital-details">
          <h2>{hospital.Name}</h2>
          <p className="star-rating-static">Overall Rating: {renderStaticStars(roundedAbc)}</p>
          <p>Place: {hospital.PLACE}</p>
          <p>Phone: {hospital.PHONE}</p>
          <p>Doctors: {hospital.DOCTORS}</p>
          <p>Nurses: {hospital.NURSING_STAFFS}</p>
          <p>Other Staffs: {hospital.OTHER_STAFFS}</p>
          <p>Beds: {hospital.BED_NO}</p>
        </div>
        <div className="services-form">
          <h3>Services</h3>
          <div>
            <select
              className="services-select"
              value={selectedProcedure}
              onChange={(e) => setSelectedProcedure(e.target.value)}
            >
              <option value="">Select a procedure</option>
              {procedures.map((procedure) => (
                <option key={procedure.ID} value={procedure.NAMES}>
                  {procedure.NAMES}
                </option>
              ))}
            </select>
          </div>

          <div className="star-rating">
            <h3>Give Star Rating</h3>
            {renderStars(5)}
          </div>
          <textarea
            placeholder="Write your review..."
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
          <button onClick={submitReview}>Submit</button>

        </div>
      </div>
      {/* <button onclick={calculateAverageRating}>rating</button> */}
      <div className="hospital-detail-review">

        <h3>Reviews</h3>
        {reviewsdata.map((review) => (
          
          <div key={review.R_ID} className="review-container">
            <p>User: {review.USERNAME}</p>                    
            <p>Service: {review.SERVICE}</p>
            <p>Reviewed Date/Time: {new Date(review.PUBDATE).toLocaleString()}</p> 
            <p className="star-rating-static">Rating: {renderStaticStars(review.RATING)}</p>
            <p>Review: {review.REVIEW}</p>
          </div>
        ))}

      </div>


    </div>
  );
}

export default HospitalDetail;
