import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './techieindexregister.css';
import PageLogLocation from '../PageLog/PageLogLocation';



function TechieIndexRegister() {

    const [name, setName] = useState("");
    const [id, setID] = useState("");
    const [key, setKey] = useState("");
    const [idError, setIDError] = useState("");
    const [keyError, setKeyError] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [type, setType] = useState('techieindex');

    const [randomCode, setRandomCode] = useState('');


    const navigate = useNavigate()

    useEffect(() => {
       
        setRandomCode(generateRandomNumber());
    }, []);



    const generateRandomNumber = () => {
        return Math.floor(100000 + Math.random() * 900000);
    };

    const validateID = (id) => {
        if (!id || id.trim() === "") {
            return "Username is required.";
        }
        return "";
    };

    const validateKey = (key) => {
        if (!key || key.trim() === "") {
            return "Password is required.";
        }
        return "";
    };

    const validatePhone = (phone) => {
        // Use a regular expression to check for a valid phone number format
        if (!/^[0-9]{10}$/.test(phone)) {
            return "Phone number should be 10 digits long.";
        }
        return "";
    };

    const validateEmail = (email) => {
        // Use a regular expression to check for a valid email format
        if (!/^\S+@\S+\.\S+$/.test(email)) {
            return "Invalid email address format.";
        }
        return "";
    };


    const signup = () => {

        if (phone !== "") {
            console.log("phone", phone)
            const phoneError = validatePhone(phone);


            if (phoneError) {
                setPhoneError(phoneError);

                return;
            }
        }

        if (email !== "") {
            console.log("phone", phone)

            const emailError = validateEmail(email);

            if (emailError) {

                setEmailError(emailError);
                return;
            }
        }
        //debugger
        const idError1 = validateID(id);
        const keyError1 = validateKey(key);



        if (idError1 || keyError1) {
            setIDError(idError1);
            setKeyError(keyError1);

            return;
        }
        signupfn();
    }

    const signupfn = () => {

        axios.post("/techieindex/signup/details", { "name": name, "id": id, "key": key, "phone": phone, "email": email, "type": type })
            .then((response) => {
                console.log("register response", response.status)

                if (response.status === 201) {

                    const { message, insertedUserId } = response.data;

                    console.log(message);
                    console.log('Inserted User ID:', insertedUserId);
                    if (email === '') {
                        alert("Registered Sucessfully")
                        navigate(`/techieindexactivate?id=${insertedUserId}&email=${email}`);
                    }
                    else {
                        alert("Registered Sucessfully,please click on link send to mail to activate")
                        sendverificationlink(insertedUserId);
                        navigate("/tlogin");
                    }

                }
            })
            .catch((error) => {
                // Handle errors, including 403 status code
                if (error.response && error.response.status === 403) {
                    alert("User already registered with this email, try resetting your password");

                } else {
                    console.error("Error:", error.message);
                    // Handle other errors as needed
                }
            });
    }

    const encryptRandomCode = () => {
        // Use a secret key for encryption
        const secretKey = '#techieindex!';
        const randomCodeAsString = String(randomCode);

        return CryptoJS.AES.encrypt(randomCodeAsString, secretKey).toString();
    };




    const sendverificationlink = async (insertedUserId) => {

        const encrpytednum = encryptRandomCode()
        const encodednum = encodeURIComponent(encrpytednum);
        const url = `https://techie-index.com/techieindexactivate?id=${insertedUserId}&email=${email}&enum=${encodednum}`

        try {
            const emailResponse = await axios.post('/techieindex/activation/email', {
                to: email,
                subject: ` ${type} Activation link`,
                html: `<p>Click on the link below to Activate your ${type} Account</p> 
                <p>Your otp to activate is:${randomCode}</p>   
                    <a href=${url}>Activate Account</a>`,
                fromemail: 'techieindexmedia15@gmail.com',
                password: 'rgtn dstl bogv upic'
            });

            console.log(emailResponse.data);
            alert(emailResponse.data.message)
            //setEmail('');
        } catch (error) {
            // Handle errors, including 403 status code
            // if (error.response && error.response.status === 404) {
            //     alert("User with this email id does not exist, please create an account");
            // } else
            {
                console.error("Error:", error.message);
                // Handle other errors as needed
            }
        }

    };





    return (
        <div>
            <PageLogLocation />
            <br /><br />
            <div className='techieindex-register-form-container'>


                <br></br>
                <div>
                    <input placeholder="Name" type="text" onChange={(e) => { setName(e.target.value) }} />
                </div>
                <br></br>
                <div>
                    <input placeholder="Username" type="text" onChange={(e) => { setID(e.target.value); setIDError(""); }} />
                    {idError && <span className="error-message">{idError}</span>}
                </div>
                <br></br>
                <div>
                    <input placeholder="password" type="password" onChange={(e) => { setKey(e.target.value); setKeyError(""); }} />
                    {keyError && <span className="error-message">{keyError}</span>}
                </div>
                <br></br>
                <div>
                    <input placeholder="Phone" type="text" onChange={(e) => { setPhone(e.target.value); setPhoneError(""); }} />
                    {phoneError && <span className="error-message">{phoneError}</span>}
                </div>
                <br></br>
                <div>
                    <input placeholder="Email" type="text" onChange={(e) => { setEmail(e.target.value); setEmailError(""); }} />
                    {emailError && <span className="error-message">{emailError}</span>}
                </div>
                <br></br>

                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="techieindex">Techieindex</option>
                    <option value="ijobs">IJobs</option>
                    <option value="icampus">ICampus</option>
                </select>
                <br></br>
                <div className="register-button-container">
                    <button onClick={signup} className="register-button">Signup</button>
                </div>

            </div>
        </div>
    )
}

export default TechieIndexRegister