import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './style.css';
import Card2 from './card2';
import Heading from './Heading';
import 'bootstrap/dist/css/bootstrap.min.css';
import PageLog from '../../../PageLog/PageLog';

const AllSpotlights = () => {

    const [data1, setData] = useState([])
    const [data2, SetImage1] = useState([])
    const [data3, SetImage2] = useState([])
    const [data4, setData1] = useState([])
    const [portal_img1, SetPortalImg1] = useState([])
    const [portal_img2, SetPortalImg2] = useState([])
    const [startIndex, setStartIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    let site = sessionStorage.getItem("site")
    let port3 = sessionStorage.getItem("portalid")
    let parentport = sessionStorage.getItem("parentportalid")

    useEffect(() => {

        axios.all([
            axios.post('/news/headlines/Spotlight/All', { "port3": port3, "parentport": parentport }),
            axios.post(`/myblock/altimage/spotlight`),
            axios.post(`/techieindex/altimage/spotlight`),
            axios.post(`/news/headlines/Spotlight/techieindex/All`, { "port3": port3, "parentport": parentport }),
            axios.post(`/myblock/altimage/spotlight/portalid`, { "port": port3 }),
            axios.post(`/techieindex/altimage/spotlight/portalid`, { "port": port3 }),
        ])
            .then(axios.spread((data1, data2, data3, data4, data5, data6) => {
                // output of req.
                console.log('spotlight data1', data1, 'spotlight image data2', data2, 'spotlight image  data3', data3, 'spotlight image  data3', data4)

                setData(data1.data)
                SetImage1(data2.data)
                SetImage2(data3.data)
                setData1(data4.data)
                SetPortalImg1(data5.data)
                SetPortalImg2(data6.data)
                setMaxIndex(Math.max(data1.data.length, data4.data.length));

            }));

    }, []);

    const handleNextClick = () => {
        //console.log('Next button clicked')
        if (startIndex + 18 < maxIndex) {
            setStartIndex(prevIndex => prevIndex + 18);
        }
    };

    const handlePrevClick = () => {
        //console.log('Previous button clicked')
        if (startIndex - 18 >= 0) {
            setStartIndex(prevIndex => prevIndex - 18);
        }
    };

    return (
        <div>

            <section className='vendor'>
                <div className='container'>
                    <Heading />
                    <PageLog/>
                    <div className='content'>

                        {/* {site === "Myblock" ? (
                            data1.slice(startIndex, startIndex + 18).map((item, i) => (

                                if (portal_img1[i] === undefined) {
                                    portal_img1[i] = 0;
                                  }
                                
                                <Card2 key={i} item={item} data={data2[i]} portal_img={portal_img1[i]} />
                            ))
                        ) : site === "Techie-Index" ? (
                            data4.slice(startIndex, startIndex + 18).map((item, i) => (
                                if (portal_img2[i] === undefined) {
                                    portal_img2[i] = 0;
                                  }
                                <Card2 key={i} item={item} data={data3[i]} portal_img={portal_img2[i]} />
                            ))
                        ) : null} */}

                        {
                            site === "Myblock"
                                ? data1.slice(startIndex, startIndex + 18).map((item, i) => {
                                    if (portal_img1[i] === undefined) {
                                        portal_img1[i] = 0;
                                    }
                                    return <Card2 key={i} item={item} data={data2[i]} portal_img={portal_img1[i]} />;
                                })
                                : site === "Techie-Index"
                                    ? data4.slice(startIndex, startIndex + 18).map((item, i) => {
                                        if (portal_img2[i] === undefined) {
                                            portal_img2[i] = 0;
                                        }
                                        return <Card2 key={i} item={item} data={data3[i]} portal_img={portal_img2[i]} />;
                                    })
                                    : null
                        }

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='left-buttons'>
                            <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        </div>
                        <div className='archives-link'>
                            <p align="center"  >
                                <a href={`http://61.2.142.91:8400/CityFromId/${port3}`}  >Archives</a>
                            </p>
                        </div>
                        <div className='right-buttons'>
                            <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 18 >= maxIndex}>Next</button>
                        </div>

                    </div>

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        <p align="center"  >
                            <a href={`http://61.2.142.91:8400/CityFromId/${port3}`}  >Archives</a>
                        </p>

                        <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 3 >= maxIndex}>Next</button>
                    </div> */}
                </div>
            </section>


        </div>
    )
}

export default AllSpotlights



