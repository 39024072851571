import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeadingD from './HeadingVend';
import './style.css';
import UserContent from './UserContent';
import ImageList from './ImageList';
import Ranking from './Ranking';
import { useNavigate, NavLink } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';

const AllUserContent = () => {
    const [data1, setData] = useState([]);
    const [data2, SetImage1] = useState([]);
    const [data3, SetImage2] = useState([])
    const [startIndex, setStartIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    let site = sessionStorage.getItem('site');
    let port1 = sessionStorage.getItem('portalid');
    let parentport = sessionStorage.getItem('parentportalid');
    const navigate = useNavigate();

    useEffect(() => {
        axios.all([
            axios.post('/news/headlines/usercontent/all', { "port1": port1, "parentport": parentport }),
            axios.post(`/myblock/altimage/usercontent`),
            axios.post(`/techieindex/altimage/usercontent`),

        ])
            .then(axios.spread((data1, data2, data3) => {
                console.log('Article data1', data1);
                console.log('Article data2', data2);

                setData(data1.data);
                SetImage1(data2.data);
                SetImage2(data3.data)
                setMaxIndex(data1.data.length);
            }));
    }, [port1, parentport]);

    const handleNextClick = () => {
        if (startIndex + 18 < maxIndex) {
            setStartIndex(prevIndex => prevIndex + 18);
        }
    };

    const handlePrevClick = () => {
        if (startIndex - 18 >= 0) {
            setStartIndex(prevIndex => prevIndex - 18);
        }
    };

    const handleTitleClick = (title) => {
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'auto',
        // });

        // if (site === 'Techie-Index' && port1 === '10231') {
        //     navigate('/imagelist'); // Navigate to ImageList when port1 is '10231'
        // } else if (site === 'Techie-Index' && port1 === '9455') {
        //     navigate('/Ranking'); // Navigate to Ranking when port1 is '9455'
        //     navigate('/UsaVC');
        // }

        window.scrollTo({
            top: 0,
            behavior: 'auto',
        });

        if (site === 'Techie-Index' && port1 === '10231') {
            navigate('/imagelist');
        } else if (site === 'Techie-Index' && port1 === '9455') {
            if (title === 'Startup Ranking 2023') {
                navigate('/Ranking');
            } else if (title === 'VC Firm Ranking 2023') {
                navigate('/UsaVC');
            }
        } else if (site === 'Techie-Index' && port1 === '10226') {
            navigate('/H1b');
        } else if (site === 'Techie-Index' && port1 === '9458') {
            navigate('/CanadaUniversity');
        }

    };

    // Default rendering for other cases
    return (
        <div>
            <PageLog/>
            <section className='vendor'>
                <div className='container'>
                    <HeadingD />
                    <div className='content'>

                        {site === 'Techie-Index' && port1 === '10231' && (

                            <div className='box' onClick={() => handleTitleClick('Ranking list of 2021')}>
                                <div className='ima'>
                                    <img src="../images/Techieindex/UserContent/icampus.jpeg" alt="" />
                                </div>
                                <h3>Ranking list of 2021</h3>
                            </div>
                        )}
                        {/* 
                        {site === 'Techie-Index' && port1 === '9455' && (

                            <div className='box' onClick={handleTitleClick}>
                                <div className='ima'>
                                    <img src="../images/Techieindex/UserContent/statupusa.png" alt="" />
                                </div>
                                <h3>Startup Ranking2023</h3>
                            </div>
                        )} */}


                        {site === 'Techie-Index' && port1 === '9455' && (
                            <div className='box' onClick={() => handleTitleClick('Startup Ranking 2023')}>
                                <div className='ima'>
                                    <img src="../images/Techieindex/UserContent/statupusa.png" alt="" />
                                </div>
                                <h3>Startup Ranking 2023</h3>
                            </div>
                        )}

                        {site === 'Techie-Index' && port1 === '9455' && (
                            <div className='box' onClick={() => handleTitleClick('VC Firm Ranking 2023')}>
                                <div className='ima'>
                                    <img src="../images/Techieindex/UserContent/vc.webp" alt="" />
                                </div>
                                <h3>VC Firm Ranking 2023</h3>
                            </div>
                        )}
                        {site === 'Techie-Index' && port1 === '10226' && (
                            <div className='box' onClick={() => handleTitleClick('H1B Sponsors')}>
                                <div className='ima'>
                                    <img src="../images/Techieindex/UserContent/h1b.jpg" alt="" />
                                </div>
                                <h3>H1B Sponsors Ranking 2023</h3>
                            </div>
                        )}
                        {site === 'Techie-Index' && port1 === '9458' && (
                            <div className='box' onClick={() => handleTitleClick('canada university')}>
                                <div className='ima'>
                                    <img src="../images/Techieindex/UserContent/canada-uni.webp" alt="" />
                                </div>
                                <h3>Canada University Ranking 2023</h3>
                            </div>
                        )}



                        {data1.slice(startIndex, startIndex + 18).map((item, i) => {
                            if (data2[i] === undefined) {
                                data2[i] = 0;
                            }
                            if (data3[i] === undefined) {
                                data3[i] = 0;
                            }

                            if (item === undefined) {
                                item = 0;
                            }

                            if (site === 'Myblock') {
                                return <UserContent key={i} item={item} data={data2[i]} />;
                            } else if (site === 'Techie-Index') {
                                return <UserContent key={i} item={item} data={data3[i]} />;
                            }
                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='left-buttons'>
                            <button
                                className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`}
                                onClick={handlePrevClick}
                                disabled={startIndex === 0}
                            >
                                Previous
                            </button>
                        </div>
                        <div className='archives-link'>
                            <p align='center'>
                                <a href={`http://61.2.142.91:8400/CityFromId/${port1}`}>Archives</a>
                            </p>
                        </div>
                        <div className='right-buttons'>
                            <button
                                className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`}
                                onClick={handleNextClick}
                                disabled={startIndex + 18 >= maxIndex}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

};

export default AllUserContent;