import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function FacebookUrlGoogleScrapper() {


    const [keyword, setKeywords] = useState('');
    const [count, setCount] = useState('');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    //const [type, setType] = useState('page'); // Added state for type
    const [batch, setBatch] = useState(null);
    const navigate = useNavigate()
    const type ='page';

    const handleSubmit = async (e) => {
        //e.preventDefault();
        setLoading(true);
        //setData(null); // Clear old data
        setError(null); // Clear old error
        try {
            const trimmedKeyword = keyword.trim(); // Trim spaces from start and end
            const encodedKeyword = encodeURIComponent(trimmedKeyword.replace(/\s+/g, '+'));
            const response = await axios.get(`/google/pagegrouplink/scrape?keyword=${encodedKeyword}&count=${count}&type=${type}`);
            setData(response.data.insertResult.insertedData);
            console.log('response', response.data.insertResult.insertedData[0].batchNum);
            setBatch(response.data.insertResult.insertedData[0].batchNum)
            setLoading(false);
            alert(response.data.response)
        } catch (error) {
            setError(error.response.data.error);
            setLoading(false);
        }
    };

    const handleSubmitcount = async (e) => {
        //e.preventDefault();
        setLoading(true);
        //setData(null); // Clear old data
        setError(null); // Clear old error
        try {
          
            const response = await axios.get(`/google/pageanalytics?batch=${batch}`);
            setData(response.data.insertResult);            
            setLoading(false);
            alert(response.data.response)
            navigate(`/displayanalytics?id=${batch}`);

        } catch (error) {
            setError(error.response.data.error);
            setLoading(false);
        }
    };
    console.log('data', data);
    return (
        <div>
            <div>
                <input type="text" value={keyword} onChange={(e) => setKeywords(e.target.value)} placeholder="Enter Google text,avoid special characters e.g. Facebook Pages of builders in Ernakulam " />
                <input type="text" value={count} onChange={(e) => setCount(e.target.value)} placeholder="Enter Count of google links that need to be examined,try keeping this number small" />
                {/* <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="page">Page</option>
                    <option value="group">Group</option>
                </select> */}
                <button type="submit" onClick={handleSubmit}>Submit</button>
            </div>
          
            {error && <p>Error: {error}</p>}
            {data && (
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>URL</th>
                            <th>type</th>
                            {/* <th>follower count</th>
                            <th>Page Likes</th>
                            <th>Info about errors or issues</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.href}</td>
                                <td>{item.type}</td>
                                {/* <td>{item.followercount}</td>
                                <td>{item.pagelikes}</td>
                                <td>{item.comments}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
              {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." />} {/* Display the GIF */}
            {data &&
                (<button type="submit" onClick={handleSubmitcount}>Generate page followers/likes count</button>)
            }

        </div>
    );
}

export default FacebookUrlGoogleScrapper