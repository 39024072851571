import { Button } from 'bootstrap'
import React,{useRef} from 'react'
import './TechieIndexLatest.css'

const MaterialTechie  = ({portal:{id,portalname,portalid,parentportalid}})  => {

  //console.log("portname",portalname);
  //console.log("portid",portalid);
  const data = useRef();

    const setPortalid =()=>{
        // console.log(PORTALID)
        sessionStorage.setItem("portalid",portalid)
        sessionStorage.setItem("city",portalname)
        if( parentportalid=='' )
        {
          console.log("error in material",parentportalid);
          parentportalid=0;
        }
        sessionStorage.setItem("parentportalid",parentportalid)
        window.location.reload();
        
    }
    
  return (
    <div >
     
      <div>
      <button className='button' onClick={setPortalid}>{portalname}</button>
      </div>
      
 
    </div>
  )
}

export default MaterialTechie
