import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import './instagrouplistshedule.css';
import InstaPageCheckSchedule from './InstaPageCheckSchedule';

function InstaGroupListShedule(props) {

    const { bardStory, id } = props;
    // console.log("insta group props", bardStory, id)

    const portalid = Cookies.get("portalid");
    const firmid = Cookies.get("firmid");
    const facebook_token = Cookies.get("facebook_token");
    const [grouplist, setGroupList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUrls, setSelectedUrls] = useState([]);
    const userid = Cookies.get("userid")
    //const extractedIDs = [];
    const [extractedIDs, setExtractedIDs] = useState([]);
    const [textAreaValue, setTextAreaValue] = useState('');




    useEffect(() => {
        getlist();
    }, []);

    const getlist = () => {

        axios.post('/group/list/for_insta', { "userid": userid, "firmid": firmid })
            .then(function (response) {
                // console.log("group list", response.data);
                setGroupList(response.data)
            })
    }

    useEffect(() => {
        if (selectAll) {
            setSelectedRows(grouplist.map(item => item.ID));
        } else {
            setSelectedRows([]);
        }
    }, [selectAll, grouplist]);


    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
    };


    const toggleSelectRow = (rowId) => {
        if (selectedRows.includes(rowId)) {
            // Checkbox is checked, remove the rowId from selectedRows
            setSelectedRows([]);

            // Clear the extractedIDs array
            setExtractedIDs([]);
        } else {
            // Checkbox is unchecked, set selectedRows with the clicked rowId
            setSelectedRows([rowId]);

            // Clear the extractedIDs array
            setExtractedIDs([]);
        }
    };



    useEffect(() => {
        const selectedUrls = grouplist
            .filter(item => selectedRows.includes(item.ID))
            .map(item => ({
                SOCIAL_URL: item.SOCIAL_URL,
            }));
        setSelectedUrls(selectedUrls);

        if (selectedRows.length > 0) {
            const extractedIDs = selectedUrls.map((selectedData) => {
                const match = selectedData.SOCIAL_URL.match(/\d+/);
                return match ? { group_page_id: match[0] } : null;
            }).filter(Boolean);

            setExtractedIDs(extractedIDs);
        } else {
            // Clear extractedIDs when no checkboxes are checked
            setExtractedIDs([]);
        }

    }, [selectedRows, grouplist]);


    // console.log("selectedUrls", selectedUrls)

    // selectedUrls.forEach((selectedData) => {
    //     // Use a regular expression to match numbers in the URL
    //     const match = selectedData.SOCIAL_URL.match(/\d+/);
    //     // Check if a match was found
    //     if (match) {

    //         // Extracted number is in match[0]
    //         const extractedNumberObject = { group_page_id: match[0] };

    //         extractedIDs.push(extractedNumberObject);
    //     }
    // });


    console.log("extractedIDs", extractedIDs)



    return (
        <div>
            <h3>Instagram Scheduling</h3>

            <textarea
            className='instagrouplistshedule-textarea'
                value={textAreaValue}
                onChange={(e) => setTextAreaValue(e.target.value)}
                placeholder="Enter your Instagram post caption here"
            />
            <h2>Select a page associated with instagram account(details will show on selecting a page)</h2>
            <table className="content-table">
                <thead>
                    <tr>
                        <th className="checkbox-header">

                            {/* <input
                            type="checkbox"
                            onChange={toggleSelectAll}
                            checked={selectAll}
                        /> */}

                        </th>
                        <th>ID</th>
                        <th>Group/Page Url</th>
                        <th>Group/Page Description</th>

                    </tr>
                </thead>
                <tbody>
                    {grouplist.map((item) => (
                        <tr key={item.ID}>
                            <td className="checkbox-column">
                                <input
                                    type="checkbox"
                                    onChange={() => toggleSelectRow(item.ID)}
                                    checked={selectedRows.includes(item.ID)}
                                />
                            </td>
                            <td>{item.ID}</td>
                            <td>{item.SOCIAL_URL}</td>
                            <td>{item.URL_NAME}</td>

                        </tr>
                    ))}
                </tbody>
            </table>

            {extractedIDs.length > 0 && (
                <InstaPageCheckSchedule bardStory={textAreaValue} id={id} extractedIDs={extractedIDs} />
            )}
            {/* <InstaPageCheckSchedule bardStory={bardStory} id={id} extractedIDs={extractedIDs} /> */}
        </div>
    )
}

export default InstaGroupListShedule