



import React, { useEffect, useState } from 'react';
import './interview.css'



function Interviewjoe_alsop() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content from the same server
    fetch('/Interview/joe_alsop.html')
      .then(response => response.text())
      .then(data => setHtmlContent(data));
  }, []);

  return (
    <div className='interview-container'>
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>

  );
}

export default Interviewjoe_alsop;