import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './AllNews.css';
import Content from './Content';
import HeadingNews from './HeadingNews';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';

const AllNews = () => {

  const [data1, setData] = useState([])
  const [data2, SetImage1] = useState([])
  const [data3, SetImage2] = useState([])
  const [portal_img1, SetPortalImg1] = useState([])
  const [portal_img2, SetPortalImg2] = useState([])
  const [startIndex, setStartIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  let port = sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")
  let site = sessionStorage.getItem("site")
  console.log("myport", port)

  useEffect(() => {

    axios.all([
      axios.post(`/news/headlines/entertainment/more`, {
        "port": port, "parentport": parentport
      }),
      axios.post(`/myblock/altimage/news`),
      axios.post(`/techieindex/altimage/news`),
      axios.post(`/myblock/altimage/news/portalid`, { "port": port }),
      axios.post(`/techieindex/altimage/news/portalid`, { "port": port })
    ])
      .then(axios.spread((data1, data2, data3,data4,data5) => {
        // output of req.
        console.log('data1', data1, 'data2', data2, 'data3', data3)

        setData(data1.data)
        SetImage1(data2.data)
        SetImage2(data3.data)
        SetPortalImg1(data4.data)
        SetPortalImg2(data5.data)
        setMaxIndex(data1.data.length);

      }));

  }, []);

  const handleNextClick = () => {
    //console.log('Next button clicked')
    if (startIndex + 21 < maxIndex) {
      setStartIndex(prevIndex => prevIndex + 21);
    }
  };

  const handlePrevClick = () => {
    //console.log('Previous button clicked')
    if (startIndex - 21 >= 0) {
      setStartIndex(prevIndex => prevIndex - 21);
    }
  };



  return (
    <div>

      
<section>
        <div className='container'>
          <HeadingNews />
          <PageLog/>
          <section className='hero_news'>
            <div className='container'>
              {/* Conditional rendering based on viewport width */}
              {window.innerWidth <= 768 ? (
                // Display 4 news items for mobile view
                data1.slice(startIndex, startIndex + 4).map((item, i) => {
                  if (portal_img1[i] === undefined) {
                    portal_img1[i] = 0;
                  }
                  if (portal_img2[i] === undefined) {
                    portal_img2[i] = 0;
                  }
                  if (site === "Myblock") {
                    return <Content key={i} item={item} data={data2[i]} portal_img={portal_img1[i]} />;
                  } else if (site === "Techie-Index") {
                    return <Content key={i} item={item} data={data3[i]} portal_img={portal_img2[i]} />;
                  }
                  return null;
                })
              ) : (
                // Display 21 news items for desktop view
                data1.slice(startIndex, startIndex + 21).map((item, i) => {
                  if (portal_img1[i] === undefined) {
                    portal_img1[i] = 0;
                  }
                  if (portal_img2[i] === undefined) {
                    portal_img2[i] = 0;
                  }
                  if (site === "Myblock") {
                    return <Content key={i} item={item} data={data2[i]} portal_img={portal_img1[i]} />;
                  } else if (site === "Techie-Index") {
                    return <Content key={i} item={item} data={data3[i]} portal_img={portal_img2[i]} />;
                  }
                  return null;
                })
              )}
            </div>
          </section>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <div className='left-buttons'>
              <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
            </div>
            <div className='archives-link'>
              <p align="center"  >
                <a href={`http://61.2.142.91:8400/CityFromId/${port}`}  >Archives</a>
              </p>
            </div>
            <div className='right-buttons'>
              <button className={`btn btn-primary ${startIndex + 21  >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 21  >= maxIndex}>Next</button>
            </div>

          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>

            <p align="center"  >
              <a href={`http://61.2.142.91:8400/CityFromId/${port}`}  >Archives</a>
            </p>

            <button className={`btn btn-primary ${startIndex + 21 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 21 >= maxIndex}>Next</button>
          </div> */}
        </div>
      </section>


    </div>
  )
}

export default AllNews


