import React from 'react'
import Homepages from '../Home/Homepages'
import Home from '../Home/Main/homes/Home'
import News from '../Home/News/News'
import Vendor from '../Home/vendor/Vendor'
import Home_techie from './homes_techie/Home_techie'
import News_techie from './News/News_techie'
import News_press from './Press_news/Press_news_techie'




const Techie_index = () => {
  return (
    <div>
      <News />
      <Home_techie />
      <News_techie />
      <News_press />
      <Vendor />
    </div>
  )
}

export default Techie_index
