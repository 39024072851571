import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './style.css';



function ChildLinks() {

    const [myblock, setmyblock] = useState([])
    const [techieindex, settechieindex] = useState([])
    const [health, sethealth] = useState([])
    // let port = sessionStorage.getItem("portalid")
    let site = sessionStorage.getItem("site")
    let inside_site = sessionStorage.getItem("inside_site")
    // console.log("portal id in extra",id)
    // if (id === null) {
    //   id = 0
    // }
    console.log("inside_site",inside_site)
    //debugger
    useEffect(() => {

        axios.all([

            axios.post('/top/myblock/links'),
            axios.post(`/top/techieindex/links`),
            axios.post(`/top/health/links`)

        ])
            .then(axios.spread((data1, data2,data3) => {
                // output of req.
                //console.log("data1 & data2", data1, data2)

                setmyblock(data1.data)
                settechieindex(data2.data)
                sethealth(data3.data)

            }));

    }, []);


    // var currentUrl = window.location.href;
    // var newUrl = currentUrl.substring(0, currentUrl.indexOf("/") + 1);

    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const newUrl = urlObject.origin;

    return (
        <div>

            <br />

            <div className={ site === "Myblock" ? "container" : "tab-container"}>
                {
                    (site === "Myblock" && inside_site === " ") ? (

                        myblock.map((portal, index) => (
                            <React.Fragment key={portal.portalid}>
                                <a href={newUrl + "/CityFromId/" + portal.portalid} className="ahref">{portal.headerdisplay}</a>
                                {index !== myblock.length - 1 && <span className="space"> </span>}
                            </React.Fragment>
                        ))
                    ) : site === "Techie-Index" ? (
                        techieindex.map((portal, index) => (
                                <React.Fragment key={portal.portalid}>
                                    <a href={newUrl + "/CityFromId/" + portal.portalid} className="tab">{portal.headerdisplay}</a>
                                    {index !== techieindex.length - 1 && <span className="space"> </span>}
                                </React.Fragment>
                        ))

                    ) : (site === "Myblock" && inside_site === "health") ? (

                        health.map((portal, index) => (
                            <React.Fragment key={portal.portalid}>
                                <a href={newUrl + "/CityFromId/" + portal.portalid} className="ahref">{portal.headerdisplay}</a>
                                {index !== health.length - 1 && <span className="space"> </span>}
                            </React.Fragment>
                        ))
                    ) : null

                }
            </div>

        </div>
    )
}

export default ChildLinks


