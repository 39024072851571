import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './addleaders.css';
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';

function AddLeaders() {

  const [leaderData, setLeaderData] = useState({

    interviewPerson: '',
    designation: '',
    companyName: '',
    aboutPerson: '',
  });
  const [leaderAxios, setLeaderAxios] = useState({});

  const [imageFile, setImageFile] = useState(null);
  let portalid = sessionStorage.getItem("portalid")
  let parentportalid = sessionStorage.getItem("parentportalid")
  const userid = Cookies.get("userid")
  let flag = 'adddata';

  // console.log("userid", userid)

  useEffect(() => {
    setdata();
  }, []);


  const setdata = () => {
    axios.post('/spotlight/companies/name', { "id": userid })
      .then(function (response) {
        // console.log("leaders", response.data);

        setLeaderAxios(response.data)
        if (response.data.length >= 1) {
          setLeaderData({
            interviewPerson: response.data[0].interviewPerson,
            designation: response.data[0].designation,
            companyName: response.data[0].companyName,
            aboutPerson: response.data[0].aboutPerson
          })
        }


      })
  };


  const handleChange = (field, value) => {
    setLeaderData({
      ...leaderData,
      [field]: value
    });
  };

  const finalpath = 'leaders';

  const handleAdd = (event) => {
    // event.preventDefault()
    const formData = new FormData();
    formData.append('NAME', leaderData.interviewPerson);
    formData.append('DESIGNATION', leaderData.designation);
    formData.append('COMP_NAME', leaderData.companyName);
    formData.append('SHORT_DESC', leaderData.aboutPerson);
    formData.append('finalpath', finalpath);
    formData.append('portalid', portalid);
    formData.append('parentportalid', parentportalid);
    formData.append('userid', userid);
    formData.append('image', imageFile);

    //debugger
    console.log("leaderData", formData)
    console.log("imageFile", imageFile)
    alert("hi")
    if (flag === 'adddata') {
      //console.log("adddata")
      axios.post('/addleadersdata', formData)
        .then(response => {
          // Handle the response as needed
          alert(response.data.message)
          console.log(response.data.message)
        })
        .catch(error => {
          // Handle any errors
        });
    }
    if (flag === 'update') {
      //console.log("update")


    }
    setdata();
    window.location.reload();

    // setLeaderData({
    //   NAME: '',
    //   DESIGNATION: '',
    //   COMP_NAME: '',
    //   SHORT_DESC: ''
    // });
    // setImageFile(null);
  };

  const handleUpdate = (event) => {
    event.preventDefault()
    flag = 'update'
    //handleAdd(event);

    const formData = new FormData();
    formData.append('NAME', leaderData.interviewPerson);
    formData.append('DESIGNATION', leaderData.designation);
    formData.append('COMP_NAME', leaderData.companyName);
    formData.append('SHORT_DESC', leaderData.aboutPerson);
    formData.append('finalpath', finalpath);
    formData.append('portalid', portalid);
    formData.append('parentportalid', parentportalid);
    formData.append('userid', userid);
    formData.append('image', imageFile);


    console.log("leaderData in update", formData)
    console.log("leaderData in update", leaderData)
    // alert("hi")

    axios.post('/updateleadersdata', formData)
      .then(response => {
        // Handle the response as needed
        alert(response.data.message)
        console.log(response.data.message)
      })
      .catch(error => {
        // Handle any errors
      });
  }




  function handleImageUpload(e) {
    const file = e.target.files[0]; // Get the first selected file

    if (file) {
      setImageFile(file);

    } else {
      setImageFile(null);
    }
  }


  return (
    <div>
      <br /><br />

      {leaderAxios.length === 1 ?
        (


          <div className='addleaders-form-container'>
            <PageLog />
            {/* {console.log("leaderData in return", leaderData)} */}
            <h2>Update My information</h2>
            <table>
              <tbody>

                <tr>
                  <td>
                    <input
                      type="text"
                      value={leaderData.interviewPerson}
                      onChange={(e) => handleChange("interviewPerson", e.target.value)}
                      placeholder='Name'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      value={leaderData.designation}
                      onChange={(e) => handleChange("designation", e.target.value)}
                      placeholder='Designation'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      value={leaderData.companyName}
                      onChange={(e) => handleChange("companyName", e.target.value)}
                      placeholder='Company Name'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <textarea
                      value={leaderData.aboutPerson}
                      onChange={(e) => handleChange("aboutPerson", e.target.value)}
                      placeholder='Short Description'
                      className='addleaders-textarea'

                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <button className="addleaders-button" onClick={handleUpdate}>Update</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>) : (

          <div className='addleaders-form-container'>

            {/* {console.log("leaderData in return", leaderData.length)} */}
            <h2>Add My information</h2>
            <table>
              <tbody>

                <tr>
                  <td>
                    <input
                      type="text"
                      value={leaderData.interviewPerson}
                      onChange={(e) => handleChange("interviewPerson", e.target.value)}
                      placeholder='Name'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      value={leaderData.designation}
                      onChange={(e) => handleChange("designation", e.target.value)}
                      placeholder='Designation'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      value={leaderData.companyName}
                      onChange={(e) => handleChange("companyName", e.target.value)}
                      placeholder='Company Name'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <textarea
                      value={leaderData.aboutPerson}
                      onChange={(e) => handleChange("aboutPerson", e.target.value)}
                      placeholder='Short Description'
                      className='addleaders-textarea'

                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <button className="addleaders-button" onClick={handleAdd}>Add</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )

      }
      <br /><br />
    </div>
  );
}

export default AddLeaders;