import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import InstaPageCheck from './InstaPageCheck';
import { useNavigate } from 'react-router-dom';

function InstaGroupList(props) {

    const { bardStory, id } = props;
    const portalid = Cookies.get("portalid");
    const firmid = Cookies.get("firmid");
    const facebook_token = Cookies.get("facebook_token");
    const [grouplist, setGroupList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUrls, setSelectedUrls] = useState([]);
    const userid = Cookies.get("userid")
    const extractedIDs = [];
    const [pagelist, setPagelist] = useState([]);
    const navigate = useNavigate();
    const facebook_status = Cookies.get("facebook_status")

    useEffect(() => {
        //getlist();
        getpagelist();
    }, [facebook_token]);



    const getpagelist = async () => {

        let updatedPagelist = [];

        // if(facebook_token===undefined && facebook_status===2){
        //     //debugger
        //    // window.location.reload();
        //    navigate(`/approvestory`)
        // }


        try {
            const response = await axios.get(`https://graph.facebook.com/v19.0/me?access_token=${facebook_token}`);
            console.log("response in me", response);

            const myid = response.data.id;

            const pagelist_response = await axios.get(`https://graph.facebook.com/v19.0/${myid}/accounts?access_token=${facebook_token}`);
            console.log("response in pagelist_response", pagelist_response.data.data);

            const data = pagelist_response.data.data;
            //debugger
            updatedPagelist = data.map(page => ({
                group_page_id: page.id,

            }));
            setPagelist(updatedPagelist);
            // debugger
            console.log("Pagelist: in insta 1st", updatedPagelist);

            console.log("Pagelist: in insta ", pagelist);

        }
        catch (error) {
            console.log(error);
        }
    }


    // console.log("Pagelist: in insta outside ", pagelist);


    // const getlist = () => {

    //     axios.post('/group/list/for_insta', { "userid": userid, "firmid": firmid })
    //         .then(function (response) {
    //             console.log("group list in instagram", response.data);
    //             setGroupList(response.data)
    //         })
    // }

    // useEffect(() => {
    //     if (selectAll) {
    //         setSelectedRows(grouplist.map(item => item.ID));
    //     } else {
    //         setSelectedRows([]);
    //     }
    // }, [selectAll, grouplist]);




    // const toggleSelectAll = () => {
    //     setSelectAll(!selectAll);
    // };




    // const toggleSelectRow = (rowId) => {
    //     if (selectedRows.includes(rowId)) {
    //         setSelectedRows(selectedRows.filter(id => id !== rowId));
    //     } else {
    //         setSelectedRows([...selectedRows, rowId]);
    //     }

    // };


    // useEffect(() => {
    //     const selectedUrls = grouplist
    //         .filter(item => selectedRows.includes(item.ID))
    //         .map(item => ({
    //             SOCIAL_URL: item.SOCIAL_URL,
    //         }));
    //     setSelectedUrls(selectedUrls);



    // }, [selectedRows, grouplist]);


    // console.log("selectedUrls", selectedUrls)





    // selectedUrls.forEach((selectedData) => {
    //     // Use a regular expression to match numbers in the URL
    //     const match = selectedData.SOCIAL_URL.match(/\d+/);
    //     // Check if a match was found
    //     if (match) {

    //         // Extracted number is in match[0]
    //         const extractedNumberObject = { group_page_id: match[0] };

    //         extractedIDs.push(extractedNumberObject);
    //     }
    // });








    // console.log("extractedIDs", extractedIDs)



    return (
        <div>

            {/* <table className="content-table">
                <thead>
                    <tr>
                        <th className="checkbox-header"> <input
                            type="checkbox"
                            onChange={toggleSelectAll}
                            checked={selectAll}
                        /></th>
                        <th>ID</th>
                        <th>Instagram User Name</th>
                        <th>Page Url</th>


                    </tr>
                </thead>
                <tbody>
                    {grouplist.map((item) => (
                        <tr key={item.ID}>
                            <td className="checkbox-column">
                                <input
                                    type="checkbox"
                                    onChange={() => toggleSelectRow(item.ID)}
                                    checked={selectedRows.includes(item.ID)}
                                />
                            </td>
                            <td>{item.ID}</td>
                            <td>{item.URL_NAME}</td>
                            <td>{item.SOCIAL_URL}</td>


                        </tr>
                    ))}
                </tbody>
            </table> */}

            {/* <InstaPageCheck bardStory={bardStory} id={id} extractedIDs={extractedIDs}/> */}
            <InstaPageCheck bardStory={bardStory} id={id} extractedIDs={pagelist} />

        </div>
    )
}

export default InstaGroupList