import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './uploadmultipieimages.css'
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';
import FacebookLogin_dev from '../ImageUpload/FacebookLogin_dev';

function UploadMultipleImages() {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [uploading, setUploading] = useState(false);
  // const [showMessage, setShowMessage] = useState(false);
  const userid = Cookies.get("userid")
  const portalid = Cookies.get("portalid")
  const firmid = Cookies.get("firmid")
  const finalpath = `${portalid}/${userid}`;
  const [mid, setMultipleId] = useState('');
  const navigate = useNavigate()
  const facebook_status = Cookies.get("facebook_status")
  const facebook_token = Cookies.get("facebook_token")

  console.log("facebook_token", facebook_token)
  console.log("facebook_status in multi", facebook_status)


  useEffect(() => {

    createfolder();

  }, []);

  const createfolder = async () => {
    try {
      const response = await axios.post('/create/folder/session', { "portalid": portalid, "userid": userid });
      console.log("result", response);
    }
    catch (error) {
      console.log(error);
    }
  };


  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (selectedFiles && selectedFiles.length > 13) {
      alert('For Now, You can upload a maximum of 13 images only.');
      setSelectedFiles(null);
      return;
    }

    const formData = new FormData();
    formData.append('finalpath', finalpath);
    formData.append('userid', userid);
    formData.append('portalid', portalid);
    formData.append('firmid', firmid);

    for (const file of selectedFiles) {
      formData.append('images', file);
    }
    // formData.append('images', selectedFiles);


    try {
      setUploading(true);
      const response = await axios.post('/upload/multiple/images', formData,);


      const { message, newUserId } = response.data;
      console.log('Server response:', response.data);
      console.log('newUserId', newUserId);
      setMultipleId(newUserId);

      setUploading(false);
      setSelectedFiles(null);
      // setShowMessage(true);

      if (newUserId) {
        setMultipleId(newUserId);
        // Log the value after setting
      } else {
        console.error('newUserId is invalid:', newUserId);
      }



    } catch (error) {
      console.error('Error uploading files:', error);
    }
    finally {

    }
  };

  useEffect(() => {
    if (mid) {
      console.log('mid after setMultipleId:', mid);
      alert("UPLOADED,navigating to images page")
      navigate(`/viewmultipleimages?mid=${mid}`);
    }
  }, [mid]);

  // console.log("showMessage", showMessage)

  return (
    <div className='uploadmultipieimages-content-container'>
      <PageLog />

      {['0', '1'].includes(facebook_status) && <FacebookLogin_dev />}

      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploading && (
        <div>
          <p>Uploading...</p>
          <img
            src="../images/website_images/Spinner-1s-200px.gif"
            alt="Loading spinner"
            style={{ width: '50px', height: '50px' }}
          />
        </div>
      )}
      <br /><br />

      {/* {showMessage && (
        <div style={{ color: 'green' }}>
          Images successfully uploaded!
        </div>
      )} */}

      {/* <div className="centered-link-container">

      <Link to={`/viewmultipleimages?mid=${mid}`}>View Images</Link>

        

      </div> */}





    </div>
  );
}

export default UploadMultipleImages;