import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import Cookies from 'js-cookie';
import './ViewHistory.css';

const ViewHistory = () => {
    const [emails, setEmails] = useState([]);
    const [openGroup, setOpenGroup] = useState(null); // Track which group is open
    const userid = Cookies.get("userid")
    const usertype = Cookies.get("usertype")
    const navigate = useNavigate(); // Use useNavigate for navigation

    useEffect(() => {
        if (!userid) {
          alert("Please login");
          navigate('/login');
        } else if (usertype === "USERAPP") {
          alert("Login with Businuess User Credentials");
          navigate('/login');
        }
      }, [userid, navigate]);
    


    useEffect(() => {
        // Fetch email history grouped by BATCH_NUM and GROUPID
        axios
            .get('/api/emails/grouped')
            .then((response) => {
                setEmails(response.data);
            })
            .catch((error) => {
                console.error('There was an error fetching the email data!', error);
            });
    }, []);

    const handleClick = (key, groupId, batchNum) => {
        setOpenGroup(openGroup === key ? null : key); // Toggle open/close
        // Navigate to ViewSentEmails when the group is clicked
        if (openGroup !== key) {
            navigate(`/viewsentemails?groupid=${groupId}&batch=${batchNum}`); // Use navigate instead of history.push
        }
    };
    
    const renderEmailGroup = (batchNum, groupId, groupName, groupEmails) => {
        const key = `${batchNum}-${groupId}`; // Create unique key for the group
        const email = groupEmails[0]; // Assuming the first email represents the group
    
        return (
            <div key={key} onClick={() => handleClick(key, groupId, batchNum)} className="email-group">
                <h3 className="email-group-title">
                    {groupName ? `Group Name: ${groupName}` : 'Group Name: N/A'}, 
                    {groupId === null ? ' Group ID: N/A' : ` Group ID: ${groupId}`}, 
                    {batchNum === null ? ' Batch: N/A' : ` Batch: ${batchNum}`} 
                </h3>
                <p className="email-group-count">
                    {`Emails: ${email.email_count}`}
                </p>
                <p>Click to view details</p>
    
                {openGroup === key && ( // Conditionally render details
                    <div className="email-details-container">
                        <h2>Email Details</h2>
                        <p><strong>Subject:</strong> {email.SUBJECT}</p>
                        <p><strong>Content:</strong> {email.CONTENT}</p>
                    </div>
                )}
            </div>
        );
    };
    
    const groupedEmails = emails.reduce((groups, email) => {
        const { BATCH_NUM, GROUPID, GROUP_NAME } = email; // Destructure GROUP_NAME
        const key = `${BATCH_NUM}-${GROUPID}`;

        if (!groups[key]) {
            groups[key] = { groupName: GROUP_NAME, emails: [] }; // Store group name and emails
        }
        groups[key].emails.push(email);

        return groups;
    }, {});

    return (
        <div className="view-history-container">
            <h1>Email History by Batch and Group</h1>
            {Object.entries(groupedEmails).map(([key, { groupName, emails }]) => {
                const [batchNum, groupId] = key.split('-');
                return renderEmailGroup(batchNum, groupId, groupName, emails); // Pass group name here
            })}
        </div>
    );
};

export default ViewHistory;
