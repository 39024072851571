import React, { useState, useEffect } from 'react';
import './health.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import PageLog from '../PageLog/PageLog';

function Myhealth() {
  // State variables
  const [portalid, setPortalId] = useState('');
  const [healthLink, setHealthLink] = useState('');
  const [showReport, setShowReport] = useState(false);
  const [showMyNewsContent, setShowMyNewsContent] = useState(true);

  // Add a new state variable to store the extracted numbers
  const [extractedNumbers, setExtractedNumbers] = useState('');

  // Function to retrieve portalid from cookies
  const retrievePortalId = () => {
    const retrievedPortalId = Cookies.get('portalid');
    if (retrievedPortalId) {
      console.log('Retrieved portalid:', retrievedPortalId);
      setPortalId(retrievedPortalId);
    } else {
      console.error('Please set the portalid cookie before using this app.');
    }
  };

  // Function to handle the My Health link click
  const handleMyHealthClick = (reportType) => {
    // Retrieve the user ID (userId) from the cookie
    const retrievedUserId = Cookies.get('userid');

    if (!retrievedUserId) {
      console.error('User ID not found in the cookie.');
      return;
    }

    console.log('Retrieved User ID:', retrievedUserId);

    // Make an Axios GET request to fetch the link based on the user ID and reportType
    axios
      .get(`/api/fetch-link?userId=${retrievedUserId}&reportType=${reportType}`)
      .then((response) => {
        const link = response.data.link;

        if (link) {
          console.log('Received value from the backend:', link);

          if (reportType === 'quick') {
            window.location.href = link;
          }
          else {

            // Extract the last numbers from the link using a regular expression
            const numbersMatch = link.match(/\d+$/);

            if (numbersMatch) {
              const extractedNumbers = numbersMatch[0];
              console.log('Extracted Numbers:', extractedNumbers);

              // Construct the new URL by appending the extracted numbers
              const newUrl = `https://www.mydoctorspot.com/care/c3/index.php/Doc_app/intellegent/${extractedNumbers}`;

              // Redirect the current window to the new URL
              window.location.href = newUrl;
            } else {
              console.error('No numbers found in the link.');
            }
          }

        } else {
          console.error('No valid link received from the backend.');
        }
      })
      .catch((error) => {
        console.error('Error fetching link:', error);
      });
  };

  // Call retrievePortalId when the component mounts
  useEffect(() => {
    retrievePortalId();
  }, []);

  return (
    <div className="health-container">
      <PageLog/>
      <div class="health-content">
        <nav className="health-nav">
          <a href="#" onClick={() => handleMyHealthClick('quick')}>Quick Report</a>
          <a href="#" onClick={() => handleMyHealthClick('intelligent')}>Intelligent Report</a>
        </nav>
      </div>
    </div>
  );
}

export default Myhealth;
