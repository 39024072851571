import React from 'react'
import './HeadingNews.css'

const HeadingNews = () => {
 // const getSite=sessionStorage.getItem("site")
  return (
    
    <div className='headingnews'>
    

    <h6>News</h6>
    </div>
  )
}

export default HeadingNews
