import React from 'react';
import { useNavigate } from 'react-router-dom';
import Singlepages from '../../singlepages/Singlepages';
import './style.css'




const Vendcontent = ({vend:{id,VEND_ID,image,VEND_DESC,VEND_TITL}}) => {

    const navigate= useNavigate()

    function goToVendorpage(){
     navigate("/vendorpage",{state : {id : VEND_ID}})
    }

  return (
    <>
    
    <div className='box'> 
         <div className='ima'>
            <img src={image} alt='' />
        </div>
             <h3 className='tittle' onClick={goToVendorpage}>{VEND_TITL}</h3>
             
    </div>
    
    </>
  )
}

export default Vendcontent

