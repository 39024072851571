import React from 'react';
import { useNavigate } from 'react-router-dom';
import Singlepages from '../../singlepages/Singlepages';
import './style.css'




const InterviewContent = ({vend:{intrvw_id,interviewPerson,photo }}) => {

    const navigate= useNavigate()

    function goToInterviewDetails(){
     navigate("/InterviewDetails",{state : {id : intrvw_id}})
    }

  return (
    <>
    
    <div className='box'> 
         <div className='ima'>
             <img src={photo} alt='' />
        </div>
             <h3 className='tittle' onClick={goToInterviewDetails}>{interviewPerson}</h3>
             
    </div>
    
    </>
  )
}

export default InterviewContent

