import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gap.css';
import Cookies from 'js-cookie';

const RoadmapAnalysis = () => {
  const [leaders, setLeaders] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [roadmap, setRoadmap] = useState(null);
  const userid = Cookies.get("userid"); // Get userid from cookies

  useEffect(() => {
    const fetchLeaders = async () => {
      try {
        const response = await axios.get('/get-leaders');
        setLeaders(response.data);
      } catch (error) {
        console.error('Error fetching leaders:', error);
      }
    };

    fetchLeaders();
  }, []);

  const handleRunRoadmapAnalysis = async () => {
    if (!selectedLeader) {
      setMessage('Please select a leader.');
      return;
    }

    setLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await axios.post('/run-roadmap', { leaderId: selectedLeader, userid: userid }); // Send userid along with selected leader ID
      console.log('Roadmap Analysis Response:', response.data);
      setRoadmap(response.data.roadmap);
      setMessage('Roadmap analysis completed successfully.');
    } catch (error) {
      console.error('Error running roadmap analysis:', error);
      setError('Error running roadmap analysis. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="roadmap-container">
      <h1>Roadmap Analysis</h1>
      <div className="form-group">
        <label htmlFor="leader-select">Select Leader:</label>
        <select
          id="leader-select"
          value={selectedLeader}
          onChange={(e) => setSelectedLeader(e.target.value)}
        >
          <option value="">--Select a Leader--</option>
          {leaders.map((leader) => (
            <option key={leader.id} value={leader.id}>
              {leader.name}
            </option>
          ))}
        </select>
      </div>
      <div className="button-group">
        <button onClick={handleRunRoadmapAnalysis} disabled={loading}>
          {loading ? 'Running...' : 'Run Roadmap Analysis'}
        </button>
      </div>
      {message && <p>{message}</p>}
      {error && <p className="error">{error}</p>}
      {roadmap && (
        <div className="roadmap-results">
          <h2>Roadmap Results</h2>
          <div>
            <h3>Education Roadmap:</h3>
            <ul>
              {roadmap.education.map((item, index) => (
                <li key={index}>
                  {item.action}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Skills Roadmap:</h3>
            <ul>
              {roadmap.skills.map((item, index) => (
                <li key={index}>
                  {item.action}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoadmapAnalysis;
