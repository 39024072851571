import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./pagelogdisplay.css"

function PageLogDisplay() {

    const[pageLogs, setPageLogs] = useState([]);

    useEffect(() => {
        const fetchPageLogs = async () => {
            try {
                const response = await axios.get('/fetch_pagelog_data'); // Assuming your backend API is served from the same origin
                setPageLogs(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPageLogs();
    }, []);

    return (
        <div className='pagelog-home'>
            <h1>Today's Page Logs</h1>
            <table className='pagelog-w1-table'>
                <thead>
                    <tr>
                        <th>User IP</th>
                        <th>Request URL</th>                        
                        <th>Visited By</th>
                        <th>Browser</th>
                        <th>Visited Date Time</th>
                        <th>LATITUDE</th>
                        <th>LONGITUDE</th>
                        <th>Locale</th>
                    </tr>
                </thead>
                <tbody>
                    {pageLogs.map((log) => (
                        <tr key={log._id}>
                            <td>{log.USER_IP}</td>
                            <td>{log.REQ_URL}</td>                            
                            <td>{log.VISITED_BY }</td>
                            <td>{log.BROWSER}</td>
                            <td>{new Date(log.VISITED_DATE_TIME).toLocaleString()}</td>
                            <td>{log.LATITUDE }</td>
                            <td>{log.LONGITUDE}</td>
                            <td>{log.LOCALE}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PageLogDisplay