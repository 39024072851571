import React,{useState,useEffect} from "react";
import ReactEcharts from "echarts-for-react";

const Chart2 = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetch("http://localhost:3000/message")
      .then((response) => response.json())
      .then((data) => setMessage(data));
  }, []);
  const option = {
    // title: {
    //   text: "Rainfall vs Evaporation",
    //   subtext: "Fake Data",
    // },
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Killed", "Injured"],
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    calculable: true,
    xAxis: [
      {
        type: "category",
        // prettier-ignore
        data: ['2014','2015','2016','2017'],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Killed",
        type: "bar",
        data: [
          message[0],message[1],message[2],message[3],
        ],
        markPoint: {
          data: [
            { type: "max", name: "Max" },
            { type: "min", name: "Min" },
          ],
        },
        markLine: {
          data: [{ type: "average", name: "Avg" }],
        },
      },
      {
        name: "Injured",
        type: "bar",
        data: [
          message[4],message[5],message[6],message[7],
        ],
        markPoint: {
          data: [
            { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
            { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 },
          ],
        },
        markLine: {
          data: [{ type: "average", name: "Avg" }],
        },
      },
    ],
  };
  return (
    <div>
      <ReactEcharts option={option} />
    </div>
  )
};

export default Chart2;
