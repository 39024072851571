import React, { useEffect, useState } from 'react';
import './CindexPythonTestScreen.css';
import CindexPythonForm from './CindexPythonForm'; 
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';

const CindexPython = ({ onSelectTest }) => {
  const [showLabForm, setShowLabForm] = useState(false);
  const [selectedTest, setSelectedTest] = useState('');
  const [testid, setTestid] = useState('');
  const userid = Cookies.get('userid');
  const navigate = useNavigate()


  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } 
  }, [userid, navigate]);

  const handleTestClick = (keyword,testid) => {
    console.log("Selected test:", keyword);
    setSelectedTest(keyword); // Set the selected test
    setTestid(testid); // Set the selected test
    setShowLabForm(true);
  };

  return (
    <div className="centered">
      <h1>Personal Index</h1>
      <div className="buttonContainer">
        {/* <div className="buttonWrapper">
          <button className="testButton liver" onClick={() => handleTestClick('liver',203)}>
            LFT (Liver Function Test)
          </button>
        </div> */}
        {/* <div className="buttonWrapper">
          <button className="testButton kidney" onClick={() => handleTestClick('kidney',0)}>
            Kidney Function Test
          </button>
        </div>
        <div className="buttonWrapper">
          <button className="testButton heart" onClick={() => handleTestClick('heart',0)}>
            Heart Function Test
          </button>
        </div>
        <div className="buttonWrapper">
          <button className="testButton lungs" onClick={() => handleTestClick('lungs',0)}>
            Lungs Function Test
          </button>
        </div>
        <div className="buttonWrapper">
          <button className="testButton Air" onClick={() => handleTestClick('Air',0)}>
            Air Quality Test
          </button>
        </div> */}

        <div className="buttonWrapper">
          <button className="testButton Air" onClick={() => handleTestClick('landslide',330)}>
         Land Slide Prediction
          </button>
        </div>
      </div>
      {showLabForm && (
        <CindexPythonForm selectedTest={selectedTest} onSelectTest={onSelectTest} testid={testid}/>
      )}
    </div>
  );
};

export default CindexPython;
