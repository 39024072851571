

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './interviewspotlight.css'
import { NavLink } from 'react-router-dom';

const InterviewCard = ({ imgSrc, name, company, about }) => (
  <div className="interview-card-container">
    <img className="interview-card-image" src={imgSrc} alt={name} />
    <div className="interview-card-details">
      <h6 className="interview-card-name">{name}</h6>
      <h6 className="interview-card-company">{company}</h6>
      <p className="interview-card-position">{about}</p>
    </div>
  </div>
);

const InterviewSpotlight = () => {
  const [interviewData, setInterviewData] = useState([]);

  // let port3 = sessionStorage.getItem("portalid")
  // let parentport = sessionStorage.getItem("parentportalid")
  let getSite = sessionStorage.getItem("site")

  useEffect(() => {
    // Fetch interview data using Axios
    //axios.post('/interview-spotlight', { "port3": port3, "parentport": parentport })
    axios.post('/interview-spotlight', { "type": getSite})
      .then(response => {
        setInterviewData(response.data);
      })
      .catch(error => {
        console.error('Error fetching interview data:', error);
      });
  }, []);

  const middleIndex = Math.ceil(interviewData.length / 2);
  const column1Data = interviewData.slice(0, middleIndex);
  const column2Data = interviewData.slice(middleIndex);

  return (

    <div className='interviewspotlight-container'>
      <div style={{ textAlign: 'center' }}>
        <h1>Techie Speak</h1>
      </div>
      <br />
      <div className='columns-container'>
        <div className='column'>
          {column1Data.map((data, index) => (
               <NavLink to={`/spotlightdetailspage?id=${data.intrvw_id}&type=interview`}  className='navlink-Interview'>
            <InterviewCard
              key={index}
              imgSrc={data.PERSON_PHOTO}
              name={data.interviewPerson}
              company={`From ${data.companyName}`}
              about={data.aboutPerson ? `${data.aboutPerson.slice(0, 100)}...` : ''}
            />
            </NavLink>
          ))}
        </div>
        <div className='column'>

          {column2Data.map((data, index) => (
            <NavLink to={`/spotlightdetailspage?id=${data.intrvw_id}&type=interview`} className='navlink-Interview' >
              <InterviewCard
                key={index}
                imgSrc={data.PERSON_PHOTO}
                name={data.interviewPerson}
                company={`From ${data.companyName}`}
                about={data.aboutPerson ? `${data.aboutPerson.slice(0, 100)}...` : ''}
              />

            </NavLink>
          ))}
        </div>
      </div>
      <NavLink to={`/InterviewMainPage`} className='navlink-archives'>Archives</NavLink>
      <div className='footer'>
        <p>Copyright 2023</p>
      </div>
    </div>
  );
};

export default InterviewSpotlight;