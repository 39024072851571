import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// Importing Axios
import axios from "axios";

import Img from "./new.jpg";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Lunch() {
  //State for fetching data
  const [data, setCardData] = useState();

  //Fetching data
  useEffect(() => {
    axios
      .get("http://localhost:9000/foodsRequired")
      .then((response) => {
        setCardData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(data);
  return (
    <>
      <Card sx={{ minWidth: 275 }} style={{ boxShadow: "none" }}>
        <CardContent>
          <Typography variant="h5">Lunch</Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {data
              ?.filter((word) => word.state === "Punjab")
              ?.map((d) => {
                return (
                  <Grid item xs={6}>
                    <Item>
                      <Card
                        sx={{ display: "flex" }}
                        style={{ boxShadow: "none" }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography component="div" variant="h6">
                              {d.name}
                            </Typography>
                            <Typography
                              variant="h6"
                              color="text.secondary"
                              component="div"
                            >
                              {d.diet}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                            >
                              {d.flavor_profile}
                            </Typography>
                          </CardContent>
                        </Box>

                        <CardMedia
                          component="img"
                          sx={{ width: 151 }}
                          image={Img}
                          alt="Live from space album cover"
                        />
                      </Card>
                    </Item>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
