import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Addvendor.css';
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';

const Addvendor = () => {
  const [VEND_ID, setVEND_ID] = useState('');
  const [VEND_TITL, setVEND_TITL] = useState('');
  const [VEND_DESC, setVEND_DESC] = useState('');
  const [VEND_SDATE, setVEND_SDATE] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const userid = Cookies.get('userid');
  const portalid = Cookies.get('portalid');
  const [email, setEmail] = useState(''); // New state for email
  const [phone, setPhone] = useState(''); // New state for phone
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [showAddSuccessMessage, setShowAddSuccessMessage] = useState(false);
  const [showUpdateSuccessMessage, setShowUpdateSuccessMessage] = useState(false);

  const usertype = Cookies.get("usertype")

  console.log("portalid", portalid)
  console.log("userid", userid)

  const finalpath = `${portalid}/${userid}`;

  useEffect(() => {

    fetchOptions();
    createfolder();
    fetchkfvendorData();
  }, []);

  const handleEdit = (item) => {
    // Set the form data with the selected row for editing
    setVEND_TITL(item.VEND_TITL);
    setVEND_DESC(item.VEND_DESC);
    setEmail(item.email);
    setPhone(item.phone);
    //setVEND_SDATE(item.VEND_SDATE);
    const dateObject = new Date(item.VEND_SDATE);

    // Format the date as "yyyy-MM-dd"
    const formattedDate = dateObject.toISOString().split('T')[0];

    // Set the state with the formatted date
    setVEND_SDATE(formattedDate);
    console.log("formattedDate", formattedDate)

    setSelectedOption(item.VEND_CATEGRY);
    setSelectedImage(null); // Clear the image selection for editing
    setIsEditing(true);
    // Set the editing data for later use in handleSubmit
    setEditingData(item);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const createfolder = async () => {

    try {
      const response = await axios.post('/create/folder/session', { "portalid": portalid, "userid": userid });
      console.log("result", response);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchkfvendorData = async () => {
    try {
      const portalid = Cookies.get('portalid');
      const userid = Cookies.get('userid');

      if (!portalid || !userid) {
        console.error('portalid and userid are required');
        return;
      }

      const response = await axios.get('/fetch_kf_vendor_Data', {
        params: {
          portalid: portalid,
          userid: userid
        }
      });

      console.log("kf_vendor_data response status:", response.status);
      console.log("kf_vendor_data response data:", response.data);

      setData(response.data);
    } catch (error) {
      console.error('Error fetching kf_vendor_data:', error.message, error.response.data);
    }
  };

  const fetchOptions = async () => {
    try {
      const response = await axios.get('/KF_vendor-categry');
      setOptions(response.data);

      // Log the fetched options to the console
      console.log('Fetched options:', response.data);
    } catch (error) {
      console.log(error);
    }
  };


  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    console.log("selectedOption", selectedOption)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('VEND_TITL', VEND_TITL);
    formData.append('VEND_DESC', VEND_DESC);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('VEND_SDATE', VEND_SDATE);
    formData.append('VEND_CATEGRY', selectedOption);
    formData.append('portalid', portalid);
    formData.append('userid', userid);
    formData.append('finalpath', finalpath);
    formData.append('usertype', usertype);
    formData.append('selectedImage', selectedImage);

    try {
      if (isEditing) {
        setShowUpdateSuccessMessage(true);
        // If editing, use update API instead of add API
        const response = await axios.put(`/kf_vendor_update/${editingData.VEND_ID}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Update Response:', response.data);
        
        setShowUpdateSuccessMessage(false);

        // if (response.status === 200) {
        //   // If the response status is successful, set the update success message to true
         
         
        //   // Automatically hide the update success message after 3 seconds
        //   // setTimeout(() => {
        //   //   setShowUpdateSuccessMessage(false);
        //   // }, 3000);
        // }
      } else {
        // If not editing, use add API
        setShowAddSuccessMessage(true);
        const response = await axios.post('/kf_vendor_upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          // If the response status is successful, set the success message to true
          setShowAddSuccessMessage(false);
          // setTimeout(() => {
          //   setShowAddSuccessMessage(false);
          // }, 1000);
        }
      }

      // Reset form fields
      setSelectedOption('');
      setSelectedImage(null);
      setVEND_SDATE('');
      setVEND_DESC('');
      setVEND_TITL('');
      setEmail('');
      setPhone('');

      // Reset editing state
      setIsEditing(false);
      setEditingData(null);

      // Fetch updated data
      fetchkfvendorData();
    } catch (error) {
      console.log('Error:', error);
    }
  };




  const handleDelete = (VEND_ID) => {
    axios
      .put(`/kf_vendor_DELETE/${VEND_ID}`)
      .then((response) => {
        console.log('Vendor deleted successfully');
        // Fetch the updated data after soft-deletion
        fetchkfvendorData();
      })
      .catch((error) => {
        console.error('Error deleting vendor:', error);
      });
  };

  return (
    <div className="v-body">
      <PageLog/>

      <form onSubmit={handleSubmit} className="v-form">
        <h2>
          <u>Add Vendor Details</u>
        </h2>

        <label>
          Title:
          <input
            type="text"
            value={VEND_TITL}
            onChange={(e) => setVEND_TITL(e.target.value)}
            required
          />
        </label>

        <div>
          <label>DESCRIPTION: </label>
        </div>
        <textarea
          value={VEND_DESC}
          onChange={(e) => setVEND_DESC(e.target.value)}
          required
        ></textarea>

        <div className="row-container">
          <div className="input-container">
            <label>Email:</label>
            <input
              className='email'
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="input-container">
            <label>Phone:</label>
            <input
              className='phone'
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
        </div>
        <br />

        <label>
          Date:
          <input
            type="date"
            value={VEND_SDATE}
            onChange={(e) => setVEND_SDATE(e.target.value)}
            required
          />
        </label>

        <label htmlFor="category">category:</label>
        <select
          id="category"
          className="vn-select"
          value={selectedOption}
          onChange={handleOptionChange}
          required
        >
          <option value="" disabled>Select a Category</option>
          {options.map((option) => (
            <option key={option.CONFIG_DES} value={option.CFG_PRNT_CD}>
              {option.CONFIG_DES}
            </option>
          ))}
        </select>

        <div>
          <label htmlFor="image">Select an image:</label>
          <input
            type="file"
            className="input1"
            id="image"
            onChange={handleImageChange}
          />
        </div>
      
        <div className="btn-container">
          
          {showAddSuccessMessage && (
            <div className="success-message  show">
              {/* <p>Added successful!</p> */}
              <p>Adding....</p>
            </div>
          )}
          {showUpdateSuccessMessage && (
            <div className="success-message  show">
              {/* <p>Update successful!</p> */}
              <p>Updating....</p>
            </div>
          )}
          <button type="submit" className="v-button">
            Submit
          </button>
        </div>
      </form>

      <table className="content-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Title</th>
            <th>DESCRIPTION</th>
            <th>Email</th>
            <th>Phone</th>
            <th>DATE</th>
            <th>CATEGORY</th>
            <th>IMAGE</th>
            <th>Doc_price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            console.log('Mapping data for item:', item);
            if (item.DOC_PRICE >= 1) {
              return (
                <tr key={item.VEND_ID}>
                  <td>{item.VEND_ID}</td>
                  <td>{item.VEND_TITL}</td>
                  <td>{item.VEND_DESC}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{new Date(item.VEND_SDATE).toLocaleDateString()}</td>
                  <td>{item.VEND_CATEGRY}</td>
                  <td>
                    <a href={item.IMAGE} target="_blank" rel="noopener noreferrer">
                      View Image
                    </a>
                  </td>
                  <td>{item.DOC_PRICE}</td>
                  <td>
                    <button
                      className="v-button"
                      type="button"
                      onClick={() => handleEdit(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="v-button"
                      type="button"
                      onClick={() => handleDelete(item.VEND_ID)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Addvendor;