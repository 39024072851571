import React, { useEffect, useState } from 'react';
import InstaPosting from './InstaPosting';
import './insta.scss'

function InstaAccountSelect(props) {

    const { bardStory, id, processedIDs } = props;

    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedUrls, setSelectedUrls] = useState([]);


    useEffect(() => {
        if (selectAll) {
            setSelectedRows(processedIDs.map(item => item.id));
        } else {
            setSelectedRows([]);
        }
    }, [selectAll, processedIDs]);

    useEffect(() => {
        const selectedUrls = processedIDs
            .filter(item => selectedRows.includes(item.id))
            .map(item => ({
                id: item.id,
            }));
        setSelectedUrls(selectedUrls);

    }, [selectedRows, processedIDs]);

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
    };

    const toggleSelectRow = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }

    };

    console.log("selectedUrls", selectedUrls)



    return (
        <div class="card card--accent">

            <h2>Select One or more instagram account and post</h2>
            <table className="instagram-table">
                <thead>
                    <tr>
                        <th className="checkbox-header"> <input
                            type="checkbox"
                            onChange={toggleSelectAll}
                            checked={selectAll}
                        /></th>
                        {/* <th>ID</th> */}
                        {/* <th>Page Url</th> */}
                        <th>Name</th>
                        <th>Profile</th>

                    </tr>
                </thead>
                <tbody>
                    {processedIDs.map((item) => (
                        <tr key={item.id}>
                            <td className="checkbox-column">
                                <input
                                    type="checkbox"
                                    onChange={() => toggleSelectRow(item.id)}
                                    checked={selectedRows.includes(item.id)}
                                />
                            </td>
                            <td>{item.username}</td>
                            <td><a href = {item.URL_NAME} className='button-blue'> Go to Page</a></td>

                        </tr>
                    ))}
                </tbody>
            </table>


            <InstaPosting bardStory={bardStory} id={id} processedIDs={selectedUrls} />
        </div>
    )
}

export default InstaAccountSelect