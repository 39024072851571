import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './mobilenews.css';




const MobileContent = (props) => {

   const navigate= useNavigate()
   //console.log("img",props.portal_img.image_url)
  //  if (props.portal_img.image_url === undefined) {
  //   props.portal_img.image_url = 0;
  // }


    function goToSinglepage(){
    navigate("/singlepagesmobile",{state : {id : props.item.DOC_ID}})
   } 

  return (
    <>


    
      <div className='box'>
        <div className='img'>
       
       <img src={props.item.image || props.portal_img.image_url || props.data.image_url} alt='' />
       </div>
        <div className='text'>
         
       <h1 onClick={goToSinglepage}>{props.item.DOC_TITL.slice(0,35)}...</h1><br />
        <div className='author flex'>
        <span> {new Date(props.item.DOC_SDATE).toLocaleDateString('en-IN', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
        </div>
        </div>
      </div> 
    
    </>
  )
}

export default MobileContent

