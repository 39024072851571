import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './testqm.css'
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';

const TestQM = () => {
    const [topics, setTopics] = useState([]);

    const firmid = Cookies.get("firmid")
    const usertype = Cookies.get("usertype")

    console.log("usertype", usertype)

    useEffect(() => {
        // Make an API request to fetch data from your backend
        axios.get(`/api/getTopics/${usertype}/${firmid}`) // Replace with your API endpoint
            .then((response) => {
                setTopics(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div>
            <PageLog />
            <center>
                <h1>QM List</h1>

            </center>


            <div className='testqm-form-container'>

                <ol>
                    {topics.map((topic) => (
                        <li key={topic.QM_ID}>
                            <a href={`/qmquestion/${topic.QM_ID}`}>{topic.TOPIC}</a>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default TestQM;