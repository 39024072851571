import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './style.css';
import Vendcontent from './Ventcontent';
import HeadingD from './HeadingVend';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import PageLog from '../../PageLog/PageLog';

const AllVendors = () => {

    const [data1, setData] = useState([])


    const location = useLocation();
    console.log(location.state)
    let vend_cat = location.state?.id

    let port1 = sessionStorage.getItem("portalid")
    //let pin1 = sessionStorage.getItem("pincode")
    let parentport = sessionStorage.getItem("parentportalid")

    const [startIndex, setStartIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);
    let site = sessionStorage.getItem("site")

    //console.log("myport", port)



    useEffect(() => {
        if (site === "Techie-Index") {

            axios.post('/local/vendor/list/techieindex/all', { "port1": port1, "parentport": parentport })
                .then(function (response) {
                    console.log("full vendors data", response.data)
                    setData(response.data)
                    setMaxIndex(response.data.length);
                })

        } else if (site === "Myblock") {

            axios.post('/local/vendor/list/all', { "port1": port1, "parentport": parentport, "vend_cat": vend_cat })
                .then(function (response) {
                    console.log("full vendors data", response.data)
                    setData(response.data)
                    setMaxIndex(response.data.length);
                })
        }





    }, []);

    const handleNextClick = () => {
        //console.log('Next button clicked')
        if (startIndex + 18 < maxIndex) {
            setStartIndex(prevIndex => prevIndex + 18);
        }
    };

    const handlePrevClick = () => {
        //console.log('Previous button clicked')
        if (startIndex - 18 >= 0) {
            setStartIndex(prevIndex => prevIndex - 18);
        }
    };

    return (
        <div>
            <PageLog/>

            <section className='vendor'>
                <div className='container'>
                    <HeadingD />
                    <div className='content'>

                        {data1.slice(startIndex, startIndex + 18).map((item, i) => {

                            return <Vendcontent key={i} vend={item} />;

                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='left-buttons'>
                            <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        </div>
                        <div className='archives-link'>
                            <p align="center"  >
                                <a href={`http://61.2.142.91:8400/CityFromId/${port1}`}  >Archives</a>
                            </p>
                        </div>
                        <div className='right-buttons'>
                            <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 18 >= maxIndex}>Next</button>
                        </div>

                    </div>

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className={`btn btn-primary ${startIndex === 0 ? 'disabled' : ''}`} onClick={handlePrevClick} disabled={startIndex === 0}>Previous</button>
                        <p align="center"  >
                            <a href={`http://61.2.142.91:8400/CityFromId/${port1}`}  >Archives</a>
                        </p>

                        <button className={`btn btn-primary ${startIndex + 18 >= maxIndex ? 'disabled' : ''}`} onClick={handleNextClick} disabled={startIndex + 18 >= maxIndex}>Next</button>
                    </div> */}
                </div>
            </section>


        </div>
    )
}

export default AllVendors



