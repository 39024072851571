import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./pagelogdisplay.css"
import { Link } from "react-router-dom";

function PageDisplayCount() {

    const [urlCounts, setUrlCounts] = useState([]);
    const [datetype, setDatetype] = useState('alltime');

    useEffect(() => {    

        fetchPageLogs(datetype);
        console.log("datetype in useeffect",datetype)
    }, [datetype]);

    const handleButtonClick = (type) => {
         setDatetype(type);
        fetchPageLogs(type);
    };

    const fetchPageLogs = async (type) => {
        console.log("datetype",type)
        try {
            const response = await axios.get('/fetch_pagelog_url_count', {
                params: {
                    datetype: type
                }
            });
            setUrlCounts(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    console.log("urlCounts",urlCounts)

    return (
        <div className='pagelog-home'>
            <h1>URL Counts</h1>
            <div style={{ textAlign: 'center' }}>

            <button onClick={() => handleButtonClick('today')}>Today's Count</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={() => handleButtonClick('alltime')}>All time Count</button>
            <br/><br/>
            <Link to="/pagelogdisplay">View Today's visit details</Link>
            </div>
            
            <table className='pagelog-w1-table'>
                <thead>
                    <tr>
                        <th>URL</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {urlCounts.map((urlCount, index) => (
                        <tr key={index}>
                            <td>{urlCount._id}</td>
                            <td>{urlCount.count}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}


export default PageDisplayCount