import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Leaders.css';
import PageLog from '../../PageLog/PageLog';

const Leaders = () => {
  const [interviewPerson, setInterviewPerson] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [aboutPerson, setAboutPerson] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState([]);
  const portalid = Cookies.get('portalid');
  const userid = Cookies.get('userid');
  const adminuser = Cookies.get("adminuser");
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [showAddSuccessMessage, setShowAddSuccessMessage] = useState(false);
  const [showUpdateSuccessMessage, setShowUpdateSuccessMessage] = useState(false);



  console.log("portalid", portalid)
  console.log("userid", userid)

  const finalpath = `${portalid}/${userid}/leaders`;

  useEffect(() => {
    createfolder();
    fetchLeadersData();
  }, []);

  const handleEdit = (item) => {
    // Set the form data with the selected row for editing
    setInterviewPerson(item.interviewPerson);
    setDesignation(item.designation);
    setCompanyName(item.companyName);
    setAboutPerson(item.aboutPerson);
    setSelectedImage(null); // Clear the image selection for editing
    setIsEditing(true);
    // Set the editing data for later use in handleSubmit
    setEditingData(item);
  };

  const createfolder = async () => {

    try {
      const response = await axios.post('/create/folder/session', { "portalid": portalid, "userid": userid });
      console.log("result", response);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchLeadersData = async () => {
    try {
        const portalid = Cookies.get('portalid');

        const response = await axios.get('/fetch_Leaders_data', {
            params: {
                portalid: portalid,
            }
        });

        console.log("Leaders_data response status:", response.status);
        console.log("Leadersr_data response data:", response.data);

        setData(response.data);
    } catch (error) {
        console.error('Error fetching Leaders_data:', error.message, error.response.data);
    }
};



  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

      // Check if required fields are empty
  if (!interviewPerson || !designation || !companyName || !aboutPerson) {
    console.log('Please fill in all required fields.');
    return;
  }
  
    const formData = new FormData();
    formData.append('interviewPerson', interviewPerson);
    formData.append('designation', designation);
    formData.append('companyName', companyName);
    formData.append('aboutPerson', aboutPerson);
    formData.append('portalid', portalid);
    formData.append('finalpath', finalpath);
    formData.append('userid', userid);
    formData.append('image', selectedImage);
  
    try {
      if (isEditing) {
        // If editing, use update API instead of add API
        const response = await axios.put(`/Leaders_Edit/${editingData.intrvw_id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        console.log('Update Response:', response.data);

        if (response.status === 200) {
          // If the response status is successful, set the update success message to true
          setShowUpdateSuccessMessage(true);
  
          // Automatically hide the update success message after 3 seconds
          setTimeout(() => {
            setShowUpdateSuccessMessage(false);
          }, 3000);
        }
      } else {
        // If not editing, use add API
        const response = await axios.post('/Leaders_data_upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
  
        console.log('Add Response:', response.data);
        if (response.status === 200) {
          // If the response status is successful, set the success message to true
          setShowAddSuccessMessage(true);
          setTimeout(() => {
            setShowAddSuccessMessage(false);
          }, 3000);
        }
      }

      setSelectedImage(null); // Clear the image selection for editing
      setIsEditing(true);
  
      // Reset form fields
      setSelectedImage(null);
      setAboutPerson('');
      setCompanyName('');
      setDesignation('');
      setInterviewPerson('');
  
      // Reset editing state
      setIsEditing(false);
      setEditingData(null);
  
     fetchLeadersData();
    } catch (error) {
        console.log('Error:', error);
      }
  };

  const handleDelete = (intrvw_id) => {
    axios
        .put(`/Leaders_DELETE/${intrvw_id}`)
        .then((response) => {
            console.log('Leader deleted successfully');
            fetchLeadersData();
        })
        .catch((error) => {
            console.error('Error deleting Leader:', error);
        });
};

  
  return (
    <div className="custom-form-body">
      <PageLog/>
      <form onSubmit={handleSubmit} className='custom-leaders-form'>
        <h2>
          <u>Add Leaders</u>
        </h2>

        <label className="custom-form-label">
          Name:
          <input
            type="text"
            value={interviewPerson}
            onChange={(e) => setInterviewPerson(e.target.value)}
            required
            className="custom-form-input"
          />
        </label>
        <label className="custom-form-label">
          Designation:
          <input
            type="text"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            required
            className="custom-form-input"
          />
        </label>

        <label className="custom-form-label">
          CompanyName:
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            className="custom-form-input"
          />
        </label>

        <div className="custom-form-label">
          <label>About person: </label>
        </div>
        <textarea
          value={aboutPerson}
          onChange={(e) => setAboutPerson(e.target.value)}
          required
          className="custom-form-textarea"
        ></textarea>
        <br />

        <div>
          <label htmlFor="image" className="custom-form-label">Select an image:</label>
          <input
            type="file"
            className="custom-form-input"
            id="image"
            onChange={handleImageChange}
          />
        </div>
        <br />
        <div className="custom-btn-container">
      {showAddSuccessMessage && (
        <div className="Add-success-message">
            Added successful!
          </div>
         )}
      {showUpdateSuccessMessage && (
        <div className="update-success-message">
          Update successful!
        </div>
         )}        
          <button className="custom-form-button" 
          onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>


      <h2>Uploaded Data</h2>
      <table className="content-table">
        <thead>
          <tr>
            <th>int_id</th>
            <th>Member-Id</th>
            <th>Name</th>
            <th>designation</th>
            <th>Company</th>
            <th>About</th>
            <th>DOC_PRI</th>
            <th>Photo</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {data.map((item) => {
    if (item.DOC_PRICE >= 3) {
      return (
        <tr key={item.intrvw_id}>
          <td>{item.intrvw_id}</td>
          <td>{item.vendorId}</td>
          <td>{item.interviewPerson}</td>
          <td>{item.designation}</td>
          <td>{item.companyName}</td>
          <td>{item.aboutPerson}</td>
          <td>{item.DOC_PRICE}</td>
          <td>
          <a href={item.photo} target="_blank" rel="noopener noreferrer">
            View Image
          </a>
          </td>
          <td>
          {/* {adminuser === 'Active' && (
                <button
                  className="T-kf-button"
                  type="button"
                  onClick={() => handleApprove(item.DOC_ID)}
                >
                  Approve
                </button>
              )} */}
          <button
            className="L-button"
            type="button"
            onClick={() => handleEdit(item)}
          >
            Edit
          </button>
          <button
            className="L-button"
            type="button"
            onClick={() => handleDelete(item.intrvw_id)}
          >
            Delete
          </button>
          </td>
        </tr>
      );
    } else {
      // If doc_price is not equal to 5, skip rendering this row
      return null;
    }
  })}
</tbody>
      </table>
    </div>
  );
};

export default Leaders;
