// StartupList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Details from './Details'; // Import the CompanyDetails component
import './Ranking1.css';
import PageLog from '../../PageLog/PageLog';

const itemsPerPage = 100;

const StartupList = () => {
  const [startups, setStartups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null); // Track the selected company

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/canada-univ');
        console.log('Fetched data from backend:', response.data);
        setStartups(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = startups.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(startups.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };
    // Scroll to the top of the table
    const tableElement = document.querySelector('.startup-table');
  
    if (tableElement) {
      const tableTop = tableElement.offsetTop;
  
      window.scrollTo({
        top: tableTop,
        behavior: 'auto',
      });
    } else {
      console.error('Table element not found');
    }

  return (
    <div>
      <PageLog/>
      <h1>Ranking of Canadas Top universities</h1>
      <p>This personalized guide ranks Canadas universities for Indian students based on visa friendliness, 
        ensuring a smooth transition. It offers a simplified list that balances academic excellence with visa accessibility,
         making it easier for students to choose the right university without visa hurdles. This ranking system aids Indian students
          in selecting reputable institutions that also align with their visa requirements, streamlining their application process and
           ensuring a more straightforward educational journey in Canada.</p>
      <table className="startup-table">
        <thead>
          <tr>
            <th>Ranking</th>
            <th>University</th>
            <th>Place</th>
            <th>Application Fee</th>
            <th>Tutuion Fee</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((startup) => (
            <tr key={startup.ST_ID}>
              <td>{startup.Ranking}</td>
              <td> {startup.company_name}</td>
              <td>{startup.country}</td>
              <td>{startup.Industry}</td>
              <td>{startup.valuation}</td>
              <td>{startup.state}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {pageNumbers.map((number) => (
          <span
            key={number}
            className={number === currentPage ? 'active' : ''}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </span>
        ))}
      </div>

      {/* Render the CompanyDetails component when a company is selected */}
      {selectedCompany && <Details company={selectedCompany} />}
    </div>
  );
};

export default StartupList;
