import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

import React, { useState,useEffect } from "react";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// States Data
const states = [
  { code: "AD", value: 1, label: "Andhra Pradesh" },

  { code: "AR", value: 2, label: "Arunachal Pradesh" },
  { code: "AS", value: 3, label: "Assam" },
  { code: "BR", value: 4, label: "Bihar" },
  { code: "CG", value: 5, label: "Chattisgarh" },
  { code: "DL", value: 6, label: "Delhi" },
  { code: "GA", value: 7, label: "Goa" },
  { code: "GJ", value: 8, label: "Gujrat" },
  { code: "HR", value: 9, label: "Haryana" },

  { code: "HP", value: 10, label: "Himanchal Pradesh" },
  { code: "JK", value: 11, label: "Jammu and Kashmir" },
  { code: "JH", value: 12, label: "Jharkhand" },

  { code: "KA", value: 13, label: "Karnataka" },
  { code: "KL", value: 14, label: "Kerala" },
  { code: "MP", value: 15, label: "Madhya Pradesh" },
  { code: "MH", value: 16, label: "Maharshtra" },
  { code: "MN", value: 17, label: "Manipur" },
  { code: "ML", value: 18, label: "Meghalaya" },
  { code: "MZ", value: 19, label: "Mizoram" },
  { code: "NL", value: 20, label: "Nagaland" },
  { code: "Odisha", value: 21, label: "Odisha" },
  { code: "PY", value: 22, label: "Pondicherry" },
  { code: "PB", value: 23, label: "Punjab" },
  { code: "RJ", value: 24, label: "Rajasthan" },
  { code: "SK", value: 25, label: "Sikkim" },
  { code: "TN", value: 26, label: "Tamil Nadu" },
  { code: "TS", value: 27, label: "Telangana" },
  { code: "TR", value: 28, label: "Tripura" },
  { code: "UP", value: 29, label: "Uttar Pradesh" },
  { code: "UK", value: 30, label: "Uttarakhand" },
  { code: "WB", value: 31, label: "West Bengal" },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Grids() {
  //importing Data
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetch("http://localhost:3000/message")
      .then((response) => response.json())
      .then((data) => setMessage(data));
  }, []);
  const [category, setCategory] = useState("");

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };
  console.log(category);

  // Rendering Data based on the condition
  const data=()=>{
    if (category === 1) {
      return message.slice(0,9)
    } else if (category === 2) {
      return message[10]
    } else {
      return null
    }
  }
  console.log(data())
  return (
    <>
      <div>
        <TextField
          id="photo"
          select
          label="Category"
          value={category}
          onChange={handleCategory}
          helperText="Select your photo Category"
        >
          {states.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  );
}
