import { React, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Material from './material';
import ChildLinks from '../Home/ChildLinks/ChildLinks';
import './mainpage.css';
import { Link } from 'react-router-dom';

const PinPage = () => {

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const pin = query.get('pin');
    const searchcity = query.get('searchcity');
    const inputRef = useRef(null);
    const inputRefCity = useRef(null);
    const [pindata, setPin] = useState([])
    const navigate = useNavigate()

    useEffect(() => {

        
        axios.post('/pincode', { "pin": pin })
            .then(response => {
                setPin(response.data);
            })
            .catch(error => {
                console.error(error);
            });

    }, []);


    const handlepincode = (event) => {
        event.preventDefault();
        const pin = inputRef.current.value;
        const searchcity = inputRefCity.current.value;

        console.log(pin)
        //sessionStorage.setItem("pincode", data1.current.value)        
        const path = `/PinPage?pin=${pin}&searchcity=${searchcity}`;

        navigate(path);
        window.location.reload();
    }


    const handleclickportal = (portalid,portalname,parentportalid) => {
        sessionStorage.setItem("portalid", portalid)
        sessionStorage.setItem("city", portalname)
        sessionStorage.setItem("parentportalid", parentportalid)
        var currentUrl = window.location.href;
        var baseUrl = currentUrl.split("/")[2]; // Get the domain (e.g., "example.com")
        var newUrl = window.location.protocol + "//" + baseUrl + "/home";
        window.location.href = newUrl;


    }


    console.log("pin data", pindata)


    return (
        <div>
            <ChildLinks />
            <h3 style={{ textAlign: 'center' }}>List of Portals</h3>
            <div className='page container paddingTB'>
                <div className='city'>
                    <h5>Enter your Pincode</h5>
                    <input name="input" placeholder="Enter Pincode/Zipcode" ref={inputRef} />
                    <br/>
                    <p className="or-text" >OR</p>
                    <input name="city" placeholder="Enter City/Town Name" ref={inputRefCity} />
                    <button onClick={(event) => handlepincode(event)}>search</button>



                </div>
            </div>

            <div >

                {pindata.length > 0 ? (
                    <table style={{ margin: '10px 350px', border: '1px solid black', borderCollapse: 'collapse' }} >
                        <thead>
                            <tr>
                                <th>Portal Name</th>
                                <th>Portal ID</th>
                                <th>Parent Portal ID</th>
                                <th>Parent Portal Name</th>
                                {/* Add more column headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {pindata.map(portal => (
                                <tr key={portal.id}>
                                    <td>
                                        <a href="#" onClick={() => handleclickportal(portal.portalid,portal.portalname,portal.parentportalid)}>{portal.portalname}</a>

                                        </td>
                                    <td>{portal.portalid}</td>
                                    <td>{portal.parentportalid}</td>
                                    <td>{portal.parentport}</td>
                                    {/* Add more table cells for other data in the row */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p style={{ textAlign: 'center' }}>No data found</p>
                )}


            </div>

        </div>
    )
}

export default PinPage