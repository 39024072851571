import {React,useEffect,useState} from 'react';
import axios from 'axios';
import './advert.css'
import AD from './AD';

const Advert = () => {
    const [advert,setAdvert]=useState([])
    const getportal=sessionStorage.getItem("portalid")
    let adpin=sessionStorage.getItem("pincode")
    // console.log(adpin);

    useEffect(()=>{
        axios.post('/local/city/advert',{"id":getportal,"adpin":adpin})
.then(function (response) {
console.log(response.data);
setAdvert(response.data)
})
} ,[]); 
    

  return (
    <>
    <div >
      
        <h4 className='headad'> Advertisement</h4>
        <section className='ad'>
          <div className='container'> 
            {advert.map((ad)=>{
                return (

                   <AD key={ad.id} data={ad} />
                   
      //   <div className='advert'>
      //       <img src={ad.image} alt='' />
      //   <h4>{ad.DOC_TITL}</h4>
      //   <div><h3>{ad.DOC_DESC}</h3></div>
      // </div>
    )})}
      </div> 
      </section>
      </div>
    </>
  )
}

export default Advert
