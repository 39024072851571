import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import './profile.css'
import Cookies from 'js-cookie';
import PageLog from '../../PageLog/PageLog';
import LoginPopup from '../../loginpage/LoginPopup';

const EditAccountProfile = () => {
    const [vendorUser, setVendorUser] = useState({});
    const userid = Cookies.get("userid");
    const homePortalId = Cookies.get("portalid");

    const inputRef = useRef(null);
    const [pindata, setPin] = useState([]);
    const [selectedPortal, setSelectedPortal] = useState('');
    const [showLogin, setShowLogin] = useState(false); // State to control showing login window
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [portalname, setPortalName] = useState('');

    useEffect(() => {
        if (!userid) {
            setShowLogin(true); // Show login window when user is not logged in
        } else {
            fetchProfile();
            fetchportalname();
        }
    }, []);

    const fetchportalname = async (reload = false) => {

        try {
            const response = await axios.post('/fetchPortalName', { homePortalId });
            console.log("response", response.data.portalName)
            setPortalName(response.data.portalName)

            sessionStorage.setItem("city", response.data.portalName);
            if (reload) {
                window.location.reload();
                
            }


        } catch (error) {
            console.error('Error fetching profile:', error);
        }


    }


    const fetchProfile = async () => {
        try {
            const response = await axios.post('/userprofile', { userid });
            setVendorUser(response.data[0] || {});
            // Set latitude and longitude from vendorUser
            setLatitude(response.data[0]?.LATITUDE || '');
            setLongitude(response.data[0]?.LONGITUDE || '');
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const handlepincode = async (event) => {
        event.preventDefault();
        const pin = inputRef.current.value;
        try {
            const response = await axios.post('/pincode', { pin });
            setPin(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const handleUpdate = () => {
        //debugger
        const updatedUser = { ...vendorUser };

        if (selectedPortal) {
            updatedUser.HOME_PORTALID = selectedPortal;
        }

        console.log("latitude", latitude)
        console.log("longitude", longitude)
        if (latitude) {
            updatedUser.LATITUDE = latitude;
        }
        if (longitude) {
            updatedUser.LONGITUDE = longitude;
        }
        if (password1 && password1 === password2) {
            updatedUser.PASSWORD = password1;
        } else if (password1 !== password2) {
            alert("Passwords don't match");
            return;
        }

        if (!updatedUser.NAME.trim() || !updatedUser.USERNAME.trim()) {
            alert("Name and Username cannot be empty");
            return;
        }

        console.log("updatedUser", updatedUser)

        setVendorUser(updatedUser);

        axios.post('/profile/Update', { updatedUser })
            .then(response => {
                if (response.status === 200) {
                    alert(response.data.message)
                    Cookies.set('portalid',selectedPortal);
                    sessionStorage.setItem("portalid", selectedPortal);
                    fetchportalname(true);
                    // window.location.reload();
                    setPassword1('');
                    setPassword2('');
                    fetchProfile();
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const requestLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                },
                (error) => {
                    console.log(`Error getting location: ${error.message}`);
                }
            );
        } else {
            console.log('Geolocation is not supported by your browser.');
        }
    };

    // const handleReload = () => {
    //     fetchportalname(true); // Pass true to trigger reload
    // };

    return (
        <div className="center-container">
            <PageLog />
            {showLogin && (
                <div className='template-modal-overlay'>
                    <div className='template-modal'>
                        <LoginPopup />
                    </div>
                </div>
            )}

            <div className="edit-profile-container-account">
                <br /><br />

                {/* <button onClick={handleReload}>Reload</button> */}


                <div className="edit-profile-form">




                    <div className="centered-input">

                    <br/>
                        <div>
                            <label style={{ display: 'inline-block' }}>My Town News - Current town: </label>
                            <label style={{ display: 'inline-block' }}>&nbsp; {portalname}</label>
                        </div>
                        <input name="input" type="text" placeholder="Enter Pincode" ref={inputRef} />
                    </div>
                    <div className="register-button-container">
                        <button className="register-button" onClick={(event) => handlepincode(event)}>search pincode</button>
                    </div>

                    {pindata.length !== 0 && (
                        <table style={{ margin: '10px 0px', border: '1px solid black', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Portal Name</th>
                                    <th>Portal ID</th>
                                    <th>Parent Portal ID</th>
                                    <th>District Name</th>
                                    <th>State Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pindata.map(portal => (
                                    <tr key={portal.id}>
                                        <td>
                                            <input
                                                type="radio"
                                                name="selectedPortal"
                                                value={portal.portalid}
                                                onChange={() => setSelectedPortal(portal.portalid)}
                                            />
                                        </td>
                                        <td>{portal.portalname}</td>
                                        <td>{portal.portalid}</td>
                                        <td>{portal.parentportalid}</td>
                                        <td>{portal.parentport}</td>
                                        <td>{portal.state}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <br/>

                    <h2>Edit Profile</h2>
                    <div className="form-group">
                        <label>Name:</label>
                        <input type="text" value={vendorUser.NAME || ''} onChange={(e) => setVendorUser({ ...vendorUser, NAME: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" value={vendorUser.USERNAME || ''} onChange={(e) => setVendorUser({ ...vendorUser, USERNAME: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" value={password1} onChange={(e) => setPassword1(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Repeat Password</label>
                        <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                    </div>



                    <div className="form-group">
                        <label>Latitude</label>
                        <input type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Longitude</label>
                        <input type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                    </div>
                    <div className="register-button-container">
                        <button onClick={requestLocation}>Load My Current GPS</button>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <button className="profile-button" onClick={handleUpdate}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAccountProfile;