import React, { useState, useEffect } from 'react';

const UploadTest = () => {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const processWords = (newText) => {
      // Append new text to the existing text
      setText(prevText => prevText + newText);
    };

    const fetchStream = async () => {
      const url = "http://61.2.142.91:7434/api/generate";
      const headers = { 
        "Content-Type": "application/json"
      };
      const payload = {
        model: "tinyllama",
        prompt: `Summarize the following text in plain English. The summary should be in English: Take a seat at the sushi bar and experience a warm welcome from our Okinawan Masterchef @yonaha0218 at @farandeastbengaluru Delight in exquisite Japanese cuisine, crafted with passion and precision. For reservations, visit the link in our bio or call +91 76191 46006.#FSBengaluru #FourSeasons #FineDining #BangaloreFood #AsianRestaurant #JapaneseFood #Sushi #LoveFourSeasons`
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload)
        });

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        function readStream() {
          reader.read().then(({ done, value }) => {
            if (done) {
              setLoading(false); // Stop loading when the stream finishes
              return;
            }
            const textChunk = decoder.decode(value, { stream: true });
            console.log('Original text chunk:', textChunk);
            const jsonChunks = textChunk.split('\n').filter(line => line.trim() !== '');
            jsonChunks.forEach(chunk => {
              try {
                const parsedChunk = JSON.parse(chunk);
                if (parsedChunk.response) {
                  processWords(parsedChunk.response);
                }
              } catch (e) {
                setError(`JSON parse error: ${e.message}`);
              }
            });
            readStream(); // Continue reading the stream
          }).catch((error) => {
            setError(`Stream read error: ${error.message}`);
            setLoading(false);
          });
        }

        readStream();
      } catch (error) {
        setError(`Fetch error: ${error.message}`);
        setLoading(false); // Stop loading on error
      }
    };

    fetchStream();
  }, []);

  return (
    <div>
      <textarea 
        value={text}
        readOnly
        rows="10"
        cols="50"
        style={{ width: '100%', fontFamily: 'monospace' }}
      />
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default UploadTest;