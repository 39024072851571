





import React, { useEffect, useState } from 'react';
import './interview.css'



function InterviewWilliam_Seidman() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content from the same server
    fetch('/Interview/William_Seidman.html')
      .then(response => response.text())
      .then(data => setHtmlContent(data));
  }, []);

  return (
    <div className='interview-container'>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
    
  

  );
}

export default InterviewWilliam_Seidman;