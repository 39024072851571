import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './singlepages.css'
import PageLog from '../PageLog/PageLog';


const Singlepages = () => {
  const location = useLocation();
  // console.log(location.state)
  let id = location.state.id
  let port2 = sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")
  console.log("id",id)
  console.log("parentport in single page", parentport)

  const [newsdata, setNewsData] = useState([])



  useEffect(() => {
    axios.post('/citynews/news/singlepages', { "id": id, "port2": port2, "parentport": parentport })
      .then(function (response) {
        console.log("news data", response.data);
        setNewsData(response.data)
      })
  }, []);


  const formattedDate = (date1) => {
    const originalDate = new Date(date1);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const formattedDatenew = `${monthNames[originalDate.getMonth()]} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
    return formattedDatenew;

  }

  console.log("newsdata",newsdata)


  return (
    <div className='pages'>



      {newsdata.map((news) => {
        return (<div className='singlepage'>
          <img src={news.image} alt='' />
          <h3>{news.DOC_TITL}</h3>
          <br />
          <span>
            <h6><b>DATE : </b>{formattedDate(news.DOC_SDATE)}</h6>
          </span>
          <br />
          <h6>{news.DOC_DESC}</h6>
          {news.DOC_DET && news.DOC_DET.trim() !== '' && (
            <div className="text-justify">
              <h6>
                {
                  news.DOC_DET.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                      <br />
                    </React.Fragment>
                  ))

                }
              </h6>
              <script>
                {console.log('Number of newline characters:', news.DOC_DET.split('\n').length - 1)}
              </script>
            </div>
          )}
          <a href={news.DOC_URL}>
            <button>More</button>
          </a>
        </div>
        )
      })}

      <PageLog />
    </div>
  )

}

export default Singlepages
