import React from 'react'
import './HeadingVend.css'

const HeadingD = () => {
  
  return (
    <div >
 

  </div>
  )
}

export default HeadingD
