import React, { useState, useEffect } from "react";
import "./Layout.css";
import axios from 'axios';
//import { useParams } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import PageLog from "../../../../PageLog/PageLog";

function SpotlightDetailsPage() {

  //const { id } = useParams();


  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id');
  const type = query.get('type');

  console.log("type", type)
  const [data, setData] = useState({
    interviewPerson: "",
    aboutPerson: "",
    photo: "",
    company: "",
    founded: "",
    employees: "",
    headquarters: "",
    revenue: "",
    url: "",
    logo: "",
    quote: "",
    Founded: "",
    Employees: "",
    Headquarters: "",
    Revenue: "",
    url: "",
  });
  const [topics, setTopics] = useState([]); // State to store topics
  const [selectedTopic, setSelectedTopic] = useState(null); // State to track selected topic
  const [loading, setLoading] = useState(true);

  // Fetch interview details and topics from respective endpoints
  useEffect(() => {
    axios.get(`/spotlight-details/${id}`)
      .then((response) => response.data)
      .then((dataArray) => {
        const firstItem = dataArray[0] || { /* your default data */ };
        console.log("Received data from backend:", firstItem);
        setData(firstItem);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching interview details:", error);
        setLoading(false);
      });

    axios.get(`/spotlight-topics/${id}`)
      .then((response) => response.data)
      .then((topicsArray) => {
        console.log("Received topics from backend:", topicsArray);
        setTopics(topicsArray);
      })
      .catch((error) => {
        console.error("Error fetching topics:", error);
      });

    console.log("type", type)
  }, []);


  // Function to handle topic selection
  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);

    axios.get(`/spotlight-topic-details/${topic.intsp_dtlid}`)
      .then((response) => response.data)
      .then((detailData) => {
        // Assuming detailData is an array of objects containing 'detail' field
        // Update the state with the fetched detailData for the selected topic
        console.log("detail", detailData[0].detail)
        setData({
          ...data,
          [topic.topic.toLowerCase().replace(/\s/g, '')]: detailData[0].detail,
        });
      })
      .catch((error) => {
        console.error("Error fetching topic details:", error);
      });
  };
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="spotlight-container">
      <PageLog/>
      <div className="spotlight-heading">
        <div className="logo-container">
          {data.logo && <img src={data.logo} alt="Logo" className="logo" />}
          {/* <img src="./images/logo.png" alt="Logo" className="logo" /> */}
          {/* {data.logo && <img src={`${process.env.PUBLIC_URL}/${data.logo}`} alt="Logo" className="logo" />} */}

        </div>
        {type != 'interview' ? (
          <div className="interview-details">
            <h1>{data.companyName}</h1>
            <p><strong></strong> {data.quote}</p>
          </div>) : (<div className="interview-details">
            <h1>{data.interviewPerson}</h1>
            <p><strong></strong> {data.quote}</p>
          </div>

        )

        }
      </div>

      <div className="horizontal">
        {type != 'interview' ?
          (<div className="vertical">
            {/* Display topics */}
            {topics.map((topic) => (
              <button key={topic.intsp_dtlid} onClick={() => handleTopicClick(topic)}>
                {topic.topic}
              </button>
            ))}
          </div>) : (
            <div className="vertical">

              <button >
                <Link to={`/spotlightdetailspage?id=${id}&type=interview`}>Interview</Link>
              </button>

            </div>



          )
        }
        <div className="vertical center">

          {/* Display selected topic detail or default values */}
          {type === 'interview' ? (
            (!selectedTopic ? (
              <div className="about">
                <h1>Interview</h1>
                <h6 className="h6-css">

                  {
                    data.INTERVIEW && data.INTERVIEW.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />

                      </React.Fragment>
                    ))
                  }
                </h6>
              </div>
            ) : (
              <div className="about">
                <h1>{selectedTopic.topic}</h1>
                <p>{data[selectedTopic.topic.toLowerCase().replace(/\s/g, '')]}</p>
              </div>
            ))

          ) :
            (!selectedTopic ? (
              <div className="about">
                <h1>Company</h1>
                <p>{data.aboutPerson}</p>
              </div>
            ) : (
              <div className="about">
                <h1>{selectedTopic.topic}</h1>
                <p>{data[selectedTopic.topic.toLowerCase().replace(/\s/g, '')]}</p>
              </div>
            ))
          }
        </div>

        <div className="vertical3">
          {/* {data.photo && <img className="photo" src={data.photo} alt="Interviewee" />} */}

          {type === 'interview' ? (
            <img className="photo" src={data.PERSON_PHOTO} />)
            : (<img className="photo" src={data.photo} />)
          }


          <div className="company-info">
            {type === 'interview' ? (
              <div>
                <p><strong>Designation:</strong> {data.designation}</p>
                <p><strong>Company:</strong> {data.companyName}</p></div>)
              : (<p><strong>Company:</strong> {data.companyName}</p>)
            }

            <p><strong>Founded:</strong> {data.Founded}</p>
            <p><strong>Employees:</strong> {data.Employees}</p>
            <p><strong>Headquarters:</strong> {data.Headquarters}</p>
            <p><strong>Revenue:</strong> {data.Revenue}</p>
            <p><strong>URL:</strong> <a href={data.url}>{data.url}</a></p>

          </div>
        </div>
      </div>
      <div className="horizontal">
        {/* Content for the second horizontal section */}
      </div>
    </div>
  );
}

export default SpotlightDetailsPage;
