import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './trendsshowfollowercount.css';

function TrendsShowFollowerCount() {
  const [portals, setPortals] = useState([]);
  const [selectedPortalId, setSelectedPortalId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const category = 'Hotel';
  const [followerCounts, setFollowerCounts] = useState([]);


  useEffect(() => {
    const apiUrl = '/trends/kfportaldetails';

    const fetchPortals = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPortals(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPortals();
  }, []);

  const handlePortalChange = (event) => {
    const selectedPortalId = event.target.value;
    setSelectedPortalId(selectedPortalId);
    passPortalIdToAnotherFunction(selectedPortalId);
  };

  const passPortalIdToAnotherFunction =  async(portalid) => {
    // This is where you handle the selected portal ID
    console.log(`Selected portal ID: ${portalid}`);

    try {
        

        const response = await axios.get('/trends/followercount', {
            params: {                
                portalid:portalid,
                category:category
            }
        });
        setFollowerCounts(response.data); // Assuming response.data is an array of follower counts


        console.log("response",response.data)
     
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    // Add your logic here to use the selected portal ID
  };

  
  if (loading) {
    return (
      <div className="trendsShowFollowerCount-loading-text">
        Loading<span className="trendsShowFollowerCount-loading-dots">...</span>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="trendsShowFollowerCount-container">
      <h1>Select a Portal</h1>
      <select onChange={handlePortalChange} defaultValue=""  className="trendsShowFollowerCount-select">
        <option value="" disabled>Select a portal</option>
        {portals.map((portal) => (
          <option key={portal.portalid} value={portal.portalid}>
            {portal.portalname}
          </option>
        ))}
      </select>
   
      <div className="follower-counts-list">
        {followerCounts.length > 0 ? (
          <ul>
            {followerCounts.slice(0, 20).map((follower, index) => (
              <li key={index}>
                <p className="title">{follower.VEND_TITL}</p>
                <p className="facebook-page">Facebook Page: <a href={follower.FB_PAGE_URL} target="_blank" rel="noopener noreferrer">{follower.FB_PAGE_URL}</a></p>
                <p className="followers-count">Facebook Followers Count: {follower.FB_FOLLOWER_COUNT}</p>
                <p className="facebook-page">Instagram Page:</p>
                <p className="followers-count">Instagram Followers Count:</p>
                <br /> {/* Optionally, you can apply CSS directly to <br /> tag */}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default TrendsShowFollowerCount;