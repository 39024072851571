import { React, useState, useEffect } from 'react'
import './style.css'
import axios from 'axios';
import PageLog from '../PageLog/PageLog';


const Restaurant = () => {

  let getPortal = sessionStorage.getItem("portalid")
  console.log("portal", getPortal);

  const [data, setData] = useState([])

  let id = sessionStorage.getItem("portalid", "****")




  useEffect(() => {
    axios.post('/citylocal/vendor/restarantdetails', { "id": getPortal })
      .then(function (response) {
        console.log("restarant details", response.data);
        setData(response.data)
      })
  }, []);




  return (
    <div className='total'>
      <PageLog/>

      <div className='dochead'>
        {/* <img   src="../images/restaurant/restaurant.png" /> */}
        <h6>
          {data.map((portal) => {
            return (
              <div key={portal.id}>
                <h3>Restaurant Name: {portal.VEND_TITL}</h3>
                <div>Restaurant Description: {portal.VEND_DET}</div>
                <span>Link: <a href={portal.VEND_URL}>{portal.VEND_URL}</a></span>
                <br /><br /><br />
              </div>

            )
          })}
        </h6>
      </div>
    </div>
  )
}

export default Restaurant
