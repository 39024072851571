import React from 'react'
import { Container, Nav, Navbar, Button, NavDropdown, Form, span } from 'react-bootstrap';


export default function HeaderContents() {
    let site = sessionStorage.getItem("site")
    if (site === "Myblock" || site === null) {
        return (
            <>      <NavDropdown title="Business" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/restaurant">Restaurant</NavDropdown.Item>
                <NavDropdown.Item href="/doctors">
                    Doctor
                </NavDropdown.Item>
                <NavDropdown.Item href="/heros">Heros</NavDropdown.Item>
                <NavDropdown.Item href="/organizations">Organizations</NavDropdown.Item>
                <NavDropdown.Divider />
            </NavDropdown>

                <NavDropdown title="Explore" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/Placetovisit">Place to visit</NavDropdown.Item>
                    <NavDropdown.Item href="/highlights">
                        Highlights
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/travel">Travel</NavDropdown.Item>
                    <NavDropdown.Divider />
                </NavDropdown></>
        )
    }
}
