import React from 'react';

const Table = ({ parameters, values, handleInputChange, procedureCategory }) => {

    console.log("parameters",parameters)
   

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Parameter Name</th>
                        <th>Units</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {parameters                      
                        .map(param => (
                            <tr key={param.name}>
                                <td>{param.name}</td>
                                <td>{param.units}</td>
                                <td>
                                {(param.name === 'Rain start time' || param.name === 'Rain end time') ? (
                                    <input
                                        type="datetime-local"
                                        value={values[param.name] || ''}
                                        onChange={e => handleInputChange(e, param.name)}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        value={values[param.name] || ''}
                                        onChange={e => handleInputChange(e, param.name)}
                                    />
                                )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            * all values are compulsary
        </div>
    );
};

export default Table;